import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: 60,
    userSelect: "none",
    msUserSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
  },
  content: {
    width: "100%",
    // maxWidth: "630px",
    padding: "0px 16px",
    marginTop:'19px',
    '@media(min-width: 960px)': {
      width: '90%',
    },
  },
  pageTitle: {
    color: theme.palette.neutrals.n80,
    fontWeight: "bold",
    fontSize: 20,
    margin: "20px 0px",
  },
  credit: {
    paddingRight: "20px",
    paddingLeft: "25px",
    paddingTop: "5px",
    paddingBottom: "5px",
    backgroundColor: theme.palette.warning.main,
    borderRadius: "8px",
    fontSize: "30px",
    color: theme.palette.neutrals.nf,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.warning.main,
    },
  },
  pageDesc: {
    color: theme.palette.neutrals.n80,
    fontSize: 12,
  },
  imBtn: {
    backgroundColor: theme.palette.neutrals.n0,
    color: theme.palette.primary.main,
    borderRadius: "8px",
    marginTop: 20,
    height: '100%'
  },
  imMenu: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px 10px",
    borderRadius: "8px",
    width: "100%",
  },
  imName: {
    fontSize: 18,
    fontWeight: 700,
    marginTop: 7,
    marginBottom: 0,
    textTransform: "none",
    textAlign: "left",
  },
  imDesc: {
    color: theme.palette.neutrals.n80,
    fontSize: 12,
    textAlign: "left",
    textTransform: "none",
    marginTop: 0,
  },
}));

export default useStyles;
