import React, { useEffect, useState } from "react";

// material-ui components
import Grid from "@material-ui/core/Grid";
// import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";

// import { useHistory } from "react-router-dom";
import html2canvas from "html2canvas";

// Styles
import useStyles from "./styles/index";

import results from "../../pages/tesMinatBakat/game/data/results.json";
import { useSiswa } from "../../components/SiswaProvider";
import { useFirebase } from "../../components/FirebaseProvider";
import { useTo } from "../../components/TryoutProvider";
import { useSnackbar } from "notistack";
import { dataURItoFile } from "../../utils/converter";
import share from "../../utils/share";

// Component
import noPic from "../../images/no-pic.svg";
import { HFHeader } from "../../components/AppHeaderNew";

function searchTMB(inp) {
  let out;
  if (inp === "E") {
    out = "Extrovert";
  }
  if (inp === "I") {
    out = "Introvert";
  }
  if (inp === "N") {
    out = "Intuition";
  }
  if (inp === "S") {
    out = "Sensing";
  }
  if (inp === "T") {
    out = "Thinking";
  }
  if (inp === "F") {
    out = "Feeling";
  }
  if (inp === "P") {
    out = "Perception";
  }
  if (inp === "J") {
    out = "Judgement";
  }

  return out;
}

function Bagikan() {
  //
  // const history = useHistory();
  //
  const classes = useStyles();
  //
  const { profile } = useSiswa();
  const { user } = useFirebase();
  const { hasiltryout, tryout } = useTo();
  //
  const { enqueueSnackbar } = useSnackbar();
  //
  const tmbSplit = profile?.hasil_tesMinatBakat?.split("");
  //
  const resultTMB =
    profile && profile.hasil_tesMinatBakat
      ? results[profile.hasil_tesMinatBakat]
      : "";
  //
  const [listTo, setListTo] = useState([]);
  //
  useEffect(() => {
    if (tryout) {
      const toDoc =
        hasiltryout.sort((a, b) => {
          return b.created_at.toMillis() - a.created_at.toMillis();
        }) || [];
      setListTo(
        tryout.map((doc) => {
          const to = toDoc.find((to) => to.tryout.id === doc.id);
          return {
            hasiltryout: to,
            ...doc,
          };
        })
      );
    }
  }, [tryout, hasiltryout]);
  //
  // const raport = {
  //   data: `
  //           ${profile && profile.nama}
  //           ${profile && profile.sekolah && profile.sekolah.nama}
  //           ${profile && profile.kampus ? profile.kampus.nama : ""}
  //           ${profile && profile.jurusan ? profile.jurusan.nama : ""}
  //           \b
  //           ${
  //             resultTMB &&
  //             `
  //           Tes Minat Bakat
  //           Kepribadian: ${profile && profile.hasil_tesMinatBakat}
  //           Keahlian: ${resultTMB.short}
  //           Kemampuan: ${resultTMB.kemampuan}
  //           `
  //           }
  //           ${listTo.map((item) => {
  //             return (
  //               item.hasiltryout &&
  //               item.hasiltryout.status === "done" &&
  //               `
  //                   \b
  //                   ${item.hasiltryout.tryout.nama}
  //           Hasil Jawaban Benar
  //           ${Object.entries(item.hasiltryout.hasil).map(([key, values]) => {
  //             return `${values.nama}: ${values.benar}
  //           `;
  //           })}
  //           `
  //             );
  //           })}
  //       `,
  // };
  //
  const shareBody = async (e) => {
    try {
      const canvas = await html2canvas(document.body, {
        allowTaint: false,
        useCORS: true,
        windowHeight: 900,
        windowWidth: 450,
      });
      // console.log(canvas.toDataURL())
      const file = dataURItoFile(canvas.toDataURL());
      //console.log(file);
      const shareData = {
        files: [file],
        title: "Karir.ai",
        text: "Cari Kerja Lebih Mudah & Cepat",
      };
      // const response = await share(shareData);
      await share(shareData);
      //  console.log(response)
    } catch (e) {
      enqueueSnackbar(e.message);
    }
  };

  //
  return (
    <div className={classes.root} >
      <HFHeader
        title="logo"
        pgDesc={false}
      />
      <Grid
        className={classes.page}
        container
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          className={classes.whiteBox}
        >
          <div className={classes.avatarCon}>
            <img
              alt=""
              src={user?.photoURL ? user?.photoURL : noPic}
              crossOrigin="anonymous"
              className={classes.avatarImg}
            />
          </div>
          <Typography className={classes.name}>{profile?.nama}</Typography>
          <Typography className={classes.txt1}>
            {profile?.sekolah?.nama}
          </Typography>
          {profile.kampus && (
            <Typography className={classes.txt1}>
              {profile && profile.kampus && profile.kampus.nama}
            </Typography>
          )}
          {profile.jurusan && (
            <Typography className={classes.txt1}>
              {profile && profile.jurusan && profile.jurusan.nama}
            </Typography>
          )}
        </Grid>
        {resultTMB && (
          <Grid
            item
            xs={12}
            container
            direction="column"
            className={classes.whiteBox}
          >
            <Typography
              className={`${classes.boxTitle} ${classes.centerTmb}`}
              color="primary"
            >
              Tes Minat Bakat
            </Typography>
            <Typography className={classes.tmbRes1}>
              {profile?.hasil_tesMinatBakat}
            </Typography>
            <Typography className={classes.tmbRes2}>
              {profile?.hasil_tesMinatBakat &&
                `${searchTMB(tmbSplit[0])}, ${searchTMB(
                  tmbSplit[1]
                )}, ${searchTMB(tmbSplit[2])}, & ${searchTMB(tmbSplit[3])}`}
            </Typography>
            <Typography className={classes.tmbRes3}>
              {resultTMB?.short}
            </Typography>
            <Typography className={classes.tmbRes3}>
              {resultTMB?.kemampuan}
            </Typography>
            <br />
            <Typography className={classes.tmbRes4}>
              Tokoh dengan kepribadian yang sama
            </Typography>
            <Typography className={classes.tmbRes3}>
              {resultTMB?.tokoh}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          container
          justifyContent="center"
          alignItems="flex-start"
          direction="column"
          className={classes.whiteBox}
        >
          <Typography className={classes.txt3}>
            Jangan sampai salah pilih karir, cari tahu karir impianmu di{" "}
            <Link href="https://karir.ai/">karir.ai</Link>. <br />
            <br /> Karir.ai - Aplikasi yang akan membantumu menemukan dan
            mendapatkan karir impianmu, lebih cepat dan lebih mudah!
          </Typography>
        </Grid>
        {listTo.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.hasiltryout && item.hasiltryout.status === "done" ? (
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="center"
                  alignItems="flex-start"
                  direction="column"
                  className={classes.whiteBox}
                  data-html2canvas-ignore
                >
                  <Typography className={classes.boxTitle} color="primary">
                    {item.hasiltryout.tryout.nama}
                  </Typography>
                  <Typography className={classes.txt2}>
                    Hasil Jawaban Benar
                  </Typography>
                  {Object.entries(item.hasiltryout.hasil).map(
                    ([key, values]) => {
                      return (
                        <div className={classes.liquidRow} key={key}>
                          <Typography className={classes.liquidLabel2}>
                            {values.nama}
                          </Typography>
                          <Typography className={classes.liquidValue}>
                            <span className={classes.mr5}>:</span>{" "}
                            <span>{values.benar}</span>
                          </Typography>
                        </div>
                      );
                    }
                  )}
                </Grid>
              ) : null}
            </React.Fragment>
          );
        })}
        <Grid item xs={12} className={classes.clrBox} style={{ margin: "auto", justifyContent: "center" }} data-html2canvas-ignore >
          {/* <Button
            size="small"
            variant="contained"
            color="secondary"
            className={classes.clrBtn}
            onClick={() => {
              shareRaport(raport.data.replace(/,/g, ""));
              history.push("/konsultasi/semua");
            }}
          >
            Konsultasi
          </Button> */}
          <Button
            size="small"
            variant="contained"
            color="primary"
            className={classes.clrBtn}
            onClick={shareBody}
            disableElevation
          >
            Bagikan
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export default Bagikan;
