// React
import React, { useCallback, useContext, useEffect } from "react";

// Firebase
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";

// Firebase Config
import firebaseConfig, { publicVapidKey } from "../config/firebase";

// Firebase Hooks
import { useAuthState } from "react-firebase-hooks/auth";
import localforage from "localforage";

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const facebookAuth = new firebase.auth.FacebookAuthProvider();
export const googleAuth = new firebase.auth.GoogleAuthProvider();
export const functions = firebase.app().functions("asia-southeast2"); //firebase.functions();
export const Arr = firebase.firestore.FieldValue;
let messagingSupported = null;

if (firebase.messaging.isSupported()) {
  messagingSupported = firebase.messaging();

  messagingSupported.getToken(publicVapidKey);
}

export const messaging = messagingSupported;

// React-Context
const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

function FirebaseProvider(props) {
  // messaging.usePublicVapidKey(publicVapidKey);

  const [user, loading, error] = useAuthState(auth);
  const uid = user?.uid

  useEffect(() => {
    if (uid) {
      // eslint-disable-next-line no-unused-expressions
      window.mixpanel?.identify(uid);
    }
  }, [uid]);

  const setLockedParamsToFirestore = useCallback(() => {
    // console.log(uid)
    if (uid) {
      // console.log(localforage)

      localforage.getItem('lockedParams').then(lockedParams => {
        // console.log(lockedParams)

        if (Object.keys(lockedParams).length > 0) {
          // console.log('gud')
          firestore.doc(`users/${uid}`).set({ ...lockedParams }, { merge: true })
        }
      })

    }
  }, [uid]);
  // useEffect(() => {
  //   if (user?.uid) {
  //     localforage.getItem('lockedParams').then(lockedParams => {
  //       if (lockedParams != null) {
  //         if (Object.keys(lockedParams).length > 0) {
  //           firestore.doc(`users/${user?.uid}`).set({ ...lockedParams }, { merge: true })
  //           // setDoc(doc(firestore, `users/${user?.data?.uid}`), { ...lockedParams }, { merge: true });
  //         }
  //       }
  //       })

  //     }

  // }, [user?.uid]);
  // console.log(user?.uid)


  return (
    <FirebaseContext.Provider
      value={{
        facebookAuth,
        googleAuth,
        firebase,
        messaging,
        Arr,
        auth,
        firestore,
        functions,
        user,
        loading,
        error,
        setLockedParamsToFirestore
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;
