import React, { useEffect, useState } from "react";

// Style
import useStyles from "./styles/index";

// Components
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import StyledBadge from "../../components/Badge";
import { baseURL } from "../../images/s3";
import { Rate } from "./Rating";

import { functions } from "../../components/FirebaseProvider";

function MitraProfession({ data, status }) {
  const profession = data;
  const classes = useStyles();

  switch (profession) {
    case "gurubk":
      return (
        <div className={classes.detailBox}>
          {/* <div className={classes.detailRow}>Asal Sekolah: SMKN 1 Bandung</div>
                <div className={classes.detailRow}>Asal Kota/Kab: Bandung</div> */}
          <div className={classes.detailRow}>
            {status || "Halo Saya Mitra PakBudi !"}
          </div>
        </div>
      );
    case "motivator":
      return (
        <div className={classes.detailBox}>
          {/* <div className={classes.detailRow}>Psikolog / Motivator</div>
                <div className={classes.detailRow}>Asal Kota/Kab: Bandung</div> */}
          <div className={classes.detailRow}>
            {status || "Halo Saya Mitra PakBudi !"}
          </div>
        </div>
      );
    case "mahasiswa":
      return (
        <div className={classes.detailBox}>
          {/* <div className={classes.detailRow}>Jurusan Kuliah: Kimia</div>
                <div className={classes.detailRow}>Asal PTN: ITB</div> */}
          <div className={classes.detailRow}>
            {status || "Halo Saya Mitra PakBudi !"}
          </div>
        </div>
      );
    default:
      return null;
  }
}

function ProfilMitra({
  open,
  handleOk,
  handleClose,
  nama_mitra,
  harga,
  mitra_type,
  url,
  online,
  status,
  rating,
  keterangan,
  isLoad,
  durasi,
  mitraId,
}) {
  const classes = useStyles();

  const [data, setData] = useState({
    loading: false,
    data: {},
  });

  const Online = online === false ? classes.offline : classes.online;

  let professions = null;

  let Txt = "Chat Sekarang";

  if (mitra_type === "gurubk") {
    professions = "Guru Bk";
  }
  if (mitra_type === "motivator") {
    professions = "Motivator";
  }
  if (mitra_type === "mahasiswa") {
    professions = "Mahasiswa";
  }
  if (isLoad === true) {
    Txt = "Loading...";
  }

  const result = ((rating ?? 0) / 5) * 100;

  useEffect(() => {
    const getRate = async () => {
      try {
        setData((prev) => ({
          ...prev,
          loading: true,
        }));

        const countMitraRate = functions.httpsCallable("countMitraRate");

        const data = await countMitraRate({
          mitra_id: mitraId,
        });

        setData((prev) => ({
          ...prev,
          loading: false,
          data: data?.data,
        }));
      } catch (error) {
        console.log(error);
        setData((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    if (mitraId) {
      getRate();
    }
  }, [mitraId]);

  return (
    <div className={classes.profilMitra}>
      <div className={classes.profilBody}>
        <Dialog
          classes={{ paper: classes.profilDialog }}
          onClose={isLoad === true ? null : handleClose}
          aria-labelledby="profile-dialog"
          open={open}
        >
          {/* <div className={classes.profilBackdrop}></div>
                    <div className={classes.profilBackdropTwo}></div> 
                    <DialogTitle disableTypography className={classes.centerText} id="dialog-title"> PakBudi</DialogTitle>*/}
          <DialogTitle className={classes.DialogTitle}>
            <span className={classes.pageName}>Mitra</span>
            <img
              className={classes.logoImg}
              src={`${baseURL}logo-full-w.svg`}
              alt=""
            />
          </DialogTitle>
          <DialogContent className={classes.profilWrap}>
            <div className={classes.profilBox}>
              <div className={classes.profilBoxRow}>
                <div className={classes.profilAvatarBody}>
                  <StyledBadge
                    overlap="circle"
                    classes={{ badge: Online }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    variant="dot"
                  >
                    <Avatar
                      className={classes.profilAvatar}
                      alt=""
                      src={url || `${baseURL}pakbudi-logo.png`}
                    />
                  </StyledBadge>
                </div>
              </div>
              <div className={classes.profilBoxRow}>
                <h3 className={classes.profilName}>{nama_mitra}</h3>
                <p className={classes.profilDesc}>
                  <span className={classes.profession}>{professions} - </span>
                  <span className={classes.description}>{keterangan}</span>
                </p>

                <div className={classes.rateBox}>
                  <Rate rating={rating} />
                </div>
                {!harga ? (
                  <span className={classes.priceTagNb}>Gratis</span>
                ) : (
                  <span className={classes.priceTagNb}>
                    {harga}{" "}
                    <img
                      className={classes.imgCoin}
                      src={`${baseURL}icon-coin.png`}
                      alt=""
                    />
                  </span>
                )}
                <span className={classes.priceTagNb} style={{ marginTop: 0 }}>
                  (durasi {durasi} menit)
                </span>
              </div>
              <div className={classes.mitraStats}>
                <div className={classes.statRow}>
                  <div className={classes.statLabel}>Respon</div>
                  <div className={classes.statValue}>
                    {data?.loading
                      ? "...."
                      : `${Math.round(data?.data?.response_rate ?? 0)}%`}
                  </div>
                </div>
                <div className={classes.statRow}>
                  <div className={classes.statLabel}>Result</div>
                  <div className={classes.statValue}>
                    {data?.loading ? "...." : `${Math.round(result)}%`}
                  </div>
                </div>
                <div className={classes.statRow}>
                  <div className={classes.statLabel}>Active</div>
                  <div className={classes.statValue}>
                    {data?.loading
                      ? "...."
                      : `${Math.round(data?.data?.active_rate ?? 0)}%`}
                  </div>
                </div>
              </div>
              <div className={classes.profilBoxRow}>
                <div className={classes.professionDetail}>
                  <MitraProfession data={mitra_type} status={status} />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <Button
              className={classes.btnNo}
              onClick={handleClose}
              disabled={data?.loading}
            >
              Tidak
            </Button>
            <Button
              onClick={handleOk}
              disabled={isLoad || data?.loading}
              color="primary"
              variant="contained"
              className={classes.btnChatNow}
            >
              {Txt}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

export default ProfilMitra;
