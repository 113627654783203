import React, { useContext } from "react";

import { useFirebase } from "./FirebaseProvider";
import { useCollectionData } from "react-firebase-hooks/firestore";
import AppLoading from "./AppLoading";
const NewsContext = React.createContext();

export function useNews() {
  return useContext(NewsContext);
}

function NewsProvider(props) {
  const { firestore } = useFirebase();
  const notifCollection = firestore.collection("news");
  const [newsItems, loadingItems] = useCollectionData(
    notifCollection.where("status", "==", "published"),
    { idField: "id" }
  );

  if (loadingItems) {
    return <AppLoading />;
  }

  return (
    <NewsContext.Provider
      value={{
        newsItems,
        loadingItems,
      }}
    >
      {props.children}
    </NewsContext.Provider>
  );
}

export default NewsProvider;
