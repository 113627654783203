import React from 'react';
import Button from '@material-ui/core/Button';
import {Dialog, DialogActions,DialogContent} from '@material-ui/core';
// import DialogTitle from '@material-ui/core/DialogTitle';
import useStyles from './styles/timeoutDialog';
import notice from '../../images/notice.svg';


export default function EndChatDialog({ open, handleClose, endChat }) {
    const classes = useStyles();
    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
        >
            {/* <DialogTitle className={classes.DialogTitle}>{"Mengakhiri Sesi"}</DialogTitle> */}
            <DialogContent>
                <img className={classes.noticeImgAlt} src={notice} alt="" />
                <p className={classes.endTxt}>Keluar dan akhiri konsultasi mu?</p>
            </DialogContent>
            <DialogActions className={classes.dialogActionEnd}>
                <Button
                    onClick={handleClose}
                    fullWidth
                    className={classes.btnDontEnd}
                >
                    Tidak
                </Button>
                <Button
                    fullWidth
                    onClick={endChat}
                    className={classes.btnEndChat}
                >
                    YA
                </Button>
                
            </DialogActions>
        </Dialog>
    );
}