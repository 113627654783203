import React, { useState } from 'react';

import { HFHeader } from '../../components/AppHeaderNew';
import useStyles from './styles';
import { useSiswa } from '../../components/SiswaProvider';
import BottomNav from '../../components/BottomNav';
import { Backdrop, Button, CircularProgress } from '@material-ui/core';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { functions, useFirebase } from "../../components/FirebaseProvider";
import { urlRiasec } from "../../config/url";
//  

function moreResult(inp) {
    let out;
    if (inp === "R") {
        out = "Realistic";
    }
    if (inp === "I") {
        out = "Investigative";
    }
    if (inp === "A") {
        out = "Artistic";
    }
    if (inp === "S") {
        out = "Social";
    }
    if (inp === "E") {
        out = "Enterprising";
    }
    if (inp === "C") {
        out = "Conventional";
    }

    return out;
}

function RiasecResult(props) {
    const classes = useStyles();
    const { user } = useFirebase()
    const { riasecData } = useSiswa();
    const [loading, setLoading] = useState(false);
    const code = riasecData?.result?.report[0]?.value?.split("");

    // const resultJson = riasecData?.result && JSON.parse(riasecData?.result)

    // const urlDownload = resultJson?.urlReport && Object.values(resultJson?.urlReport);
    // console.log(riasecData)
    // const { user } = useFirebase();
    // const { enqueueSnackbar } = useSnackbar();
    // const params = useParams();


    // if (loading || load || loadsnap || loadKateg) {
    //     return (
    //         <Container maxWidth="xs">
    //             <CircularProgres style={{ marginTop: '90%' }} />
    //         </Container>
    //     )
    // }


    // reasec internal
    const handleSubmitRiasec = async (e) => {
        setLoading(true)
        try {
            const getTokenReasec = functions.httpsCallable("user-reqCustomToken")
            const response = await getTokenReasec()
            // console.log(response)
            if (response?.data?.customToken) {
                // eslint-disable-next-line no-unused-expressions
                window?.mixpanel?.track("Get Token Riasec Button Clicked", {
                    userId: user?.uid,
                    tempUID: user?.tempUID,
                })
                window.location.href = `${urlRiasec}/${response?.data?.customToken}`
            }
        } catch (e) {
            console.log(e.message)
        }
        setLoading(false)
    }

    console.log(user)

    return (
        <>
            <Backdrop style={{ zIndex: 1000 }} open={loading}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <div className={classes.root}>


                <HFHeader title="logo" pgDesc={false} />
                {/* <img
                    src={`https://auto.karir.ai/mtracking.gif?page_title=dashboard_riasec_result&email=${profile?.email}`} alt=''
                /> */}
                <div className={classes.cover}>
                    <p className={classes.title}>Hasil Quiz Minat Karir</p>
                    <div className={classes.item1}>
                        <p style={{ fontSize: '18px', color: '#008000', fontWeight: "bold" }}>Test Minat Karir : {riasecData?.result?.report?.[0]?.value} ({moreResult(code[0])} - {moreResult(code[1])} - {moreResult(code[2])})</p>
                        {/* <ul>
                            {
                                riasecData?.result?.report?.map((res) => {
                                    return <li >{res}</li>
                                })
                            }
                        </ul> */}
                        {/* <p></p> */}
                    </div>
                    <div className={classes.item2}>
                        <p style={{ fontSize: '18px', color: '#FFA500', fontWeight: "bold" }}>List Pekerjaan RIASEC</p>
                        <p>Paling Sesuai</p>
                        <ul>
                            {/* <li>{riasecData?.result?.report?.[2]?.value?.dipertimbangkan}</li> */}
                            {
                                riasecData?.result?.report?.[2]?.value?.paling_sesuai?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p>Dipertimbangkan</p>
                        <ul>
                            {/* <li>{riasecData?.result?.report?.[2]?.value?.dipertimbangkan}</li> */}
                            {
                                riasecData?.result?.report?.[2]?.value?.dipertimbangkan?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={classes.item3}>
                        <p style={{ fontSize: '18px', color: '#357EC7', fontWeight: "bold" }}>Definisi Minat Karir</p>
                        <p>{riasecData?.result?.report?.[3]?.value}</p>
                        <ul>
                            {
                                // resultJson?.uraian?.area_pengembangan?.map((res) => {
                                //     return <li>{res}</li>
                                // })
                            }
                        </ul>
                    </div>
                    <div className={classes.item4}>
                        <p style={{ fontSize: '18px', color: '#E7A1B0', fontWeight: "bold" }}>Penilaian Diri CPA</p>
                        <p>{moreResult(code[0])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[4]?.value?.[moreResult(code[0])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p>{moreResult(code[1])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[4]?.value?.[moreResult(code[1])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p>{moreResult(code[2])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[4]?.value?.[moreResult(code[2])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={classes.item2}>
                        <p style={{ fontSize: '18px', color: '#FFA500', fontWeight: "bold" }}>Stereotipe CPA</p>
                        <p>{moreResult(code[0])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[5]?.value?.[moreResult(code[0])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p>{moreResult(code[1])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[5]?.value?.[moreResult(code[1])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p>{moreResult(code[2])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[5]?.value?.[moreResult(code[2])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={classes.item4}>
                        <p style={{ fontSize: '18px', color: '#E7A1B0', fontWeight: "bold" }}>Value CPA</p>
                        <p>{moreResult(code[0])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[6]?.value?.[moreResult(code[0])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p>{moreResult(code[1])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[6]?.value?.[moreResult(code[1])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p>{moreResult(code[2])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[6]?.value?.[moreResult(code[2])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={classes.item3}>
                        <p style={{ fontSize: '18px', color: '#357EC7', fontWeight: "bold" }}>Tujuan Hidup CPA</p>
                        <p>{moreResult(code[0])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[7]?.value?.[moreResult(code[0])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p>{moreResult(code[1])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[7]?.value?.[moreResult(code[1])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p>{moreResult(code[2])}</p>
                        <ul>
                            {
                                riasecData?.result?.report?.[7]?.value?.[moreResult(code[2])]?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                    </div>
                    <div className={classes.item2}>
                        <p style={{ fontSize: '18px', color: '#FFA500', fontWeight: "bold" }}>Keterampilan CPA</p>
                        {/* <p>{}</p> */}
                        <ul>
                            <li>{riasecData?.result?.report?.[8]?.value?.[0]}</li>
                            <li>{riasecData?.result?.report?.[8]?.value?.[1]}</li>
                            <li>{riasecData?.result?.report?.[8]?.value?.[2]}</li>

                        </ul>
                    </div>
                    <div className={classes.item3}>
                        <p style={{ fontSize: '18px', color: '#357EC7', fontWeight: "bold" }}>Kemampuan Terbesar CPA</p>
                        {/* <p>{}</p> */}
                        <ul>
                            <li>{riasecData?.result?.report?.[9]?.value?.[0]}</li>
                            <li>{riasecData?.result?.report?.[9]?.value?.[1]}</li>
                            <li>{riasecData?.result?.report?.[9]?.value?.[2]}</li>

                        </ul>
                    </div>
                    <div style={{ margin: '30px 16px', display: "flex", flexDirection: "row-reverse", gap: 10 }}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={() => {
                                window.open(`${riasecData?.result?.reportUrl?.pdf}`)
                            }}
                            size="large"
                            style={{ padding: "10px", fontSize: "13px", fontWeight: "bold" }}
                        >
                            {/* <CloudDownloadIcon style={{ marginRight: '8px' }} /> */}
                            Download Full PDF
                        </Button>
                        <Button
                            fullWidth
                            variant="outlined"
                            color="primary"
                            disableElevation
                            onClick={handleSubmitRiasec}
                            size="large"
                            style={{ padding: "10px", fontSize: "13px", fontWeight: "bold" }}
                        >
                            Kerjakan Ulang
                        </Button>
                    </div>
                </div>

            </div >
            <BottomNav />
        </>
    );

}

export default RiasecResult;