// React
import React from 'react';

// Styles
import useStyles from './styles';

function PrivacyPolicy() {
    // UseStyles
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.tcBody}>
                <div className={classes.pageHeading}>
                    <h2 className={classes.pageTitle}><strong>Privacy Policy for Karir.ai</strong></h2>
                </div>
                <div className={classes.pageContent}>
                    
                </div>
            </div>
        </div>
    )
}

export default PrivacyPolicy;