import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper } from '@material-ui/core';
import Draggable from 'react-draggable';
import { firestore } from './FirebaseProvider';
import { useDocumentData } from 'react-firebase-hooks/firestore';

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

export default function GuideDialog({ open, handleClose, content, idTutorial }) {

    // get data
    // let q = query(
    //     collection(firestore, 'tutorial'),
    //     where("label", "==", content)
    // );

    // const { data: list, isLoading } = useFirestoreQueryData(
    //     [
    //         "tutorial",
    //         {
    //             label: content,
    //         },
    //     ],
    //     q,
    //     { subscribe: true, idField: "id" }
    // );

    const ref = firestore.doc(`tutorial/dashboard_${idTutorial}`);

    const [dataTutor] = useDocumentData(ref);




    // console.log(item)
    // const [open, setOpen] = React.useState(false);



    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (

        <Dialog
            open={open}
            onClose={handleClose}
            disableEnforceFocus // Allows other things to take focus
            hideBackdrop  // Hides the shaded backdrop

            // disableBackdropClick  // Prevents backdrop clicks
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            maxWidth='xs'
            style={{
                // top: '30%', // Position however you like
                // left: '30%',
                height: 'fit-content',  // Ensures that the dialog is 
                width: 'fit-content',   // exactly the same size as its contents
            }}
            disableScrollLock={true}
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {content}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>


                    <div
                        dangerouslySetInnerHTML={{ __html: dataTutor?.src }}
                    >

                        {/* <iframe title="pengalaman kerja" src="https://player.vdocipher.com/v2/?otp=20160313versASE323KQl0K0jWbz6azug7Q5lq5pFORdRFLTuWq050i5mIVwM5Xw&playbackInfo=eyJ2aWRlb0lkIjoiYjFiNDE1NzhmYTk2NGM4YjhjMjE5MTBhODU0ZmRhZjgifQ==" style={{ border: 0, maxWidth: '100%', position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', }} allowFullScreen="true" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe> */}
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
                {/* <Button onClick={handleClose}>Subscribe</Button> */}
            </DialogActions>
        </Dialog>
    );
}