import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// import * as serviceWorker from './serviceWorker';
import SwProvider from "./components/SwProvider";
import Update from "./components/Dialog/Update";
// material-ui themes
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import theme from "./theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from "notistack";
import FirebaseProvider from "./components/FirebaseProvider";

const modTheme = createTheme(theme);

ReactDOM.render(
  <SwProvider>
    <React.Fragment>

      <MuiThemeProvider theme={modTheme}>
        <CssBaseline />
        {/* <DownloadApp> */}
        <div className="App">
          <div className="Content">
            <SnackbarProvider maxSnack={1} autoHideDuration={3000}>
              <FirebaseProvider>
                <App />
              </FirebaseProvider>
            </SnackbarProvider>
          </div>
        </div>
        {/* </DownloadApp> */}
      </MuiThemeProvider>
    </React.Fragment>
    <Update />
  </SwProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
//

// navigator.serviceWorker.getRegistrations().then(function(registrations) {
//     for(let registration of registrations) {
//         if(!registration.scope.includes('firebase-cloud-messaging-push-scope')){
//             registration.unregister();
//         }
//    } })
