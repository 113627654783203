// React
import React from "react";
import "./App.css";

// Router
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// material-ui themes
// import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
// import theme from "./theme";
// import CssBaseline from "@material-ui/core/CssBaseline";

// Private Route
import PrivateRoute from "./components/PrivateRoute";

// Firebase Provider
// import FirebaseProvider, { useFirebase } from "./components/FirebaseProvider";

// Components Pages
import LayoutA from "./pages/layoutA";
import LayoutB from "./pages/layoutB";
import LayoutC from "./pages/layoutC";
import Landing from "./pages/landing";
import Login from "./pages/login";
import Checkout from "./pages/checkout";
import LoginViaLink from "./pages/LoginViaLink";
import LoginByToken from "./pages/LoginViaLink/token";
import Register from "./pages/register";
import LupaPassword from "./pages/lupaPassword";
import NotFound from "./pages/notFound";
import TesMinatBakat from "./pages/tesMinatBakat";
import Chat from "./pages/chat/chatWindow";
import PublicBerita from "./pages/berita/public";
import Restricted from "./pages/restricted";
import TryOut from "./pages/tryout";
import HasilTryout from "./pages/home/hasilTryout";
import Esport from "./pages/esport/index";
import FormPendaftaran from "./pages/acara/formPendaftaran";
import EventIntro from "./pages/acara/intro";
import FormPembayaran from "./pages/acara/formPembayaran";
import KonfirmasiPembayaran from "./pages/acara/konfirmasiPembayaran";
import EventProcess from "./pages/acara/proses";
import Home from "./pages/home";
import Assessment from "./pages/assessment";
// import PBLeaderboard from "./pages/leaderboard";

// import DownloadApp from "./components/DownloadApp";

// Notification
// import { SnackbarProvider } from "notistack";
// import queryString from 'query-string';
// import { getData, setData } from "./utils/localforage";
import PersonaResult from "./pages/personaTestResult";
// import localforage from "localforage";
import AjakTeman from "./pages/ajakTeman";
import QueryPersist from "./hooks/useQueryPersist";
import RiasecResult from "./pages/riasecResult";
import CheckoutCredit from "./pages/myCredit/checkout";
import Course from "./pages/course";
// Theme
// const modTheme = createTheme(theme);

function App() {
  // let location = useLocation();

  // console.log(param, value)
  QueryPersist()
  return (
    // <React.Fragment>
    //   <CssBaseline />
    //   <MuiThemeProvider theme={modTheme}>
    //     {/* <DownloadApp> */}
    <div className="App">
      <div className="Content">
        {/* <SnackbarProvider maxSnack={1} autoHideDuration={3000}>
              <FirebaseProvider> */}
        <Router>
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/login" component={Login} />
            <Route path="/checkout/:slug?" component={Checkout} />
            <Route path="/login-via-link" component={LoginViaLink} />
            <Route
              path="/login-by-token/:token" exact
              component={LoginByToken}
            />
            <Route
              path="/login-by-token/:token/:menu"
              component={LoginByToken}
            />
            <Route path="/register" component={Register} />
            <Route path="/lupaPassword" component={LupaPassword} />
            <Route path="/syaratKetentuan" component={LayoutA} />
            <Route path="/privacyPolicy" component={LayoutA} />
            <Route
              exact
              path="/acara/:eventId"
              component={EventIntro}
            />
            <Route
              path="/acara/:eventId/daftar"
              component={FormPendaftaran}
            />
            <Route
              path="/acara/:eventId/pembayaran/:transaksiId"
              component={FormPembayaran}
            />
            <Route
              path="/acara/:eventId/konfirmasi/:transaksiId"
              component={KonfirmasiPembayaran}
            />
            <Route
              path="/acara/:eventId/proses/:transaksiId"
              component={EventProcess}
            />
            <PrivateRoute
              exact
              path="/acara/u/:eventId"
              component={LayoutC}
            />
            <PrivateRoute
              path="/acara/u/:eventId/daftar"
              component={LayoutC}
            />
            <PrivateRoute
              path="/acara/u/:eventId/pembayaran/:transaksiId"
              component={LayoutC}
            />
            <PrivateRoute
              path="/acara/u/:eventId/konfirmasi/:transaksiId"
              component={LayoutC}
            />
            <PrivateRoute
              path="/acara/u/:eventId/lengkapi-data/:pesertaId"
              component={LayoutC}
            />
            <PrivateRoute
              path="/acara/ticket/:eventId/:pesertaId"
              component={LayoutC}
            />
            <PrivateRoute path="/home" component={Home} />
            <PrivateRoute path="/notifikasi" component={LayoutC} />
            <PrivateRoute path="/konsultasi" component={LayoutB} />
            <PrivateRoute path="/chat" component={LayoutB} />
            <PrivateRoute path="/myChat/:chatId" component={Chat} />
            <PrivateRoute path="/assessment" component={Assessment} />
            <PrivateRoute path="/course" component={Course} />

            <PrivateRoute path="/settings" component={LayoutC} />
            <PrivateRoute path="/vouchers" component={LayoutC} />
            <PrivateRoute path="/editprofile" component={LayoutC} />
            <PrivateRoute
              path="/notifikasisettings"
              component={LayoutC}
            />
            <PrivateRoute path="/myCredit" component={LayoutC} />
            <PrivateRoute path="/topUp" component={LayoutC} />
            <PrivateRoute
              path="/topUpCheckout/:no_transaksi"
              component={CheckoutCredit}
            />
            <PrivateRoute path="/topUpHistory" component={LayoutC} />
            <PrivateRoute path="/verifikasi" component={LayoutA} />
            <PrivateRoute path="/lengkapiData" component={LayoutA} />
            <PrivateRoute path="/harapan" component={LayoutA} />
            <PrivateRoute
              path="/ubahemaildanpassword"
              component={LayoutC}
            />
            <PrivateRoute path="/article/:id" component={LayoutC} />
            <Route path="/warta" component={PublicBerita} />
            {/* <PrivateRoute
              path="/beritaDetail/:id"
              component={LayoutC}
            /> */}
            <PrivateRoute
              path="/tesminatbakat"
              component={TesMinatBakat}
            />
            <PrivateRoute
              path="/tesminatbakat/game"
              component={TesMinatBakat}
            />
            <PrivateRoute
              path="/tesminatbakat/result/:symbol"
              component={TesMinatBakat}
            />
            <PrivateRoute path="/bagikan" component={LayoutC} />
            <PrivateRoute
              path="/tryout/:hasil_tryout_id"
              component={TryOut}
            />
            <PrivateRoute
              path="/hasiltryout/:to_id"
              component={HasilTryout}
            />
            <PrivateRoute path="/misi" component={LayoutC} />
            <PrivateRoute path="/listmisi" component={LayoutC} />
            <PrivateRoute
              path="/konfirmasimisi/:id"
              component={LayoutC}
            />
            <PrivateRoute path="/leaderboard/:to_id" component={LayoutC} />
            <PrivateRoute path="/esport" component={Esport} />
            <PrivateRoute path="/personaTestResult" component={PersonaResult} />
            <PrivateRoute path="/riasecResult" component={RiasecResult} />
            <PrivateRoute path="/ajakTeman" component={AjakTeman} />
            <Route path="/restricted" component={Restricted} />

            <Route render={() => <NotFound />} />
          </Switch>
        </Router>
        {/* </FirebaseProvider>
            </SnackbarProvider> */}
      </div>
    </div>
    //     {/* </DownloadApp> */}
    //   </MuiThemeProvider>
    // </React.Fragment>
  );
}

export default App;
