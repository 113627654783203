import { useEffect } from "react";
import localforage from "localforage";
import queryString from 'query-string';
import { useFirebase } from "../components/FirebaseProvider";

export default function QueryPersist() {
    const { setLockedParamsToFirestore } = useFirebase();

    const param = queryString.parse(window.location.search)
    // const value = Object.values(queryString.parse(window.location.search))?.[0]
    // console.log(queryString.parse(window.location.search))
    useEffect(() => {
        async function storeUTMData() {
            let params = {}
            // if (param && value && setLockedParamsToFirestore) {
            for (const property in param) {
                // const [paramId, value] = property;
                // console.log(`${property}: ${param[property]}`)
                if (property?.startsWith('utm_') || property?.startsWith('ref')) {
                    params[property] = param[property]
                }
            }

            // console.log(params)
            localforage.getItem('lockedParams').then((currVal) => {
                if (!currVal) {
                    localforage.setItem('lockedParams', params).then(() => {
                        setLockedParamsToFirestore();
                    });
                } else {
                    const { ref_id, utm_source, ...restParams } = params;
                    let updatedParams = { ...currVal, ...restParams };

                    // dont update ref_id if already there
                    if (!currVal.ref_id && ref_id) {
                        updatedParams['ref_id'] = ref_id;
                    }
                    // dont update utm_source if already there
                    if (!currVal.utm_source && utm_source) {
                        updatedParams['utm_source'] = utm_source;
                    }
                    localforage.setItem('lockedParams', updatedParams).then(() => {
                        setLockedParamsToFirestore();
                    });

                }

            })
            // const utm_source = await getData('utm_source');
            // console.log(utm_source, 'hiuhi')
            // if (!utm_source) {

            //   const qs = queryString.parse(window.location.search);

            //   if (qs.utm_source) {
            //     await setData('utm_source', qs.utm_source);
            //   }
            // }

            // }

        }
        storeUTMData();
    }, [param, setLockedParamsToFirestore]);
}