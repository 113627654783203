// React
import React, { useState } from "react";

// Styles
import useStyles from "./styles";

// List
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import Button from "@material-ui/core/Button";

// Divider
import Divider from "@material-ui/core/Divider";

// Icon
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
// import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

// Router
import { Redirect } from "react-router-dom";

// Component
import AlertDialogSlide from "../../components/Dialog";
import LaporMasalah from "./LaporMasalah";

// Utils
import { useSiswa } from "../../components/SiswaProvider";
import IcoProfile from "../../images/ico-profile.svg";
// import IcoEmail from "../../images/ico-email.svg";
import IcoVoucher from "../../images/ico-voucher.svg";
import IcoNotif from "../../images/ico-notif.svg";
import { HFHeader } from "../../components/AppHeaderNew";
import { useFirebase } from "../../components/FirebaseProvider";
import TopNav from "../../components/TopNav";
import { Box, Hidden } from "@material-ui/core";

function Settings(props) {
  //
  const { setPageEvent } = useSiswa();

  // UseStyles
  const classes = useStyles();

  // firebase
  const { user } = useFirebase();
  // State
  const [selectedIndex, setSelectedIndex] = useState();

  // OnClick
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  // const handleClick = () => {
  //   props.history.goBack();
  // };

  setPageEvent("settings");

  // Condition
  if (selectedIndex === 0) {
    return <Redirect to="/editprofile" />;
  }
  if (selectedIndex === 1) {
    return <Redirect to="/ubahemaildanpassword" />;
  }
  if (selectedIndex === 2) {
    return <Redirect to="/vouchers" />;
  }
  if (selectedIndex === 3) {
    return <Redirect to="/notifikasisettings" />;
  }

  return (
    <div className={classes.root}>
      {/* <img
        src={`https://auto.karir.ai/mtracking.gif?page_title=dashboard_settings&email=${profile?.email}`} alt=''
      /> */}
      {/* <HFHeader
        title="Setelan"
        pgDesc={false}
      /> */}
      <HFHeader title="logo" pgDesc={false} noBorder={true} />
      {/* <div className={classes.pageHeading}>
        <Button className={classes.historyBack} onClick={handleClick}>
          <ChevronLeftIcon />
        </Button>
        <h2 className={classes.pageTitle}>Setelan</h2>
      </div> */}
      
      <div className={classes.settingBody}>
        <Hidden mdUp>
          <Box style={{ marginBottom: '20px' }}><TopNav /></Box>
        </Hidden>
        <div className={classes.settingBodyInner}>
          <p className={classes.pageTitle}>Setelan</p>
          <List
            className={classes.settingList}
            component="nav"
            aria-label="main mailbox folders"
          >
            <ListSubheader className={classes.subHeader}>Akun</ListSubheader>
            <ListItem
              button
              selected={selectedIndex === 0}
              onClick={(event) => {
                // eslint-disable-next-line no-unused-expressions
                window?.mixpanel?.track(" Settings - edit profile Button Clicked", {
                  userId: user?.uid,
                  tempUID: user?.tempUID,
                })
                handleListItemClick(event, 0)
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <img src={IcoProfile} alt="" />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Edit Profil"
              />
              <ListItemSecondaryAction>
                <IconButton
                  className={classes.iconBtn}
                  edge="end"
                  disabled
                  aria-label="Edit Profil"
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            {/* <ListItem
                          button
                          selected={selectedIndex === 1}
                          onClick={event => handleListItemClick(event, 1)}
                          
                      >
                          <ListItemIcon classes={{root:classes.listIcon}}>
                              <img src={IcoEmail} alt="" />
                          </ListItemIcon>
                          <ListItemText classes={{primary: classes.listItemText}} primary="Ubah Email Dan Kata Sandi" />
                          <ListItemSecondaryAction>
                              <IconButton className={classes.iconBtn} edge="end" disabled aria-label="Ubah Email Dan Kata Sandi">
                                  <KeyboardArrowRightIcon />
                              </IconButton>
                          </ListItemSecondaryAction>
                      </ListItem> */}

            <ListItem
              button
              selected={selectedIndex === 2}
              onClick={(event) => {
                // eslint-disable-next-line no-unused-expressions
                window?.mixpanel?.track(" Settings - redeem voucher Button Clicked", {
                  userId: user?.uid,
                  tempUID: user?.tempUID,
                })
                handleListItemClick(event, 2)
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <img src={IcoVoucher} alt="" />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Redeem Voucher"
              />
              <ListItemSecondaryAction>
                <IconButton
                  className={classes.iconBtn}
                  edge="end"
                  disabled
                  aria-label="Redeem Voucher"
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListSubheader className={classes.subHeader}>Setelan</ListSubheader>
            <ListItem
              button
              selected={selectedIndex === 3}
              onClick={(event) => {
                // eslint-disable-next-line no-unused-expressions
                window?.mixpanel?.track(" Settings - notifikasi Button Clicked", {
                  userId: user?.uid,
                  tempUID: user?.tempUID,
                })
                handleListItemClick(event, 3)
              }}
            >
              <ListItemIcon classes={{ root: classes.listIcon }}>
                <img src={IcoNotif} alt="" />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Notifikasi"
              />
              <ListItemSecondaryAction>
                <IconButton
                  className={classes.iconBtn}
                  edge="end"
                  disabled
                  aria-label="Notifikasi"
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListSubheader className={classes.subHeader}>Tentang</ListSubheader>
            <LaporMasalah />

            <AlertDialogSlide />
          </List>
          </div>
      </div>
    </div>
  );
}

export default Settings;
