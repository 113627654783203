import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function DashboardIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 20">
      <path d="M6.7 8.9H2.2C1 8.9 0 7.9 0 6.7V2.2C0 1 1 0 2.2 0H6.6C7.8 0 8.8 1 8.8 2.2V6.6C8.9 7.9 7.9 8.9 6.7 8.9ZM2.2 2.2V6.6H6.6V2.2H2.2Z" />
      <path d="M17.7992 8.9H13.3992C12.1992 8.9 11.1992 7.9 11.1992 6.7V2.2C11.1992 1 12.1992 0 13.3992 0H17.7992C18.9992 0 19.9992 1 19.9992 2.2V6.6C19.9992 7.9 18.9992 8.9 17.7992 8.9ZM13.2992 2.2V6.6H17.6992V2.2H13.2992Z" />
      <path d="M6.7 20H2.2C1 20 0 19 0 17.8V13.4C0 12.2 1 11.2 2.2 11.2H6.6C7.8 11.2 8.8 12.2 8.8 13.4V17.8C8.9 19 7.9 20 6.7 20ZM2.2 13.3V17.7H6.6V13.3H2.2V13.3Z" />
      <path d="M17.7992 20H13.3992C12.1992 20 11.1992 19 11.1992 17.8V13.4C11.1992 12.2 12.1992 11.2 13.3992 11.2H17.7992C18.9992 11.2 19.9992 12.2 19.9992 13.4V17.8C19.9992 19 18.9992 20 17.7992 20ZM13.2992 13.3V17.7H17.6992V13.3H13.2992V13.3Z" />
    </SvgIcon>
  );
}
