import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // index
  root: {
    flexGrow: 1,
    userSelect: "none",
    msUserSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
  },
  profileBg: {
    width: "100%",
    top: 0,
    zIndex: 3,
    position: "sticky",
    backgroundColor: theme.palette.primary.main,
    paddingTop: "20px",
    paddingBottom: "1px",
    borderBottomLeftRadius: "15px",
    borderBottomRightRadius: "15px",
  },
  page: {
    margin: "0 auto 10px !important",
    position: "relative",
    "@media(min-width: 320px)": {
      width: "95% !important",
    },
    "@media(min-width: 560px)": {
      width: "80% !important",
    },
    "@media(min-width: 960px)": {
      width: "70% !important",
    },
    "@media(min-width: 1100px)": {
      width: "50% !important",
    },
  },
  page2: {
    margin: "0 auto 90px !important",
    position: "relative",
    "@media(min-width: 320px)": {
      width: "95% !important",
    },
    "@media(min-width: 560px)": {
      width: "80% !important",
    },
    "@media(min-width: 960px)": {
      width: "70% !important",
    },
    "@media(min-width: 1100px)": {
      width: "50% !important",
    },
  },
  fab: {
    position: "absolute",
    top: 4,
    right: 10,
  },
  profileImg: {
    margin: "15px auto",
    width: "100px !important",
    height: "100px !important",
    pointerEvents: "none",
  },
  username: {
    margin: "5px 0",
    fontSize: 20,
    letterSpacing: 4,
    textTransform: "uppercase",
    fontWeight: 700,
    color: theme.palette.textColor.white,
  },
  profile: {
    margin: "0",
    fontSize: 13,
    fontWeight: 300,
    color: theme.palette.textColor.white,
  },
  editBtnCon: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 10,
  },
  editBtn: {
    textTransform: "capitalize",
    fontSize: 10,
    fontWeight: 600,
    backgroundColor: theme.palette.darkBlue.main,
    "&:hover": {
      backgroundColor: "#166DBD",
    },
    color: theme.palette.textColor.white,
  },
  progressTitle: {
    fontWeight: 600,
    color: theme.palette.darkBlue.main,
    fontSize: 16.67,
  },
  missionBtn: {
    fontWeight: 600,
    fontSize: 16.67,
    backgroundColor: "#FDED1F",
    "&:hover": {
      backgroundColor: "#FDF141",
    },
    color: theme.palette.darkBlue.main,
    padding: 0,
    borderBottom: "3px solid #1461A8",
  },
  box: {
    backgroundColor: theme.palette.textColor.white,
    padding: 10,
    borderRadius: 10,
  },
  boxTitle: {
    fontWeight: 600,
    color: theme.palette.darkBlue.main,
    fontSize: 16.67,
  },
  boxTxt: {
    fontStyle: "italic",
    fontWeight: 400,
    color: theme.palette.textColor.gray8,
    fontSize: 12,
  },
  boxTxt2: {
    color: theme.palette.textColor.gray8,
    fontWeight: 500,
    fontSize: 13.33,
  },
  boxTxt3: {
    fontStyle: "italic",
    fontWeight: 400,
    color: theme.palette.darkBlue.main,
    fontSize: 12,
  },
  boxTxt4: {
    color: theme.palette.textColor.gray6,
    fontWeight: 500,
    fontSize: 13.33,
  },
  boxTxt5: {
    fontWeight: "bold",
    fontSize: 13.33,
  },
  boxTxt6: {
    color: theme.palette.textColor.gray8,
    fontWeight: "bold",
    fontSize: 10,
  },
  boxTxt7: {
    fontStyle: "italic",
    color: theme.palette.textColor.gray8,
    fontSize: 10,
  },
  cta: {
    borderRadius: 100,
    padding: "2px 10px",
    fontWeight: 600,
  },
  linkTo: {
    width: "100%",
    borderRadius: 10,
  },
  divider: {
    margin: "10px 0px !important",
  },

  // listTryout
  box2: {
    backgroundColor: theme.palette.textColor.gray,
    padding: 10,
    borderRadius: 10,
  },
  boxTitle2: {
    fontWeight: 600,
    color: theme.palette.textColor.white,
    fontSize: 13.33,
  },
  boxTxt8: {
    color: theme.palette.textColor.gray9,
    fontSize: 10,
  },
  cta2: {
    fontWeight: 600,
    borderRadius: 100,
    padding: "2px 10px",
    color: "#FFF",
  },
  cta3: {
    fontWeight: 600,
    borderRadius: 100,
    padding: "2px 10px",
    color: theme.palette.textColor.gray9,
  },
  cta4: {
    fontWeight: 600,
    borderRadius: 100,
    padding: "2px 10px",
    color: "#FFF",
    backgroundColor: "#D72020",
    "&:hover": {
      backgroundColor: "#B71B1B",
    },
  },

  // DialogTransaksiTo & DialogTo
  DialogTitle: {
    fontSize: 18,
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  content: {
    padding: "0px 24px",
  },
  ListItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  dtxt1: {
    fontSize: 13.33,
    fontWeight: 400,
    color: theme.palette.textColor.gray,
  },
  dtxt2: {
    fontSize: 13.33,
    fontWeight: 550,
    color: theme.palette.textColor.gray8,
  },
  dtxt3: {
    fontSize: 12,
    color: theme.palette.textColor.gray8,
  },
  logoWrap: {
    position: "relative",
    width: 180,
    margin: "auto",
  },
  mainLogo: {
    marginTop: 5,
    width: 150,
  },
  pageName: {
    position: "absolute",
    color: theme.palette.textColor.white,
    fontSize: 12,
    top: -5,
    left: 56,
    textTransform: "capitalize",
    fontStyle: "italic",
  },
}));

export default useStyles;
