import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/index';
import AppHeader from '../../components/AppHeader';
import { Switch, Route } from 'react-router-dom';
import ListBerita from './list';
import BeritaDetail from './beritaDetail';
// 
function PublicBerita(props) {
    const { classes } = props;


    return (
        <div className={classes.rootMain}>
            <div className={classes.blueBg}></div>
            <div className={classes.appBody}>

                <div className={classes.root}>
                    <AppHeader />

                    <Switch>
                        <Route path="/warta/:id/:slug" component={BeritaDetail} />
                        <Route component={ListBerita} />
                    </Switch>

                </div>
            </div>
        </div>
    );

}

export default withStyles(styles)(PublicBerita);