import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles/index';
// import AppHeader from '../../components/AppHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
// import IconButton from '@material-ui/core/IconButton';
// import ShareIcon from '@material-ui/icons/Share';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import Button from '@material-ui/core/Button';
import CircularProgres from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
//
// import useRest from '../../hooks/useRest';
import { date } from '../../utils/formatter';
import { firestore, useFirebase } from '../../components/FirebaseProvider';
// import { shareURL } from '../../utils/share';
// import { useSnackbar } from 'notistack';
// import slugify from 'slugify';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Divider } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { HFHeader } from '../../components/AppHeaderNew';
//  
function Berita(props) {
    const { classes, } = props;

    const { user } = useFirebase();
    // const { enqueueSnackbar } = useSnackbar();
    const params = useParams();

    // get kaktegori
    const kategDoc = firestore.collection(`news`).where('kategori.title', '==', params?.kategori || 'null').where('status', '==', 'published');
    const [dataKeteg, loadKateg] = useCollectionData(kategDoc, { idField: 'newsId' });

    // console.log(dataKeteg)

    // const [response, loading] = useRest({
    //     url: 'news/kategori', method: 'get', params: {
    //         type: 'kategori'
    //     }
    // }
    // )

    // const [snapshot, loadsnap] = useRest({
    //     url: 'news/banner', method: 'get', params: {
    //         type: 'banner'
    //     }
    // }
    // )

    // const [res, load] = useRest({
    //     url: 'news', method: 'get', params: {
    //         type: 'list'
    //     }
    // })

    // const [idName, setIdName] = useState({
    //     id: '',
    // });
    // console.log(response)
    // const linkBanner = snapshot && snapshot.data && snapshot.data.Item && snapshot.data.Item.targetUrl;
    // const PhotoBanner = snapshot && snapshot.data && snapshot.data.Item && snapshot.data.Item.photoUrl;

    // useEffect(() => {
    //     if (response && response.data && response.data.Item && response.data.Item.items) {
    //         setIdName({
    //             id: response.data.Item.items[0] && response.data.Item.items[0].id ? response.data.Item.items[0].id : '',
    //         })
    //     }
    // }, [response])


    // const handleShare = (data) => async (e) => {
    //     try {

    //         // const response = await share(shareData);
    //         await shareURL(data);
    //         //  console.log(response)
    //     } catch (e) {
    //         enqueueSnackbar(e.message)
    //     }
    // }

    if (loadKateg) {
        return (
            <Container maxWidth="xs">
                <CircularProgres style={{ marginTop: '90%' }} />
            </Container>
        )
    }

    return (

        <div className={classes.newsWrap}>
            <HFHeader
                title={params.kategori === "Skills" ? "Upgrade Skill" : params.kategori === "Magang" && "Info Magang"}
                desc="Update selalu tips, informasi, dan berita mengenai karir dan pekerjaan disini"
                image={params.kategori === "Skills" ? "skill" : params.kategori === "Magang" && "magang"}
            />

            {/* <div className={classes.newsCategory}>
                <div className={classes.categorySlide}>
                    {
                        response && response.data && response.data.Item && response.data.Item.items ?
                            response.data.Item.items.map(item => {
                                return (
                                    <div key={item.id} className={classes.categoryLink}>
                                        <Button
                                            classes={{ disabled: classes.disabledBtn }}
                                            color="primary"
                                            className={classes.categoryBtn}
                                            disabled={item.id === idName.id}
                                            onClick={() => setIdName({ id: item.id })}
                                        >
                                            {item.title}
                                        </Button>
                                    </div>
                                )
                            })
                            :
                            null
                    }
                </div>
            </div> */}
            <div className={classes.newsBody}>
                <div className={classes.newsInner}>
                    {/* {
                        response && response.data && response.data.Item && response.data.Item.items ?
                            response.data.Item.items.map((item) => {
                                return (
                                    <div key={item.id} className={classes.newsCore}>

                                        {
                                            item.pinNews !== undefined && item.id === idName.id &&
                                            <div >
                                                <img
                                                    src={item.pinNews.thumbnailURL}
                                                    alt=''
                                                    className={classes.thumbNewsCore}
                                                    height='80'
                                                />
                                                <h4 >{item.pinNews.title}</h4>
                                            </div>
                                        }

                                    </div>
                                )
                            })
                            :
                            []
                    } */}

                    <div className={classes.newsFeed}>

                        {
                            dataKeteg ?
                                dataKeteg.sort((a, b) => { return b.publishedAt - a.publishedAt }).map((item) => {
                                    // console.log(item)
                                    return (
                                        <div key={item.newsId}>

                                            {
                                                item.pinNews !== true ?
                                                    <div className={classes.newsFeed}>
                                                        <List component="nav" className={classes.listNews}>
                                                            <ListItem className={classes.listItemNews} button onClick={() => {
                                                                const newspath = user ? 'article' : 'warta';
                                                                props.history.push(`/${newspath}/${params?.kategori}/${item.newsId}`)
                                                            }} alignItems="flex-start">
                                                                <ListItemAvatar>
                                                                    <Avatar className={classes.newsThumb} alt="" src={item.thumbnailURL} />
                                                                </ListItemAvatar>
                                                                <ListItemText>
                                                                    <p className={classes.newsTitle}>{item.title}</p>
                                                                    <span className={classes.dateTime}>{date(item.publishedAt)}</span>
                                                                </ListItemText>
                                                                {/* <ListItemSecondaryAction>
                                                                    <IconButton
                                                                    onClick={handleShare({ url: `${window.location.origin}/warta/${item.newsId}/${slugify(item.title.toLowerCase())}` })}
                                                                    edge="end" aria-label="share">
                                                                    <ShareIcon />
                                                                    </IconButton>
                                                                </ListItemSecondaryAction> */}
                                                            </ListItem>
                                                        </List>
                                                        <Divider />
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    )
                                })
                                :
                                []
                        }
                    </div>
                </div>
                {/* {
                    linkBanner !== undefined && PhotoBanner !== undefined ?
                        <div className={classes.banner}>
                            <Button className={classes.btnBanner}>
                                <img onClick={() => window.open(linkBanner, '_blank')} className={classes.bannerImg} alt="" src={PhotoBanner} />
                            </Button>
                        </div>
                        :
                        <div style={{ marginBottom: 100 }}></div>
                } */}
            </div>
        </div >

    );

}

export default withStyles(styles)(Berita);