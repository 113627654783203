// React
import React, { useRef, useEffect, useMemo, useState, useLayoutEffect } from 'react';
// Styles
import useStyles from './styles';
// Component's
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import TextField from '@material-ui/core/TextField';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import CircularProgress from '@material-ui/core/CircularProgress';
import groupBy from 'lodash/groupBy';
import { animateScroll as scroll, Element } from 'react-scroll';
import ChatHeading from './chatHeading';
// import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
// import mainLogo from '../../images/img-logo.png';
// import Avatar from '@material-ui/core/Avatar';
import CloseIcon from '@material-ui/icons/Close';
import { baseUrl, baseURL } from '../../images/s3';
import axios from 'axios';
// Firebase
import { firestore, Arr, functions } from '../../components/FirebaseProvider';
import { useDocumentData, useCollectionData } from 'react-firebase-hooks/firestore';
import { useSiswa } from '../../components/SiswaProvider';
// Router
import { useParams, useHistory } from 'react-router-dom';
// Formatter
import { unixToIsoDate, unixToTime, isoToRelative } from '../../utils/formatter';
// 
import MessageIn from './MessageIn';
// import isStandAlone from '../../utils/isStandAlone';
import SendRaport from './sendRaport';
import SendChat from '../../images/send.png';
import CantSend from '../../images/cantSend.png';
import AttachFiles from '../../images/attach-ico.png';
import AttachCamera from '../../images/attach-camera.png';
import AttachPhoto from '../../images/attach-photo.png';
import AttachMinatBakat from '../../images/attach-minatbakat.png';
import AttachTO from '../../images/attach-to.png';
import CameraDialog from './camera';
import results from '../tesMinatBakat/game/data/results.json';
import ToListDialog from './ToListDialog';
import { useDownloadApp } from '../../components/DownloadApp';
import DialogImage from './imgZoom';



function searchTMB(inp) {

    let out;
    if (inp === "E") {
        out = "Extrovert"
    }
    if (inp === "I") {
        out = "Introvert"
    }
    if (inp === "N") {
        out = "Intuition"
    }
    if (inp === "S") {
        out = "Sensing"
    }
    if (inp === "T") {
        out = "Thinking"
    }
    if (inp === "F") {
        out = "Feeling"
    }
    if (inp === "P") {
        out = "Perception"
    }
    if (inp === "J") {
        out = "Judgement"
    }

    return out;
}


function MyChat(props) {
    // Styles
    const classes = useStyles();
    // Router
    const params = useParams();
    const history = useHistory();
    const [open, setOpen] = useState();
    // Firebase
    // const raport = location.state && location.state.raport;
    const { profile } = useSiswa();
    //sending
    const [isSending, setSending] = useState(false);
    // Query
    const docRef = firestore.doc(`chat/${params.chatId}`);
    const chatItemsRef = docRef.collection('items');
    const [data, loading] = useDocumentData(docRef);
    const [chatItems, loadingItems] = useCollectionData(chatItemsRef.orderBy("created_at", "asc"), { idField: 'id' });

    // State
    const textRef = useRef(null);
    const [attachmentShown, setAttachmentShown] = useState(false);
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [isTOListOpen, setTOListOpen] = useState(false);
    const [previewSrc, setPreviewSrc] = useState(null);
    const [attachedFile, setAttachedFile] = useState(null);
    const [imgUrl, setImgUrl] = useState("");
    // Check
    const disabled = data && data.status !== 'active';
    const { hide } = useDownloadApp();
    // UseEffect
    useEffect(() => {
        if (data && data.status === 'canceled') {
            history.push(`/chat`)
        }
    }, [data, history])


    useLayoutEffect(() => {

        scroll.scrollToBottom({
            containerId: "chatWindow",
            offset: 0,
            isDynamic: true,
            duration: 0
        })

    }, [chatItems])

    // Function's
    const sendChat = (e) => {
        e.preventDefault();
        if (isSending) {
            return null
        }
        if (textRef.current.value) {
            setSending(true)
            docRef.set({
                updated_at: Arr.serverTimestamp(),
                siswa_typing: false,
                nama_siswa: profile.nama,
                last_chat: {
                    from_mitra: false,
                    from_siswa: true,
                    text: textRef.current.value,
                    created_at: Date.now()
                },
                mitra_unread_count: Arr.increment(1)
            }, { merge: true });
            chatItemsRef.add({
                from_mitra: false,
                from_siswa: true,
                text: textRef.current.value,

                created_at: Date.now(),
                read: false,
                push: false
            })

            setSending(false)
            textRef.current.value = ''
            // textRef.current.focus()


        }
    }
    // 
    const handleChange = () => {
        if (textRef.current.value.length === 1) {
            docRef.set({
                siswa_typing: true,
            }, { merge: true })
        }
        if (textRef.current.value.length === 0) {
            docRef.set({
                siswa_typing: false,
            }, { merge: true })
        }
    }

    // handle attach file

    const handleAttachFile = (e) => {
        // console.log(e.target.files[0])
        setPreviewSrc(URL.createObjectURL(e.target?.files?.[0]));
        setAttachedFile(e.target.files[0]);
        setIsCameraOpen(true);
    }

    // handle send image
    const sendImage = async () => {

        const reader = new FileReader();

        reader.onload = async () => {
            setIsCameraOpen(false);
            setAttachmentShown(false);
            try {
                const generateUploadUrl = functions.httpsCallable('generateUploadUrl');

                const result = await generateUploadUrl({
                    filename: attachedFile.name,
                    directory: `images/konsultasi/${params.chatId}`,
                    contentType: attachedFile.type,
                    id: Date.now()
                })

                await axios.put(result.data.uploadUrl, attachedFile, {
                    headers: {
                        'Content-Type': attachedFile.type
                    }
                })
                setSending(true)
                docRef.set({
                    updated_at: Arr.serverTimestamp(),
                    siswa_typing: false,
                    nama_siswa: profile.nama,
                    last_chat: {
                        from_mitra: false,
                        from_siswa: true,
                        text: 'sent an attachment',
                        type: 'image',
                        url: result.data.photoUrl,
                        created_at: Date.now()
                    },
                    mitra_unread_count: Arr.increment(1)
                }, { merge: true });
                chatItemsRef.add({
                    from_mitra: false,
                    from_siswa: true,
                    text: 'sent an attachment',
                    type: 'image',
                    url: result.data.photoUrl,
                    created_at: Date.now(),
                    read: false,
                    push: false
                })

                setSending(false)

            } catch (err) {
                console.log(err.message)
            }
            URL.revokeObjectURL(previewSrc);
        }

        reader.readAsDataURL(attachedFile)
    }

    // send TMB Result
    const sendTMBResult = async () => {

        if (!profile.hasil_tesMinatBakat) {
            if (window.confirm('Anda belum melakukan tes minat bakat, mau coba sekarang gratis?')) {
                history.push('/tesminatbakat')
            }
            return null;
        }
        setSending(true)
        docRef.set({
            updated_at: Arr.serverTimestamp(),
            siswa_typing: false,
            nama_siswa: profile.nama,
            last_chat: {
                from_mitra: false,
                from_siswa: true,
                text: 'sent TMB result',
                type: 'tmb-result',
                // url: result.data.photoUrl,
                tmb: {
                    result: profile.hasil_tesMinatBakat,
                    short: results?.[profile.hasil_tesMinatBakat]?.short,
                    nama: profile.nama,
                },
                created_at: Date.now()
            },
            mitra_unread_count: Arr.increment(1)
        }, { merge: true });
        chatItemsRef.add({
            from_mitra: false,
            from_siswa: true,
            text: 'sent TMB result',
            type: 'tmb-result',
            // url: result.data.photoUrl,
            tmb: {
                result: profile.hasil_tesMinatBakat,
                short: results?.[profile.hasil_tesMinatBakat]?.short,
                nama: profile.nama,
            },
            created_at: Date.now(),
            read: false,
            push: false
        })

        setSending(false)
        setAttachmentShown(false)
    }

    // send TO 
    const sendTO = async (to) => {

        setSending(true)
        docRef.set({
            updated_at: Arr.serverTimestamp(),
            siswa_typing: false,
            nama_siswa: profile.nama,
            last_chat: {
                from_mitra: false,
                from_siswa: true,
                text: 'sent TO result',
                type: 'tmb-result',
                to,
                // url: result.data.photoUrl,
                created_at: Date.now()
            },
            mitra_unread_count: Arr.increment(1)
        }, { merge: true });
        chatItemsRef.add({
            from_mitra: false,
            from_siswa: true,
            text: 'sent TO result',
            type: 'to-result',
            // url: result.data.photoUrl,
            to,
            created_at: Date.now(),
            read: false,
            push: false
        })

        setSending(false)
        setTOListOpen(false)
        setAttachmentShown(false)
    }
    // 
    const closeBox = () => {
        docRef.set({
            showBox: false
        }, { merge: true })
    }
    // 
    const itemsGroup = useMemo(() => {
        if (chatItems) {
            return groupBy(chatItems, (item) => {

                return unixToIsoDate(item.created_at)
            })
        }
        return {}
    }, [chatItems])
    // Check
    if (loading || loadingItems) {
        return (
            <CircularProgress style={{ margin: ' 80% auto' }} color="primary" />
        )
    }
    // 
    return (
        <div className={classes.root}>
            <div className={classes.chatBody}>
                <ChatHeading data={data} />
                {(data && data.status === 'active') ? <SendRaport /> : null}
                <Element name="container" className={hide ? classes.chatWindow1 : classes.chatWindow} id="chatWindow">
                    {Object.keys(itemsGroup).map(dateStr => {

                        return <React.Fragment key={dateStr}>
                            <Element className={classes.chatDayWrap} name={`${dateStr}`}>
                                <Typography className={classes.chatDay} variant="caption">{isoToRelative(dateStr)}</Typography>
                            </Element>
                            {
                                data && data.status === 'active' && data.showBox === true ?
                                    <div className={classes.boxWelcome}>
                                        <div className={classes.boxHeading} >
                                            <img height="30px" alt="logo" src={`${baseURL}new-logo-c-o.png`} />
                                            <div className={classes.textHeadingWrap}>
                                                <Typography align="left" variant="h5" className={classes.textHeading} >Selamat datang di PakBudi Konseling</Typography>
                                                <span className={classes.textSubHeading}>Kamu berada didalam chat room bersama mitra kami</span>
                                            </div>
                                            <IconButton onClick={closeBox} className={classes.closeBtn} >
                                                <CloseIcon color="error" />
                                            </IconButton>
                                        </div>
                                        <Typography className={classes.boxDetail} align="justify" >
                                            {/* Halo <b>{data && data.nama_siswa}!</b> Kamu telah terhubung dengan <b>{data && data.nama_mitra}</b> harap menunggu sebentar ya... */}
                                            Untuk kenyamanan dan keamanan bersama, dilarang menyebarkan data-data pribadi seperti nomor telepon, akun sosial media, dan data pribadi lainnya. Serta dilarang melakukan transaksi jual - beli atau promosi dalam bentuk apapun. Dan dilarang keras melakukan perbuatan yang mengandung SARA.
                                        </Typography>
                                    </div>
                                    :
                                    null
                            }
                            {
                                itemsGroup[dateStr].map((item, index) => {

                                    if (item.from_mitra) {
                                        return <MessageIn item={item} key={item.id} handleOpen={(open, img) => { setOpen(open); setImgUrl(img) }} />
                                    }

                                    const tmbSplit = item?.tmb?.result.split("");

                                    return <Element name={`item-${item.id}`} key={item.id} className={classes.myChatBubble}>
                                        {(!item.type || item.type === 'text') &&
                                            <div className={classes.myTextBody}>
                                                {item.text.split('\n').map((text, i) => <Typography key={i} className={classes.myText} variant="body1" translate="no">{text}</Typography>)}
                                                <div className={classes.deliveryDetail}>
                                                    {!item.read && <CheckIcon className={classes.sentIcon} />}
                                                    {item.read && <DoneAllIcon className={classes.readIcon} />}
                                                    <Typography className={classes.myTimeStamp} variant="caption">{unixToTime(item.created_at)}</Typography>
                                                </div>
                                            </div>}

                                        {/* image attachment bubble start */}
                                        {item.type === 'image' &&
                                            <div className={classes.myTextBody}>
                                                <Button style={{ padding: 0 }} onClick={() => { setImgUrl(item?.url); setOpen(true); }}>
                                                    <div className={classes.myAttachment}>
                                                        <img style={{ objectFit: 'cover', borderRadius: 5 }} src={item.url} alt="" />
                                                    </div>
                                                </Button>
                                                <div className={classes.deliveryDetail}>
                                                    {!item.read && <CheckIcon className={classes.sentIcon} />}
                                                    {item.read && <DoneAllIcon className={classes.readIcon} />}
                                                    <Typography className={classes.myTimeStamp} variant="caption">{unixToTime(item.created_at)}</Typography>
                                                </div>
                                            </div>}
                                        {/* image attachment bubble end */}


                                        {/* static tmb bubble start */}
                                        {item.type === 'tmb-result' &&
                                            <div className={classes.tmbBubble}>
                                                <div className={classes.myTmbTextBody}>
                                                    <div className={classes.myTmbText}>
                                                        <div className={classes.tmbBoxWrap}>
                                                            <div className={classes.tmbBox}>
                                                                <p className={classes.tmbResultcaption}>Hasil tes minat bakat untuk:</p>
                                                                <h3 className={classes.tmbResultNameWrap}><span className={classes.tmbResultName}>{item.tmb?.nama}</span></h3>
                                                                <img className={classes.tmbResultImg} src={`${baseUrl}${item.tmb?.result?.toLowerCase?.()}.png`} alt="" />
                                                                <h2 className={classes.tmbResultTitle}>{item.tmb?.result}</h2>
                                                                <p className={classes.tmbResultSplit}>{item?.tmb?.result && `${searchTMB(tmbSplit[0])} - ${searchTMB(tmbSplit[1])} - ${searchTMB(tmbSplit[2])} - ${searchTMB(tmbSplit[3])}`}</p>
                                                                <p className={classes.tmbResultSubtitle}>({item?.tmb?.short ?? '-'})</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.deliveryDetail}>
                                                        {/* replace with dynamic time */}
                                                        {!item.read && <CheckIcon className={classes.sentIcon} />}
                                                        {item.read && <DoneAllIcon className={classes.readIcon} />}
                                                        <Typography className={classes.myTimeStamp} variant="caption">{unixToTime(item.created_at)}</Typography>
                                                    </div>
                                                </div>
                                            </div>}
                                        {/* tmb bubble end */}
                                        {/* static To bubble start */}
                                        {
                                            item.type === 'to-result' &&


                                            <div className={classes.toBubble}>
                                                <div className={classes.myToTextBody}>
                                                    <div className={classes.myToText}>
                                                        <div className={classes.toBox}>
                                                            <h2 className={classes.toBoxTitle}>{item?.to?.judul}</h2>
                                                            <span className={classes.toBoxSubtitle}>Hasil jawaban benar</span>
                                                            <div className={classes.toBoxDetail}>
                                                                {Object.entries(item.to?.hasil ?? {}).map(([key, item]) => {
                                                                    return (
                                                                        <div className={classes.toBoxDetailRow} key={key}>
                                                                            <span className={classes.detailLabel}> {item.nama}</span>
                                                                            <span className={classes.detailValue}>: {item.benar}</span>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.deliveryDetail}>
                                                        {/* replace with dynamic time */}
                                                        {!item.read && <CheckIcon className={classes.sentIcon} />}
                                                        {item.read && <DoneAllIcon className={classes.readIcon} />}
                                                        <Typography className={classes.myTimeStamp} variant="caption">{unixToTime(item.created_at)}</Typography>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {/* to bubble end */}

                                    </Element>
                                })
                            }
                            {data?.status === 'done' && <MessageIn item={{ read: true, id: "mitra-closing-message", text: "Terima kasih sudah konsultasi denganku, tolong beri review yang jujur ya, dan jangan segan-segan untuk konsultasi kembali disini aja :)", created_at: Date.now() }} />}




                        </React.Fragment>

                    })}
                    {data && data.status === 'waiting-for-accept' &&
                        <Element className={classes.SesiWrap} name={`chat-not-active`}>
                            <Typography className={classes.chatDay} variant="caption">Menunggu Persetujuan Mitra</Typography>
                        </Element>
                    }
                    {data && data.status === 'done' &&
                        <Element className={classes.SesiWrap} name={`chat-not-active`}>
                            <Typography className={classes.chatDay} variant="caption">Sesi Chat Telah Berakhir</Typography>
                        </Element>
                    }
                    {data && data.mitra_typing === true &&
                        <Element className={classes.SesiWrap} name={`chat-not-active`}>
                            <Typography className={classes.chatDay} variant="caption">Mengetik...</Typography>
                        </Element>
                    }


                    {(attachmentShown && !disabled) &&
                        <div className={classes.floatingMenu}>
                            <div className={classes.attachWrap}>
                                <Button
                                    className={classes.attachBtn}
                                    onClick={sendTMBResult}
                                    classes={{ label: classes.btnLabel }}>
                                    <img className={classes.attachIcons} src={AttachMinatBakat} alt="" />
                                    <span className={classes.attachBtnLabel}>Tes MinatBakat</span>
                                </Button>
                                <Button
                                    className={classes.attachBtn}
                                    onClick={() => {
                                        setTOListOpen(val => !val);
                                    }}
                                    classes={{ label: classes.btnLabel }}><img className={classes.attachIcons} src={AttachTO} alt="" /><span className={classes.attachBtnLabel}>Tryout</span></Button>

                                <label htmlFor="camera-input">
                                    <input id="camera-input" type="file" capture="environment" accept="image/*" style={{ display: 'none' }} onChange={handleAttachFile} />
                                    <Button component="span" className={classes.attachBtn} classes={{ label: classes.btnLabel }}>
                                        <img className={classes.attachIcons} src={AttachCamera}

                                            alt="" />
                                        <span className={classes.attachBtnLabel}>Kamera</span>

                                    </Button>
                                </label>
                                <label htmlFor="gallery-input">
                                    <input id="gallery-input" type="file" accept="image/*" style={{ display: 'none' }} onChange={handleAttachFile} />
                                    <Button component="span" className={classes.attachBtn} classes={{ label: classes.btnLabel }}><img className={classes.attachIcons} src={AttachPhoto} alt="" /><span className={classes.attachBtnLabel}>Galeri</span></Button>
                                </label>
                            </div>
                            <IconButton className={classes.closeAttach}
                                onClick={() => {
                                    setAttachmentShown(val => !val);
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                </Element>


                <form onSubmit={sendChat}>
                    <div className={classes.chatInput}>

                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                style: { marginLeft: 10 }
                            }}
                            inputProps={{
                                ref: textRef
                            }}
                            autoComplete="off"
                            className={classes.chatText}
                            id="chat-input"
                            placeholder="Kirim Pesan"
                            label=""
                            onChange={handleChange}
                            multiline
                            disabled={disabled || data === undefined}
                            rowsMax={2}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    sendChat(e)
                                }
                            }}
                        />

                        <IconButton className={classes.attachFile}
                            disabled={disabled || data === undefined}
                            onClick={() => {
                                setAttachmentShown(val => !val);
                            }}
                        >
                            <img className={classes.attachIco} src={AttachFiles} alt="" />
                        </IconButton>

                        <IconButton
                            className={classes.sendBtn}
                            type="submit"
                            disabled={disabled || data === undefined || isSending}
                            classes={{ disabled: classes.disabledSendBtn }}
                        >
                            {
                                data && data.status === 'active' ?
                                    <img className={classes.sendIcon} src={SendChat} alt="" />
                                    :
                                    <img className={classes.sendIcon} src={CantSend} alt="" />
                            }
                        </IconButton>

                    </div>
                </form>
            </div>
            <CameraDialog
                open={isCameraOpen}
                handleClose={() => {
                    setIsCameraOpen(false);
                }}
                previewSrc={previewSrc}
                sendImage={sendImage}
            />

            <ToListDialog
                open={isTOListOpen}
                handleClose={() => {
                    setTOListOpen(false);
                }}
                sendTO={sendTO}
            />
            <DialogImage open={open} handleClose={() => { setOpen(false); setImgUrl("") }} img={imgUrl} />
        </div >
    );

}

export default MyChat;