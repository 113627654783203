
import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { CheckCircleOutline, } from '@material-ui/icons';

export default function Fifth({ form, handleChange, error, setForm, setError }) {
    const handleRadioChange = (event) => {
        setForm({
            ...form,
            status: event.target.value

        });

        setError({
            ...error,
            status: ''
        });
    };
    return (
        <>
            <div style={{ textAlign: 'left' }}>
                <Typography style={{ marginBottom: '10px' }}>Apakah saat ini  {form?.nama_panggilan} sedang memiliki pekerjaan?*</Typography>

                <FormControl style={{ marginLeft: '20px' }} component="fieldset" error={error.status ? true : false}
                // className={classes.formControl}
                >
                    <RadioGroup aria-label="quiz" name="quiz" value={form?.status} onChange={handleRadioChange}>
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Iya, full-time" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Iya, full-time" />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Iya, part-time" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Iya, part-time " />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Tidak" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Tidak" />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Masih mahasiswa" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Masih mahasiswa" />
                    </RadioGroup>
                    {/* <FormHelperText>{helperText}</FormHelperText> */}

                </FormControl>
                <Typography variant='subtitle2' color='error'>{error.status && error.status}</Typography>
            </div>
        </>
    )
}

