
import React from 'react';
import { Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { unixToDate } from '../../utils/formatter';

export default function Second({ form, setForm, error, setError }) {
    // console.log(new Date(form?.tgl_lahir?.toMillis()),)

    const handleDateChange = (date) => {
        setForm({
            ...form,
            tgl_lahir: date
        });
        setError({
            ...error,
            tgl_lahir: ''
        })
    };
    return (
        <>
            <div style={{ textAlign: 'left' }}>
                <Typography>Kapan tanggal lahirmu {form?.nama_panggilan} ?*</Typography>
                <Typography variant='subtitle2'>Tolong isi dalam format DD/MM/YYYY. Contoh, jika kamu lahir pada tanggal 17 Agustus 1945, tolong isi dengan 17/08/1945.</Typography>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <KeyboardDatePicker
                        // style={{ textAlign: 'left' }}
                        maxDate={new Date()}
                        disableFuture
                        inputVariant='outlined'
                        margin="normal"
                        id="date-picker-dialog"
                        // label="dd/MM/yyyy"
                        placeholder="dd/MM/yyyy"
                        format="dd/MM/yyyy"
                        value={form?.tgl_lahir}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        defaultValue={form?.tgl_lahir?.seconds ? unixToDate(form?.tgl_lahir?.toMillis()) : '22/04/1998'}
                        error={error.tgl_lahir ? true : false}
                        helperText={error.tgl_lahir}
                    /></MuiPickersUtilsProvider>
            </div>

        </>
    )
}

