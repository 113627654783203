import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 90,
        userSelect: 'none',
        msUserSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none'
    },
    pageTitle1: {
        color: theme.palette.textColor.gray,
        fontSize: 20,
        marginBottom: 10,
    },
    pageSubtitle:{
        color: theme.palette.textColor.gray,
        fontSize: 14,
        marginTop: 0,
    },
    changeProfileBody:{
        margin: '30px auto 10px',
        '@media(min-width: 320px)': {
            width: '90%',
        },
        '@media(min-width: 960px)': {
            width: '50%',
        },
    },
    historyBack: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 0,
        margin: 'auto',
    },
    formRow:{
        width: '100%',
        margin: '0 auto 10px',
        display: 'flex',
        justifyContent: 'space-between',
    },
    textField:{
        width: '100%',
    },
    formControl:{
        width: '100%',
    },
    formRowBtn:{
        margin: '10px auto 15px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column nowrap',
    },
    DialogTitel: {
        textAlign: 'center' , 
        width: '100%'
    },
    txt: {
        textAlign: 'center', 
        width: '75%', 
        margin: '0 auto'
    },
    btnOke: {
        padding: '10px', 
        marginTop: '20px', 
        borderTop: '1px solid #dedede', 
        color: '#38b1db', 
        borderBottom: '1px solid #dedede',
        textTransform: 'capitalize',
    },
    btnNo: {
        padding: '10px',
        textTransform: 'capitalize',
    },
    pageHeading:{
        backgroundColor: theme.palette.textColor.white,
        padding: '25px 0',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '& > h2':{
            margin: 0,
        }
    },
    btnPrimary:{
        margin: '20px auto',
        width: 207,
        height: 27,
        fonSize: 13,
        textTransform: 'capitalize',
    }
}))

export default useStyles;