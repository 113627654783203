import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    pageHeading: {
        margin: '0 auto 30px',
        padding: '0 16px',
        // maxWidth: "630px",
        "@media(min-width: 360px)": {
            width: "100% !important",
        },
        "@media(min-width: 560px)": {
            width: "80% !important",
        },
        "@media(min-width: 960px)": {
            width: "70% !important",
        },
        "@media(min-width: 1100px)": {
            width: "50% !important",
        },
    },
    formRow: {
        position: 'relative',
        margin: '0 auto 25px',
    },
    formControl: {
        width: '100%',
    },
    selecto: {
        width: '100%',
        textAlign: 'left',
        color: theme.palette.textColor.gray,
    },
    Select: {
        marginTop: '25px',
    },
    formRowBtn: {
        margin: '10px auto 15px',
        display: 'flex',
        justifyContent: 'center',
        flexFlow: 'column nowrap',
    },
    pageTitle: {
        color: theme.palette.textColor.gray,
        fontSize: 24,
        marginBottom: 10,
    },
    pageSubtitle: {
        color: theme.palette.textColor.gray,
        fontSize: 14,
        marginTop: 0,
    },
    textField: {
        width: '100%',
    },
    textFieldLast: {
        fontSize: '13px !important',
        color: '#798790 !important',
    },
    btnInfo: {
        position: 'absolute',
        top: 15,
        right: -29,
        width: 20,
        height: 20,
    },
    poperContent: {
        width: 250,
        height: 60,
        backgroundColor: '#fff',
        padding: 10,
        border: 'solid 1px' + theme.palette.textColor.gray,
    },
    poperTxt: {
        fontStyle: 'italic',
        fontSize: 12,
        margin: 2,
    },
    formWrap: {
        margin: '0 auto 10px',
        padding: '0 16px',
        // maxWidth: "630px",
        "@media(min-width: 360px)": {
            width: "100% !important",
        },
        "@media(min-width: 560px)": {
            width: "80% !important",
        },
        "@media(min-width: 960px)": {
            width: "70% !important",
        },
        "@media(min-width: 1100px)": {
            width: "50% !important",
        },
    },
    formRowPrize: {
        backgroundColor: '#ebeeec',
        padding: 20,
    },
    textFieldRef: {
        backgroundColor: '#aae0ff',
    },
    prizeInfo: {
        borderTop: 'solid 1px #b0b2b3',
        fontSize: 12,
        fontWeight: 'bold',
        paddingTop: 20,
    },
    textFieldUnderline: {
        borderBottom: 'solid 2px #798790 !important',
        '&::after': {
            borderBottom: 'solid 2px #798790 !important',
        }
    },
    DialogTitle: {
        fontSize: 18,
        textAlign: 'center',
        backgroundColor: '#e0fcfa'
    },
    txt: {
        textAlign: 'center'
    },
    imgCoinwrapp: {
        textAlign: 'center'
    },
    btneditt: {
        padding: '10px',
        borderTop: '1px solid #dedede',
        color: '#38b1db',
        borderBottom: '1px solid #dedede',
        textTransform: 'capitalize',
    },
    label: {
        marginBottom: 10,
        fontWeight: "bold",
        fontSize: 16,
        color: theme.palette.neutrals.n80,
        textAlign: "left",
    },
    radioChecked: {
        color: theme.palette.primary,
        border: '1px solid ' + theme.palette.primary
    }
}))

export default useStyles;