import React from 'react';

// import komponen material ui 
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


// import komponen React Router DOM
import { Link } from 'react-router-dom';

// import styles
import useStyles from './styles';

import { baseUrl } from '../../../images/s3';
import AppHeader from '../../../components/AppHeader';

// buat sebuah fungsi komponen dengan nama NotFound
function Home(props) {
    const classes = useStyles();

    // const handleBack = () => {
    //     props.history.goBack();
    // }

    return (
        <Container maxWidth="lg" component="main" className={classes.mainWrap}>

            <div className={classes.mainBody}>
                <div>
                    <AppHeader/>
                    {/* <Button className={classes.historyBack} onClick={handleBack} ><ChevronLeftIcon /></Button>
                    <div className={classes.logoWrap}>
                        <img className={classes.logo} src={`${logo}`} alt="logo" />
                    </div>
                    <div className={classes.btnSlot}></div> */}
                </div>
                <Typography className={classes.heroTitle} variant="h1">87%</Typography>
                <Typography className={classes.subtitle} variant="h3">Orang Salah Pilih Jurusan Kuliah !!</Typography>
                <Typography className={classes.text} variant="subtitle1">Ikuti Tes Minat Bakat Online</Typography>
                <Typography className={classes.free} variant="h4">GRATIS!!</Typography>
                <Typography className={classes.text} variant="subtitle1">Agar kamu bisa tau jurusan kuliah dan jenis pekerjaan yang sesuai dengan bakatmu:</Typography>
                <div className={classes.btnWrap}>
                    <Button className={classes.btnTest} size="large" component={Link} to="/tesminatbakat/game" variant="contained"> ISI TES SEKARANG</Button>
                </div>
                <div className={classes.botSection}>
                    <img className={classes.heroImg} src={`${baseUrl}chars-all.png`} alt="logo" />
                </div>
            </div>

        </Container>
    );
}

export default Home;