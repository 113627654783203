// React
import React, { useEffect, useState } from "react";
import AppHeader from "../../components/AppHeader";
import EventBusyOutlinedIcon from "@material-ui/icons/EventBusyOutlined";

// Styles
import useStyles from "./styles";

// MUI
import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

// Firebase
import { firestore, useFirebase } from "../../components/FirebaseProvider";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";

// React Router Dom
import { useHistory, useParams } from "react-router-dom";
import AppLoading from "../../components/AppLoading";

// Snackbar
import { useSnackbar } from "notistack";

const CustomFCL = withStyles((theme) => ({
  label: {
    fontSize: 12,
    color: "#4F4F4F",
    fontStyle: "italic",
  },
}))(FormControlLabel);

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: 4,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
      "&:hover fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
    },
  },
})(TextField);

const CustomButton = withStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundColor: "#1461A8",
    textTransform: "Capitalize",
    fontWeight: "bold",
    margin: "0px 0px 28px",
    "&:hover": {
      backgroundColor: "#166DBD",
    },
  },
}))(Button);

function UserEventIntro(props) {
  const classes = useStyles();

  const { eventId } = useParams();

  const { user } = useFirebase();

  const history = useHistory();

  const eventDoc = firestore.doc(`events/${eventId}`);

  const [event, loadingEvent] = useDocumentData(eventDoc);

  const [check, setCheck] = useState(false);

  const [code, setCode] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const trxDoc = firestore
    .collection("transaksi_event")
    .where("event_id", "==", eventId)
    .where("email_user", "==", user?.email)
    .where("status", "==", "sukses");

  const [trx, loadingTrx] = useCollectionData(trxDoc);

  const { enqueueSnackbar } = useSnackbar();

  const handleChecked = () => {
    setCheck(!check);
  };

  const handleClick = async () => {
    await firestore
      .collection("event_state")
      .doc(`${eventId}_${user?.uid}`)
      .set(
        {
          path: `/acara/u/${eventId}/daftar`,
          prevPath: props?.location?.pathname ?? "/home",
          eventId,
          uid: user?.uid,
        },
        { merge: true }
      );

    history.push(`/acara/u/${eventId}/daftar`);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const transaksi = await firestore
        .collection("transaksi_event")
        .where("kode_daftar", "==", code)
        .where("event_id", "==", eventId)
        .get();

      const pesertaId = transaksi?.docs?.[0]?.data()?.pesertaId ?? "";

      if (!transaksi.empty) {
        const peserta = await firestore
          .doc(`events/${eventId}`)
          .collection("peserta_event")
          .where("email_user", "==", user?.email)
          .where("pesertaId", "==", pesertaId)
          .get();

        const lengkap = peserta?.docs?.[0]?.data()?.registrasi_lengkap;

        if (!peserta.empty && lengkap === true) {
          await firestore
            .collection("event_state")
            .doc(`${eventId}_${user?.uid}`)
            .set(
              {
                path: `/acara/ticket/${eventId}/${pesertaId}`,
              },
              { merge: true }
            );

          history.push(`/acara/ticket/${eventId}/${pesertaId}`);
        } else if (peserta.empty && lengkap === undefined) {
          history.push(`/acara/u/${eventId}/lengkapi-data/${pesertaId}`);
        } else {
          enqueueSnackbar("Kode Daftar Sudah Terpakai !!!", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Kode Daftar Tidak Valid !!!", {
          variant: "error",
        });
      }
    } catch (error) {
      console.log(error);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const getUrl = async () => {
      // const url = await getData(eventId);

      const data = await firestore
        .collection("event_state")
        .doc(`${eventId}_${user?.uid}`)
        .get();

      if (data.exists) {
        const url = data?.data();

        history.push(url?.path);
      } else {
        const trxData = trx?.sort((a, b) => {
          const ca = a.created_at;
          const cb = b.created_at;

          if (ca > cb) {
            return -1;
          }

          return 1;
        })?.[0];

        if (trxData) {
          await firestore
            .collection("event_state")
            .doc(`${eventId}_${user?.uid}`)
            .set(
              {
                path: `/acara/ticket/${eventId}/${trxData?.pesertaId}`,
              },
              { merge: true }
            );

          history.push(`/acara/ticket/${eventId}/${trxData?.pesertaId}`);
        }
      }
    };

    getUrl();
  }, [eventId, history, trx, user]);

  if (loadingEvent || loadingTrx) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <AppHeader />
      </div>
      {event !== undefined ? (
        <div className={classes.tcBody}>
          <div className={classes.pageHeading}>
            <h2 className={classes.pageTitle2}>
              Selamat datang di halaman pendaftaran acara {event?.nama ?? "-"}
            </h2>
            <p
              className={classes.pageSubtitle2}
              dangerouslySetInnerHTML={{ __html: event?.deskripsi ?? <p></p> }}
            />
          </div>
          <CustomButton
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            Daftar Sekarang
          </CustomButton>
          <Divider className={classes.hr} />
          <div className={classes.inputKode}>
            <CustomFCL
              control={
                <Checkbox
                  size="small"
                  name="haveCode"
                  color="primary"
                  checked={check}
                  onChange={handleChecked}
                />
              }
              label="Saya sudah punya kode daftar"
            />

            <CustomTextField
              style={{ margin: "10px 0px" }}
              placeholder="Input Kode"
              name="kode"
              variant="outlined"
              size="small"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />

            <Button
              className={classes.btn}
              style={{ marginBottom: 20 }}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSubmit}
              disabled={!check || isSubmitting}
            >
              Submit
            </Button>
          </div>

          <Divider className={classes.hr} />
          <div className={classes.pageContent}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ul className={classes.ul}>
                  <li>
                    Soal dibuat baru, bukan hasil ambil dari internet / buku
                  </li>
                  <li>Kualitas soal 99% prediktif</li>
                  <li>
                    Sistem pengerjaan dan penilaian mirip SBMPTN aslinya untuk
                    pengalaman serupa
                  </li>
                  <li>Tempat acara yang nyaman</li>
                  <li>Panitia yang ramah</li>
                  <li>Pembahasan mendetail</li>
                </ul>
              </Grid>
              <Grid item xs={6}>
                <ul className={classes.ul}>
                  <li>Mentoring belajar bedah soal gratis rutin</li>
                  <li>Ranking secara nasional melawan puluhan ribu peserta</li>
                  <li>Goodiebag, bonus, hadiah, dan masih banyak lagi</li>
                  <li>
                    Protokol kesehatan yang telah lolos perizinan dan
                    sertifikasi
                  </li>
                  <li>Asuransi Covid-19</li>
                </ul>
              </Grid>
            </Grid>
            <p className={classes.note}>
              Ikuti terus info dan perkembangan acara kami di Instagram{" "}
              <span style={{ color: "#F26722" }}>@masukkeptn</span> dan kolom
              berita aplikasi PakBudi
            </p>
          </div>
        </div>
      ) : (
        <div className={classes.tcBody} style={{ marginTop: 80 }}>
          <EventBusyOutlinedIcon style={{ fontSize: 100, color: "#0e4970" }} />
          <h3 style={{ color: "#4F4F4F" }}>Belum Ada Acara</h3>
          <Button
            color="secondary"
            variant="text"
            style={{ textTransform: "capitalize" }}
            onClick={() => {
              history.goBack();
            }}
          >
            Kembali
          </Button>
        </div>
      )}
    </div>
  );
}

export default UserEventIntro;
