import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        userSelect: 'none',
        msUserSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none'
    },
    pageHeader: {
        height: 130,
        margin: '0 auto 10px',
        position: 'relative',
        '@media(min-width: 320px)': {
            width: '90%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    pageTitle: {
        color: '#fff',
        fontSize: 24,
        margin: '10px 0',
    },
    pageWrap:{
        width: '90%',
        margin: 'auto',
        minHeight: 500,
        padding: '7px 7px',
        marginBottom: 70,
        '@media(min-width: 400px)': {
            width: '92%',
        },
        '@media(min-width: 600px)': {
            width: '90%',
        },
        '@media(min-width: 800px)': {
            width: '85%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    pageContent: {
        backgroundColor: '#fff',
        width: '90%',
        margin: 'auto',
        minHeight: 500,
        borderRadius: 6,
        padding: '7px 7px',
        
        '@media(min-width: 400px)': {
            width: '92%',
        },
        '@media(min-width: 600px)': {
            width: '90%',
        },
        '@media(min-width: 800px)': {
            width: '85%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    topPart:{
        backgroundColor: '#fff',
        borderRadius: 10,
        marginBottom: 10,
        padding: 10,
    },
    topInfo: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '10px 0',
    },
    topImg: {
        width: 50,
        textAlign: 'center',
    },
    topDesc: {
        width: '100%',
    },
    
    descTitle: {
        margin: 0,
        textAlign: 'center',
        color: theme.palette.darkBlue.main,
        fontSize: 17,
        fontWeight: 600,  
    },
    descTxt: {
        margin: 0,
        textAlign: 'center',
        color: theme.palette.textColor.gray8,
        fontSize: 10,
        fontWeight: 500,
    },
    filterBox:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    filterLabel:{
        color: theme.palette.textColor.gray,
        fontSize: 12,
        fontWeight: 500,
    },
    sortBtn:{
        color: theme.palette.lightBlue.main,
        fontSize: 12,
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    theTabs:{
        minHeight: 23,
    },
    rootTab: {
        fontSize: 11,
        padding: '6px 8px !important',
        border: 'solid 1px ' + theme.palette.textColor.gray6,
        borderRadius: 3,
        height: 23,
        minHeight: 23,
        marginRight: 4,
        textTransform: 'capitalize'
    },
    selectedTab:{
        border: 'solid 1px ' + theme.palette.lightBlue.main,
    },
    searchBox: {
        margin: '10px 0 0',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.textColor.gray11,
        borderRadius: 3,
    },
    searchIcon: {
        color: theme.palette.textColor.gray6,
        width: 17,
        height: 17,
        marginRight: 5,
    },
    botPart:{
        borderRadius: 10,
        marginBottom: 30,
    },
    inputBase: {
        width: '80%',
        fontSize: 12,
        backgroundColor: theme.palette.textColor.gray11,
        color: theme.palette.textColor.gray6,
    },
    konsultasiBox: {
        position: 'relative',
    },
    konsultasiList: {
        position: 'relative',
        padding: '0 !important',
    },
    listRoot:{
        display: 'flex'
    },
    listItem: {
        padding: '13px 0',
        margin: '7px 0',
        backgroundColor: '#fff',
        borderRadius: 10,
        position: 'relative',
        zIndex: 2,
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        "&:hover": {
          backgroundColor: "#EDEDED",
        },
    },
    listItemAva: {
        marginLeft: 10,
    },
    avatar: {
        background: 'transparent',
        justifyContent: 'flex-end',
        position: 'relative',
        overflow: 'visible',
    },
    onlineStats: {
        backgroundColor: theme.palette.textColor.green,
        width: 11,
        height: 11,
        position: 'absolute',
        top: 0,
        right: 1,
        borderRadius: 24,
    },
    icoThumb: {
        width: 20,
        height: 20,
    },
    profile: {
        display: 'flex',
        flexFlow: 'column nowrap',
        justifyContent: 'space-between',
    },
    profileName: {
        fontSize: 13,
        color: theme.palette.textColor.gray8,
        fontWeight: 600,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '@media(min-width: 320px)': {
            width: 90,
        },
        '@media(min-width: 360px)': {
            width: 115,
        },
        '@media(min-width: 400px)': {
            width: 155,
        },
        '@media(min-width: 440px)': {
            width: 185,
        },
        '@media(min-width: 480px)': {
            width: 220,
        },
        '@media(min-width: 540px)': {
            width: 265,
        },
        '@media(min-width: 600px)': {
            width: 300,
        },
        '@media(min-width: 660px)': {
            width: 350,
        },
        '@media(min-width: 768px)': {
            width: 400,
        },
    },
    profileJob: {
        fontSize: 12,
        color: theme.palette.textColor.gray6,
        fontWeight: 500,
    },
    chatEnabledBtn: {
        fontSize: 10,
        color: '#fff',
    },
    freeBadge: {
        color: theme.palette.textColor.green,
        fontSize: 10,
        fontWeight: 'bold',
        padding: 0,
        textTransform: 'capitalize',
    },
    btnChat: {
        color: theme.palette.orange.main,
        fontSize: 16,
        fontWeight: 600,
        padding: 0,
        textAlign: 'left',
        textTransform: 'capitalize',
    },
    rateBtn: {
        fontSize: 10,
        color: theme.palette.textColor.blue,
        border: 'solid 1px' + theme.palette.textColor.blue,
    },
    thumbUpIcon: {
        width: 14,
        height: 'auto',
        marginRight: 5,
    },
    profileStats: {
        fontSize: 12,
        marginBottom: 10,
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'center'
    },
    inActiveprofileStats: {
        fontSize: 12,
        marginBottom: 3,
        display: 'flex',
        flexFlow: 'column nowrap',
        filter: 'grayscale(100%)',
    },
    notifValue: {
        marginLeft: 10,
    },
    notifTxt: {
        fontSize: 13,
    },
    timeStamp: {
        marginLeft: 5,
        fontSize: 11,
        color: theme.palette.textColor.gray,
    },
    inactiveList: {
        padding: 0,
        marginBottom: 3,
        filter: 'grayscale(100%)',
        height: 100,
    },
    disabledBtn: {
        filter: 'grayscale(100%)',
        color: theme.palette.textColor.gray,
        border: 'solid 1px' + theme.palette.textColor.gray,
    },
    noMitra: {
        marginTop: '10%',
        fontSize: 14
    },
    profileAva: {
        width: 40,
        height: 40,
        borderRadius: 10,
    },
    online: {
        color: theme.palette.textColor.green,
        backgroundColor: '#44b700',
        padding: 5
    },
    offline: {
        color: theme.palette.textColor.gray2,
        backgroundColor: theme.palette.textColor.gray,
        padding: 5
    },
    Coin: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.lightBlue.main,
        padding: 0,
        borderRadius: 3,
        width: 74,
        height: 23,
        color: '#fff',
        fontSize: 10,
        fontWeight: 600,
    },
    price: {
        color: '#d47604',
        fontWeight: 'bold',
    },
    imgCoin: {
        width: 20,
        height: 20,
        marginLeft: 5
    },
}))

export default useStyles;