// React
import React from 'react';

// Router
import { Route } from 'react-router-dom';

// Styles
import useStyles from './styles';

// Component's
import AppHeader from '../../components/AppHeader';
// import LengkapiData from '../lengkapiData';
import Verifikasi from '../verifikasi';
import Harapan from '../harapan';
import SyaratKetentuan from '../syaratKetentuan';
import PrivacyPolicy from '../syaratKetentuan/privacyPolicy';
import NewQuestion from '../lengkapiData/newQuestion';

function LayoutA() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <AppHeader />
            <div className={classes.appBody}>
                <Route path="/lengkapiData" render={matchProps => <NewQuestion {...matchProps} />} />
                <Route path="/verifikasi" render={matchProps => <Verifikasi {...matchProps} />} />
                <Route path="/harapan" render={matchProps => <Harapan {...matchProps} />} />
                <Route path='/syaratKetentuan' component={SyaratKetentuan} />
                <Route path='/privacyPolicy' component={PrivacyPolicy} />
            </div>
        </div>
    );
}

export default LayoutA;