import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
        userSelect: 'none',
        msUserSelect: 'none',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        width: '100%'
    },
    rooot:{
        flexGrow: 1,
        height: '100vh',
        backgroundColor: '#fff',
        overflow: 'auto',
    },
    rootNotif:{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexGrow: 1,
        userSelect: 'none',
        width: '100%',
        '@media(min-width: 960px)': {
            width: '70%',
            margin: 'auto',
        },
    }, 
    appBody:{
        position: 'relative',
        zIndex: 1,
        width: '100%',
        margin: 'auto',
    },
    pageHeading:{
        backgroundColor: theme.palette.textColor.white,
        padding: '25px 0',
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        '& > h2':{
            margin: 0,
        }
    },
    pageTitle: {
        color: theme.palette.neutrals.n80,
        fontWeight: "bold",
        fontSize: 20,
        margin: "20px 0px",
      },
    DialogTitle: {
        textAlign: 'center',
    },
    txt: {
        textAlign: 'center', 
        width: '70%', 
        margin: '0 auto'
    },
    ig: {
        color: theme.palette.primary.main
    },
    logoIg: {
        width: 200, 
        height: 200,
        margin: 'auto'
    },
    btnOke: {
        padding: '10px', 
        marginTop: '20px', 
        borderTop: '1px solid #dedede', 
        color: '#38b1db', 
        borderBottom: '1px solid #dedede',
        textTransform: 'capitalize',
    },
    btnNo: {
        padding: '10px',
        textTransform: 'capitalize',
    },
    settingBody:{
        width: '100%',
        padding: '19px 16px'
    },
    settingBodyInner:{
        // maxWidth: "630px",
        margin: '0 auto 80px',
        // border: 'solid 1px' + theme.palette.textColor.gray2,
        '@media(min-width: 320px)': {
            width: '100%',
        },
        '@media(min-width: 960px)': {
            width: '70%',
        },
    },
    historyBack: {
        position: 'absolute',
        top: 0,
        left: 10,
        right: 'auto',
        bottom: 0,
        margin: 'auto',
    },
    historyBack1: {
        position: 'absolute',
        left: 0,
        top: 15,
        color: '#fff',
    },
    settingList:{
        padding: '0 !important',
    },
    subHeader:{
        // backgroundColor: theme.palette.textColor.gray3,
        textAlign: 'left',
        marginTop: 20,
        paddingLeft: 45,
        fontSize: 17,
        fontWeight: 700,
        color: theme.palette.textColor.gray6,
    },
    subHeaderAlt:{
        textAlign: 'left',
        marginTop: 20,
        fontSize: 17,
        fontWeight: 700,
        color: theme.palette.textColor.gray6,
    },
    listItemText:{
        color: theme.palette.textColor.gray8,
        fontWeight: 700,
        fontSize: 14,
    },
    listIcon:{
        minWidth: "30px !important",
        color: theme.palette.textColor.gray8,
    },
    iconBtn:{
        color: theme.palette.textColor.gray8 + ' !important',
    },
    avatarChange:{
        position: 'relative',
        width: 55,
        height: 55,
        margin: '5px auto',
    },
    fabProgress:{
        position: 'absolute',
        top: -3,
        left: -4,
    },
    change:{
        position: 'absolute',
        right: -55,
        bottom: 30,
        width: 25,
        height: 25,
        backgroundColor: theme.palette.textColor.gray2,
        padding: 0,
    },
    icoCamera:{
        width: 15,
        height: 15,
    },
    avatarImg: {
        margin: '10px auto' ,
        width: 109,
        height: 109,
    },
    voucherHead:{
        backgroundColor: theme.palette.lightBlue.main,
        borderBottomLeftRadius: 30,
        borderBottomRightRadius: 30,
        height: 70,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        zIndex: 2,
    },
    voucherTitle:{
        color: '#fff',
        textAlign: 'center',
        fontSize: 21,
        margin: 0,
    },
    voucherBox:{
        backgroundColor: '#fff',
        position: 'relative',
        zIndex: 1,
        borderRadius: 6,
        padding: '16px',
        margin: '0 auto 30px',
        '@media(min-width: 320px)': {
            width: '92%',
        },
        '@media(min-width: 960px)': {
            width: '40%',
        },
    },
    kodeVoucher:{
        width: 'calc(100% - 71px)',
        height: 'fit-content',
        color: theme.palette.textColor.gray6,
        // borderRadius: 10,
        // padding: '0',
        // marginRight: 5,
        // borderColor:'transparent',
        // borderWidth: 0,
    },
    marginDense:{
        transform: 'translate(9px, 9px) scale(1) !important',
    },
    tfRoot:{
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense':{
            transform: 'translate(14px, 9px) scale(1)'
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink':{
            transform: 'translate(14px, -6px) scale(0.75)',
        },
    },
    btnKirimWrap:{
        minWidth: 71,
        marginLeft: 10,
    },
    btnKirim:{
        minWidth: 71,
        width: 'fit-content',
        height: 'fit-content',
        margin: '0',
        display: 'block',
        padding: '5px 15px',
        borderRadius: 3,
        backgroundColor: theme.palette.orange.main,
        color: '#fff',
        fontSize: '1rem',
        fontWeight: 700,
    },
    listHead:{
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        padding: '15px 0',
    },
    listTitle:{
        color: theme.palette.textColor.gray8,
        fontSize: '0.625rem',
        fontWeight: 500,
    },
    listTitleRight:{
        color: theme.palette.textColor.gray8,
        fontSize: '0.625rem',
        fontWeight: 500,
        paddingRight: 20,
    },
    // listBody:{
    //     '& > div:last-child':{
    //         borderBottom: 'none',
    //     }
    // },
    voucherRow:{
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: 'solid 1px ' + theme.palette.textColor.gray6,
        paddingBottom: 12,
        marginBottom: 7,
        
    },
    voucherIcon:{
        width: 33,
        height: 30,
        '& > img':{
            width: '100%',
            height: 'auto'
        }
    },
    voucherDesc:{
        textAlign: 'left',
        width: '75%',
        margin: '0 10px',
    },
    voucherName:{
        color: theme.palette.textColor.gray8,
        fontSize: '0.813rem',
        fontWeight: 500,
    },
    voucherExpire:{
        color: theme.palette.textColor.gray6,
        fontSize: '0.500rem',
        fontWeight: 500,
    },
    seeDetail:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    radioWrap:{
        marginTop: -5,
        position: 'relative',
        zIndex: 1,
    },
    uncheckedRadio:{
        width: 16,
        height: 16,
        padding: 0,
    },
    checkedRadio:{
        color: theme.palette.orange.main,
        width: 16,
        height: 16,
        padding: 0,    
    },
    detailLink:{
        color: theme.palette.lightBlue.main,
        fontSize: '0.500rem',
        fontWeight: 400,
        marginTop: -5,
        position: 'relative',
        zIndex: 2,    
    },
    okBtn:{
        backgroundColor: theme.palette.lightBlue.main,
        width: 94,
        height: 28,
        color: '#fff',
        fontSize: '0.938rem',
        fontWeight: 700,
        margin: '30px auto 10px'
    },
    voucherModal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
      },
    voucherPaper: {
        width: 250,
        backgroundColor: '#fff',
        border: '2px solid #aaa',
        textAlign: 'center'
    },
    voucherDialogTitle:{
        textAlign: 'center',
    },
    dialogAct:{
        justifyContent: 'center',
    },
    btnVoucher: {
        width: '100%',
        height: 40,
        borderTop: '1px solid' + theme.palette.textColor.gray2,
        textTransform: 'capitalize',
    },
    load: {
        position: 'absolute',
        top: '80%',
        left: '45%',
        marginTop: -6,
    },
    historyBackk: {
        position: 'absolute',
        left: 0,
        top: 25,
    },
    pageTitle1:{
        color: theme.palette.textColor.gray,
        fontSize: 24,
        marginBottom: 10,
    },
    formBox:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export default useStyles;