import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { useDocumentData } from "react-firebase-hooks/firestore";

import useStyles from "./styles/checkout";

import Button from "@material-ui/core/Button";
import { Backdrop, CircularProgress } from "@material-ui/core";

import {
  useFirebase,
  firestore,
  functions,
} from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";
import { currency } from "../../utils/formatter-rupiah";
import { HFHeader } from "../../components/AppHeaderNew";
import AppPageLoading from "../../components/AppPageLoading";
import BottomNav from "../../components/BottomNav";
//import axios from "axios";

function CheckoutCredit(props) {
  const classes = useStyles();
  const { user } = useFirebase();
  const { profile } = useSiswa();
  const params = useParams();

  // const [isSubmitting, setSubmitting] = useState(true);
  const [loading, setLoading] = useState(false);
  const [transaksi, transaksiLoading] = useDocumentData(
    firestore.collection("transaksi").doc(params.no_transaksi)
  );

  const handleClick = async () => {
    setLoading(true);

    try {
      //post payment xendit
      // setSubmitting(true);

      const docId = params.no_transaksi;
      const noTransaksi = docId;

      //functions.useEmulator("localhost", 5001);
      const transaksiTopup = functions.httpsCallable("payment-topup");
      const response = await transaksiTopup({
        user_id: user?.uid,
        doc_id: docId,
        nama: profile?.nama ?? "",
        email: profile?.email ?? "",
        no_hp: profile?.no_hp ?? "08121075203",
        no_transaksi: `${noTransaksi}`,
      });

      const data = response.data;

      if (data?.invoice_url !== "") {
        // eslint-disable-next-line no-unused-expressions
        window?.mixpanel?.track(" Coin - Proses Button Clicked", {
          userId: user?.uid,
          tempUID: user?.tempUID,
        });
        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_topupCheckout&email=${profile?.email}`);
        window.open(data?.invoice_url, "_self");
      }
      // setSubmitting(false);
    } catch (err) {
      console.log(err.message);
    }
    setLoading(false);
  };

  // console.log(loading);
  // console.log(transaksi);

  return (
    <>
      <Backdrop style={{ zIndex: 1000 }} open={loading}>
        <CircularProgress color="secondary" />
      </Backdrop>
      <div className={classes.root}>
        <HFHeader title="logo" pgDesc={false} noBorder={true} />

        {transaksiLoading ? (
          <AppPageLoading />
        ) : (
          <div className={classes.content}>
            <p className={classes.pageTitle}>Checkout Credit</p>
            <p className={classes.pageDesc}>
              Anda akan melakukan top-up
              <span className={classes.credit}>
                {" "}
                {transaksi.jumlah_coin} Credit
              </span>{" "}
              senilai
            </p>
            <p className={classes.textRp}>
              {currency(parseInt(transaksi.jumlah_transfer) + 1500)} *
            </p>
            <p className={classes.pageSubtitle}>
              *( sudah termasuk biaya admin Rp 1.500
            </p>
            <div>
              <Button
                style={{
                  marginTop: 20,
                  fontWeight: "bold",
                }}
                variant="contained"
                color="primary"
                onClick={handleClick}
                disableElevation
                size="large"
                fullWidth
              >
                Lanjutkan Pembayaran
              </Button>
            </div>
          </div>
        )}
      </div>
      <BottomNav />
    </>
  );
}

export default CheckoutCredit;
