import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: '55vh',
    },
    loadingBody:{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',
        width: 135,
        height: 250,
    },
    logo:{
        width: 135,
        margin: '20px auto',
    },
    logotype:{
        fontSize: 36,
        fontWeight: 'bold',
    },
    logotypeA:{
        color: theme.palette.primary.main,
    },
    logotypeB:{
        color: theme.palette.secondary.main,
    },
    subtitleText:{
        fontSize: 10,
    },
    loadingProgress:{
        width: '100%',
        margin: '0 auto',
    },
    loadingText:{
        margin: '15px 0 0',
        fontSize: 15,
        // fontWeight: 'bold'
        fontStyle: "italic",
    },
    quoteWrap:{
        position: 'absolute',
        top: 'auto',
        left: '0',
        right: '0',
        bottom: 20,
        margin: 'auto',
        width: '80%',
    },
    quoteText:{
        textAlign: 'center',
        fontWeight: 'bold',
        color: '#999999'
    }
}))

export default useStyles;