// React
import React from 'react';

// Styles
import useStyles from './styles';

function SyaratKetentuan() {
    // UseStyles
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.tcBody}>
            <div className={classes.pageHeading}>
                <h2 className={classes.pageTitle}><strong>Terms and Conditions</strong></h2>
            </div>
            <div className={classes.pageContent}>
                <p>Welcome to Karir.ai</p>

            </div>
            </div>
        </div>
    )
}

export default SyaratKetentuan;