import React from "react";

import useStyles from "./styles";

import Button from "@material-ui/core/Button";
import History from "@material-ui/icons/History";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import PersonAddOutlined from "@material-ui/icons/PersonAddOutlined";
//import { useSiswa } from "../../components/SiswaProvider";
import { Hidden, Box } from "@material-ui/core";

import { useCoin } from "../../components/CoinProvider";
import { delimiters } from "../../utils/formatter-rupiah";
import { HFHeader } from "../../components/AppHeaderNew";
import CreditIcon from "../../images/icon-credits";
import { useFirebase } from "../../components/FirebaseProvider";
import TopNav from "../../components/TopNav";
import Grid from "@material-ui/core/Grid";

function MyCredit(props) {
  const classes = useStyles();
  const { Coin } = useCoin();
  const { user } = useFirebase();
  //const { profile } = useSiswa();

  const koin = Coin && Coin.coin ? Coin.coin : 0;

  const handleTransaksi = () => {
    // eslint-disable-next-line no-unused-expressions
    window?.mixpanel?.track(" Mycredit - riwayat topup Button Clicked", {
      userId: user?.uid,
      tempUID: user?.tempUID,
    })
    props.history.push("/topUpHistory");
  };

  return (
    <div className={classes.root}>
      
      {/* <img
        src={`https://auto.karir.ai/mtracking.gif?page_title=dashboard_kredit&email=${profile?.email}`} alt=''
      /> */}
      <HFHeader title="logo" pgDesc={false} noBorder={true} />
      <div className={classes.content}> 
        <Hidden mdUp>
          <Box style={{ marginBottom: '20px' }}><TopNav /></Box>
        </Hidden>
        <p className={classes.pageTitle}>Saldo Kredit Saya</p>
        <Button
          variant="contained"
          disableElevation
          className={classes.credit}
          startIcon={<CreditIcon color="inherit" style={{ fontSize: 25 }} />}
        >
          {delimiters(koin)}
        </Button>
        <p className={classes.pageDesc}>
          Maksimalkan fitur-fitur karir.ai dengan menggunakan credit agar kamu
          makin mudah mendapatkan pekerjaan impianmu!
        </p>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Button
            fullWidth
            className={classes.imBtn}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              window?.mixpanel?.track(" Mycredit - topup Button Clicked", {
                userId: user?.uid,
                tempUID: user?.tempUID,
              })
              props.history.push("/topUp")
            }}
            >
              <div className={classes.imMenu}>
                <AddCircleOutline style={{ fontSize: 30 }} />
                <div style={{ marginLeft: "15px" }}>
                  <p className={classes.imName}>Top-up</p>
                  <p className={classes.imDesc}>
                    Lakukan top-up untuk menambah credit anda
                  </p>
                </div>
              </div>
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
            fullWidth
            className={classes.imBtn}
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              window?.mixpanel?.track(" Mycredit - ajak teman Button Clicked", {
                userId: user?.uid,
                tempUID: user?.tempUID,
              })
              props.history.push("/ajakTeman")
            }}
            >
              <div className={classes.imMenu}>
                <PersonAddOutlined style={{ fontSize: 30 }} />
                <div style={{ marginLeft: "15px" }}>
                  <p className={classes.imName}>Ajak Teman</p>
                  <p className={classes.imDesc}>
                    Ajak teman anda dan dapatkan reward credit
                  </p>
                </div>
              </div>
            </Button>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button fullWidth className={classes.imBtn} onClick={handleTransaksi}>
              <div className={classes.imMenu}>
                <History style={{ fontSize: 30 }} />
                <div style={{ marginLeft: "15px" }}>
                  <p className={classes.imName}>Riwayat Top-up</p>
                  <p className={classes.imDesc}>
                    Daftar riwayat transaksi top-up anda
                  </p>
                </div>
              </div>
            </Button>
          </Grid>
        </Grid>
        
        
        
      </div>
    </div>
  );
}

export default MyCredit;
