
import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { CheckCircleOutline, } from '@material-ui/icons';

export default function Sixth({ form, handleChange, error, setForm, setError }) {
    const handleRadioChange = (event) => {
        setForm({
            ...form,
            pengalaman: event.target.value

        });

        setError({
            ...error,
            pengalaman: ''
        });
    };
    return (
        <>
            <div style={{ textAlign: 'left' }}>
                <Typography style={{ marginBottom: '10px' }}>Berapa tahun pengalaman kerja yang {form?.nama_panggilan} memiliki?*</Typography>
                <Typography variant='subtitle2'>Jika {form?.nama_panggilan} adalah fresh graduate yang belum pernah bekerja full-time sebelumnya, silahkan pilih none.</Typography>
                <FormControl style={{ marginTop: '20px', marginLeft: '20px' }} component="fieldset" error={error.pengalaman ? true : false}
                // className={classes.formControl}
                >
                    <RadioGroup aria-label="quiz" name="quiz" value={form?.pengalaman} onChange={handleRadioChange}>
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="None" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="None" />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="<1" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="<1 " />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="1-2" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="1-2" />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="3-5" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="3-5" />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value=">5" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label=">5" />
                    </RadioGroup>
                    {/* <FormHelperText>{helperText}</FormHelperText> */}

                </FormControl>
                <Typography variant='subtitle2' color='error'>{error.pengalaman && error.pengalaman}</Typography>

            </div>
        </>
    )
}

