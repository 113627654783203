import React, { useState } from 'react';
import { IconButton } from "@material-ui/core"
import GuideDialog from './GuideDialog';
import { YouTube } from '@material-ui/icons';

export default function GuideComponents({ content, idTutorial }) {
    const [dialogGuide, setDialogGuide] = useState(false);

    return (<>
        <IconButton
            onClick={() => {
                setDialogGuide(true)
            }}
            style={{
                padding: 0,
                marginBottom: '2px',
                // float: 'right'
                marginLeft: '8px',
                // marginTop: '10px'
            }}
        >
            <YouTube color="primary" />
            {/* <Lottie animationData={ideaIcon} loop={true} style={{
                    height: 50,
                    width: 40
                }} /> */}

            {/* {View} */}
            {/* <Lottie
                    options={defaultOptions}
                    height={50}
                    width={40}
                /> */}
        </IconButton>
        <GuideDialog
            open={dialogGuide}
            handleClose={() => {
                setDialogGuide(false)
            }}
            content={content}
            idTutorial={idTutorial}
        />
    </>
    )
}