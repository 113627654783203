
import { TextField, Typography } from '@material-ui/core';
import React from 'react';

export default function Fourth({ form, handleChange, error }) {


    return (
        <> <div style={{ textAlign: 'left' }}>
            <Typography style={{ marginBottom: '10px' }}>Apa nama akun Instagram / TikTok / Facebook {form?.nama_panggilan} ?*</Typography>
            <Typography variant='subtitle2'>Jika {form?.nama_panggilan || 'kamu'} menggunakan username berbeda, pisahkan dengan tanda koma (IG:karirai , FB:karir.ai_official , Tiktok: kariraiofficial)</Typography>
        </div>
            <TextField
                fullWidth
                variant="outlined"
                id="nama_sosmed"
                name="nama_sosmed"
                placeholder="Akun Instagram"
                // className={classes.textField}
                value={form.nama_sosmed}
                onChange={handleChange}
                error={error.nama_sosmed ? true : false}
                helperText={error.nama_sosmed}
            // disabled={isSubmiting}
            />
        </>
    )
}

