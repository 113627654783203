import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: 60,
    userSelect: "none",
    msUserSelect: "none",
    WebkitUserSelect: "none",
    MozUserSelect: "none",
  },
  content: {
    width: "100%",
    // maxWidth: "630px",
    padding: "16px 16px",
    '@media(min-width: 960px)': {
      width: '90%',
      margin: 'auto',
  },
  },
  pageTitle: {
    color: theme.palette.neutrals.n80,
    fontWeight: "bold",
    fontSize: 20,
    margin: "20px 0px",
  },
  counterBtn: {
    backgroundColor: theme.palette.secondary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
    borderRadius: "0px",
  },
  counterItem: {
    width: 25,
    height: 25,
  },
  InputProps: {
    textAlign: "center",
    height: 49,
  },
  inputProps: {
    textAlign: "center",
    color: theme.palette.neutrals.n80,
    fontSize: 18,
    fontWeight: "bold",
  },
  txtField: {
    backgroundColor: theme.palette.neutrals.n0,
    borderRadius: 8,
    height: 49,
    marginBottom: 20,
  },
  prcBtn: {
    fontWeight: "bold",
    textTransform: "none",
  },
  dividerpromo: {
    borderTop: "solid 1px #ddd",
    marginTop: 50,
    marginBottom: 30,
  },
  promoRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    marginBottom: 15,
  },
  promoTitle: {
    color: theme.palette.neutrals.n80,
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    alignItems: "center",
    "& > span": {
      fontSize: 18,
      fontWeight: "bold",
      marginLeft: 10,
    },
  },
  strikeText: {
    textDecoration: "line-through",
    color: theme.palette.error.main,
    fontSize: 12,
  },
  promoBtn: {
    textTransform: "capitalize",
    color: "#FFFF",
    width: 120,
    height: 30,
  },
}));

export default useStyles;
