import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    appBar: {
        borderBottom: 'solid 5px #106aff',
    },


    header: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: 50,
      "@media(min-width: 320px)": {
        width: "90%",
      },
      "@media(min-width: 560px)": {
        width: "80%",
      },
      "@media(min-width: 960px)": {
        width: "70%",
      },
      "@media(min-width: 1100px)": {
        width: "50%",
      },
      margin: "auto",
    },
    pageName: {
      position: "absolute",
      color: "#fff",
      fontSize: 12,
      top: -10,
      fontStyle: "italic",
      textTransform: "capitalize",
      fontWeight: 500,
      left: 50,
    },


    logo: {
        width: 150,
        height: 'auto',
        display: 'block',
        margin: 'auto',
        padding: 10,
    },
    container: {
        padding: 10,
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
    },
    guide: {
        margin: '10px auto',
        width: 300,
        height: 435,
        color: '#fff',
        fontSize: 14,
        textAlign: 'left',
        backgroundColor: '#2bafe8',
        borderRadius: 8,
        padding: 15,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    list: {
        padding: '0 0 0 20px',
    },
    listItem: {
        marginBottom: 5,
        fontWeight: 'bold',
        fontSize: 13,
    },
    frontImg: {
        display: 'block',
        margin: '10px auto',
        border: 'solid 3px #fff',
        borderRadius: '50%',
    },
    startBtn: {
        display: 'block',
        textAlign: 'center',
        margin: '20px auto',
        border: 'none',
        padding: '13px 18px',
        backgroundColor: '#FBA518',
        boxShadow: '2px 2px 1px #fff',
        color: '#fff',
        fontWeight: 'bold',
        width: '90%',
        borderRadius: 25,
        "&:hover": {
          backgroundColor: '#E99104',
          boxShadow: '2px 2px 1px #fff',
        },
    },
    btnWrap: {
        position: 'relative',
        margin: '25px 0 40px',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    statement: {
        width: 280,
        margin: 'auto',
    },
    statementImg: {
        width: 280,
        height: 160,
    },
    option: {
        position: 'relative',
        marginBottom: 20,
        marginLeft: 0,
        padding: 5,
        border: 'solid 1px #ddd',
        borderRadius: 6,
    },
    topMsgText: {
        color: '#777',
        fontSize: 14,
        width: 280,
        margin: '0 auto 10px',
    },
    topProgress: {
        width: 280,
        margin: 'auto',
    },
    progressCount: {
        color: '#777',
        fontSize: 14,
        margin: '10px 0 0'
    },
    certainMsg: {
        margin: '5px 0 10px',
        height: 21,
    },
    certainMsgText: {
        fontSize: 13,
    },
    illustration: {
        width: 280,
        height: 160,
        overflow: 'hidden',
        position: 'relative',
        borderRadius: 6,
    },
    statementCaption: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '99.9%',
        backgroundColor: 'rgba(0,0,0,0.7)',
        color: '#fff',
        padding: 5,
        fontSize: 13,
    },
    radio: {
        position: 'absolute',
        top: 10,
        right: 10,
        zIndex: 30,
        color: '#fff',
    }
}))