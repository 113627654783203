// React
import React, { useEffect, useState } from "react";

// Components
import AppLoading from "../../components/AppLoading";
import AppHeader from "../../components/AppHeader";
import {
  firestore,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";

// Styles
import useStyles from "./styles";

// MUI
import { withStyles } from "@material-ui/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";

// Icon
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import isEmail from "validator/lib/isEmail";

// React Router Dom
import { useHistory, useParams } from "react-router-dom";

// React Firebase Hooks
import { useDocumentData } from "react-firebase-hooks/firestore";

// JSON
import lokasiUjian from "./lokasi_ujian.json";
import dataKelas from "../../components/AppSelect/data/kelas.json";
import dataProvinsi from "../../components/AppSelect/data/provinsi.json";

// Hooks
import useRest from "../../hooks/useRest";
import { number } from "../../utils/validation";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: 4,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
      "&:hover fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
    },
  },
})(TextField);

function LengkapiPendaftaran({ register = false }) {
  const classes = useStyles();

  const { eventId, pesertaId } = useParams();

  const { user } = useFirebase();

  const history = useHistory();

  const [isSubmitting, setIsSubmtting] = useState(false);

  const eventDoc = firestore.doc(`events/${eventId}`);

  const [event, loadingEvent] = useDocumentData(eventDoc);

  const pesertaDoc =
    register === false
      ? eventDoc.collection("peserta_event").doc(pesertaId)
      : null;

  const [peserta, loadingPeserta] = useDocumentData(pesertaDoc);

  const pilihan = [{ title: "SAINTEK" }, { title: "SOSHUM" }];

  const [form, setForm] = useState({
    nama_lengkap: "",
    asal_sekolah: "",
    provinsi: null,
    kota: null,
    kelas: null,
    jurusan: "",
    no_telepon: "",
    no_wa: "",
    email: "",
    type: null,
    location: null,
    tahu: "",
    teman: "",
    telepon_teman: "",
  });

  const [error, setError] = useState({
    nama_lengkap: "",
    asal_sekolah: "",
    provinsi: "",
    kota: "",
    kelas: "",
    jurusan: "",
    no_telepon: "",
    no_wa: "",
    email: "",
    type: "",
    location: "",
    telepon_teman: "",
  });

  const [listKota, loadingKota] = useRest({
    url: "kota",
    method: "get",
    params: {
      provinsi_id: form?.provinsi?.provinsi_id,
    },
  });

  useEffect(() => {
    if (register === false && peserta) {
      setForm((f) => ({
        ...f,
        nama_lengkap: peserta?.nama_lengkap ?? "",
        no_telepon: peserta?.no_telepon ?? "",
        no_wa: peserta?.no_wa ?? "",
        email: peserta?.email ?? "",
        location: peserta?.lokasi_event ?? null,
        type: {
          title: peserta?.jenis_tryout,
        },
      }));
    }
  }, [peserta, register]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((e) => ({
      ...e,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors = { ...error };

    const {
      nama_lengkap,
      asal_sekolah,
      provinsi,
      kota,
      kelas,
      jurusan,
      no_telepon,
      no_wa,
      email,
      type,
      location,
      teman,
      telepon_teman,
    } = form;

    if (!nama_lengkap) {
      newErrors.nama_lengkap = "Nama wajib diisi!";
    }

    if (!asal_sekolah) {
      newErrors.asal_sekolah = "Asal Sekolah wajib diisi!";
    }

    if (provinsi === null) {
      newErrors.provinsi = "Provinsi wajib diisi";
    }

    if (kota === null) {
      newErrors.kota = "Kota / Kabupaten wajib diisi";
    }

    if (kelas === null) {
      newErrors.kelas = "Kelas wajib diisi!";
    }

    if (!jurusan) {
      newErrors.jurusan = "Jurusan wajib diisi";
    }

    if (!no_telepon) {
      newErrors.no_telepon = "No. Telepon wajib diisi!";
    } else if (!number.test(no_telepon)) {
      newErrors.no_telepon = "No. Telepon tidak valid!";
    }

    if (!no_wa) {
      newErrors.no_wa = "No. Whatsapp Aktif wajib diisi!";
    } else if (!number.test(no_wa)) {
      newErrors.no_wa = "No. Whatsapp tidak valid!";
    }

    if (!email) {
      newErrors.email = "Alamat Email wajib diisi!";
    } else if (!isEmail(email)) {
      newErrors.email = "Alamat Email tidak valid!";
    }

    if (type === null) {
      newErrors.type = "Jenis Tryout wajib diisi!";
    }

    if (location === null) {
      newErrors.location = "Lokasi Ujian wajib diisi!";
    }

    if (teman && number.test(teman)) {
      newErrors.teman = "Nama tidak valid!";
    }

    if (telepon_teman && !number.test(telepon_teman)) {
      newErrors.telepon_teman = "No. Telepon tidak valid!";
    }

    return newErrors;
  };

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setIsSubmtting(true);

        const {
          nama_lengkap,
          asal_sekolah,
          provinsi,
          kota,
          kelas,
          jurusan,
          no_telepon,
          no_wa,
          email,
          type,
          location,
          tahu,
          teman,
          telepon_teman,
        } = form;

        const newData =
          register === true
            ? {
                nama_lengkap,
                asal_sekolah,
                provinsi,
                kota,
                kelas,
                jurusan,
                no_telepon,
                no_wa,
                email,
                email_user: user?.email,
                jenis_tryout: type?.title,
                lokasi_event: location,
                tahu,
                teman,
                telepon_teman,
                registrasi_lengkap: true,
                event_id: eventId,
                uid: user?.uid,
                status: "created",
                created_at: Date.now(),
              }
            : {
                asal_sekolah,
                provinsi,
                kota,
                kelas,
                jurusan,
                registrasi_lengkap: true,
                email_user: user?.email,
                tahu,
                teman,
                telepon_teman,
                updated_at: Date.now(),
              };

        if (register === true) {
          const transaksiRef = firestore.collection("transaksi_event");

          const transaksiId = transaksiRef.doc().id;

          await firestore
            .doc(`transaksi_event/${transaksiId}`)
            .set({ ...newData, event_transaksi_id: transaksiId });

          await firestore
            .collection("event_state")
            .doc(`${eventId}_${user?.uid}`)
            .set(
              {
                path: `/acara/u/${eventId}/pembayaran/${transaksiId}`,
              },
              { merge: true }
            );

          history.push(`/acara/u/${eventId}/pembayaran/${transaksiId}`);
        } else {
          // bonusRefEvent
          const bonusRefEvent = functions.httpsCallable("bonusRefEvent");

          if (tahu !== "" && teman !== "" && telepon_teman !== "") {
            await bonusRefEvent({});
          }

          await pesertaDoc.set(newData, { merge: true });

          await firestore
            .collection("event_state")
            .doc(`${eventId}_${user?.uid}`)
            .set(
              {
                path: `/acara/ticket/${eventId}/${peserta?.pesertaId}`,
              },
              { merge: true }
            );

          history.push(`/acara/ticket/${eventId}/${peserta?.pesertaId}`);
        }

        setIsSubmtting(false);
      } catch (error) {
        console.log(error);
        setIsSubmtting(false);
      }
    }
  };

  if (loadingEvent || loadingPeserta) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.tcBody}>
        <AppHeader />
        <div className={classes.pageHeading}>
          <h1 className={classes.pageTitle}>Lengkapi Data</h1>
          <h2 className={classes.pageSubtitle1}>Acara {event?.nama ?? "-"}</h2>
        </div>
        <div className={classes.pageContent}>
          <div className={classes.itemTf}>
            <p className={classes.label}>Nama Lengkap</p>
            <CustomTextField
              name="nama_lengkap"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.nama_lengkap}
              disabled={isSubmitting}
            />
            {error?.nama_lengkap && (
              <p className={classes.error}>{error?.nama_lengkap}</p>
            )}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Asal Sekolah</p>
            <CustomTextField
              name="asal_sekolah"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.asal_sekolah}
              disabled={isSubmitting}
            />
            {error?.asal_sekolah && (
              <p className={classes.error}>{error?.asal_sekolah}</p>
            )}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Provinsi</p>
            <Autocomplete
              options={dataProvinsi}
              getOptionLabel={(option) => option.nama}
              popupIcon={
                <ArrowDropDown
                  fontSize="small"
                  style={{
                    color: "#fff",
                    backgroundColor: "#777",
                    borderRadius: 4,
                  }}
                />
              }
              renderInput={(params) => (
                <>
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  {error?.provinsi && (
                    <p className={classes.error}>{error?.provinsi}</p>
                  )}
                </>
              )}
              onChange={(e, v) => {
                setForm((f) => ({
                  ...f,
                  provinsi: v,
                  kota: null,
                }));

                setError((e) => ({
                  ...e,
                  provinsi: "",
                }));
              }}
              value={form?.provinsi}
              disabled={isSubmitting}
            />
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Kota / Kabupaten</p>
            <Autocomplete
              options={listKota?.data?.Items ?? []}
              getOptionLabel={(option) => option.nama}
              popupIcon={
                <ArrowDropDown
                  fontSize="small"
                  style={{
                    color: "#fff",
                    backgroundColor: "#777",
                    borderRadius: 4,
                  }}
                />
              }
              renderInput={(params) => (
                <>
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  {error?.kota && (
                    <p className={classes.error}>{error?.kota}</p>
                  )}
                </>
              )}
              onChange={(e, v) => {
                setForm((f) => ({
                  ...f,
                  kota: v,
                }));

                setError((e) => ({
                  ...e,
                  kota: "",
                }));
              }}
              value={form?.kota}
              loading={loadingKota}
              disabled={isSubmitting || form?.provinsi === null}
            />
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Kelas</p>
            <Autocomplete
              options={dataKelas}
              getOptionLabel={(option) => option.nama}
              popupIcon={
                <ArrowDropDown
                  fontSize="small"
                  style={{
                    color: "#fff",
                    backgroundColor: "#777",
                    borderRadius: 4,
                  }}
                />
              }
              renderInput={(params) => (
                <>
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  {error?.kelas && (
                    <p className={classes.error}>{error?.kelas}</p>
                  )}
                </>
              )}
              onChange={(e, v) => {
                setForm((f) => ({
                  ...f,
                  kelas: v,
                }));

                setError((e) => ({
                  ...e,
                  kelas: "",
                }));
              }}
              value={form?.kelas}
              disabled={isSubmitting}
            />
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Jurusan</p>
            <CustomTextField
              name="jurusan"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.jurusan}
              disabled={isSubmitting}
            />
            {error?.jurusan && (
              <p className={classes.error}>{error?.jurusan}</p>
            )}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>No. Telepon</p>
            <CustomTextField
              name="no_telepon"
              placeholder="628123456789"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.no_telepon}
              disabled={isSubmitting}
            />
            {error?.no_telepon && (
              <p className={classes.error}>{error?.no_telepon}</p>
            )}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>No. Whatsapp Aktif</p>
            <CustomTextField
              name="no_wa"
              placeholder="628123456789"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.no_wa}
              disabled={isSubmitting}
            />
            {error?.no_wa && <p className={classes.error}>{error?.no_wa}</p>}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Alamat Email</p>
            <CustomTextField
              name="email"
              fullWidth
              variant="outlined"
              size="small"
              onChange={handleChange}
              value={form?.email}
              disabled={isSubmitting}
            />
            {error?.email && <p className={classes.error}>{error?.email}</p>}
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Pilihan Jenis Tryout</p>
            <Autocomplete
              options={pilihan}
              getOptionLabel={(option) => option.title}
              popupIcon={
                <ArrowDropDown
                  fontSize="small"
                  style={{
                    color: "#fff",
                    backgroundColor: "#777",
                    borderRadius: 4,
                  }}
                />
              }
              renderInput={(params) => (
                <>
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  {error?.type && (
                    <p className={classes.error}>{error?.type}</p>
                  )}
                </>
              )}
              onChange={(e, v) => {
                setForm((f) => ({
                  ...f,
                  type: v,
                }));

                setError((e) => ({
                  ...e,
                  type: "",
                }));
              }}
              value={form?.type}
              disabled={isSubmitting}
            />
          </div>
          <div className={classes.itemTf}>
            <p className={classes.label}>Pilihan Lokasi Ujian</p>
            <Autocomplete
              options={lokasiUjian?.sort((a, b) => {
                if (a.name < b.name) {
                  return -1;
                }
                if (a.name > b.name) {
                  return 1;
                }
                return 0;
              })}
              getOptionLabel={(option) => option.name}
              popupIcon={
                <ArrowDropDown
                  fontSize="small"
                  style={{
                    color: "#fff",
                    backgroundColor: "#777",
                    borderRadius: 4,
                  }}
                />
              }
              renderInput={(params) => (
                <>
                  <CustomTextField
                    {...params}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                  {error?.location && (
                    <p className={classes.error}>{error?.location}</p>
                  )}
                </>
              )}
              onChange={(e, v) => {
                setForm((f) => ({
                  ...f,
                  location: v,
                }));

                setError((e) => ({
                  ...e,
                  location: "",
                }));
              }}
              value={form?.location}
              disabled={isSubmitting}
            />
          </div>
          <div className={classes.pageContent2}>
            <Divider className={classes.hr} style={{ width: "100%" }} />
            <p
              className={classes.pageSubtitle2}
              style={{ textAlign: "center", margin: "30px 0px" }}
            >
              Dapatkan gratis 50 coin PakBudi jika kamu menjawab
              pertanyaan-pertanyaan berikut
            </p>
            <div className={classes.itemTf}>
              <p className={classes.label}>
                Darimana kamu tahu acara MasukPTNid ?
              </p>
              <CustomTextField
                name="tahu"
                fullWidth
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={form?.tahu}
                disabled={isSubmitting}
              />
            </div>
            <div className={classes.itemTf}>
              <p className={classes.label}>
                Nama teman yang kamu rekomendasikan untuk ikut acara ini
              </p>
              <CustomTextField
                name="teman"
                fullWidth
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={form?.teman}
                disabled={isSubmitting}
              />
              {error?.teman && <p className={classes.error}>{error?.teman}</p>}
            </div>
            <div className={classes.itemTf}>
              <p className={classes.label}>No. Telepon temanmu</p>
              <CustomTextField
                name="telepon_teman"
                placeholder="628123456789"
                fullWidth
                variant="outlined"
                size="small"
                onChange={handleChange}
                value={form?.telepon_teman}
                disabled={isSubmitting}
              />
              {error?.telepon_teman && (
                <p className={classes.error}>{error?.telepon_teman}</p>
              )}
            </div>
          </div>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LengkapiPendaftaran;
