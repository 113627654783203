import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        height: '100%',
        // backgroundColor: theme.palette.textColor.gray4,
        // overflow: 'auto',
        position: 'relative',
    },
    appBody: {
        position: 'relative',
        zIndex: 1,
        // width: '90%',
        width: '100%',
        margin: 'auto',
    },
    layer: {
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0,0,0,0.8)',
        position: 'fixed',
        zIndex: 2
    },
    ket: {
        fonSize: '100px',
        fontWeight: 'bold',
        marginTop: '25%',
        color: 'white',
    },
    blueBg: {
        width: '100%',
        height: 250,
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top:0,
        left: 0,
        zIndex: 1,
    },
}))

export default useStyles;