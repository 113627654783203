import React from "react";

import { Redirect } from "react-router-dom";

import useStyles from "./styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Block from "@material-ui/icons/Block";

import { useFirebase } from "../../components/FirebaseProvider";

function Restricted() {
  const classes = useStyles();

  // Firebase
  const { auth, user } = useFirebase();
  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.content}>
      <Block color="primary" fontSize="large" className={classes.ico} />
      <Typography className={classes.title}>Halaman Tidak Dapat Diakses</Typography>
      <Typography className={classes.subtitle}>
        Periksa koneksi internet anda lalu reload halaman. Pastikan anda telah login menggunakan akun user
      </Typography>
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              className={classes.refreshBtn}
              fullWidth
              disableElevation
              onClick={() => {
                window.location.reload();
              }}
            >
              Reload
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.logoutBtn}
              onClick={() => {
                auth.signOut();
              }}
            >
              Logout
            </Button>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default Restricted;
