import React, { useState, useEffect } from "react";

import { Redirect } from "react-router-dom";
import isEmail from "validator/lib/isEmail";

import useStyles from "./styles/index";
// import { withStyles } from "@material-ui/core";

// import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
// import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  useFirebase,

  auth,
  // facebookAuth,
  googleAuth,
  firestore,
} from "../../components/FirebaseProvider";
import AppHeader from "../../components/AppHeader";
import AppLoading from "../../components/AppLoading";
import GoogleLogo from "../../images/google";
import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";

// const CustomTextField = withStyles({
//   root: {
//     backgroundColor: "#fff",
//     borderRadius: 4,
//     "& .MuiOutlinedInput-root": {
//       "& fieldset": {
//         border: "none",
//       },
//       "&.Mui-focused fieldset": {
//         boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
//       },
//       "&:hover fieldset": {
//         boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
//       },
//     },
//   },
// })(TextField);

function Login(props) {
  // Class & Location
  const { location } = props;
  const classes = useStyles();
  // Firebase Use
  const { user, loading } = useFirebase();
  const [open, setOpen] = useState();
  // const [data, setData] = useState()
  // Sign in With Google & Facebook
  // const handleSaveChatColl = async (userauth) => {
  //   await firestore.doc(`chats/${userauth}`).set({

  //   })

  // }
  const handleSocialLogin = (provider) => async () => {
    setOpen(true);
    console.log("mana")
    await auth.signInWithRedirect(provider);
    // const result = await auth.getRedirectResult()
    // setData(result)
    // console.log(result)

    setOpen(false);
  };
  // Set to database for Social Login
  useEffect(() => {
    setOpen(true);
    if (user) {
      const getResult = async () => {
        try {
          const result = await auth.getRedirectResult();
          if (location.search) {
            const idChat = new URLSearchParams(location.search).get('wa')

            await firestore.doc(`chats/${idChat}`).set({
              user_id: result?.user?.uid || ''
            })
            // handleSaveChatColl(userauth?.)
          }
          // if (result.credential) {
          //   const user = result.user;
          //   const date = new Date();
          //   const ref = firestore.doc(`users/${user.uid}`);
          //   const snap = await ref.get();
          //   if (snap.exists) {
          //     await ref.set(
          //       {
          //         ...snap.data(),
          //         nama: user.displayName,
          //         email: user.email,
          //         diperbarui_pada: date,
          //       },
          //       { merge: true }
          //     );
          //   } else {
          //     await ref.set(
          //       {
          //         nama: user.displayName,
          //         email: user.email,
          //         dibuat_pada: date,
          //       },
          //       { merge: true }
          //     );
          //   }
          // }
        } catch (error) {
          console.log(error.message);
        }
      };
      getResult();
    }
    setOpen(false);
  }, [location.search, user]);



  // Form
  const [form, setForm] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  // Error
  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const handleClickShowPassword = () => {
    setForm({ ...form, showPassword: !form.showPassword });
  };

  // On change
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value === "") {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }

    setForm({
      ...form,
      [name]: value,
    });

    setError({
      ...error,
      [name]: "",
    });
  };

  // Submit Button
  const [isSubmitting, setSubmitting] = useState(false);
  // const [loadingg, setLoadingg] = useState(false);
  const timer = React.useRef();

  // Validator error
  const validate = () => {
    const newError = { ...error };
    const { email, password } = form;

    if (!email) {
      newError.email = "Email wajib di isi";
    } else if (!isEmail(email)) {
      newError.email = "Email tidak valid";
    }

    if (!password) {
      newError.password = "Password wajib di isi";
    }
    return newError;
  };

  // On Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmitting(true);
        await auth.signInWithEmailAndPassword(form.email, form.password);
        setSubmitting(false)
      } catch (e) {
        setSubmitting(false)
        const newError = {};

        switch (e.code) {
          case "auth/user-not-found":
            newError.email = "Alamat Email Tidak Terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Alamat Email Tidak Valid";
            break;
          case "auth/wrong-password":
            newError.password = "Kata Sandi Salah";
            break;
          case "auth/user-disabled":
            newError.email = "User Di Blokir";
            break;
          default:
            newError.email = "Terjadi Kesalahan Silahkan Coba Lagi";
            break;
        }
        setError(newError);
      }
    }
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      clearTimeout(timer.current);
    };
  }, []);

  // const handleClick = () => {
  //   if (!loadingg) {
  //     setLoadingg(true);
  //     timer.current = setTimeout(() => {
  //       setLoadingg(false);
  //     }, 2000);
  //   }
  // };
  console.log(new URLSearchParams(location.search).get('wa'))

  // Condition Loading
  if (loading) {
    return <AppLoading />;
  }

  // conditions when there are users for SignIn with form
  if (user) {
    const redirectTo =
      location.state && location.state.from
        ? location.state.from.pathname + location.state.from.search
        : "/lengkapiData";
    return <Redirect to={redirectTo} />;
  }

  return (
    <div className={classes.root}>
      <AppHeader />
      <div className={classes.pageHeading}>
        <h2 className={classes.pageTitle} style={{ marginBottom: 0 }}>
          Selamat datang!
        </h2>
        silahkan masuk menggunakan akun google anda
        <br />
        <br />
      </div>
      <div className={classes.formRowBtn}>
        {/* <p className={classes.otherText}>
            <span className={classes.lineText} />
            <span className={classes.otherTextBody}>Atau</span>
          </p> */}
        {/* <div className={classes.otherReg}> */}
        <Button
          classes={{
            startIcon: classes.stIcon,
          }}
          className={`${classes.goBtn} ${classes.regBtns}`}
          variant="contained"
          onClick={handleSocialLogin(googleAuth)}
          value="google"
          disabled={open}
          size="large"
          startIcon={<GoogleLogo />}
        >
          {/* <img
                className={classes.brandIcon}
                src="https://karirai-dev.s3.ap-southeast-3.amazonaws.com/images/google-logo.png"
                alt=""
              /> */}
          Sign In With Google
        </Button>
        {/* <Button
              disableElevation
              size="medium"
              className={`${classes.fbBtn} ${classes.regBtns}`}
              variant="contained"
              color="primary"
              onClick={handleSocialLogin(facebookAuth)}
              value="facebook"
            >
              <img
                className={classes.brandIcon}
                src="https://pakbudi.s3.ap-southeast-1.amazonaws.com/images/fb-logo-01.png"
                alt=""
              />
              Masuk dengan Facebook
            </Button>
          </div> */}
      </div>
      <div>
        <p style={{
          fontSize: '20px'
        }}>or</p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classes.formRow}>
          <p className={classes.label}>Email</p>
          <TextField
            variant="outlined"
            placeholder="Masukkan Email Anda"
            id="email"
            name="email"
            className={classes.textField}
            value={form.email}
            onChange={handleChange}
            disabled={isSubmitting}
          />
          {error?.email && <p className={classes.error}>{error?.email}</p>}
        </div>
        <div className={classes.formRow}>
          <div className={classes.passw}>
            <p className={classes.label} style={{ margin: 0 }}>
              Password
            </p>
            <Button
              color="primary"
              className={classes.forgetBtn}
              onClick={() => props.history.push("/lupapassword")}
            >
              Lupa Password ?
            </Button>
          </div>
          <TextField
            variant="outlined"
            placeholder="Masukkan Password Anda"
            id="password"
            name="password"
            className={classes.textField}
            value={form.password}
            type={form.showPassword ? "text" : "password"}
            onChange={handleChange}
            disabled={isSubmitting}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibilityOutlined"
                    onClick={handleClickShowPassword}
                  >
                    {form.showPassword ? (
                      <VisibilityOutlined />
                    ) : (
                      <VisibilityOffOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error?.password && (
            <p className={classes.error}>{error?.password}</p>
          )}
        </div>
        <div className={classes.formRow}>
          <Button
            className={classes.loginBtn}
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Masuk
          </Button>
        </div>
        <div style={{
          display: 'flex'
        }}>
          <p>belum punya akun?</p>
          <span
            style={{
              color: "#F26722",
              cursor: "pointer",
              textDecoration: "underline",
              marginTop: '15px',
              marginLeft: '5px'
            }}
            onClick={() => {
              window.location.pathname = '/register'
            }}
          >
            Daftar disini
          </span>
        </div>

        {/* <div className={classes.formRowBtn}>
          <p>
            Belum punya akun?{" "}
            <Button
              color="primary"
              className={classes.btnRegister}
              onClick={() => props.history.push("/register")}
            >
              Daftar sekarang
            </Button>
          </p>
        </div> */}
      </form>
      <Backdrop open={open} style={{ zIndex: 10 }}>
        <CircularProgress size={50} className={classes.buttonProgress} />
      </Backdrop>
    </div>
  );
}

export default Login;
