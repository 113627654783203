import { Button } from '@material-ui/core';
import { withStyles } from "@material-ui/styles";
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Redirect, } from 'react-router-dom';
import AppPageLoading from '../../components/AppPageLoading';
import { firestore, useFirebase } from '../../components/FirebaseProvider';
import Eighth from './eighth';
import Fifth from './fifth';
import First from './first';
import Fourth from './fourth';
import Ninth from './ninth';
import Second from './second';
import Seventh from './seventh';
import Sixth from './sixth';
import useStyles from './styles';
import Third from './third';
//import { useSiswa } from "../../components/SiswaProvider";
import axios from "axios";

function NewQuestion() {
    //const { profile } = useSiswa();
    const classes = useStyles();
    const { user } = useFirebase();
    const { enqueueSnackbar } = useSnackbar();
        // Yes
    const Btn1 = withStyles({
        label: {
        textTransform: "capitalize",
        fontSize: 10,
        fontWeight: 600,
        },
        root: {
        height: 35,
        padding: "6px 9px"
        },
    })(Button);

    // No
    const Btn0 = withStyles({
        label: {
        textTransform: "capitalize",
        fontSize: 10,
        fontWeight: 600,
        color: "#777",
        },
        root: {
        border: "1.5px solid #777",
        height: 35,
        padding: "6px 9px"
        },
    })(Button);

    const [page, setPage] = useState(0);
    const [form, setForm] = useState({
        nama_panggilan: '',
        tgl_lahir: '',
        no_wa: '',
        nama_sosmed: '',
        status: '',
        pengalaman: '',
        cari_kerja: '',
        jumlah_lamaran_kerja_sebulan: '',
        dapat_info_loker: []
    });
    const [error, setError] = useState({
        nama_panggilan: '',
        tgl_lahir: '',
        no_wa: '',
        nama_sosmed: '',
        status: '',
        pengalaman: '',
        cari_kerja: '',
        jumlah_lamaran_kerja_sebulan: '',
        dapat_info_loker: ''
    });

    // other field 
    const [other, setOther] = useState()

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
        setError({
            [e.target.name]: ''
        })
    }

    // console.log(form)
    // console.log(other)
    const conditionalComponent = () => {
        switch (page) {
            case 0:
                return <First form={form} handleChange={handleChange} error={error} />;
            case 1:
                return <Second form={form} setForm={setForm} error={error} setError={setError} />;
            case 2:
                return <Third form={form} handleChange={handleChange} error={error} />;
            case 3:
                return <Fourth form={form} handleChange={handleChange} error={error} />;
            case 4:
                return <Fifth form={form} setForm={setForm} error={error} setError={setError} />;
            case 5:
                return <Sixth form={form} setForm={setForm} error={error} setError={setError} />;
            case 6:
                return <Seventh form={form} setForm={setForm} error={error} setError={setError} />;
            case 7:
                return <Eighth form={form} setForm={setForm} error={error} setError={setError} />;
            case 8:
                return <Ninth form={form} setForm={setForm} error={error} setError={setError} other={other} setOther={setOther} />;
            default:
                return <First form={form} handleChange={handleChange} error={error} />;
        }
    };

    const userDoc = firestore.doc(`users/${user?.uid}`);
    const [dataUser, loadDatauser] = useDocumentData(userDoc);
    const handleSubmit = async (e) => {
        const newError = { ...error };
        // console.log(newError)
        if (page === 0) {
            if (!form.nama_panggilan) {
                newError.nama_panggilan = 'nama panggilan harus diisi';
            } else {
                await userDoc.set({
                    nama_panggilan: form?.nama_panggilan
                }, { merge: true })
                setPage(page + 1)
            }
        } else if (page === 1) {
            if (!form.tgl_lahir) {
                newError.tgl_lahir = 'tgl_lahir harus diisi';
            } else if (form.tgl_lahir > Date.now()) {
                newError.tgl_lahir = 'tgl lahir tidak boleh melebihi tgl sekarang'
            } else {
                await userDoc.set({
                    tgl_lahir: form?.tgl_lahir
                }, { merge: true })
                setPage(page + 1)
            }
        } else if (page === 2) {
            if (!form.no_wa) {
                newError.no_wa = 'no_wa harus diisi';
            } else {
                await userDoc.set({
                    no_wa: form?.no_wa
                }, { merge: true })
                setPage(page + 1)
            }
        } else if (page === 3) {
            if (!form.nama_sosmed) {
                newError.nama_sosmed = 'nama_sosmed harus diisi';
            } else {
                await userDoc.set({
                    nama_sosmed: form?.nama_sosmed
                }, { merge: true })
                setPage(page + 1)
            }
        } else if (page === 4) {
            if (!form.status) {
                newError.status = 'status harus diisi';
            } else {
                await userDoc.set({
                    status: form?.status
                }, { merge: true })
                setPage(page + 1)
            }
        } else if (page === 5) {
            if (!form.pengalaman) {
                newError.pengalaman = 'pengalaman harus diisi';
            } else {
                await userDoc.set({
                    pengalaman: form?.pengalaman
                }, { merge: true })
                setPage(page + 1)
            }
        } else if (page === 6) {
            if (!form.cari_kerja) {
                newError.cari_kerja = 'cari_kerja harus diisi';
            } else {
                if (form.cari_kerja === 'Iya, aktif mencari pekerjaan baru') {
                    await userDoc.set({
                        cari_kerja: form?.cari_kerja
                    }, { merge: true })
                    setPage(page + 1)

                } else {
                    await userDoc.set({
                        cari_kerja: form?.cari_kerja,
                        isiSurvey: true
                    }, { merge: true });
                    enqueueSnackbar('Selamat!, anda telah menjawab semua pertanyaan dan mendapatkan 50 koin', { variant: 'success' })
                    //await axios.get(form.cari_kerja === 'Iya, aktif mencari pekerjaan baru' ? `https://auto.karir.ai/mtracking.gif?page_title=dashboard_isiSurvey&email=${profile?.email}&firstname=${form?.nama_panggilan}&mobile=${form?.no_wa}&tags=jobseeker` : `https://auto.karir.ai/mtracking.gif?page_title=dashboard_isiSurvey&email=${profile?.email}&firstname=${form?.nama_panggilan}&mobile=${form?.no_wa}`);
                    await axios.post('https://asia-southeast2-karirai-prod.cloudfunctions.net/integration-sendWA',{nomorWhatsapp:form.no_wa, nama:form.nama_panggilan});
                    // history.push('/')
                }
            }
        } else if (page === 7) {
            if (!form.jumlah_lamaran_kerja_sebulan) {
                newError.jumlah_lamaran_kerja_sebulan = 'jumlah lamaran kerja sebulan harus diisi';
            } else {
                await userDoc.set({
                    jumlah_lamaran_kerja_sebulan: form?.jumlah_lamaran_kerja_sebulan
                }, { merge: true })
                setPage(page + 1)
            }
        } else if (page === 8) {
            if (!form.dapat_info_loker) {
                newError.dapat_info_loker = 'dapat info loker harus diisi';
            } else {
                await userDoc.set({
                    dapat_info_loker: other ? [...form?.dapat_info_loker, other] : form?.dapat_info_loker,
                    isiSurvey: true
                }, { merge: true });
                enqueueSnackbar('Selamat!, anda telah menjawab semua pertanyaan dan mendapatkan 50 koin', { variant: 'success' });
                //await axios.get(form.cari_kerja === 'Iya, aktif mencari pekerjaan baru' ? `https://auto.karir.ai/mtracking.gif?page_title=dashboard_isiSurvey&email=${profile?.email}&firstname=${form?.nama_panggilan}&mobile=${form?.no_wa}&tags=jobseeker` : `https://auto.karir.ai/mtracking.gif?page_title=dashboard_isiSurvey&email=${profile?.email}&firstname=${form?.nama_panggilan}&mobile=${form?.no_wa}`);
                await axios.post('https://asia-southeast2-karirai-prod.cloudfunctions.net/integration-sendWA',{nomorWhatsapp:form.no_wa, nama:form.nama_panggilan});
                
                //setPage(page + 1)
            }
        }
        setError(newError)
        // return newError;
    }
    const handlePrev = () => {
        setPage(page - 1)
    }

    useEffect(() => {
        if (dataUser) {
            setForm({
                ...dataUser
            })
        }
    }, [dataUser]);

    if (dataUser && dataUser.isiSurvey) {
        return <Redirect to="/home" />;
    }
    if (loadDatauser) {
        return <AppPageLoading />
    }
    return (
        <div className={classes.formWrap}>
            <div>
                {conditionalComponent()}

            </div>
            {
                page > 0 &&
                <Btn0 onClick={handlePrev}
                    style={{ marginTop: '20px' }}
                    variant="outlined"
                    size="small"
                    
                >
                    {`< Prev`}
                </Btn0>
            }
            <Btn1 onClick={handleSubmit}
                style={{ marginTop: '20px' }}
                variant="contained"
                color="primary"
                size="small"
                
            >
                {page < 6 ? "Next >" : "Next  >"}
            </Btn1>
        </div>
    )
}

export default NewQuestion;