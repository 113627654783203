import React, { useState, useEffect } from 'react';

import Button from '@material-ui/core/Button';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import useStyles from './styles/index';
import useTimer from '../../components/useTimer';

// import { functions } from '../../components/FirebaseProvider';
// import { useParams } from 'react-router-dom';

import AccessAlarmsIcon from '@material-ui/icons/AccessAlarms';

export default function TryoutHeader({ to, hasilTo, activeSoal, openConfirmEnd, setTimeoutDialog }) {
    const classes = useStyles()
    // const params = useParams();
    const [milisecondstart] = useState(hasilTo.started_at);
    const [milisecondend] = useState(Date.now())
    const { countDownStr, countDownSeconds, stopTimer } = useTimer(milisecondstart, milisecondend, to.waktu);

    useEffect(() => {

        if (countDownSeconds <= 0) {
            const handleEndTO = async e => {

                try {
                    // const endTryout = functions.httpsCallable('endTryout');
                    // await endTryout({
                    //     hasil_tryout_id: params.hasil_tryout_id,
                    //     waktu: Date.now()
                    // })
                    setTimeoutDialog({ open: true });
                } catch (e) {


                    console.log(e.message)
                }
            }

            stopTimer();
            handleEndTO();
        }
    }, [countDownSeconds, stopTimer, setTimeoutDialog]);


    return (<div className={classes.headerContent}>
        <div className={classes.headerContentTop}>
            <div className={classes.TOName}>{to.judul}</div>
            <div className={classes.TOType}>
                <p>Tipe Soal: {activeSoal.kategori_soal && activeSoal.kategori_soal.nama}</p>
            </div>
        </div>
        <div className={classes.headerContentBot}>
            <div className={classes.TOtime}>
                {countDownSeconds > 0 && <p><AccessAlarmsIcon style={{ width: 20, height: 20, marginRight: 5, alignSelf: 'baseline' }} /> {countDownStr}</p>}
                {countDownSeconds <= 0 && <p>Waktu Habis</p>}
            </div>
            <div className={classes.endBtnWrap}>
                <Button onClick={openConfirmEnd} variant="contained" className={classes.endBtn}>
                    <PowerSettingsNewIcon className={classes.endIcon} /> Selesai
                </Button>
            </div>
        </div>
    </div>)
}