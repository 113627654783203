export const rest = (process.env.REACT_APP_FIREBASE_PROD === "YES") ? `https://wec193xcsg.execute-api.ap-southeast-1.amazonaws.com/production/` : `https://5lbq4iyv36.execute-api.ap-southeast-1.amazonaws.com/develop/`;
let siswaUrl = (process.env.REACT_APP_FIREBASE_PROD === "YES") ? 'https://app.karir.ai/' : 'https://app-dev.karir.ai/';

if (process.env.NODE_ENV === 'development') {
    siswaUrl = 'http://localhost:3000/'
}

export const siswa = siswaUrl;

export const urlCV = (process.env.REACT_APP_FIREBASE_PROD === "YES") ? 'https://cv.karir.ai/login-by-token' : 'https://smartcvbuilderdev.karir.ai/login-by-token';

export const urlRiasec = (process.env.REACT_APP_FIREBASE_PROD === "YES") ? 'https://quizminat.karir.ai/login-by-token' : 'https://quiz.karir.ai/login-by-token';

export const urlKarirhack = (process.env.REACT_APP_FIREBASE_PROD === "YES") ? 'https://hack.karir.ai/login-by-token' : 'https://hackdev.karir.ai/login-by-token';