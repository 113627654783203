// 
import React from 'react'

// 
import useStyles from './styles'

// 
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'

// 
// import AppHeader from '../../components/AppHeader'
import NoMisi from "../../images/illustration-mission.png";

// 
import { useHistory } from 'react-router-dom'
import { useCoin } from '../../components/CoinProvider'
import { formatDate } from '../../utils/formatter'
import { HFHeader } from '../../components/AppHeaderNew'

function ListMisi() {
    // 
    const classes = useStyles();
    // 
    const history = useHistory();
    // 
    const { listMisi } = useCoin();
    // 
    return (
        <div className={classes.root}>
            <HFHeader title="logo" pgDesc={false} />
            {/* <div className={classes.listMissionHead}>
                <AppHeader />
            </div> */}
            <div className={classes.listBody1}>
                <div className={classes.pageHeading}>
                    <h2 className={classes.listTitle} >Daftar Misi</h2>
                    <p className={classes.listSubtitle}>Segera lakukan misi dan konfirmasi dengan mengupload bukti misi</p>
                </div>
                <div className={classes.tableWrap} >
                    <div className={classes.tableTitleWrap}>
                        <h3 className={classes.tableTitle}>Menunggu Konfirmasi</h3>
                    </div>
                    <div className={classes.TableHead}>
                        <p className={classes.tableCellTopID}>Id Misi</p>
                        <p className={classes.tableCellTopDate}>Tanggal</p>
                        <p className={`${classes.tableCell} ${classes.tableCellTopCoin}`}>Coin</p>
                    </div>
                    <div className={classes.TableBody}>
                        {
                            listMisi.length > 0 ?
                                listMisi.sort((a, b) => { return b.tanggal - a.tanggal }).map(item => {


                                    if (item.status !== 'review') {
                                        return null;
                                    }
                                    return (
                                        <div key={item.id} className={classes.TableRowWait}>
                                            <div key={item.id} className={classes.TableRow}>
                                                {/* <Typography noWrap className={classes.TRow}>{item.id}</Typography> */}
                                                <p className={`${classes.TRow} ${classes.TRowID}`}>{item.id}</p>
                                                <p className={classes.TRow}>{formatDate(item.tanggal)}</p>
                                                <p className={`${classes.TRow} ${classes.TRowCoin}`}><strong>{item.jumlah_coin}</strong></p>
                                            </div>

                                        </div>
                                    )
                                })
                                :
                                <div style={{ marginTop: 20 }} >
                                    <img src={NoMisi} alt="noTransaksi" style={{width: "150px", filter: "grayscale(100%)", opacity: "40%"}} />
                                    <Typography align="center" className={classes.noData} >Belum ada misi</Typography>
                                    {/* <Typography align="center" className={classes.caption} >harap menunggu beberapa saat atau pantau social media PakBudiApp</Typography> */}
                                    {/* <Button onClick={() => history.push('/misi')} className={classes.btnMisi} variant="contained" color="primary" >Kerjakan Misi</Button> */}
                                </div>
                        }
                    </div>
                </div>
                <div className={classes.tableWrap} >
                    <div className={classes.tableTitleWrap}>
                        <h3 className={classes.tableTitle}>Riwayat Misi</h3>
                    </div>
                    <div className={classes.TableHead}>
                        <p className={classes.tableCell1}>Id Misi</p>
                        <p className={classes.tableCell}>Tanggal</p>
                        <p className={`${classes.tableCell} ${classes.tableCellCoin}`}>Coin</p>
                        <p className={classes.tableCell}>Status</p>
                    </div>
                    <div className={classes.TableBody}>
                        {
                            listMisi.length > 0 ?
                                listMisi.sort((a, b) => { return b.tanggal - a.tanggal }).map(item => {
                                    const handleClick = () => {
                                        return history.push(`/konfirmasimisi/${item.id}`)
                                    }
                                    if (item.status === 'review') {
                                        return null;
                                    }

                                    return (
                                        <div key={item.id} className={classes.TableRow}>
                                            {/* <Typography noWrap className={classes.TRow}>{item.id}</Typography> */}
                                            <p className={`${classes.TRow} ${classes.TRowID}`}>{item.id}</p>
                                            <p className={`${classes.TRow} ${classes.TRowDate}`}>{formatDate(item.tanggal)}</p>
                                            <p className={`${classes.TRow} ${classes.TRowCoin}`}><strong>{item.jumlah_coin}</strong></p>
                                            <div className={classes.itemStatus}>
                                                {
                                                    item.status === 'pending' ?
                                                        <Button onClick={handleClick} className={classes.statusFalse}>
                                                            Kirim Bukti
                                                        </Button>
                                                        :
                                                        null

                                                }
                                                {
                                                    item.status === 'pending-valid' ?
                                                        <Button onClick={() => history.push(`/misi`)} className={classes.statusTrue}>
                                                            Claim Coin
                                                        </Button>
                                                        :
                                                        null

                                                }
                                                {
                                                    item.status === 'valid' ?
                                                        <Typography className={classes.statusTrue} component="p">
                                                            Selesai
                                                        </Typography>
                                                        :
                                                        null
                                                }
                                                {
                                                    item.status === 'rejected' ?
                                                        <Typography style={{ color: 'red' }} className={classes.statusTrue} component="p">
                                                            Ditolak
                                                        </Typography>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div style={{ margin: 20 }} >
                                    <img src={NoMisi} alt="noTransaksi" style={{width: "150px", filter: "grayscale(100%)", opacity: "40%"}} />
                                    <Typography align="center" className={classes.noData} >Belum ada misi yang dikerjakan</Typography>
                                    {/* <Typography align="center" className={classes.caption} >Yah... kamu belum pernah mengerjakan misi apapun, coba kerjain yuk!</Typography> */}
                                    <Button onClick={() => history.push('/misi')} className={classes.btnMisi} variant="contained" color="primary" disableElevation >Kerjakan Misi</Button>
                                </div>
                        }
                    </div>
                </div >
            </div>
        </div>
    )
}

export default ListMisi