// React
import React from "react";

// Router
import { Route, Switch, Redirect } from "react-router-dom";

// Styles
import useStyles from "./styles";

// Component's
import Manage from "./manage";
import MyChat from "../chat";

function Konsultasi() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.appBody}>
        <Switch>
          <Route exact path="/konsultasi/:type" component={Manage} />
          <Route path="/chat" component={MyChat} />
          <Redirect to="/konsultasi/semua" />
        </Switch>
      </div>
    </div>
  );
}

export default Konsultasi;
