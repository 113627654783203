import React, { useEffect, useState } from "react";
import useStyles from "./styles/index";
import { withRouter, Link, useHistory } from "react-router-dom";
import { Box, Grid, Avatar, Button, Typography, Hidden, IconButton } from "@material-ui/core";
import { useSiswa } from "../../components/SiswaProvider";
import { useFirebase } from "../../components/FirebaseProvider";
import { useCoin } from "../../components/CoinProvider";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import DashboardIcon from "../../images/icon-dashboard";
import PeopleIcon from "../../images/icon-people";
import noPic from "../../images/no-pic.svg";
import CreditIcon from "../../images/icon-credits";
import notif from "../../images/icon-notification.svg";


function TopNav({ history }) {
  const history2 = useHistory();
  const classes = useStyles();
  const { Coin } = useCoin();
  const myCredit = Coin && Coin.coin ? Coin.coin : 0;
  const { profile } = useSiswa();
  const { user } = useFirebase();
  const [isActive, setIsActive] = useState('');
  useEffect(() => {
    if (history?.location?.pathname === "/ajakTeman") {
      setIsActive('ajakTeman')
    } else if (history?.location?.pathname === "/myCredit" || history?.location?.pathname === "/topUp" || history?.location?.pathname === "/topUpHistory" || history?.location?.pathname?.split('/')?.[1] === "topUpCheckout") {
      setIsActive('credit')
    } else if (history?.location?.pathname === "/settings" || history?.location?.pathname === "/editprofile" || history?.location?.pathname === "/vouchers") {
      setIsActive('settings')
    } else if (history?.location?.pathname === "/home") {
      setIsActive('dashboard')
    }
  }, [history.location.pathname]);
  // console.log(history?.location?.pathname?.split('/')?.[1]);

  return (
    <Box className={classes.root}>

      <Box className={classes.profilee}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.containerBox}>
              <Grid item md={8} lg={9}>
                <Hidden smDown>
                  <Box className={classes.topNavBox}>
                    <ul className={classes.topNav}>
                      <li className={classes.topNavItem} >
                        <Button style={{ color: isActive === 'dashboard' && '#FAFF00', }} onClick={() => {
                          // eslint-disable-next-line no-unused-expressions
                          window?.mixpanel?.track("home dashboard Button Clicked", {
                            userId: user?.uid,
                            tempUID: user?.tempUID,
                          })
                        }} component={Link} className={classes.topNavLink} to="/home">
                          <DashboardIcon className={classes.topNavIcon} /> <Typography className={classes.topNavText} style={{ fontWeight: 'bold', textTransform: 'none' }} variant="body1">Dashboard</Typography>
                        </Button>
                      </li>
                      <li className={classes.topNavItem}>
                        <Button style={{ color: isActive === 'ajakTeman' && '#FAFF00', }} onClick={() => {
                          // eslint-disable-next-line no-unused-expressions
                          window?.mixpanel?.track("ajak teman top nav Button Clicked", {
                            userId: user?.uid,
                            tempUID: user?.tempUID,
                          })
                        }} component={Link} className={classes.topNavLink} to="/ajakTeman">
                          <PeopleIcon className={classes.topNavIcon} /> <Typography className={classes.topNavText} style={{ fontWeight: 'bold', textTransform: 'none' }} variant="body1">Ajak Teman</Typography>
                        </Button>
                      </li>
                      <li className={classes.topNavItem}>
                        <Button style={{ color: isActive === 'credit' && '#FAFF00', }} onClick={() => {
                          // eslint-disable-next-line no-unused-expressions
                          window?.mixpanel?.track("my kredit top nav Button Clicked", {
                            userId: user?.uid,
                            tempUID: user?.tempUID,
                          })
                        }} component={Link} className={classes.topNavLink} to="/myCredit">
                          <CreditIcon className={classes.topNavIcon} /><Typography className={classes.topNavText} style={{ fontWeight: 'bold', textTransform: 'none' }} variant="body1">Kredit Saya</Typography>
                        </Button>
                      </li>
                      <li className={classes.topNavItem}>
                        <Button style={{ color: isActive === 'settings' && '#FAFF00', }} onClick={() => {
                          // eslint-disable-next-line no-unused-expressions
                          window?.mixpanel?.track("settings top nav Button Clicked", {
                            userId: user?.uid,
                            tempUID: user?.tempUID,
                          })
                        }} component={Link} className={classes.topNavLink} to="/settings">
                          <SettingsOutlinedIcon className={classes.topNavIcon} /><Typography className={classes.topNavText} style={{ fontWeight: 'bold', textTransform: 'none' }} variant="body1">Setelan</Typography>
                        </Button>
                      </li>
                    </ul>
                  </Box>
                </Hidden>
              </Grid>
              <Grid item md={3} lg={2}>
                <Box className={classes.profileBox}>
                  <Avatar
                    alt=""
                    src={user?.photoURL ? user?.photoURL : noPic}
                    className={classes.profileImg}
                  />
                  <Box className={classes.profileDesc}>
                    <Typography  variant="subtitle1" className={classes.profileName}>
                      {profile?.fullName}
                    </Typography>
                    <Button
                      variant="contained"
                      disableElevation
                      className={classes.credit}
                      startIcon={
                        <CreditIcon color="primary" fontSize="small" />
                      }
                      onClick={() => {
                        // eslint-disable-next-line no-unused-expressions
                        window?.mixpanel?.track("Home my coin Button Clicked", {
                          userId: user?.uid,
                          tempUID: user?.tempUID,
                        });
                        history.push("/myCredit");
                      }}
                    >
                      <Typography className={classes.myCredit}>{myCredit}</Typography>
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Hidden smDown>
                <Grid item md={1}>
                  <IconButton
                  onClick={() => {
                    // eslint-disable-next-line no-unused-expressions
                    window?.mixpanel?.track("Header notifications Button Clicked", {
                      userId: user?.uid,
                      tempUID: user?.tempUID,
                    });
                    history2.push("/notifikasi");
                  }}
                  
                  >
                    <img draggable={false} src={notif} alt="" style={{ filter: 'invert(1) sepia(1) saturate(1) hue-rotate(313deg)', }} />
                  </IconButton>
                </Grid>
              </Hidden>
            </Grid>

          </Grid>
        </Grid>
      </Box>
    </Box>

  );
}

export default withRouter(TopNav);
