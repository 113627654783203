import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { baseURL } from "../../images/s3";
import Button from '@material-ui/core/Button';
import timeoutImg from '../../images/timeout.png';
import useStyles from './styles/index';
import { useParams } from 'react-router-dom';
import { functions } from '../../components/FirebaseProvider';
// import clsx from 'clsx';

export default function TimeOut({ open, handleClose }) {


    const params = useParams();
    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState('sm');
    const classes = useStyles();

    const handleSelesai = async () => {

        try {
            const endTryout = functions.httpsCallable('endTryout');
            await endTryout({
                hasil_tryout_id: params.hasil_tryout_id,
                waktu: Date.now()
            })
        } catch (e) {
            console.log(e.message);
        }
    }
    return <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEscapeKeyDown={true}

    >
        <DialogTitle className={classes.DialogTitleTimeout} >
            <span className={classes.pageNameAlt}>Tryout</span>
            <img className={classes.logoImg} src={`${baseURL}logo-full-w.svg`} alt="" />
        </DialogTitle>
        <DialogContent>


            <div className={classes.timeoutBox}>
                <img src={timeoutImg} alt="" className={classes.gonnaCry} />
                <div className={classes.timeoutMsg}>
                    <span className={classes.timeoutTxt}>Ooops!!</span>
                    <span className={classes.timeoutTxt}>waktu kamu habis</span>
                </div>
            </div>

        </DialogContent>
        <DialogActions>
            <Button
                onClick={handleSelesai}
                style={{ alignSelf: 'center', margin: '10px auto 20px', width: 112, height: 27, padding: 0, }} variant='contained' color="primary" autoFocus>
                Selesai
            </Button>
        </DialogActions>
    </Dialog>
}