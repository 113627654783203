import React from "react";

import useStyles from "./styles/dialog";

import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Cancel from "@material-ui/icons/Cancel";

export default function TopupDialog({ open, handleClose }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent className={classes.content}>
          <Cancel color="primary" fontSize="large" />
          <p className={classes.text}>Jumlah kredit anda tidak mencukupi untuk membeli asesmen ini</p>
          <span className={classes.text2}>lakukan topup untuk menambah kredit anda</span>
          <br />
        </DialogContent>
        <DialogActions className={classes.btc}>
          <Button
            className={classes.btn1}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disableElevation
            fullWidth
          >
            Kembali
          </Button>
          <Button
            className={classes.btn2}
            onClick={() => history.push("/myCredit")}
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
          >
            Topup
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
