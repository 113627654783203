import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // index
  root: {
    margin: "auto",
    width: "100%",
    // maxWidth: "630px",
  },
  pagee: {
    margin: "0 16px 50px",
    "@media(max-width: 959px)": {
      margin: "0 16px 120px",
    },

  },
  profilee: {
    backgroundColor: theme.palette.primary.main,
    padding: "16px",
    borderRadius: "8px",
  },
  profileBox:{
    display: 'flex',
    flexFlow: 'row wrap',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  profileImg: {
    width: "54px !important",
    height: "54px !important",
    // marginRight: "16px",
    pointerEvents: "none",
  },
  profileName: {
    fontSize: "18px",
    textAlign: "left",
    color: theme.palette.neutrals.nf,
    fontWeight: "bold",
    margin: '0 10px',
    width: 'fit-content'
  },
  topNavBox:{
    display: 'flex',
    justifyContent:'flex-end',
    width: '100%',
    height: '100%',
    alignItems:'center',
  },
  topNav:{
    listStyle: 'none',
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    width: '80%',
    margin: 0,
    padding: 0,
    "@media(max-width: 1111px)": {
      width: '100%',
    },
  },
  topNavItem:{
    margin: '0 10px'
  },
  topNavLink:{
    color: '#fff',
    display: 'flex',
  },
  topNavIcon:{
    marginRight: '7px'
  },
  credit: {
    marginRight: "5px",
    paddingTop: "2px",
    paddingBottom: "2px",
    backgroundColor: theme.palette.neutrals.nf,
    borderRadius: "100px",
    fontSize: "14px",
    color: theme.palette.primary.main,
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: theme.palette.neutrals.n10,
    },
  },
  share: {
    paddingTop: 0,
    paddingBottom: 0,
    border: "2px solid" + theme.palette.neutrals.nf,
    borderRadius: "100px",
    fontSize: "14px",
    color: theme.palette.neutrals.nf,
    fontWeight: "bold",
    textTransform: "capitalize",
    // "&:hover" : {
    //   backgroundColor: theme.palette.neutrals.n10
    // }
  },
  menu: {
    margin: "0 0 20px 0px",
    padding: '0 20px',
    "@media(max-width: 480px)": {
      padding:0,
    },
  },
  imMenu: {
    // backgroundColor: theme.palette.neutrals.n0,
    border: 'solid 1px',
    borderColor: theme.palette.textColor.gray13,
    borderRadius: "8px",
    minHeight: "100px",
    height: '100%',
    padding: "20px 35px 50px",
    position: 'relative',
    "@media(max-width: 660px)": {
      padding: "15px 15px 50px",
    },
  },
  imName: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: 5,
    marginBottom: 20,
    textTransform: "uppercase",
    color: theme.palette.primary.main,
  },
  imDesc:{
    color: theme.palette.textColor.gray6,
    fontSize: '16px',
    margin: '20px 0',
    "@media(max-width: 480px)": {
      fontSize: '14px'
    },
  },
  imBtn:{
    width: '92%',
    borderRadius: '10px',
    position: 'absolute',
    left: '10px',
    bottom: '20px',
    "@media(max-width: 560px)": {
      width: '90%'
    },
  },
  articleBox:{
    padding: '0 20px',
    "@media(max-width: 480px)": {
      padding:0,
    },
  },
  arCon: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    // margin: "30px auto 10px",
    padding: "10px 16px",
    borderRadius: "8px",
    backgroundColor: theme.palette.neutrals.n0,
  },
  arT: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  arIco: {
    width: "50px",
    marginRight: "20px",
    "@media(max-width: 450px)": {
      width: "40px",
    },
  },
  arCategory: {
    fontSize: 16,
    fontWeight: "bold",
    textAlign: "left",
    "@media(max-width: 450px)": {
      fontSize: 14,
    },
  },
  arListBtn: {
    borderRadius: 100,
    backgroundColor: "rgba(242, 103, 34, 0.1)",
    "&:hover": {
      backgroundColor: "rgba(242, 103, 34, 0.2)",
    },
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textTransform: "none",
    height: 40,
    "@media(max-width: 450px)": {
      fontSize: 12,
      height: 35,
    },
  },
  arCard: {
    boxShadow: "none",
    borderRadius: 8,
    width: '98%',
    margin: 'auto'
    // backgroundColor: theme.palette.neutrals.n10,
  },
  arImg: {
    "@media(min-width: 320px)": {
      height: 165,
    },
    "@media(min-width: 360px)": {
      height: 185,
    },
    "@media(min-width: 430px)": {
      height: 200,
    },
    "@media(min-width: 480px)": {
      height: 215,
    },
    "@media(min-width: 980px)": {
      height: 270,
    },
    "@media(min-width: 1280px)": {
      height: 280,
    },
    "@media(min-width: 1366px)": {
      height: 360,
    },
  },
  arTitle: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "left",
    fontFamily: "Glacial Indifference",
  },
  arContent: {
    fontSize: 12,
    textAlign: "left",
    fontFamily: "Glacial Indifference",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export default useStyles;
