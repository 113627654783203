// React
import React from "react";

// Styles
import useStyles from "./styles";
import { withStyles } from "@material-ui/styles";

import { useSiswa } from "../../components/SiswaProvider";

import { useHistory } from "react-router-dom";

// MUI
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";

//
import { baseURL } from "../../images/s3";
import logo from "../../images/logo-full-w.svg";

export default function DialogLengkapiData({ open, handleClose }) {
  const classes = useStyles();

  const history = useHistory();

  const { setPageEvent } = useSiswa();

  // Yes
  const Btn1 = withStyles({
    label: {
      textTransform: "capitalize",
      fontSize: 10,
      fontWeight: 600,
    },
    root: {
      height: 35,
      padding: "6px 9px"
    },
  })(Button);

  // No
  // const Btn0 = withStyles({
  //   label: {
  //     textTransform: "capitalize",
  //     fontSize: 10,
  //     fontWeight: 600,
  //     color: "#777",
  //   },
  //   root: {
  //     border: "1.5px solid #777",
  //     height: 35,
  //     padding: "6px 9px"
  //   },
  // })(Button);
  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 10,
          },
        }}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className={classes.DialogTitle}>
          <img width="150px" src={logo} alt="" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText align="center">
            Lengkapi data diri kamu, dan dapatkan bonus
          </DialogContentText>
          <DialogContentText align="center">
            <img
              src={`${baseURL}img-coin-double.png`}
              className={classes.imgCoin}
              alt=""
            />
          </DialogContentText>
          <DialogContentText align="center" style={{ marginBottom: 0 }}>
            <b>50 </b>Kredit
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ margin: 8 }}>
          <Grid container spacing={1}>
            {/* <Grid item xs={6}>
              <Btn0
                onClick={handleClose}
                variant="outlined"
                size="small"
                fullWidth
              >
                Nanti
              </Btn0>
            </Grid> */}
            <Grid item xs>
              <Btn1
                onClick={() => {
                  setPageEvent("home");
                  history.push("/lengkapiData");
                }}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
              >
                Lengkapi
              </Btn1>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
