
import React from 'react';
import { TextField, Typography } from '@material-ui/core';

export default function Third({ form, handleChange, error }) {


    return (
        <>
            <div style={{ textAlign: 'left' , marginBottom: '10px'  }}>
            <Typography>Nomor Whatsapp Aktif*</Typography>
            <Typography variant='subtitle2'>Masukan nomor WA {form?.nama_panggilan} yang aktif, karena hadiah kredit, info lowongan kerja dan informasi penting lain akan dikirim ke nomor WA ini</Typography>
            </div>
            <TextField
                fullWidth
                type='number'
                variant="outlined"
                id="no_wa"
                name="no_wa"
                placeholder="No WA"
                // className={classes.textField}
                value={form.no_wa}
                onChange={handleChange}
                error={error.no_wa ? true : false}
                helperText={error?.no_wa}
            // disabled={isSubmiting}
            />
        </>
    )
}

