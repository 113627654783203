// React
import React from 'react';

// Styles
import useStyles from './styles';

// Avatar
import NoNotif from "../../images/illustration-notif.png";

// Component
import NotifikasiItem from './list';

// Utils
import { useNotifikasi } from '../../components/NotifikasiProvider';
import { HFHeader } from '../../components/AppHeaderNew';
//import { useSiswa } from '../../components/SiswaProvider';

function Notifikasi({ history }) {
    //const { profile } = useSiswa();

    // UseStyles
    const classes = useStyles();
    const { notifItems } = useNotifikasi();

    return (
        <div className={classes.root}>
            {/* <img
                src={`https://auto.karir.ai/mtracking.gif?page_title=dashboard_notifikasi&email=${profile?.email}`} alt=''
            /> */}
            <HFHeader title="logo" pgDesc={false} noBorder={true} />
            <div className={classes.pageContent}>
                <div className={classes.notifikasiBox}>
                    {
                        notifItems && notifItems.length > 0 ?
                            notifItems.map((item) =>
                                <NotifikasiItem item={item} key={item.id} />
                            )
                            :
                            <div className={classes.NoNotif}>
                                <img className={classes.imgNoNotif} alt="" src={NoNotif} />
                                {/* <p className={classes.title}>Tidak Ada Apapun !</p> */}
                                <p className={classes.subtitle}>Belum ada notifikasi</p>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Notifikasi;