import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: '#fff'
    },
    pageHeading:{
        maxWidth: 630,
        padding: "0px 16px",
        margin: 'auto',
    },
    pageTitle:{
        color: theme.palette.textColor.gray,
        fontSize: 24,
        margin: 0,
    },
    pageSubtitle:{
        color: theme.palette.textColor.gray,
        fontSize: 14,
        marginTop: 0,
        marginBottom: 50,
    },
    choicesWrap:{
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        margin: 'auto',
        marginBottom: 30,
        '@media(max-width: 1400px)': {
            width: '40%',
        },
        '@media(max-width: 700px)': {
            width: '65%',
        },
        '@media(max-width: 600px)': {
            width: '60%',
        },
        '@media(max-width: 500px)': {
            width: '70%',
        },
        '@media(max-width: 400px)': {
            width: '95%',
        },
    },
    choiceBlock:{
        width: '32%',
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'center',
    },
    labelle:{
        fontSize: 11,
    },
    choiceImg:{
        // border: 'solid 3px' + theme.palette.textColor.gray2,
        backgroundColor: "#F8F8FD",
        padding: 10,
        borderRadius: 6,
        width: 80
    },
    choiceImgChecked:{
        // border: 'solid 3px' + theme.palette.secondary.main,
        backgroundColor: "#FDE9DF",
        padding: 10,
        borderRadius: 6,
        width: 80
    }
}))

export default useStyles;