import React from 'react';

import { HFHeader } from '../../components/AppHeaderNew';
import useStyles from './styles';
import { useSiswa } from '../../components/SiswaProvider';
import BottomNav from '../../components/BottomNav';
import { Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
//  
function PersonaResult(props) {
    const classes = useStyles();

    //const { personaData, profile } = useSiswa();
    const { personaData } = useSiswa();

    const resultJson = personaData?.result && JSON.parse(personaData?.result)

    const urlDownload = resultJson?.urlReport && Object.values(resultJson?.urlReport);
    // console.log(urlDownload?.[0]?.FullTPA)
    // const { user } = useFirebase();
    // const { enqueueSnackbar } = useSnackbar();
    // const params = useParams();


    // if (loading || load || loadsnap || loadKateg) {
    //     return (
    //         <Container maxWidth="xs">
    //             <CircularProgres style={{ marginTop: '90%' }} />
    //         </Container>
    //     )
    // }

    return (
        <>
            <div className={classes.root}>

                <HFHeader
                    title="Result"
                    desc="Halaman ini merupakan result/hasil dari Test Psikometri Online"
                    image="assessment"
                />
                {/* <img
                    src={`https://auto.karir.ai/mtracking.gif?page_title=dashboard_persona_test_result&email=${profile?.email}`} alt=''
                /> */}
                <div className={classes.cover}>
                    <div className={classes.item1}>
                        <p style={{ fontSize: '20px', color: '#008000' }}>Uraian Kepribadian</p>
                        <ul>
                            {
                                resultJson?.uraian?.uraian_kepribadian?.map((res) => {
                                    return <li >{res}</li>
                                })
                            }
                        </ul>
                        <p></p>
                    </div>
                    <div className={classes.item2}>
                        <p style={{ fontSize: '20px', color: '#FFA500' }}>Kelebihan</p>
                        <ul>
                            {
                                resultJson?.uraian?.kelebihan?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p></p>
                    </div>
                    <div className={classes.item3}>
                        <p style={{ fontSize: '20px', color: '#357EC7' }}>Area Pengembangan</p>
                        <ul>
                            {
                                resultJson?.uraian?.area_pengembangan?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p></p>
                    </div>
                    <div className={classes.item4}>
                        <p style={{ fontSize: '20px', color: '#E7A1B0' }}>Karakteristik Pekrjaan</p>
                        <ul>
                            {
                                resultJson?.uraian?.karakteristik_pekerjaan?.map((res) => {
                                    return <li>{res}</li>
                                })
                            }
                        </ul>
                        <p></p>
                    </div>
                    <div style={{ marginTop: '10px' }}>
                        <Button
                            onClick={() => {
                                window.open(`${urlDownload?.[0]?.FullTPA}`)
                            }}
                        >
                            <CloudDownloadIcon style={{ marginRight: '8px' }} />
                            Download Full PDF
                        </Button>
                    </div>
                </div>

            </div >
            <BottomNav />
        </>
    );

}

export default PersonaResult;