// React
import React from 'react';

// Style
import useStyles from './styles/Home';

// Components
// import Divider from '@material-ui/core/Divider';
import Rating from './Rating';
import Typography from '@material-ui/core/Typography';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { baseURL } from '../../images/s3';
import StyledBadge from '../../components/Badge';

function Coin({ item, handleChat, handleFree, itemStyle }) {
    // Style
    const classes = useStyles();
    // 
    const online = item.online;
    const type = item.type;
    const harga = item.harga;
    const uid = item.id;
    const rating = item.average_rating;
    const nama = item.nama;
    const photo = item.photoURL;
    const keterangan = item.title;
    const status = item.status;
    const durasi = item.durasi || 5;
    // Check Online
    const Online = online === false ? classes.offline : classes.online;
    let Type = null;
    if (type === 'gurubk') {
        Type = 'Guru BK';
    }
    if (type === 'mahasiswa') {
        Type = 'Mahasiswa';
    }
    if (type === 'motivator') {
        Type = 'Motivator';
    }


    // 
    return (
        <React.Fragment>
            {
                harga !== undefined && harga !== "0" ?
                    <div style={itemStyle} className={classes.listRoot}>

                        <ListItem


                            className={online === true ? classes.listItem : classes.inactiveList}

                            disabled={online === false}

                            button={true}


                            onClick={handleChat(harga, uid, rating, keterangan, nama, type, photo, online, status, durasi)}
                        >
                            <ListItemAvatar className={classes.listItemAva}>
                                <StyledBadge
                                    overlap="circle"
                                    classes={{ badge: Online }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    variant="dot"
                                >
                                    <Avatar className={classes.avatar}>
                                        <Avatar className={classes.profileAva} src={photo || `${baseURL}pakbudi-logo.png`} alt="" />
                                    </Avatar>
                                </StyledBadge>
                            </ListItemAvatar>
                            <ListItemText>
                                <div className={classes.profile}>
                                    <span className={classes.profileName}>{nama}</span>
                                    <span className={classes.profileJob}>{Type}</span>
                                    <Rating rating={rating} />
                                    <ListItemSecondaryAction>
                                        <div className={`${classes.chatNow} ${classes.profileStats}`}>
                                            <Typography className={classes.btnChat}> {harga} </Typography>
                                            <img className={classes.imgCoin} src={`${baseURL}icon-coin.png`} alt="" />
                                        </div>
                                        <div className={classes.Coin}>
                                            Chat
                                        </div>
                                    </ListItemSecondaryAction>
                                </div>
                            </ListItemText>
                        </ListItem>
                    </div>
                    :
                    <div style={itemStyle}>
                        <ListItem

                            className={online === true ? classes.listItem : classes.inactiveList}
                            disabled={online === false}
                            button={true}
                            onClick={handleFree(uid, rating, keterangan, nama, type, photo, online, status, durasi)}
                        >
                            <ListItemAvatar className={classes.listItemAva}>
                                <StyledBadge
                                    overlap="circle"
                                    classes={{ badge: Online }}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    variant="dot"
                                >
                                    <Avatar className={classes.avatar}>
                                        <Avatar className={classes.profileAva} src={photo || `${baseURL}pakbudi-logo.png`} alt="" />
                                    </Avatar>
                                </StyledBadge>
                            </ListItemAvatar>
                            <ListItemText>
                                <div className={classes.profile}>
                                    <span className={classes.profileName}>{nama}</span>
                                    <span className={classes.profileJob}>{Type}</span>
                                    <Rating rating={rating} />
                                </div>
                                <ListItemSecondaryAction>
                                    <div className={online === true ? classes.profileStats : classes.inActiveprofileStats}>
                                        <span className={classes.freeBadge}>Chat Sekarang</span>
                                        <span className={classes.freeBadge}>(Gratis)</span>
                                    </div>
                                </ListItemSecondaryAction>
                            </ListItemText>

                        </ListItem>
                    </div>
            }
        </React.Fragment>
    )
}

export default Coin;