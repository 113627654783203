import React from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
// import ViewComfyIcon from '@material-ui/icons/ViewComfy';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import ArrowLeft from '@material-ui/icons/ChevronLeft';
import ArrowRight from '@material-ui/icons/ChevronRight';
import iconFab from '../../images/iconFab.png';

import Slide from '@material-ui/core/Slide';


// import AppHeader from '../../components/AppHeader';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Styles
import useStyles from './styles/index';

import { Prompt } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import TryoutHeader from './header';
import groupBy from 'lodash/groupBy';
import shuffle from 'lodash/shuffle';
import { firestore } from '../../components/FirebaseProvider';
import PetaSoal from './peta';
import ConfirmEnd from './confirmEnd';
import TimeOut from './timeout';




export default function SoalTO({ to, hasilTo, jawaban, soal }) {

    // Class
    const classes = useStyles();

    const [confirmEnd, setConfirmEnd] = React.useState({
        open: false
    });
    const [petasoal, setPetasoal] = React.useState({
        open: false
    })

    const [timeout, setTimeout] = React.useState({
        open: false
    })
    const [orderIds, setOrderIds] = React.useState([]);
    const [soalIndex, setSoalIndex] = React.useState(0);
    const [slide, setSlide] = React.useState({
        direction: "left",
        in: true
    });

    // for scrolling


    React.useEffect(() => {
        // const persistSoalIndex = async () => {
        //     await firestore.doc(`hasil_tryout/${hasilTo.id}/jawaban_siswa/order`).set({ currentSoalIndex: soalIndex }, { merge: true })
        // }
        // persistSoalIndex();
    }, [soalIndex, hasilTo.id]);

    React.useEffect(() => {

        const findOrder = jawaban.find(item => item.id === "order");
        if (findOrder) {

            setOrderIds(findOrder.orderIds);
            //setSoalIndex(findOrder.currentSoalIndex || 0);
        } else {
            const soalSortedByKategori = soal.sort((a, b) => a.kategori_soal.createdAt - b.kategori_soal.createdAt);

            const groupSoalByKategori = groupBy(soalSortedByKategori, item => {
                return item.kategori_soal.id
            })
            let orderSoalPerKategori = {};
            let finalOrderSoalIds = [];
            Object.keys(groupSoalByKategori).forEach((kategori_soal_id) => {
                orderSoalPerKategori[kategori_soal_id] = to.random ? shuffle(groupSoalByKategori[kategori_soal_id]) : groupSoalByKategori[kategori_soal_id].sort((a, b) => a.createdAt - b.createdAt);
                orderSoalPerKategori[kategori_soal_id].forEach(item => {
                    finalOrderSoalIds.push(item.id);
                })
            })

            const persistOrderIds = async () => {

                await firestore.doc(`hasil_tryout/${hasilTo.id}/jawaban_siswa/order`).set({ orderIds: finalOrderSoalIds }, { merge: true })
            }
            persistOrderIds();
            setOrderIds(finalOrderSoalIds);
        }
    }, [soal, jawaban, to, hasilTo])

    React.useLayoutEffect(() => {

        scroll.scrollToTop({
            containerId: "contentWrap",
            offset: 0,
            isDynamic: true,
            duration: 0
        })
    }, [soalIndex])

    const handlePrevious = () => {


        setSlide({
            direction: "left",
            in: false
        });
        setTimeout(() => {

            if (soalIndex === 0)
                return null
            setSoalIndex(index => index - 1);

            setSlide({
                direction: "right",
                in: true
            })

        }, 300)
    }

    const handleNext = () => {
        setSlide({
            direction: "right",
            in: false
        });
        setTimeout(() => {
            if (soalIndex === orderIds.length - 1)
                return null
            setSoalIndex(index => index + 1);
            setSlide({
                direction: "left",
                in: true
            })

        }, 300)



    }


    const activeSoal = soal.find(item => item.id === orderIds[soalIndex]) || {};

    const activeJawaban = jawaban.find(item => item.id === orderIds[soalIndex]) || {};

    // const previousJawaban = jawaban.find(item => item.id === orderIds[soalIndex - 1]) || {};
    // const nextJawaban = jawaban.find(item => item.id === orderIds[soalIndex + 1]) || {};


    const handleAnswer = jawaban => async e => {
        if (activeJawaban.jawaban === jawaban) {
            await firestore.doc(`hasil_tryout/${hasilTo.id}/jawaban_siswa/${activeSoal.id}`).delete()
        } else {
            await firestore.doc(`hasil_tryout/${hasilTo.id}/jawaban_siswa/${activeSoal.id}`).set({

                jawaban,
                kategori_soal: activeSoal.kategori_soal,
                soal_id: activeSoal.id,
                index: soalIndex

            }, { merge: true })
        }

    }

    const callSetTiemout = React.useCallback((newValue) => {
        setTimeout(currValue => ({ ...currValue, ...newValue }));
    }, [])

    return <div className={classes.tryoutPage}>
        <div className={classes.pageHeader}>
            {/* <AppHeader /> */}

            <TryoutHeader to={to} hasilTo={hasilTo} activeSoal={activeSoal}
                openConfirmEnd={() => {
                    setConfirmEnd({ open: true });
                }}
                setTimeoutDialog={callSetTiemout}
            />
        </div>
        <Slide direction={slide.direction} in={slide.in}>
            <Container className={classes.container} maxWidth="xs" component="main">
                <div className={classes.currentQuestionWrap}>
                    <span className={classes.currentQuestion}>Soal {soalIndex + 1}</span>
                    <span className={classes.totalQuestion}>dari {orderIds.length}</span>
                </div>
                <div className={classes.TOBox}>
                    <div className={classes.TOBoxInner}>

                        {/* <div className={classes.boxHeader}>
                            <div className={classes.navLeft}>
                                {soalIndex > 0 ? <Button
                                    onClick={handlePrevious}
                                    className={`${classes.navBtn} ${activeJawaban.jawaban ? classes.btnAnswered : null}`}>
                                    <ChevronLeftIcon className={`${classes.navIcon} ${activeJawaban.jawaban ? classes.iconAnswered : null}`} />
                                    <span className={`${classes.prevNum} ${classes.theNum} ${activeJawaban.jawaban ? classes.numAnswered : null}`}>{soalIndex} {previousJawaban.jawaban}</span>
                                </Button> : <div className={classes.emptyBtn}> </div>}
                            </div>
                            
                            <div className={classes.navRight}>
                                {soalIndex < orderIds.length - 1 ? <Button
                                    onClick={handleNext}
                                    className={classes.navBtn}>
                                    <span className={`${classes.nextNum} ${classes.theNum} ${activeJawaban.jawaban ? classes.numAnswered : null}`}>{soalIndex + 2} {nextJawaban.jawaban}</span>
                                    <ChevronRightIcon className={`${classes.navIcon} ${activeJawaban.jawaban ? classes.iconAnswered : null}`} />
                                </Button> : <div className={classes.emptyBtn}> </div>}
                            </div>
                        </div> */}

                        <div id="contentWrap" className={classes.boxContentWrap}>
                            <div className={classes.boxContent} dangerouslySetInnerHTML={{ __html: activeSoal.pertanyaan || '' }} />
                            <div className={classes.answerOptionWrap}>
                                {Object.keys(activeSoal.pilihan_jawaban || {}).sort((a, b) => {
                                    return a.localeCompare(b);
                                }).map((key, index) => {
                                    const pilihan = activeSoal.pilihan_jawaban[key]

                                    return <div key={key} className={classes.answerOptionBox}>
                                        {pilihan.type === "text" && <p className={classes.answerOptions}>
                                            <span className={classes.bulletList}> <Button onClick={handleAnswer(key.toLowerCase())} className={`${classes.answerBtn} ${activeJawaban.jawaban === key.toLowerCase() ? classes.btnChecked : null}`}>{key}</Button></span> <span className={`${classes.theAnswer} ${activeJawaban.jawaban === key.toLowerCase() ? classes.answerChecked : null}`}>{pilihan.text}</span></p>}
                                        {pilihan.type === "image" && <p className={classes.answerOptionImg}>
                                            <span className={classes.bulletList}><Button onClick={handleAnswer(key.toLowerCase())} className={`${classes.answerBtn} ${activeJawaban.jawaban === key.toLowerCase() ? classes.btnChecked : null}`}>{key}</Button></span> <img src={pilihan.url} alt={key} /></p>}
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={classes.navWrap}>
                        <div className={classes.answerBox}>
                            <div style={{
                                width: '98%',
                                // overflowY: 'auto',
                                margin: '10px auto',
                                // display: 'flex',
                                // flexFlow: 'row wrap',
                                // justifyContent: 'space-between',
                            }}>

                                <ScrollMenu
                                    LeftArrow={LeftArrow}
                                    RightArrow={RightArrow}
                                    onWheel={onWheel}
                                >
                                    {orderIds.map((id, index) => {
                                        // const activeJawaban = jawaban.find(item => item.id === id) || {};
                                        return <Button
                                            itemId={id}
                                            onClick={() => {
                                                setSoalIndex(index);

                                            }}
                                            classes={{ label: classes.navBoxLabel }}
                                            key={id}
                                            className={`${soalIndex === index && classes.navBoxChecked} ${classes.navBox}`}
                                        >
                                            <span className={classes.navNum}>{index + 1}</span>
                                        </Button>
                                    })}

                                </ScrollMenu>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.answerBox}>
                    <div className={classes.navLeft}>
                        {soalIndex > 0 ? <Button
                            onClick={handlePrevious}
                            className={`${classes.navBtn} ${activeJawaban.jawaban ? classes.btnAnswered : null}`}>
                            {/* <ChevronLeftIcon className={`${classes.navIcon} ${activeJawaban.jawaban ? classes.iconAnswered : null}`} /> */}
                            <span className={`${classes.prevBtn} ${classes.prevNextBtn} ${activeJawaban.jawaban ? classes.numAnswered : null}`}>
                                {/* {soalIndex} {previousJawaban.jawaban} */}
                                Sebelumnya
                            </span>
                        </Button> : <div className={classes.emptyBtn}> </div>}
                    </div>
                    <Fab className={classes.fabMid} aria-label="add" onClick={() => {
                        setPetasoal({ open: true })
                    }}>
                        {/* <ViewComfyIcon className={classes.fabIcon} /> */}
                        <img src={iconFab} className={classes.fabIcon} alt="" />
                    </Fab>
                    <div className={classes.navRight}>
                        {soalIndex < orderIds.length - 1 ? <Button
                            onClick={handleNext}
                            className={classes.navBtn}>
                            <span className={`${classes.nextBtn} ${classes.prevNextBtn} ${activeJawaban.jawaban ? classes.numAnswered : null}`}>
                                {/* {soalIndex + 2} {nextJawaban.jawaban} */}
                                Selanjutnya
                            </span>
                            {/* <ChevronRightIcon className={`${classes.navIcon} ${activeJawaban.jawaban ? classes.iconAnswered : null}`} /> */}
                        </Button> : <div className={classes.emptyBtn}> </div>}
                    </div>
                    {/* add class btnChecked when user choose an answer */}
                    {/* <Button onClick={handleAnswer("a")} className={`${classes.answerBtn} ${activeJawaban.jawaban === "a" ? classes.btnChecked : null}`}>A</Button>
                        <Button onClick={handleAnswer("b")} className={`${classes.answerBtn} ${activeJawaban.jawaban === "b" ? classes.btnChecked : null}`}>B</Button>
                        <Button onClick={handleAnswer("c")} className={`${classes.answerBtn} ${activeJawaban.jawaban === "c" ? classes.btnChecked : null}`}
                        >C</Button>
                        <Button onClick={handleAnswer("d")} className={`${classes.answerBtn} ${activeJawaban.jawaban === "d" ? classes.btnChecked : null}`}
                        >D</Button>
                        <Button onClick={handleAnswer("e")} className={`${classes.answerBtn} ${activeJawaban.jawaban === "e" ? classes.btnChecked : null}`}
                        >E</Button> */}
                </div>
            </Container>
        </Slide>
        {/* peta soal */}
        <PetaSoal
            {...petasoal}
            handleClose={() => {

                setPetasoal({ open: false });
            }}

            jawaban={jawaban}
            orderIds={orderIds}
            soalIndex={soalIndex}
            goToIndex={(index) => {
                setSoalIndex(index);
            }}
        />
        {/* end session */}
        <ConfirmEnd
            {...confirmEnd}
            handleClose={() => {
                setConfirmEnd({ open: false });
            }}
            to={to}
            jawaban={jawaban}
            orderIds={orderIds}
            hasilTo={hasilTo}
        />
        {/* */}

        {/* timeout dialog*/}
        <TimeOut
            {...timeout}
            handleClose={() => {
                setTimeout({ open: false })
            }}
        />

        <Prompt

            when={hasilTo.status === "on-progress"}
            message="Kamu sedang dalam proses mengerjakan soal, apakah yakin mau keluar?"
        />


    </div>


}

function onWheel(apiObj, ev) {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
        ev.stopPropagation();
        return;
    }

    if (ev.deltaY < 0) {
        apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
        apiObj.scrollPrev();
    }
}
function LeftArrow() {
    const { isFirstItemVisible, scrollPrev } =
        React.useContext(VisibilityContext);



    return <IconButton disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        {/* {!isFirstItemVisible && <ArrowLeft />} */}
        <ArrowLeft />
    </IconButton>
    // return (
    //   <Arrow disabled={isFirstItemVisible} >
    //     Left
    //   </Arrow>
    // );
}

function RightArrow() {
    const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);


    return <IconButton disabled={isLastItemVisible} onClick={() => scrollNext()}>
        {/* {!isLastItemVisible && <ArrowRight />} */}
        <ArrowRight />
    </IconButton>
    // return (
    //   <Arrow disabled={isLastItemVisible} onClick={() => scrollNext()}>
    //     Right
    //   </Arrow>
    // );
}