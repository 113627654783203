import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    // index
    root: {
        margin: "auto",
        // maxWidth: "630px",
        width: "100%",
        marginBottom: theme.spacing(12)
    },

    newsBody: {
        position: 'relative',
        width: '100%',
        marginLeft: '10px'
    },
    newsInner: {
        backgroundColor: '#fff',
        borderRadius: 6,
        padding: '13px 13px 0',
    },
    newsCategory: {
        width: '100%',
        overflow: 'hidden',
        marginBottom: 10,
    },
    categorySlide: {
        display: 'flex',
        flexFlow: 'row nowrap',
        overflow: 'auto',
    },
    categoryLink: {
        width: 80,
        height: 50,
        margin: '0 10px',
        '&:first-child': {
            marginLeft: 0,
        }
    },
    categoryBtn: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        fontSize: 14,
        lineHeight: '18px',
    },
    // pinnedNews:{
    //     width: '100%',
    //     height: 200,
    //     position: 'relative',
    //     overflow: 'hidden',
    //     backgroundSize: 'cover'
    // },
    // pinnedNewsDetail:{
    //     height: 200,
    //     position: 'relative',
    //     overflow: 'hidden',
    //     backgroundSize: 'cover'
    // },
    newsFeedDetail: {
        padding: 20,
        textAlign: 'left',
        backgroundColor: '#fff',
    },
    detailHead: {
        display: 'flex',
        flexFlow: 'row nowrap',
        justifyContent: 'space-between',
    },
    shareBtn: {
        width: 40,
    },
    // wideImg:{
    //     width: 'auto',
    //     height: '200px',
    //     position: 'absolute',
    //     left: 0,
    //     bottom: 0,
    //     zIndex: 1,
    // },
    newsCaption: {
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0,
        zIndex: 2,
        textAlign: 'left',
        backgroundImage: 'linear-gradient(to bottom, rgba(255,0,0,0), rgba(0,0,0,1))',
        padding: 10,
    },
    newsHeadline: {
        color: '#fff',
        margin: '5px 0',
        textTransform: 'capitalize',
    },
    headlineDate: {
        color: theme.palette.textColor.gray2,
        fontSize: 11,
    },
    newsThumb: {
        borderRadius: 0,
    },
    newsTitle: {
        // color: theme.palette.primary.main,
        margin: '5px 0',
        textTransform: 'capitalize',
    },
    dateTime: {
        color: theme.palette.textColor.gray,
        fontSize: 11,
    },
    banner: {
        width: '100%',
        marginTop: 15,
        marginBottom: 100,
    },
    btnBanner: {
        padding: 0,
    },
    bannerImg: {
        width: '100%',
        height: 'auto',
    },
    disabledBtn: {
        color: theme.palette.primary.main + '!important',
        backgroundColor: '#fff !important',
    },
    // listNews: {
    //     '& li': {
    //         '&:last-child': {
    //             '& $listItemNews': {
    //                 borderBottom: 'none !important',
    //             }
    //         }
    //     }
    // },
    rootMain: {
        flexGrow: 1,
        height: '100vh',
        backgroundColor: theme.palette.textColor.gray4,
        overflow: 'auto',
        position: 'relative',
    },
    appBody: {
        position: 'relative',
        zIndex: 1,
        width: '100%',
        margin: 'auto',
    },
    blueBg: {
        width: '100%',
        height: 250,
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
    },
    newsCore: {
        textAlign: "justify",
        // width: '75%',
        paddingBottom: 25,
        // paddingLeft: 5

    },
    thumbNewsCore: {
        float: 'left',
        marginRight: theme.spacing(1)
    },
}));

export default useStyles;
