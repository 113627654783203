// React
import React, { useState, } from 'react';

// input
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

// router
import { Link } from 'react-router-dom';

// Styles
import useStyles from './styles';


function Verifikasi() {

    // UseStyles
    const classes = useStyles();

    // State
    const [form, setForm] = useState({
        first: '',
        second: '',
        third: '',
        fourth: ''
    })

    const [error, setError] = useState({
        first: '',
        second: '',
        third: '',
        fourth: ''
    })

    // OnSubmit
    const handleSubmit = async e => {

    }

    // const [state, setState] = useState({

    // });

    // OnChange
    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })

        setError({
            ...error,
            [e.target.name]: ''
        })
    }

    // const handleChanges = name => event => {
    //     setState({ ...state, [name]: event.target.checked });
    // };

    // const { check } = state;

    return (
        <div className={classes.root}>
            <div className={classes.pageHeading}>
                <h2 className={classes.pageTitle}>Verifikasi</h2>
                <p className={classes.pageSubtitle}>Kami telah mengirimkan SMS<br />berisi 4 angka sebagai kode verifikasi<br />Masukkan 4 angka tersebut</p>
                <div className={classes.verifyInputWrap}>
                    <form onSubmit={handleSubmit}>
                        <div className={classes.verifyInputs}>
                            <TextField
                                id="first"
                                name="first"
                                type="number"
                                label=""
                                className={classes.verInput}
                                value={form.first}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0, max: 1 } }}
                            />
                            <TextField
                                id="second"
                                name="second"
                                type="number"
                                label=""
                                className={classes.verInput}
                                value={form.second}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0, max: 1 } }}
                            />
                            <TextField
                                id="third"
                                name="third"
                                type="number"
                                label=""
                                className={classes.verInput}
                                value={form.third}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0, max: 1 } }}
                            />
                            <TextField
                                id="fourth"
                                name="fourth"
                                type="number"
                                label=""
                                className={classes.verInput}
                                value={form.fourth}
                                onChange={handleChange}
                                InputProps={{ inputProps: { min: 0, max: 1 } }}
                            />
                        </div>
                        <div className={classes.formRowBtn}>
                            <Button
                                size="large"
                                className={classes.verifyBtn}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Verifikasi
                                </Button>
                        </div>
                    </form>
                </div>
                <div className={classes.otherLinks}>
                    <p className={classes.regText}>Tidak menerima SMS?</p>
                    <p className={classes.regText}>
                        <Link className={classes.linkTo} to={'/syaratKetentuan'}>Ganti Nomor</Link>
                    </p>
                    <p className={classes.regText}>atau</p>
                    <p className={classes.regText}>
                        <Link className={classes.linkTo} to={'/syaratKetentuan'}>Verifikasi Telepon</Link>
                    </p>
                </div>
            </div>
        </div>
    );

}

export default Verifikasi;