import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    botNav: {
        position: 'fixed',
        bottom: 0,
        borderTop: 'solid 1px #ddd',
        paddingTop: 5,
        backgroundColor: '#fff',
        zIndex: 999,
        width: '100%',
        height: "72px !important",
        '@media(max-width: 320px)': {
            width: '100%',
            margin: 'auto',
            top: 'auto',
            left: 0,
            right: 0,
            bottom: -1,
        },
    },
    botNavAct:{
        maxWidth: 160,
        fontSize: 12,
        '@media(min-width: 320px)': {
            minWidth: 65,
        },
    },
    botNavActMid:{
        maxWidth: 160,
        '@media(min-width: 320px)': {
            minWidth: 65,
        },
    },
    label:{
        fontSize: '11px !important',
        fontWeight: 700,
        marginTop: 10,
    },
    sdHolder:{
        width: 87,
        height: 47,
        position: 'fixed',
        margin: '0 auto',
        zIndex: 999,
        left: 0,
        right: 0,
        bottom: -7,
    },
    sdIcon:{
        width: 45,
        height: 45,
    },
    backdrop:{
        zIndex: 1,
    },
    speedDial: {
        position: 'absolute',
        bottom: 6,
        right: theme.spacing(2),
    },
    fab:{
        backgroundColor: '#fff !important',
        color: theme.palette.textColor.gray5,
        boxShadow: 'none !important',
        margin: '0 auto !important',
        borderRadius: 0,
    },
    staticTooltipLabel:{
        width: 125,
        fontSize: 12,
        boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
    },
    fabAction:{
        background: 'transparent',
        boxShadow: 'none'
    }
}))

export default useStyles;