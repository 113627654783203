// React
import React from "react";

// Router
import { Route, Switch } from "react-router-dom";

// Styles
import useStyles from "./styles";

// Components
import BottomNav from "../../components/BottomNav";
import Konsultasi from "../konsultasi";

function LayoutD() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.blueBg}></div>
      <Switch>
        <Route path="/chat" component={Konsultasi} />
        <Route path="/konsultasi" component={Konsultasi} />
      </Switch>

      <Route
        path={[
          "/chat",
          "/konsultasi",
          "/misi",
          "/listmisi",
          "/konfirmasimisi/:id",
        ]}
        render={() => {
          return <BottomNav />;
        }}
      />
    </div>
  );
}

export default LayoutD;
