//
import React from "react";
//
import { makeStyles } from "@material-ui/core/styles";
//
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Update from "@material-ui/icons/Update";
//
import Slide from "@material-ui/core/Slide";

import { useSw } from "../SwProvider";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    backgroundColor: "#FFF",
    paddingTop: 150,
    alignItems: "center",
  },
  btnUpdate: {
    marginTop: 40,
    backgroundColor: "#F26722",
    fontFamily: "Glacial Indifference",
    textTransform: "capitalize",
    width: 150,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#FFA79C",
    },
  },
  title: {
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
    color: "#4F4F4F",
    fontFamily: "Glacial Indifference",
    margin: "20px auto"
  },
  subtitle: {
    maxWidth: 245,
    fontSize: 12,
    fontWeight: 400,
    textAlign: "center",
    color: "#4F4F4F",
    fontFamily: "Glacial Indifference",
  },
  ico: {
    fontSize: 50,
    color: "#F26722"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const { isUpdateAvailable, updateAssets } = useSw();

  React.useEffect(() => {
    if (isUpdateAvailable) {
      setOpen(true);
    }
  }, [isUpdateAvailable]);

  return (
    <React.Fragment>
      <Dialog fullScreen open={open} TransitionComponent={Transition}>
        <div className={classes.box}>
          <Update color="primary" fontSize="large" className={classes.ico}/>
          <Typography className={classes.title}>Pembaruan Tersedia</Typography>
          <Typography className={classes.subtitle}>
            Klik tombol dibawah untuk memuat ulang
            aplikasi dengan update terbaru
          </Typography>
          <Button
            className={classes.btnUpdate}
            onClick={updateAssets}
            disableElevation
            variant="contained"
            color="primary"
            size="large"
          >
            Muat Ulang
          </Button>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
