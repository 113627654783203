import React, { useState } from "react";

import useStyles from "./styles";

import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import BottomNav from "../../components/BottomNav";
import { HFHeader } from "../../components/AppHeaderNew";
import CreditIcon from "../../images/icon-credits";
import BuyDialog from "./buyDialog";
import { firestore, functions, useFirebase } from "../../components/FirebaseProvider";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import TopupDialog from "./topupDialog";
// import set from "date-fns/fp/set/index";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { Backdrop, CircularProgress } from "@material-ui/core";
import talentlytica from "../../images/talentlytica-logo.png"
//import axios from "axios";
//import { useSiswa } from "../../components/SiswaProvider";

function ListCourse() {
    const classes = useStyles();

    const { user } = useFirebase()

    //const { profile } = useSiswa();

    const { enqueueSnackbar } = useSnackbar();

    const history = useHistory();

    // get course data
    const courseColl = firestore.collection(`course`);
    const [dataCourse] = useCollectionData(courseColl, { idField: 'id' });
    // const price = 200;

    const [loading, setLoading] = useState(false)

    // get hasil course
    const hasilCourseQuery = firestore.collection(`hasil_course`).where('user_id', '==', user?.uid || '');
    const [dataHasilCourse,] = useCollectionData(hasilCourseQuery);
    // console.log(dataHasilCourse)

    // users doc
    const usersDoc = firestore.doc(`users/${user?.uid}`);
    const [usersData] = useDocumentData(usersDoc);

    // const personaDoc = firestore.doc(`talentlytica_persona/0AsfuJlrUVaUcuBzRKGfwrxMOkr2`)
    // const [personaData] = useDocumentData(personaDoc);

    // topup dialog
    const [topupDialog, setTopUpDialog] = useState(false);

    // talentlytica asesment
    const handleSubmitCourse = courseId => async (e) => {
        setLoading(true)
        try {
            // const usersDoc = await firestore.doc(`users/${user?.uid}`).get()
            // console.log(usersDoc?.data()?.talentlytica?.token)

            const getCourse = functions.httpsCallable("course-get")

            const response = await getCourse({
                course_id: courseId,
            })
            // eslint-disable-next-line no-unused-expressions
            window?.mixpanel?.track("Buy course Button Clicked", {
                userId: user?.uid,
                tempUID: user?.tempUID,
            })
            console.log(response)
            setBuyDialog({ open: false });

            // window.location.href = response?.data?.token


        } catch (e) {
            console.log(e.message)
            if (e.message === "Koin Kurang") {
                setTopUpDialog(true);
            } else {
                enqueueSnackbar(e.message, { variant: "error" });
            }

        }
        setLoading(false)
    }

    const clickPersonaTest = course => async (e) => {
        // console.log(course)
        // if (usersData?.talentlytica?.token) {
        //     setLoading(true)
        //     // eslint-disable-next-line no-unused-expressions
        //     window?.mixpanel?.track("Persona test Kerjakan Button Clicked", {
        //         userId: user?.uid,
        //         tempUID: user?.tempUID,
        //     })
        //     window.location.href = usersData?.talentlytica?.token
        // } else {
        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_course${course?.nama}&email=${profile?.email}`);
        setBuyDialog({ open: true, name: course?.nama, price: course?.harga, callFunction: handleSubmitCourse(course?.id), enable: usersData?.talentlytica?.token })
        // }
    }



    const [buyDialog, setBuyDialog] = useState({
        open: false,
        name: "",
        price: {},
    });
    // const [okDialog, setOkDialog] = useState(false);

    return (
        <>
            <div className={classes.root}>
                <HFHeader
                    title="Course"
                    desc="Kenali dan tambah pengetahuanmu dengan menonton vidio course berikut"
                    image="discover"
                    beta={true}
                />
                <Backdrop style={{ zIndex: 1000 }} open={loading}>
                    <CircularProgress color="secondary" />
                </Backdrop>
                {
                    dataCourse?.map((course) => {
                        return <div className={classes.asmCon}>
                            <div className={classes.asmItem}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Avatar variant="rounded" alt="" className={classes.asmImg}>
                                                    {/* N */}
                                                    {/* <img style={{height: "45px", opacity: "30%"}} alt="img" src={noImg} /> */}
                                                    <img style={{ height: "45px" }} alt="img" src={talentlytica} />
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs>
                                                <p className={classes.asmTitle}>{course?.nama}</p>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item>
                                                <p className={classes.asmData}>Jumlah Video</p>
                                                {/* <p className={classes.asmData}>Waktu Pengerjaan</p> */}
                                            </Grid>
                                            <Grid item xs>
                                                <p className={classes.asmData}>
                                                    : <b>{course?.daftar_content?.length} Video</b>
                                                </p>
                                                {/* <p className={classes.asmData}>
                        : <b>1 Jam 24 Menit</b>
                      </p> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs className={classes.ibtn}>
                                        {
                                            dataHasilCourse?.[0]?.status === 'purchased' ?
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disabled={loading}
                                                    disableElevation
                                                    className={classes.btn1}
                                                    // startIcon={!usersData?.talentlytica?.token && <CreditIcon color="secondary" fontSize="small" />}
                                                    onClick={async () => {
                                                        // pixel
                                                        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_course_lihatCourse${course?.nama}&email=${profile?.email}`);
                                                        history.push(`/course/content/${course?.id}`)
                                                    }}
                                                >Lihat Course

                                                </Button>
                                                :
                                                <Button
                                                    variant="outlined"
                                                    color="secondary"
                                                    disabled={loading}
                                                    disableElevation
                                                    className={classes.btn1}
                                                    startIcon={<CreditIcon color="secondary" fontSize="small" />}
                                                    onClick={clickPersonaTest(course)}
                                                >{course?.harga}

                                                </Button>
                                        }

                                        {/* <Button
                  variant="outlined"
                  disableElevation
                  className={classes.btn2}
                >
                  Kerjakan
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  className={classes.btn3}
                >
                  Lihat Hasil
                </Button> */}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    })
                }

            </div>
            <Hidden mdUp><BottomNav /></Hidden>

            <BuyDialog
                {...buyDialog}
                // {...okDialog}
                handleClose={() => {
                    setBuyDialog(() => {
                        return {
                            ...buyDialog,
                            open: false,
                        };
                    });
                }}
                loading={loading}
            // handleOk = {()=> {
            //   setBuyDialog(() => {
            //     return {
            //       ...buyDialog,
            //       open: false,
            //     };
            //   });
            //   setOkDialog(true)
            // }}
            // handleCloseOk = {()=> {
            //   setOkDialog(false)
            // }}
            />
            <TopupDialog
                open={topupDialog}
                handleClose={() => {
                    setTopUpDialog(false)
                }}
            />
        </>
    );
}

export default ListCourse;