
import React, { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { CheckCircleOutline, RadioButtonUnchecked } from '@material-ui/icons';

export default function Ninth({ form, handleChange, error, setForm, setError, other, setOther }) {
    const [check, setCheck] = useState([])
    const handleRadioChange = (value) => (event) => {
        // console.log(value)
        const currentIndex = check?.indexOf?.(value);
        const newChecked = [...check];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheck(newChecked)
        setForm({
            ...form,
            dapat_info_loker: newChecked
        });

        setError({
            ...error,
            dapat_info_loker: ''
        });
    };
    const handleChangeOther = (e) => {
        setOther(e.target.value);

    }
    useEffect(() => {
        if (form?.dapat_info_loker) {
            setCheck(form?.dapat_info_loker)
        }
    }, [form.dapat_info_loker])
    // console.log(other)
    const list = ['Job Marketplace(Glints, Jobstreet, dll)', ' Telegram group ', 'Facebook', ' Instagram', ' Twitter', ' Career Center Kampus', ' Google', ' Tiktok']
    return (
        <>
            <div style={{ textAlign: 'left' }}>
                <Typography style={{ marginBottom: '10px' }}>Darimana {form?.nama_panggilan} mendapatkan informasi lowongan kerja ?</Typography>
                <Typography variant='subtitle2'>{form?.nama_panggilan || 'kamu'} boleh mengisi lebih dari satu</Typography>
                <FormControl style={{ marginTop: '20px', marginLeft: '20px' }} component="fieldset" error={error.dapat_info_loker ? true : false}
                // className={classes.formControl}
                >
                    <FormGroup>
                        {
                            list?.map((lst) => {
                                return <FormControlLabel key={lst} style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value={lst} onChange={handleRadioChange(lst)} control={<Checkbox checked={check?.indexOf?.(lst) !== -1} checkedIcon={<CheckCircleOutline color='primary' />} icon={<RadioButtonUnchecked />} />} label={lst} />

                            })
                        }

                        <input
                            type='text'
                            style={{
                                border: '1px solid', borderRadius: '7px', padding: '12px 5px 12px 10px',
                                marginRight: '15px',
                                marginLeft: '-11px'
                            }}
                            placeholder='Other'
                            name='dapat_info_loker'
                            value={other}
                            onChange={handleChangeOther}
                        />
                    </FormGroup>

                    {/* <FormHelperText>{helperText}</FormHelperText> */}

                </FormControl>


                <Typography variant='subtitle2' color='error'>{error.dapat_info_loker && error.dapat_info_loker}</Typography>

            </div>
        </>
    )
}

