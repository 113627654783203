import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    padding: "0px 27px 50px",
    "@media(min-width: 400px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 700px)": {
      width: "50%",
    },
    "@media(min-width: 1200px)": {
      width: "40%",
    },
  },
  formRow: {
    margin: "0 auto 10px",
  },
  formRowBtn: {
    margin: "10px auto 10px",
  },
  pageTitle: {
    color: "#222222",
    fontSize: 23.33,
    fontWeight: 700,
    textAlign: "left",
    marginBottom: 0,
  },
  pageSubtitle: {
    color: "#4F4F4F",
    fontSize: 13.33,
    textAlign: "left",
    margin: "5px 0px 30px",
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: "11px",
    margin: 0,
    marginTop: 10,
    textAlign: "left",
  },
  loginBtn: {
    borderRadius: 4,
    textTransform: "Capitalize",
    fontWeight: "bold",
    padding: "5px 25px",
    width: "100%",
    border: "1.5px solid #F26722",
    "&:hover": {
      border: "1.5px solid #F26722",
    },
  },
  btnKirim: {
    borderRadius: 4,
    textTransform: "Capitalize",
    fontWeight: "bold",
    padding: "5px 25px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#1BB3EA",
    },
  },
  regBtns: {
    marginTop: "15px",
  },
}));

export default useStyles;
