import React, { useState } from "react";

import useStyles from "./styles";

import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";

import BottomNav from "../../components/BottomNav";
import { HFHeader } from "../../components/AppHeaderNew";
import CreditIcon from "../../images/icon-credits";
import BuyDialog from "./buyDialog";
import { firestore, functions, useFirebase } from "../../components/FirebaseProvider";
import { useDocumentData } from "react-firebase-hooks/firestore";
import TopupDialog from "./topupDialog";
// import set from "date-fns/fp/set/index";
import { useSnackbar } from "notistack";
import { useSiswa } from "../../components/SiswaProvider";
import { useHistory } from "react-router-dom";
import { Backdrop, CircularProgress } from "@material-ui/core";
import talentlytica from "../../images/talentlytica-logo.png"
import karirai from "../../images/logo-i-only.png"
import { urlRiasec } from "../../config/url";
//import axios from "axios";

function Assessment() {
  const classes = useStyles();

  const { user } = useFirebase();

  //const { profile } = useSiswa();

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  // const price = 200;
  const name = "";

  const [loading, setLoading] = useState(false)
  // reasec internal
  const handleSubmitReasec = async (e) => {
    setLoading(true)
    try {
      const getTokenReasec = functions.httpsCallable("user-reqCustomToken")
      const response = await getTokenReasec()
      // console.log(response)
      if (response?.data?.customToken) {
        // pixel
        // await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_assesment_getTokenRiasec&email=${profile?.email}`);
        // eslint-disable-next-line no-unused-expressions
        window?.mixpanel?.track("Get Token Riasec Button Clicked", {
          userId: user?.uid,
          tempUID: user?.tempUID,
        })
        window.location.href = `${urlRiasec}/${response?.data?.customToken}`
      }
    } catch (e) {
      console.log(e.message)
    }
    setLoading(false)
  }

  // users doc
  const usersDoc = firestore.doc(`users/${user?.uid}`);
  const [usersData] = useDocumentData(usersDoc);

  const { personaData, riasecData } = useSiswa()
  // const personaDoc = firestore.doc(`talentlytica_persona/0AsfuJlrUVaUcuBzRKGfwrxMOkr2`)
  // const [personaData] = useDocumentData(personaDoc);

  // topup dialog
  const [topupDialog, setTopUpDialog] = useState(false);

  // talentlytica asesment
  const handleSubmitPersonaTest = async (e) => {
    setLoading(true)
    try {
      // const usersDoc = await firestore.doc(`users/${user?.uid}`).get()
      // console.log(usersDoc?.data()?.talentlytica?.token)

      const getTokenPersonaTest = functions.httpsCallable("talentlytica-assignuser")

      const response = await getTokenPersonaTest({
        fullname: usersData?.fullName,
        email: usersData?.email
      });
      // pixel
      // await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_assesment_personaTestBuy&email=${profile?.email}`);
      // eslint-disable-next-line no-unused-expressions
      window?.mixpanel?.track("Persona test buy Button Clicked", {
        userId: user?.uid,
        tempUID: user?.tempUID,
      })
      // console.log(response)
      setBuyDialog({ open: false });

      window.location.href = response?.data?.token


    } catch (e) {
      console.log(e.message)
      if (e.message === "Koin Kurang") {
        setTopUpDialog(true);
      } else {
        enqueueSnackbar(e.message, { variant: "error" });
      }

    }
    setLoading(false)
  }

  const clickPersonaTest = async (e) => {
    if (usersData?.talentlytica?.token) {
      setLoading(true);
      // pixel
      //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_topup&email=${profile?.email}`);
      // eslint-disable-next-line no-unused-expressions
      window?.mixpanel?.track("Persona test Kerjakan Button Clicked", {
        userId: user?.uid,
        tempUID: user?.tempUID,
      })
      window.location.href = usersData?.talentlytica?.token
    } else {
      setBuyDialog({ open: true, name: name, price: 100, callFunction: handleSubmitPersonaTest, enable: usersData?.talentlytica?.token })
    }
  }



  const [buyDialog, setBuyDialog] = useState({
    open: false,
    name: "",
    price: {},
  });
  // const [okDialog, setOkDialog] = useState(false);

  return (
    <>
      <div className={classes.root}>
        <HFHeader
          title="Personal Discovery"
          desc="Kenali dan jelajahi dirimu dengan mengerjakan tes-tes berikut"
          image="discover"
          beta={true}
        />
        <Backdrop style={{ zIndex: 1000 }} open={loading}>
          <CircularProgress color="secondary" />
        </Backdrop>
        <div className={classes.asmCon}>
          {/* <div className={classes.asmItem}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Avatar variant="rounded" alt="" className={classes.asmImg}>
                      N
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <p className={classes.asmTitle}>{name}</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <p className={classes.asmData}>Jumlah Soal</p>
                    <p className={classes.asmData}>Waktu Pengerjaan</p>
                  </Grid>
                  <Grid item xs>
                    <p className={classes.asmData}>
                      : <b>30 Soal</b>
                    </p>
                    <p className={classes.asmData}>
                      : <b>30 Jam</b>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.ibtn}>
                <Button
                  variant="outlined"
                  color="secondary"
                  disableElevation
                  className={classes.btn1}
                  startIcon={<CreditIcon color="secondary" fontSize="small" />}
                  onClick={() =>
                    setBuyDialog({ open: true, name: name, price: price })
                  }
                >
                  {price}
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  className={classes.btn2}
                >
                  Kerjakan
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  className={classes.btn3}
                >
                  Lihat Hasil
                </Button>
              </Grid>
            </Grid>
          </div> */}
          <div className={classes.asmItem}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Avatar variant="rounded" alt="" className={classes.asmImg}>
                      {/* N */}
                      {/* <img style={{height: "45px", opacity: "30%"}} alt="img" src={noImg} /> */}
                      <img style={{ height: "45px" }} alt="img" src={karirai} />
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <p className={classes.asmTitle}>Quiz Minat Karir</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <p className={classes.asmData}>Jumlah Soal</p>
                    <p className={classes.asmData}>Waktu Pengerjaan</p>
                  </Grid>
                  <Grid item xs>
                    <p className={classes.asmData}>
                      : <b>7 Soal</b>
                    </p>
                    <p className={classes.asmData}>
                      : <b>5 Menit</b>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.ibtn}>
                {
                  riasecData?.result ?
                    <>
                      <Button
                        disabled={loading}
                        variant="outlined"
                        disableElevation
                        className={classes.btn3}
                        // startIcon={<CreditIcon color="secondary" fontSize="small" />}
                        onClick={async () => {
                          try {
                            //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_assesment_riasecResult&email=${profile?.email}`);
                            history.push('/riasecResult')

                          } catch (e) {
                            history.push('/riasecResult')

                          }
                          // pixel

                          // window.open(`https://quiz.karir.ai/result?r=${usersData?.riasecTestResult}`)
                        }}
                      >Lihat Hasil

                      </Button>
                      {/* <Button
                        disabled={loading}
                        variant="outlined"
                        color="secondary"
                        disableElevation
                        className={classes.btn1}
                        // startIcon={<CreditIcon color="secondary" fontSize="small" />}
                        onClick={handleSubmitReasec}
                      >Kerjakan Ulang

                      </Button> */}
                    </> :
                    <Button
                      disabled={loading}
                      variant="outlined"
                      color="secondary"
                      disableElevation
                      className={classes.btn1}
                      startIcon={<CreditIcon color="secondary" fontSize="small" />}
                      onClick={handleSubmitReasec}
                    >Gratis

                    </Button>
                }

                {/* <Button
                  variant="outlined"
                  disableElevation
                  className={classes.btn2}
                >
                  Kerjakan
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  className={classes.btn3}
                >
                  Lihat Hasil
                </Button> */}
              </Grid>
            </Grid>
          </div>
        </div>
        <div className={classes.asmCon}>
          <div className={classes.asmItem}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Avatar variant="rounded" alt="" className={classes.asmImg}>
                      {/* N */}
                      {/* <img style={{height: "45px", opacity: "30%"}} alt="img" src={noImg} /> */}
                      <img style={{ height: "45px" }} alt="img" src={talentlytica} />
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <p className={classes.asmTitle}>Test Psikometri Online</p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item>
                    <p className={classes.asmData}>Jumlah Soal</p>
                    <p className={classes.asmData}>Waktu Pengerjaan</p>
                  </Grid>
                  <Grid item xs>
                    <p className={classes.asmData}>
                      : <b>11 Bagian</b>
                    </p>
                    <p className={classes.asmData}>
                      : <b>1 Jam 24 Menit</b>
                    </p>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs className={classes.ibtn}>
                {
                  personaData?.result ?
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={loading}
                      disableElevation
                      className={classes.btn1}
                      startIcon={!usersData?.talentlytica?.token && <CreditIcon color="secondary" fontSize="small" />}
                      onClick={async () => {
                        try {
                          // pixel
                          //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_assesment_personaTestResult&email=${profile?.email}`);
                          history.push(`/personaTestResult`)
                        } catch (e) {
                          history.push(`/personaTestResult`)

                        }
                      }}
                    >Lihat Hasil

                    </Button> :
                    <Button
                      variant="outlined"
                      color="secondary"
                      disabled={loading}
                      disableElevation
                      className={classes.btn1}
                      startIcon={!usersData?.talentlytica?.token && <CreditIcon color="secondary" fontSize="small" />}
                      onClick={clickPersonaTest}
                    >{usersData?.talentlytica?.token ? 'Kerjakan' : 100}

                    </Button>
                }

                {/* <Button
                  variant="outlined"
                  disableElevation
                  className={classes.btn2}
                >
                  Kerjakan
                </Button>
                <Button
                  variant="outlined"
                  disableElevation
                  className={classes.btn3}
                >
                  Lihat Hasil
                </Button> */}
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <BottomNav />

      <BuyDialog
        {...buyDialog}
        // {...okDialog}
        handleClose={() => {
          setBuyDialog(() => {
            return {
              ...buyDialog,
              open: false,
            };
          });
        }}
        loading={loading}
      // handleOk = {()=> {
      //   setBuyDialog(() => {
      //     return {
      //       ...buyDialog,
      //       open: false,
      //     };
      //   });
      //   setOkDialog(true)
      // }}
      // handleCloseOk = {()=> {
      //   setOkDialog(false)
      // }}
      />
      <TopupDialog
        open={topupDialog}
        handleClose={() => {
          setTopUpDialog(false)
        }}
      />
    </>
  );
}

export default Assessment;
