// React
import React, { useEffect } from "react";
import AppHeader from "../../components/AppHeader";

// Styles
import useStyles from "./styles";

//
import { useParams, useHistory } from "react-router-dom";

// React Firebase Hooks
import { useCollectionData } from "react-firebase-hooks/firestore";

//
import { firestore } from "../../components/FirebaseProvider";

//
import AppLoading from "../../components/AppLoading";

function EventProcess() {
  const classes = useStyles();

  const { eventId, transaksiId } = useParams();

  const history = useHistory();

  const pesertaColl = firestore
    .doc(`events/${eventId}`)
    .collection("peserta_event")
    .where("transaksi_event_id", "==", transaksiId);

  const [peserta, loadingPeserta] = useCollectionData(pesertaColl);

  useEffect(() => {
    if (peserta && peserta?.length === 1) {
      history.push(`/acara/ticket/${eventId}/${peserta?.[0]?.pesertaId}`);
    }
  }, [eventId, history, peserta]);

  if (loadingPeserta) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header2}>
        <AppHeader />
      </div>
      <div className={classes.tcBody} style={{ paddingTop: 20 }}>
        <p className={classes.sub2}>
          Kofirmasi pembayaran kamu sudah diteruskan ke tim Finance kami.
        </p>
        <p className={classes.sub2} style={{ paddingTop: 20 }}>
          Mohon kesabarannya untuk menunggu tim kami memproses data yang telah
          masuk
        </p>
        <p style={{ fontWeight: 700, fontSize: 17 }} className={classes.sub2}>
          maksimal 2 X 24 jam
        </p>

        <img
          style={{ margin: "25px 0px" }}
          width="50px"
          src="https://firebasestorage.googleapis.com/v0/b/sp-dev-e0bd5.appspot.com/o/wait.png?alt=media&token=d2c885f6-d67f-491a-b02c-5861862ad84f"
          alt=""
        />

        <p className={classes.sub2}>
          kami akan mengirimkan email ke alamat email yang kamu daftarkan jika
          pembayaranmu sudah berhasil kami proses.
        </p>
        <p className={classes.sub2} style={{ paddingTop: 20 }}>
          Jika membutuhkan bantuan tim finance, silahkan hubungi{" "}
          <strong style={{ fontWeight: 700, fontSize: 17 }}>
            0822-5744-6072
          </strong>
        </p>
      </div>
    </div>
  );
}

export default EventProcess;
