import React, { useState, useEffect } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import VisibilityOutlined from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffOutlined from "@material-ui/icons/VisibilityOffOutlined";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import { Redirect } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
// import PasswordHash from "password-hash";

import useStyles from "./styles/index";

import {
  useFirebase,
  auth,
} from "../../components/FirebaseProvider";
import AppHeader from "../../components/AppHeader";
import AppLoading from "../../components/AppLoading";



function RegisterForm(props) {
  // Firebase Use
  const { user, loading } = useFirebase();
  // const { profile } = useSiswa();


  // // Sign in With Google & Facebook
  // const handleSocialLogin = (provider) => () => {
  //   auth.signInWithRedirect(provider);
  // };

  // Set to database for Social Login
  useEffect(() => {
    if (user) {
      const getResult = async () => {
        try {
          await auth.getRedirectResult();

        } catch (error) {
          console.log(error.message);
        }
      };
      getResult();
    }
  }, [user]);

  // Class
  const classes = useStyles();

  // Form
  const [form, setForm] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    name: "",
    username: "",
    showPassword: false,
    showPasswordd: false,
  });

  // Error Form
  const [error, setError] = useState({
    email: "",
    password: "",
    passwordConfirm: "",
    name: "",
    username: "",
  });

  const handleClickShowPassword = () => {
    setForm({ ...form, showPassword: !form.showPassword });
  };



  // Submit Button
  const [isSubmitting, setSubmitting] = useState(false);

  // On Change
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  // Validator Error
  const validate = async () => {
    const newError = { ...error };

    // if (!form.name) {
    //   newError.name = "Nama wajib diisi";
    // }

    // if (!form.username) {
    //   newError.username = "Username wajib diisi";
    // } else {
    //   const validateUsername = functions.httpsCallable("user-verifyUsername");
    //   const result = await validateUsername({
    //     username: form.username,
    //   });
    //   if (!result.data.available) {
    //     newError.username = "Username tidak tersedia";
    //   }
    // }

    if (!form.email) {
      newError.email = "Email wajib diisi";
    } else if (!isEmail(form.email)) {
      newError.email = "Email tidak valid";
    }

    if (!form.password) {
      newError.password = "Kata sandi wajib diisi";
    }

    if (!form.passwordConfirm) {
      newError.passwordConfirm = "Ulangi kata sandi wajib diisi";
    } else if (form.passwordConfirm !== form.password) {
      newError.passwordConfirm = "Ulangi kata sandi tidak sama";
    }

    return newError;
  };

  // Condition if Button Submitted
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoadingBd(true);
    const findErrors = await validate();
    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmitting(true);
        await auth.createUserWithEmailAndPassword(
          form.email,
          form.password
        );
        // const date = new Date();
        // await getData("utm_source");
        // await firestore.doc(`users/${user.user.uid}`).set({
        //   nama: form.name,
        //   username: form.username,
        //   email: form.email,
        //   password: PasswordHash.generate(form.password),
        //   check: "setuju",
        //   dibuat_pada: date,
        //   utm_source: utm_source ?? "",
        // });
        // await firestore.doc(`notifikasi/${user.user.uid}`).set(
        //   {
        //     inApp: true,
        //     ringApp: {
        //       id: 1,
        //       ring: "https://cdn.glitch.com/922ee5f5-2d45-4f6a-955c-10b16e7aee54%2Fringtone.mp3?v=1575722255364",
        //       nama: "Sony",
        //       icon: true,
        //     },
        //     ringmessage: {
        //       id: 3,
        //       ring: "https://cdn.glitch.com/fa950c3d-2965-41dd-b803-2aca6d06ce3c%2Fhallo.mp3?v=1578782795030",
        //       nama: "Halo",
        //       icon: true,
        //     },
        //   },
        //   { merge: true }
        // );
      } catch (e) {
        const newError = {};

        switch (e.code) {
          case "auth/email-already-in-use":
            newError.email = "Email sudah terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Email tidak valid";
            break;
          case "auth/weak-password":
            newError.password = "Password lemah";
            break;
          case "auth/operation-not-allowed":
            newError.email = "Metode email dan password tidak didukung";
            break;
          default:
            newError.email = "Terjadi kesalahan silahkan coba lagi";
            break;
        }
        setError(newError);
        setSubmitting(false);
      }
    }
    // setLoadingBd(false);
  };



  // Condition Loading
  if (loading) {
    return <AppLoading />;
  }

  // conditions when there are users for SignUp with form
  if (user) {
    return <Redirect to="/lengkapiData" />;
  }

  return (
    <div className={classes.root}>
      <AppHeader />

      <div className={classes.formRow}>
        <p className={classes.label} style={{ margin: 0 }}>Email</p>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Masukkan Email Anda"
          id="email"
          name="email"
          // className={classes.textField}
          value={form.email}
          onChange={handleChange}
          disabled={isSubmitting}
          helperText={error?.email}
          error={error?.email ? true : false} />
        {/* {error?.email && <p className={classes.error}>{error?.email}</p>} */}
      </div><div className='' style={{
        marginTop: '20px'
      }}>
        <div className='{classes.passw}'>
          <p className={classes.label} style={{ margin: 0 }}>
            Password
          </p>
          {/* <Button
        color="primary"
        className='{classes.forgetBtn}'
        // onClick={() => props.history.push("/lupapassword")}
    >
        Lupa Password ?
    </Button> */}
        </div>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Masukkan Password Anda"
          id="password"
          name="password"
          className={classes.textField}
          value={form.password}
          type={form.showPassword ? "text" : "password"}
          onChange={handleChange}
          disabled={isSubmitting}
          helperText={error?.password}
          error={error?.password ? true : false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="toggle password visibilityOutlined"
                  onClick={handleClickShowPassword}
                >
                  {form.showPassword ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }} />
        {/* {error?.password && (
        <p className={classes.error}>{error?.password}</p>
    )} */}
      </div><div className='' style={{
        marginTop: '20px'
      }}>
        <div className='{classes.passw}'>
          <p className={classes.label} style={{ margin: 0 }}>
            Masukkan Ulang Password
          </p>
          {/* <Button
        color="primary"
        className='{classes.forgetBtn}'
        // onClick={() => props.history.push("/lupapassword")}
    >
        Lupa Password ?
    </Button> */}
        </div>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Masukkan Ulang Password Anda"
          id="passwordConfirm"
          name="passwordConfirm"
          // className={classes.textField}
          value={form.passwordConfirm}
          type={form.showPassword ? "text" : "password"}
          onChange={handleChange}
          disabled={isSubmitting}
          helperText={error?.passwordConfirm}
          error={error?.passwordConfirm ? true : false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  aria-label="toggle password visibilityOutlined"
                  onClick={handleClickShowPassword}
                >
                  {form.showPassword ? (
                    <VisibilityOutlined />
                  ) : (
                    <VisibilityOffOutlined />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }} />
        {/* {error?.password && (
        <p className={classes.error}>{error?.password}</p>
    )} */}
      </div><div className='' style={{
        marginTop: '50px'
      }}>
        <Button
          // className={classes.loginBtn}
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          Daftar
        </Button>
      </div></div>
  );
}

export default RegisterForm;
