
import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { CheckCircleOutline, } from '@material-ui/icons';

export default function Eighth({ form, handleChange, error, setForm, setError }) {
    const handleRadioChange = (event) => {
        setForm({
            ...form,
            jumlah_lamaran_kerja_sebulan: event.target.value

        });

        setError({
            ...error,
            jumlah_lamaran_kerja_sebulan: ''
        });
    };
    return (
        <>
            <div style={{ textAlign: 'left' }}>
                <Typography style={{ marginBottom: '10px' }}>Berapa rata-rata jumlah lamaran kerja yang {form?.nama_panggilan} kirimkan dalam sebulan?</Typography>
                <FormControl style={{ marginTop: '20px', marginLeft: '20px' }} component="fieldset" error={error.jumlah_lamaran_kerja_sebulan ? true : false}
                // className={classes.formControl}
                >
                    <RadioGroup aria-label="quiz" name="quiz" value={form?.jumlah_lamaran_kerja_sebulan} onChange={handleRadioChange}>
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Kurang dari 10" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Kurang dari 10" />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="10 - 50" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="10 - 50 " />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="50 - 100" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="50 - 100" />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Lebih dari 100" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Lebih dari 100" />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Lebih dari 1000" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Lebih dari 1000" />
                    </RadioGroup>
                    {/* <FormHelperText>{helperText}</FormHelperText> */}

                </FormControl>
                <Typography variant='subtitle2' color='error'>{error.jumlah_lamaran_kerja_sebulan && error.jumlah_lamaran_kerja_sebulan}</Typography>

            </div>
        </>
    )
}

