import React from "react";

import { Link } from "react-router-dom";

import useStyles from "./styles";

import Button from "@material-ui/core/Button";
import Block from "@material-ui/icons/Block";
import Typography from "@material-ui/core/Typography";

function NotFound() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Block color="primary" fontSize="large" className={classes.ico} />
      <Typography className={classes.title}>Halaman Tidak Ditemukan</Typography>
      <Typography className={classes.subtitle}>
        Klik tombol dibawah untuk kembali ke halaman home
      </Typography>
      <Button
        className={classes.btn}
        component={Link}
        to="/"
        disableElevation
        variant="contained"
        color="primary"
        size="large"
      >
        Kembali
      </Button>
    </div>
  );
}

export default NotFound;
