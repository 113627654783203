// React
import React, { useCallback, useState } from "react";

// React-DropZone
import { useDropzone } from "react-dropzone";

// Styles
import useStyles from "./styles";

// CircularProgress
import CircularProgress from "@material-ui/core/CircularProgress";

// Notification
import { useSnackbar } from "notistack";

// Input, Icons & Images
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";

// Avatar
import Avatar from "@material-ui/core/Avatar";

// Axios
import axios from "axios";
import {
  useFirebase,
  firestore,
  functions,
} from "../../components/FirebaseProvider";

// Profile Hooks
import { useSiswa } from "../../components/SiswaProvider";
import getOrientation from "../../utils/getOrientation";
function UploadFoto() {
  const classes = useStyles();

  const { user } = useFirebase();

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  // Profie
  const { profile } = useSiswa();

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const accept = ["image/png", "image/jpeg"];

  const maxSize = "20971520";

  const onDropAccepted = useCallback(
    (acceptedFiles) => {
      setError("");
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onabort = () => {
        setError("Pembacaan file dibatalkan");
      };
      reader.onerror = () => {
        setError("Pembacaan file gagal");
      };
      reader.onload = (ev) => {
        setLoading(true);
        try {
          const img = new Image();

          img.src = ev.target.result;

          img.onload = async () => {
            const elem = document.createElement("canvas");
            const width = 250;
            const ratio = img.width / img.height;

            elem.width = width;
            const height = width / ratio;
            elem.height = height;

            const srcOrientation = await getOrientation(file);
            console.log(srcOrientation);
            if (4 < srcOrientation && srcOrientation < 9) {
              elem.width = height;
              elem.height = width;
            }

            const ctx = elem.getContext("2d");

            switch (srcOrientation) {
              case 2:
                ctx.transform(-1, 0, 0, 1, width, 0);
                break;
              case 3:
                ctx.transform(-1, 0, 0, -1, width, height);
                break;
              case 4:
                ctx.transform(1, 0, 0, -1, 0, height);
                break;
              case 5:
                ctx.transform(0, 1, 1, 0, 0, 0);
                break;
              case 6:
                ctx.transform(0, 1, -1, 0, height, 0);
                break;
              case 7:
                ctx.transform(0, -1, -1, 0, height, width);
                break;
              case 8:
                ctx.transform(0, -1, 1, 0, 0, width);
                break;
              default:
                break;
            }

            // if (4 < srcOrientation && srcOrientation < 9) {
            //     ctx.drawImage(img, 0, 0, height, width);
            // } else {
            //     ctx.drawImage(img, 0, 0, width, height);
            // }
            ctx.drawImage(img, 0, 0, width, height);

            ctx.canvas.toBlob(
              async (blob) => {
                const compressedfile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                const generateUploadUrl = functions.httpsCallable(
                  "upload-generateUploadUrl"
                );

                const result = await generateUploadUrl({
                  filename: file.name,
                  directory: `images/profile/`,
                  contentType: file.type,
                });

                await axios.put(result.data.uploadUrl, compressedfile, {
                  headers: {
                    "Content-Type": file.type,
                  },
                });

                await user.updateProfile({
                  photoURL: result.data.photoUrl,
                });

                await firestore.doc(`users/${user.uid}`).set(
                  {
                    photoURL: result.data.photoUrl,
                  },
                  { merge: true }
                );

                // Show Notification
                enqueueSnackbar("Photo Profile Telah Berhasil Diperbarui.", {
                  variant: "success",
                });
                setLoading(false);
              },
              file.type,
              0.5
            );
          };
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      reader.readAsDataURL(file);
    },
    [enqueueSnackbar, user]
  );

  const onDropRejected = useCallback(
    (rejected) => {
      if (!accept.includes(rejected[0].type)) {
        setError(`Tipe file tidak didukung : ${rejected[0].type} `);
      } else if (rejected[0].size >= maxSize) {
        setError(`Ukuran file terlalu besar > 20Mb`);
      }
    },
    [accept, maxSize]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    maxSize,
    disabled: loading,
  });

  return (
    <>
      <div {...getRootProps()} className={classes.avatarChange}>
        <input {...getInputProps()} />
        {user.photoURL && (
          <Avatar
            alt={profile ? profile.nama : ""}
            src={user.photoURL}
            className={classes.avatarImg}
          />
        )}
        {!user.photoURL && (
          <Avatar
            className={classes.avatarImg}
            style={{ backgroundColor: "#0E5F96" }}
          >
            {profile && profile.nama
              ? profile.nama.charAt(0).toUpperCase()
              : "U"}
          </Avatar>
        )}
        {loading && (
          <CircularProgress size={68} className={classes.fabProgress} />
        )}
        <label htmlFor="uploud">
          <IconButton
            disabled={loading}
            className={classes.change}
            component="span"
          >
            <PhotoCameraIcon className={classes.icoCamera} />
          </IconButton>
        </label>
      </div>
      {error && <Typography color="error">{error}</Typography>}
    </>
  );
}

export default UploadFoto;
