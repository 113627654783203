const order = (misiDefault, listMisi) => {
  const defaultMisi = listMisi?.find(
    (e) => e?.id === misiDefault?.misi_id ?? ""
  );

  const newList = misiDefault?.misi_id
    ? listMisi
        ?.sort((a, b) => a.sort - b.sort)
        ?.sort((a, b) => a.order - b.order)
        ?.filter((e) => e.id !== defaultMisi?.id)
    : listMisi
        ?.sort((a, b) => a.sort - b.sort)
        ?.sort((a, b) => a.order - b.order);

  return [defaultMisi, ...newList];
};

export default order;
