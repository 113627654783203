import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // index
  root: {
    margin: "auto",
    // maxWidth: "630px",
    width: "100%",
    marginBottom: theme.spacing(12)
  },
  asmCon: {
    margin: "16px",
  },
  asmItem: {
    padding: "16px",
    backgroundColor: theme.palette.neutrals.n10,
    borderRadius: 8,
  },
  asmTitle: {
    fontSize: 16,
    fontWeight: 700,
    textTransform: "uppercase",
    color: theme.palette.neutrals.n100,
    textAlign: "left",
    margin: 0,
  },
  asmImg: {
    width: "52px !important",
    height: "52px !important",
    // marginRight: "16px",
    pointerEvents: "none",
    borderRadius: 5,
    backgroundColor: "#fff0"
  },
  asmData: {
    fontWeight: 12,
    textAlign: "left",
    margin: "5px 0px",
    color: theme.palette.neutrals.n60,
  },
  ibtn: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btn1: {
    borderRadius: "100px",
    fontWeight: "bold",
    fontSize: 16,
    border: "1.5px solid" + theme.palette.secondary.main,
    '&:hover': {
      border: "1.5px solid" + theme.palette.secondary.main,
    }
  },
  btn2: {
    borderRadius: "100px",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 16,
    color: theme.palette.info.main,
    border: "1.5px solid" + theme.palette.info.main,
    '&:hover': {
      border: "1.5px solid" + theme.palette.info.main,
    }
  },
  btn3: {
    borderRadius: "100px",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 16,
    color: theme.palette.success.main,
    border: "1.5px solid" + theme.palette.success.main,
    '&:hover': {
      border: "1.5px solid" + theme.palette.success.main,
    }
  },
  btnResult: {
    borderRadius: "100px",
    fontWeight: "bold",
    fontSize: 16,
    border: "1.5px solid" + theme.palette.secondary.main,
    '&:hover': {
      border: "1.5px solid" + theme.palette.secondary.main,
    },
    marginRight: theme.spacing(2)
  },
}));

export default useStyles;
