// React
import React, { useState } from 'react';

// Styles
import useStyles from './styles';

// List
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

// Divider
import Divider from '@material-ui/core/Divider';

// Icon
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';
// import AppHeader from '../../components/AppHeader';
// import Button from '@material-ui/core/Button';
// import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { HFHeader } from "../../components/AppHeaderNew";

// Component
import Switch from './InAppSwitch';
import Typography from '@material-ui/core/Typography';
import Ringtone, { Ring } from './Ringtone';
import { useNotifikasi } from '../../components/NotifikasiProvider';

function Notifikasi(props) {
    // UseStyles
    const classes = useStyles();

    const { notif } = useNotifikasi();

    // State
    const [Dialog, setDialog] = useState({
        open: false,
    });
    const [dialog, setdialog] = useState({
        open: false,
    });

    // Condition
    // const handleClick = () => {
    //     props.history.push('/settings');
    // }
    const handleRingtone = () => {
        setDialog({
            open: true
        })
    }
    // 
    const handleRing = () => {
        setdialog({
            open: true
        })
    }
    // 
    return (
        <div className={classes.rootNotif}>
            <HFHeader title="logo" pgDesc={false} noBorder={true} />
            {/* <AppHeader /> */}
            {/* <div className={classes.pageHeading}>
                <Button className={classes.historyBack} onClick={handleClick} ><ChevronLeftIcon /></Button>
            </div> */}
            <div style={{padding: '16px 0', width: '100%',}}>
            <h2 className={classes.pageTitle}>Setelan Notifikasi</h2>
            <div className={classes.settingBody}>
                <List className={classes.settingList} component="nav" aria-label="main mailbox folders">
                    <ListSubheader className={classes.subHeaderAlt}>Notifikasi In-App</ListSubheader>
                    <ListItem>
                        <ListItemText primary="Tampilkan Notifikasi" />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="Tampilkan Notifikasi">
                                <Switch />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    
                    <ListItem
                        button
                        onClick={handleRingtone}
                    >
                        <ListItemText primary="Bunyi" />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" disabled aria-label="Bunyi">
                                <Typography>{notif && notif.ringApp && notif.ringApp.nama}</Typography>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                    <ListSubheader className={classes.subHeaderAlt}>Notifikasi Pesan</ListSubheader>
                    <ListItem
                        button
                        onClick={handleRing}
                    >
                        <ListItemText primary="Bunyi" />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" disabled aria-label="Bunyi">
                                <Typography>{notif && notif.ringmessage && notif.ringmessage.nama}</Typography>
                                <KeyboardArrowRightIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <Divider />
                </List>
            </div>
            </div>
            <Ringtone
                {...Dialog}
                set={setDialog}
            />
            <Ring 
                {...dialog}
                set={setdialog}
            />
        </div>
    );
}

export default Notifikasi;