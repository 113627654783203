import React, { useState, useEffect } from "react";

import useStyles from "./styles/index-new";

import Button from "@material-ui/core/Button";
// import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Share from "@material-ui/icons/Share";

import LengkapiData from "./DialogLengkapiData";
import { useSiswa } from "../../components/SiswaProvider";
import { functions, useFirebase } from "../../components/FirebaseProvider";
import BottomNav from "../../components/BottomNav";
import { HomeHeader } from "../../components/AppHeaderNew";
// import { useCoin } from "../../components/CoinProvider";
// import noPic from "../../images/no-pic.svg";
// import CreditIcon from "../../images/icon-credits";
import cv from "../../images/illustration-cv.png";
import discover from "../../images/illustration-discover.png";
//import interviewpic from "../../images/interview-pic.jpg"
// import chat from "../../images/illustration-chat.png";
import loker from "../../images/illustration-job.png";
import chat_cs from "../../images/sikar-cs.png";
//import skill from "../../images/illustration-skill.png";
//import magang from "../../images/illustration-magang.png";
import { Backdrop, CircularProgress, Typography, Box, Hidden } from "@material-ui/core";
import { urlCV, urlKarirhack } from "../../config/url";
// import Help from "../../components/Help";
import { useNews } from "./../../components/NewsProvider";
// import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
// import DashboardIcon from "../../images/icon-dashboard";
// import PeopleIcon from "../../images/icon-people";
// import { Link } from "react-router-dom";
import TopNav from "../../components/TopNav";


function Home({ history }) {
  const classes = useStyles();
  // const { Coin } = useCoin();
  // const myCredit = Coin && Coin.coin ? Coin.coin : 0;
  const { profile } = useSiswa();
  const { user } = useFirebase();
  const { newsItems } = useNews();
  const [dialog, setDialog] = useState({
    open: false,
  });

  // cv bulider
  const [loading, setLoading] = useState(false);
  // reasec internal
  const handleSubmitCVMaker = async (e) => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-unused-expressions
      window?.mixpanel?.track("CV maker Button Clicked", {
        userId: user?.uid,
        tempUID: user?.tempUID,
      });
      const getTokenReasec = functions.httpsCallable("user-reqCustomToken");
      const response = await getTokenReasec();
      // console.log(response)
      if (response?.data?.customToken) {
        window.location.href = `${urlCV}/${response?.data?.customToken}`;
      }
    } catch (e) {
      console.log(e.message);
    }
    setLoading(false);
  };

  const handleSubmitKarirhack = async (e) => {
    setLoading(true);
    try {
      // eslint-disable-next-line no-unused-expressions
      window?.mixpanel?.track("Karirhack Button Clicked", {
        userId: user?.uid,
        tempUID: user?.tempUID,
      });
      const getTokenReasec = functions.httpsCallable("user-reqCustomToken");
      const response = await getTokenReasec();
      // console.log(response)
      if (response?.data?.customToken) {
        window.location.href = `${urlKarirhack}/${response?.data?.customToken}`;
      }
    } catch (e) {
      console.log(e.message);
    }
    setLoading(false);
  };

  const data = profile && profile.isiSurvey;

  useEffect(() => {
    if (!data) {
      setDialog({
        open: true,
      });
    }
  }, [data]);

  return (
    <>

      <Box className={classes.root}>
        <HomeHeader />
        {/* <img
          src={`https://auto.karir.ai/mtracking.gif?page_title=dashboard_home&email=${profile?.email}`} alt=''
        /> */}
        <Box className={classes.pagee}>
          <Backdrop style={{ zIndex: 1000 }} open={loading}>
            <CircularProgress color="secondary" />
          </Backdrop>
          {/* Profile */}
          {/* <Box className={classes.profilee}>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item md={3}>
                    <Box className={classes.profileBox}>
                      <Avatar
                          alt=""
                          src={user?.photoURL ? user?.photoURL : noPic}
                          className={classes.profileImg}
                        />
                        <p className={classes.profileName}>{profile?.fullName}</p>
                        <Button
                              variant="contained"
                              disableElevation
                              className={classes.credit}
                              startIcon={
                                <CreditIcon color="primary" fontSize="small" />
                              }
                              onClick={() => {
                                // eslint-disable-next-line no-unused-expressions
                                window?.mixpanel?.track("Home my coin Button Clicked", {
                                  userId: user?.uid,
                                  tempUID: user?.tempUID,
                                });
                                history.push("/myCredit");
                              }}
                            >
                              {myCredit}
                            </Button>
                    </Box>
                  </Grid>
                  <Grid item md={9}>
                    <Hidden smDown>
                      <Box className={classes.topNavBox}>
                        <ul className={classes.topNav}>
                          <li className={classes.topNavItem}> 
                            <Button component={Link} className={classes.topNavLink} to="/home">
                              <DashboardIcon className={classes.topNavIcon} /> <Typography variant="body1">Dashboard</Typography>
                            </Button>
                          </li>
                          <li className={classes.topNavItem}> 
                            <Button component={Link} className={classes.topNavLink} to="/ajakTeman">
                              <PeopleIcon className={classes.topNavIcon} /> <Typography variant="body1">Ajak Teman</Typography>
                            </Button>
                          </li>
                          <li className={classes.topNavItem}> 
                            <Button component={Link} className={classes.topNavLink} to="/myCredit">
                              <CreditIcon className={classes.topNavIcon} /><Typography variant="body1">Kredit Saya</Typography>
                            </Button>
                          </li>
                          <li className={classes.topNavItem}> 
                            <Button component={Link} className={classes.topNavLink} to="/settings">
                              <SettingsOutlinedIcon className={classes.topNavIcon} /><Typography variant="body1">Setelan</Typography>
                            </Button>
                          </li>
                        </ul>
                      </Box>
                    </Hidden>
                  </Grid>
                </Grid>
                
              </Grid>
            </Grid>
          </Box> */}
          <Hidden mdUp>
            <Box style={{ marginBottom: '20px' }}><TopNav /></Box>
          </Hidden>
          {/* Menu */}
          <Box className={classes.menu}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid item xs={6} md={3}>
                <Box className={classes.imMenu}>
                  <Typography className={classes.imName}>CV Maker</Typography>
                  <img src={cv} width="50px" alt="" />
                  <Typography className={classes.imDesc}>Buat CV Agar ATS-Friendly dan Mendapatkan Lebih Banyak Interview</Typography>
                  <Button variant="contained" color="primary" fullWidth className={classes.imBtn} onClick={handleSubmitCVMaker} >Buat CV</Button>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box className={classes.imMenu}>
                  {/* <img src={riasec} width="50px" alt="" /> */}
                  <Typography className={classes.imName}>Kirim Lamaran</Typography>
                  <img src={loker} height="50px" alt="" />
                  <Typography className={classes.imDesc}>Kirim secara otomatis CV ke banyak perusahaan secara sekaligus hanya dengan 1x klik.</Typography>
                  <Button variant="contained" color="primary" fullWidth className={classes.imBtn} onClick={handleSubmitKarirhack}>Kirim Sekarang</Button>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box className={classes.imMenu}>
                  <Typography className={classes.imName}>Personal Discovery</Typography>
                  <img src={discover} width="50px" alt="" />
                  <Typography className={classes.imDesc}>Kenali dan jelajahi dirimu dengan mengerjakan tes-tes berikut</Typography>
                  <Button variant="contained" color="primary" fullWidth className={classes.imBtn}
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      window?.mixpanel?.track("Home assessment Button Clicked", {
                        userId: user?.uid,
                        tempUID: user?.tempUID,
                      });
                      history.push("/assessment");
                    }}>Kerjakan Tes</Button>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box className={classes.imMenu}>
                  {/* <img src={chat} width="50px" alt="" /> */}
                  <Typography className={classes.imName}>E-course</Typography>
                  <img src={chat_cs} height="50px" alt="" />
                  <Typography className={classes.imDesc}>Kenali dan tambah pengetahuanmu dengan menonton video course berikut</Typography>
                  <Button variant="contained" color="primary" disabled fullWidth className={classes.imBtn} onClick={() => history.push("/course")}>Lihat Courses</Button>
                </Box>
              </Grid>

              {/* Article */}
              {/* <Grid item xs={12}>
                <br />
                <div className={classes.arCon}>
                  <div className={classes.arT}>
                    <img src={skill} className={classes.arIco} alt="" />
                    <p className={classes.arCategory}>Upgrade Skill</p>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="small"
                    className={classes.arListBtn}
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      window?.mixpanel?.track("Home news skils Button Clicked", {
                        userId: user?.uid,
                        tempUID: user?.tempUID,
                      })
                      history.push(`/article/Skills`);
                    }}
                  >
                    Selengkapnya
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.arCon}>
                  <div className={classes.arT}>
                    <img src={magang} className={classes.arIco} alt="" />
                    <p className={classes.arCategory}>Info Magang</p>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    size="small"
                    className={classes.arListBtn}
                    onClick={() => {
                      // eslint-disable-next-line no-unused-expressions
                      window?.mixpanel?.track("Home news magang Button Clicked", {
                        userId: user?.uid,
                        tempUID: user?.tempUID,
                      })
                      history.push(`/article/Magang`);
                    }}
                  >
                    Selengkapnya
                  </Button>
                </div>
              </Grid> */}
            </Grid>
          </Box>
          {/* Article */}
          <Box className={classes.articleBox}>
            <Grid container justifyContent="space-between">
              {/* <Grid item xs={12}>
                <div className={classes.arCon}>
                  <p className={classes.arCategory}>Info Magang</p>
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    className={classes.arListBtn}
                    onClick={() => {
                      history.push(`/berita/Magang`);
                    }}
                  >
                    Lihat Semua
                  </Button>
                </div>
              </Grid> */}
              {newsItems
                ?.sort((a, b) => {
                  return b.createdAt - a.createdAt;
                })
                ?.map((item, idx) => {
                  return (
                    <Grid container spacing={2} key={idx} item xs={12} md={6} style={{ marginTop: 8 }}>
                      <Card
                        className={classes.arCard}
                        onClick={() => {
                          history.push(`/article/${item?.id}`);
                          // eslint-disable-next-line no-unused-expressions
                          window?.mixpanel?.track(`article ${item?.id} Clicked`, {
                            userId: user?.uid,
                            tempUID: user?.tempUID,
                          })
                        }}
                      >
                        <CardActionArea>
                          <Grid container>
                            <Grid item xs={12}>
                              <CardMedia
                                className={classes.arImg}
                                image={item?.thumbnailURL}
                                style={{

                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <CardContent>
                                <Typography className={classes.arTitle}>{item?.title}</Typography>
                                <Typography noWrap className={classes.arContent}>
                                  {item?.content?.replace(/(<([^>]+)>)/gi, "")}
                                </Typography>
                              </CardContent>
                            </Grid>

                          </Grid>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      </Box>

      <LengkapiData
        {...dialog}
        handleClose={() => {
          setDialog((dialog) => {
            return {
              ...dialog,
              open: false,
            };
          });
        }}
      />

      {/* <Help/> */}
      <Hidden mdUp><BottomNav /></Hidden>

    </>
  );
}

export default Home;
