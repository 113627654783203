import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    padding: "0px 27px 50px",
    "@media(min-width: 400px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 700px)": {
      width: "50%",
    },
    "@media(min-width: 1200px)": {
      width: "40%",
    },
  },
  formRow: {
    margin: "0 auto 15px",
  },
  formRowBtn: {
    margin: "10px auto 10px",
  },
  pageTitle: {
    color: "#222222",
    fontSize: 23.33,
    fontWeight: 700,
    textAlign: "left",
    margin: 0,
  },
  pageSubtitle: {
    color: "#4F4F4F",
    fontSize: 13.33,
    textAlign: "left",
    margin: "5px 0px 20px",
  },
  textField: {
    width: "100%",
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: "11px",
    margin: 0,
    marginTop: 10,
    textAlign: "left",
  },
  forgetBtn: {
    borderRadius: 25,
    textTransform: "Capitalize",
  },
  kirimBtn: {
    color: "#fff",
    textTransform: "capitalize",
    margin: "0 auto",
    fontSize: 13.33,
    fontWeight: 600,
    width: "100%",
    "&:hover": {
      backgroundColor: "#1BB3EA",
    },
  },
  otherText: {
    margin: "3px 0 10px",
    position: "relative",
    height: 35,
  },
  lineText: {
    backgroundColor: "#222",
    width: "100%",
    height: 1,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    zIndex: 1,
  },
  otherTextBody: {
    backgroundColor: "#F2F4F3",
    color: "#222",
    position: "absolute",
    width: 230,
    fontSize: 12.5,
    fontWeight: 500,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    zIndex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  otherReg: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  fbBtn: {
    backgroundColor: "#0470B7",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#03609F",
    },
  },
  goBtn: {
    backgroundColor: "#fff",
    color: "#222",
    "&:hover": {
      backgroundColor: "#EDEDED",
    },
  },
  regBtns: {
    width: "48%",
    padding: "10px 0",
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "capitalize",
  },
  brandIcon: {
    width: 22,
    height: 22,
    display: "block",
    marginRight: 5,
  },
  skLabel: {
    fontSize: 12.5,
    fontWeight: 500,
  },
  chxb: {
    padding: 0,
    marginRight: 10,
  },
  linkTo: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  btnRegister: {
    textTransform: "capitalize",
    margin: "0",
    padding: 0,
    fontSize: 13.33,
    minWidth: "auto",
  },
  buttonProgress: {
    position: "absolute",
    top: "48%",
    left: "48%",
    marginTop: -22,
    marginLeft: -12,
  },
  label: {
    marginBottom: 10,
    fontWeight: 600,
    color: "#777",
    textAlign: "left",
  },
}));

export default useStyles;
