import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
  },
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "20px auto 70px",
    borderRadius: 8,
    marginBottom: 30,
    width: "100%",
    maxWidth: "600px",
  },
  whiteBox: {
    backgroundColor: theme.palette.textColor.white,
    borderRadius: 20,
    marginBottom: 10,
    textAlign: "left",
    padding: "10px 20px !important"
  },
  clrBox:{
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 0px !important",
  },
  avatarCon: {
    width: "100px",
    height: "100px",
    pointerEvents: "none",
    margin: "10px 0px 5px",
  },
  avatarImg: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: "100%",
  },
  txt1: {
    color: theme.palette.textColor.gray,
    fontWeight: 500,
    fontSize: 16.67,
    textAlign: "center",
  },
  txt2: {
    color: theme.palette.textColor.darkGray,
    fontSize: 13.33,
    fontWeight: 700,
  },
  txt3: {
    color: theme.palette.textColor.darkGray,
    fontSize: 13.33,
    fontWeight: 500,
  },
  liquidRow: {
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-start",
    width: "100%",
  },
  liquidLabel: {
    color: theme.palette.textColor.darkGray,
    width: 120,
    marginRight: 10,
    fontSize: 13.33,
    fontWeight: 500,
  },
  liquidLabel2: {
    color: theme.palette.textColor.darkGray,
    width: "800%",
    marginRight: 10,
    fontSize: 13.33,
    fontWeight: 500,
  },
  liquidValue: {
    color: theme.palette.textColor.darkGray,
    fontSize: 13.33,
    fontWeight: 500,
    width: "75%",
    display: "flex",
  },
  name: {
    color: theme.palette.textColor.darkGray,
    fontWeight: 700,
    marginTop: 10,
    marginBottom: 5,
    fontSize: 20, 
  },
  clrBtn:{
    textTransform: "capitalize",
    fontSize: 13.33,
    fontWeight: 600,
    color: theme.palette.textColor.white,
    width: 93.67,
    height: 27.67,
  },
  boxTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  centerTmb: {
    textAlign: "center",
  },
  tmbRes1: {
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
  },
  tmbRes2: {
    fontSize: 13.33,
    fontWeight: 400,
    textAlign: "center",
    marginBottom: 5,
  },
  tmbRes3: {
    fontSize: 13.33,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 5,
  },
  tmbRes4: {
    fontSize: 10,
    fontWeight: 400,
    textAlign: "center",
  },
  mr5:{
    marginRight:5
  }
}));

export default useStyles;
