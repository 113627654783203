import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0px auto 0px",
    padding: "0px 27px 50px",
    "@media(min-width: 400px)": {
      width: "70%",
    },
    "@media(min-width: 500px)": {
      width: "65%",
    },
    "@media(min-width: 600px)": {
      width: "60%",
    },
    "@media(min-width: 700px)": {
      width: "50%",
    },
    "@media(min-width: 1200px)": {
      width: "40%",
    },
  },
  formRow: {
    margin: "0 auto 10px",
  },
  formRowBtn: {
    margin: "10px auto 10px",
  },
  passw: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 10px",
  },
  label: {
    marginBottom: 10,
    fontWeight: 600,
    color: "#777",
    textAlign: "left",
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: "11px",
    margin: 0,
    marginTop: 10,
    textAlign: "left",
  },
  pageTitle: {
    color: "#222222",
    fontSize: 23.33,
    fontWeight: 700,
    textAlign: "center",
    marginBottom: 30,
  },
  pageSubtitle: {
    color: theme.palette.textColor.gray,
    fontSize: 14,
    marginTop: 0,
  },
  textField: {
    width: "100%",
  },
  loginBtn: {
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 13.33,
    margin: "15px 0px",
    "&:hover": {
      backgroundColor: "#1BB3EA",
    },
  },
  forgetBtn: {
    textTransform: "Capitalize",
    margin: "0",
    padding: 0,
    fontSize: 13,
    minWidth: "auto",
  },
  btnRegister: {
    textTransform: "capitalize",
    margin: "0",
    padding: 0,
    fontSize: 13,
    minWidth: "auto",
  },
  otherText: {
    margin: "-10px 0 15px",
    position: "relative",
    height: 35,
  },
  lineText: {
    backgroundColor: "#222",
    width: "100%",
    height: 1,
    // position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    zIndex: 1,
  },
  otherTextBody: {
    backgroundColor: "#F2F4F3",
    color: "#222",
    position: "absolute",
    fontWeight: 600,
    width: 70,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: "auto",
    height: 25,
    zIndex: 1,
  },
  otherReg: {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "space-between",
  },
  fbBtn: {
    backgroundColor: "#0470B7",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#03609F",
    },
  },
  goBtn: {
    backgroundColor: "#fff",
    color: "rgba(0,0,0,0.54)",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  regBtns: {
    fontFamily: "Roboto",
    borderRadius: "5px",
    fontSize: "14px",
  },
  stIcon: {
    marginLeft: "0px",
    marginRight: "20px",
    "& > *:first-child": {
      fontSize: "18px !important",
    },
  },
  brandIcon: {
    width: "18px",
    height: "18px",
    display: "block",
    marginRight: 5,
  },
  buttonProgress: {
    position: "absolute",
    top: "48%",
    left: "48%",
    marginTop: -22,
    marginLeft: -12,
  },
}));

export default useStyles;
