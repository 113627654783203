import React from "react";

// import Button from "@material-ui/core/Button";
//
// import useRest from "../../hooks/useRest";
import TypoGraphy from "@material-ui/core/Typography";
// import CircularProgres from "@material-ui/core/CircularProgress";
// import Container from "@material-ui/core/Container";
import { useParams } from "react-router-dom";

// import { shareURL } from "../../utils/share";
// import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import slugify from "slugify";
import { firestore } from "../../components/FirebaseProvider";
import { useDocumentData } from "react-firebase-hooks/firestore";
//import { useSiswa } from "../../components/SiswaProvider";
import { HFHeader } from "../../components/AppHeaderNew";
import useStyles from "./styles";

function BeritaDetail(props) {
  const classes = useStyles();

  const params = useParams();
  //const { profile } = useSiswa();
  // const { enqueueSnackbar } = useSnackbar();

  const newsDoc = firestore.doc(`news/${params?.id}`);
  const [dataNews] = useDocumentData(newsDoc, { idField: 'newsId' });
  // console.log(dataNews)

  // const [ loading] = useRest({
  //   url: `news/${params.id}`,
  //   method: "get",
  //   params: {
  //     type: "content",
  //   },
  // });

  // const [loadsnap] = useRest({
  //   url: "news/banner",
  //   method: "get",
  //   params: {
  //     type: "banner",
  //   },
  // });

  // const linkBanner =
  //   snapshot &&
  //   snapshot.data &&
  //   snapshot.data.Item &&
  //   snapshot.data.Item.targetUrl;
  // const PhotoBanner =
  //   snapshot &&
  //   snapshot.data &&
  //   snapshot.data.Item &&
  //   snapshot.data.Item.photoUrl;

  // const handleShare = (data) => async (e) => {
  //   try {
  //     // const response = await share(shareData);
  //     await shareURL(data);
  //     //  console.log(response)
  //   } catch (e) {
  //     enqueueSnackbar(e.message);
  //   }
  // };

  // if (loading || loadsnap) {
  //   return (
  //     <Container maxWidth="xs">
  //       <CircularProgres style={{ marginTop: "90%" }} />
  //     </Container>
  //   );
  // }

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Baca berita selengkapnya di Karir.ai!"
        />
        <meta
          property="og:title"
          content={`${dataNews
            ? dataNews?.title
            : "Judul Berita"
            }`}
        />
        <meta
          property="og:description"
          content="Baca berita selengkapnya di Karir.ai!"
        />
        <meta property="og:type" content="article" />
        <meta
          property="og:url"
          content={`${window.location.origin}/warta/${dataNews?.newsId
            }/${dataNews &&
            slugify(dataNews?.title.toLowerCase())
            }`}
        />
        {/* <meta property="og:image" content={getFirstImage( dataNews && dataNews.content) || logo} /> 
                <meta property="og:image" content="https://pakbudi.s3-ap-southeast-1.amazonaws.com/images/pakbudi-logo.png" /> */}
        <meta property="og:site_name" content="Karir.ai" />
      </Helmet>
      <div className={classes.root}>
        <HFHeader
          title={` Article`}
          desc="Kenali dan tambah pengetahuanmu dengan membaca berita berikut"
          image="discover"
          beta={true}
        />

        <div className={classes.newsBody}>
          {/* {
                             dataNews ?
                            <div 
                                style={{
                                    background: `url(${dataNews.thumbnailURL}) no-repeat center center`, 
                                    width: '100%',
                                    height: 200,
                                    position: 'relative',
                                    overflow: 'hidden',
                                    backgroundSize: 'cover'
                                }}  
                            >
                             </div>
                            :
                            null
                        } */}
          <div className={`${classes.newsFeed} ${classes.newsFeedDetail}`}>
            <div className={classes.detailHead}>
              <div className={classes.postDesc}>
                {dataNews ? (
                  <>
                    <h2
                      className={classes.newsTitle}
                      dangerouslySetInnerHTML={{
                        __html: dataNews.title,
                      }}
                    />
                    {/* <span className={classes.dateTime}>
                      {date(dataNews.updatedAt)}
                    </span> */}
                  </>
                ) : null}
              </div>
              {/* {dataNews && (
               
              )} */}
            </div>
            {
              dataNews?.thumbnailURL && (
                <div>
                  <img src={dataNews?.thumbnailURL} width='100%' alt="" />
                </div>
              )
            }
            <div className={classes.newsContent}>
              {/* {dataNews?.title ? (<img
                src={`https://auto.karir.ai/mtracking.gif?page_title=dashboard_article&title=${slugify(dataNews?.title)}&email=${profile?.email}`} alt=''
              />) : ''} */}
              {dataNews?.title}
              {dataNews ? (
                <TypoGraphy
                  dangerouslySetInnerHTML={{
                    __html: dataNews.content,
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BeritaDetail;
