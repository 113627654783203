// React
import React from 'react';

// Styles
import useStyles from './styles';

// Input
import Button from '@material-ui/core/Button';

// Router
import { Route, Link, useLocation, useHistory } from 'react-router-dom';

// Icons
import AddIcon from '@material-ui/icons/Add';
// import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import ChatIcon from '@material-ui/icons/Chat';
import Badge from '@material-ui/core/Badge';

import { baseURL } from '../../images/s3';

import { useCoin } from '../../components/CoinProvider';
import { useChat } from '../../components/useChatList';
import { useSiswa } from '../../components/SiswaProvider';

import { delimiters } from '../../utils/formatter-rupiah';

function TopAction() {

    const classes = useStyles();

    const { Coin } = useCoin();

    const { setPageEvent } = useSiswa();

    const { unread } = useChat();

    const koin = Coin && Coin.coin ? Coin.coin : 0;

    const location = useLocation();

    const history = useHistory();

    let from = null;

    
    if (location.pathname === '/home') {
        from = 'home'
    }

    else if (location.pathname === '/chat') {
        from = 'chat'
    }

    else if (location.pathname === '/notifikasi') {
        from = 'notifikasi'
    }

    else if (location.pathname === '/konsultasi/semua' || '/konsultasi/mahasiswa' || '/konsultasi/gurubk' || '/konsultasi/motivator') {
        from = 'konsultasi'
    }

    setPageEvent(from);

    const handleClick = () => {
        history.push(`/myCredit`)
    }

    const chat = (
        <Badge
            badgeContent={unread && unread.length}
            classes={{ badge: classes.online }}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
            }}
        >
            <ChatIcon />
        </Badge>
    )

    // const { enqueueSnackbar } = useSnackbar();
    // const captureBody = async (e) => {
    //     try {
    //         const canvas = await html2canvas(document.body)
    //         const file = dataURItoFile(canvas.toDataURL());
    //         // console.log(file);
    //         const shareData = {
    //             files: [file],
    //             title: "PakBudi.id",
    //             text: "Aplikasi Keren"
    //         }
    //         const response = await share(shareData);
    //         // console.log(response)
    //     } catch (e) {
    //         enqueueSnackbar(e.message)
    //     }
    // }
    return (
        <div className={classes.root}>
            <div className={classes.topAction}>
                <div className={classes.coinStats}>
                    <Button className={classes.coinBtn} variant="contained" size="small" onClick={handleClick} >
                        <div className={classes.btnGrp}>
                            <span className={classes.totalCoin}>{delimiters(koin)}</span>
                            <span className={classes.icoCoin}>
                                <img className={classes.imgCoin} src={`${baseURL}icon-coin.png`} alt="" />
                            </span>
                        </div>
                        <div className={classes.icoPlusWrap}>
                            <span className={classes.icoPlus}><AddIcon /></span>
                        </div>
                    </Button>
                </div>
                <div className={classes.otherAction}>
                    {/* <Route path="/home" render={() => {
                        return <IconButton onClick={captureBody} className={classes.actionBtn}><PhotoCameraIcon /></IconButton>
                    }} /> */}
                    <Route path="/konsultasi" render={() => {
                        return <IconButton className={classes.actionBtn} component={Link} to="/chat">{chat}</IconButton>
                    }} />
                    <Route path="/home" render={() => {
                        return <IconButton className={classes.actionBtn} style={{ padding: 8 }} component={Link} to="/bagikan"><ShareIcon /></IconButton>
                    }} />
                    <Route path="/notifikasi" render={() => {
                        return <div className={classes.spacing}></div>
                    }} />
                </div>
            </div>
        </div>
    );

}

export default TopAction;