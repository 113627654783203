import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        paddingBottom: 90,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    list: {
        minWidth: 46
    },
    pageTitle:{
        color: '#fff',
        fontSize: 24,
        margin: '10px 0',
    },
    pageContent:{
        backgroundColor: '#fff',
        width: '100%',
        margin: '10px auto',
        minHeight: 400,
        borderRadius: 6,
        padding: "0px 16px",
        // maxWidth: "630px",
        '@media(min-width: 960px)': {
            width: '90%',
            margin: '20px auto',
        },
    },
    subHeader:{
        color: theme.palette.primary.main,
        textAlign: 'left',
    },
    notifikasiBox:{
        '& hr:last-child':{
            display: 'none',
        }
    },
    listItem:{
        padding: 0,
        marginBottom: 3,
    },
    avatar:{
        background: 'transparent',
        justifyContent: 'flex-end',
        width: 30,
        height: 30,
    },
    avatarr: {
        background: 'transparent',
        justifyContent: 'flex-end',
        width: 30,
        height: 30,
    },
    icoThumb:{
        width: 20,
        height: 20,
    },
    icoThumbb:{
        width: 20,
        height: 20,
    },
    notifValue:{
        marginLeft: 10,
    },
    notifTxt:{
        fontSize: 13,
        display: 'block',
        marginBottom: -5,
        wordBreak: 'break-word',
    },
    timeStamp:{
        fontSize: 10,
        color: theme.palette.textColor.gray,
    },
    NoNotif: {
        paddingTop: 60,
        margin: 'auto',
    },
    imgNoNotif : {
        width: "100px",
        filter: "grayscale(100%)",
        opacity: "40%"
    },
    title: {
        fontWeight: 'bolder',
        fontSize: 18
    },
    subtitle: {
        marginTop: 10,
        // fontWeight: 'bold',
        fontSize: 14,
        color: theme.palette.textColor.gray,
        width: '80%',
        margin: 'auto'
    }
}))

export default useStyles;