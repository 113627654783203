import { makeStyles } from "@material-ui/core/styles";

const headerHeight1 = "70px";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: "630px",
    height: headerHeight1,
    margin: "auto",
    // padding: "12px 16px",
    padding: '0 0 0 16px',
    boxShadow: "inset 0px -1px 0px" + theme.palette.neutrals.border,
    position: "fixed",
    zIndex: 5,
    backgroundColor: theme.palette.neutrals.nf,
    "@media(max-width: 959px)": {
      padding: "12px 16px",
    },
  },
  root2: {
    width: "100%",
    // maxWidth: "630px",
    margin: "auto",
    padding: "0 0 0 16px",
    boxShadow: "inset 0px -1px 0px" + theme.palette.neutrals.border,
    position: "fixed",
    zIndex: 5,
    backgroundColor: theme.palette.neutrals.nf,
    "@media(max-width: 959px)": {
      padding: "12px 16px",
    },
  },
  pgAbt: { 
    margin: "10px auto 0",
    width: "100%"
  },
  pgTitle: {
    fontSize: 20,
    fontWeight: "bold",
    margin: 0,
  },
  pgDesc: {
    fontSize: 14,
    fontWeight: "bold",
    // margin: 0,
    color: theme.palette.neutrals.n80,
    lineHeight: "160%",
    textAlign: "left",
  },

  mb: {
    height: headerHeight1,
  },
  mb2: {
    height: "200px",
  },
  beta: {
    backgroundColor: theme.palette.primary.main,
    padding: "2px 5px",
    borderRadius: "100px",
    color: "#fff",
    fontSize: "10px",
    // marginLeft: "5px",
  },
}));

export default useStyles;
