import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  text: {
    fontSize: 16,
    textAlign: "center",
    fontWeight: "bold",
  },
  text2: {
    fontSize: 16,
    textAlign: "center",
  },
  name: { color: theme.palette.success.main },
  price: { color: theme.palette.secondary.main },
  btc: { padding: "20px" },
  btn1: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "capitalize",
    border: "1px solid" + theme.palette.primary.main,
    "&:hover": {
      border: "1px solid" + theme.palette.primary.main,
    },
  },
  btn2: {
    fontSize: 14,
    fontWeight: "bold",
    textTransform: "capitalize",
  },
}));

export default useStyles;
