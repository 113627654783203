import { makeStyles } from "@material-ui/styles";
const useStyles = makeStyles((theme) => ({
  content: {
    padding: "150px 20px 20px",
  },
  ico: {
    fontSize: 50,
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
    color: "#4F4F4F",
    margin: "20px auto"
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 400,
    textAlign: "center",
    color: "#4F4F4F",
    margin: "0 auto 20px",
  },
  refreshBtn: {
    borderRadius: 5,
    textTransform: "none",
  },
  logoutBtn: {
    border: "1px solid #F26722",
    borderRadius: 5,
    textTransform: "none",
  },
}));

export default useStyles;
