import React, { useEffect } from "react";

import useStyles from "./styles";

import { useParams, useHistory } from "react-router-dom";
import { Element } from "react-scroll";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";

import { unixToTime } from "../../utils/formatter";
import { firestore, Arr } from "../../components/FirebaseProvider";
import mainLogo from "../../images/new-logo-c-v.png";
import toa from "../../images/a_to.svg";
// import DialogImage from "./imgZoom";
//
export default function MessageIn({ item, handleOpen }) {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  // const [open, setOpen] = useState();
  useEffect(() => {
    if (!item.read) {
      const readChat = async () => {
        try {
          await firestore
            .doc(`chat/${params.chatId}/items/${item.id}`)
            .set({ read: true }, { merge: true });
          await firestore.doc(`chat/${params.chatId}`).set(
            {
              siswa_unread_count: Arr.increment(-1),
            },
            { merge: true }
          );
        } catch (e) { }
      };
      readChat();
    }
  }, [item.id, item.read, params.chatId]);
  return (
    <Element name={`item-${item.id}`} className={classes.clientChatBubble}>
      <div className={classes.clientTextBody}>
        {item?.type === "tmb-mitra" && (
          <ButtonBase className={classes.myLink} href="/tesminatbakat">
            <Grid container alignItems="center">
              <Grid item>
                <div className={classes.imgLink}>
                  <img draggable="false" src={mainLogo} width="50px" alt="" />
                </div>
              </Grid>
              <Grid item xs>
                <div className={classes.txtLink}>
                  <p className={classes.titleLink}>Tes Minat Bakat PakBudi</p>
                  <p className={classes.descLink}>
                    Temukan minat dan bakatmu hanya dengan membuka aplikasi
                    PakBudi
                  </p>
                </div>
              </Grid>
            </Grid>
          </ButtonBase>
        )}

        {item?.type === "to-mitra" && (
          <ButtonBase
            className={classes.myLink}
            // href={`/tryout/${item?.to?.id}`}
            onClick={() => {
              history.push(`/home?open_to=${item?.to?.id}`);
            }}
          >
            <Grid container alignItems="center">
              <Grid item>
                <div className={classes.imgLink}>
                  <img draggable="false" src={toa} height="40px" alt="" />
                </div>
              </Grid>
              <Grid item xs>
                <div className={classes.txtLink}>
                  <p className={classes.titleLink}>{item?.to?.judul}</p>
                  <p className={classes.descLink}>
                    Uji kemampuanmu dengan mengikuti {item?.to?.judul}
                  </p>
                </div>
              </Grid>
            </Grid>
          </ButtonBase>
        )}

        {item.type === "image" && (
          <>
            <Button style={{ padding: 0 }} onClick={() => { handleOpen(true, item?.url); }} >
              <div className={classes.myAttachment}>
                <img
                  style={{ objectFit: "cover", borderRadius: 8 }}
                  src={item.url}
                  alt="img"
                />

              </div>
            </Button>
          </>
        )}

        {item.text.split("\n").map((text, i) => (
          <>
            {item.type !== "image" &&
              item.type !== "tmb-mitra" &&
              item.type !== "to-mitra" && (
                <Typography
                  key={i}
                  className={classes.clientText}
                  variant="body1"
                  translate="no"
                >
                  {text}
                </Typography>
              )}
          </>
        ))}
        <Typography className={classes.clientTimeStamp} variant="caption">
          {unixToTime(item.created_at)}
        </Typography>
      </div>
    </Element>
  );
}
