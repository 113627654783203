// React
import React, { useState, useEffect } from "react";

// Styles
import useStyles from "./styles/editProfile";

// Component
import UploadFoto from "./uploadFoto";

// Input
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

// React Router
import { withRouter } from "react-router-dom";

// Notification
import { useSnackbar } from "notistack";

// Validator
import isNumber from "validator/lib/isNumeric";
import trim from "lodash/trim";

// Firebase Hooks
import { useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";

// Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { HFHeader } from "../../components/AppHeaderNew";
//import axios from "axios";

function EditProfile(props) {
  // UseStyles
  const classes = useStyles();

  // UseFirebase
  const { user, firestore } = useFirebase();

  // Document Ref
  const siswaDoc = firestore.doc(`users/${user.uid}`);
  const { profile, pageEvent } = useSiswa();

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [form, setForm] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
  });

  const [error, setError] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
  });

  const [isSubmiting, setSubmiting] = useState(false);
  // const [isChange, setChange] = useState(false);

  // OnChange
  const handleChange = (e) => {
    // setChange(true);

    const { name, value } = e.target;

    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setError((error) => ({
      ...error,
      [name]: "",
    }));
  };

  // UseEffect
  useEffect(() => {
    if (profile) {
      setForm((form) => ({ ...form, ...profile }));
      // setSekolahCustom(profile.sekolah_custom ?? false);
    }
  }, [profile]);
  useEffect(() => { }, []);

  // Validate
  const validate = async () => {
    const newError = { ...error };

    const {
      fullName,
      phoneNumber,
      email,
    } = form;

    // Akhir
    if (!fullName) {
      newError.fullName = "Nama Wajib diisi";
    }
    if (!email) {
      newError.email = "Email Wajib diisi";
    }

    if (!phoneNumber) {
      newError.phoneNumber = "No. Handphone Wajib diisi";
    } else if (phoneNumber.length < 6) {
      newError.phoneNumber = "No. Handpone Harus Berisikan 6 Digit Nomor Atau Lebih";
    } else if (phoneNumber.length > 15) {
      newError.phoneNumber = "No. Handpone Maximal Berisikan 15 Digit Nomor";
    } else if (!isNumber(trim(phoneNumber))) {
      newError.phoneNumber = "No. Handphone Harus Berupa Nomor";
    }

    return newError;
  };

  // OnSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const findErrors = await validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
      enqueueSnackbar(
        `Error - ${findErrors.fullName ||
        findErrors.email ||
        findErrors.phoneNumber
        }`,
        { variant: "error" }
      );
    } else {
      try {
        setSubmiting(true);
        const date = new Date();

        await siswaDoc.set(
          {
            fullName: form.fullName,
            email: form.email,
            phoneNumber: trim(form.phoneNumber),
            updatedAt: date,
          },
          { merge: true }
        );
        // eslint-disable-next-line no-unused-expressions
        window?.mixpanel?.track("Edit Profile: perbarui Button Clicked", {
          userId: user?.uid,
          tempUID: user?.tempUID,
        });

        // pixel
        //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_editProfil&email=${profile?.email}`);
        // Show Notification
        enqueueSnackbar(`Data Telah Berhasil Diperbarui.`, {
          variant: "success",
        });
        // setChange(false);
        setSubmiting(false);
        if (pageEvent === "home") {
          props.history.push("/home");
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  // State
  const [open, setOpen] = useState(false);

  // OnClose
  const handleClose = () => {
    setOpen(false);
  };


  const handleAccept = () => {
    if (pageEvent === "settings") {
      props.history.push("/settings");
    } else if (pageEvent === "home") {
      props.history.push("/home");
    }
  };


  return (
    <div className={classes.root}>
      {/* <HFHeader
        title="Edit Profil"
        pgDesc={false}
      /> */}
      <HFHeader title="logo" pgDesc={false} content='Edit Profil' />
      
      <div className={classes.profileBody}>
        <h2 className={classes.pageTitle}>Edit Profil</h2>
        <div className={classes.profileFormWrap}>
          <form onSubmit={handleSubmit}>
            <div className={classes.profileForm}>
              <div className={classes.profileAva}>
                <UploadFoto />
              </div>
              <div className={classes.profileTop}>
                <div className={classes.formRow}>
                  <TextField
                    id="fullName"
                    name="fullName"
                    label="Nama Lengkap"
                    value={form.fullName}
                    autoComplete="off"
                    onChange={handleChange}
                    className={classes.textField}
                    helperText={error.fullName}
                    error={error.fullName ? true : false}
                    disabled={isSubmiting}
                  />
                </div>
                <div className={classes.formRow}>
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    value={form.email}
                    autoComplete="off"
                    onChange={handleChange}
                    className={classes.textField}
                    helperText="Anda Masuk Menggunakan Akun Google"
                    // helperText={error.email}
                    error={error.email ? true : false}
                    // disabled={isSubmiting}
                    disabled
                  />
                </div>
                <div className={classes.formRow}>
                  <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    label="Nomor Telepon"
                    className={classes.textField}
                    value={form.phoneNumber}
                    autoComplete="off"
                    onChange={handleChange}
                    helperText={error.phoneNumber}
                    error={error.phoneNumber ? true : false}
                    disabled={isSubmiting}
                  />
                </div>
              </div>
              <div className={classes.formRowBtn}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmiting}
                  size="large"
                  disableElevation
                >
                  Perbarui
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle className={classes.DialogTitle}>
            {"Meninggalkan Halaman?"}
          </DialogTitle>
          <p className={classes.txt}>
            Kamu yakin ingin keluar dari halaman ini tanpa menyimpan perubahan ?
          </p>
          <Button onClick={handleAccept} fullWidth className={classes.btnOke}>
            Ya
          </Button>
          <Button onClick={handleClose} fullWidth className={classes.btnNo}>
            Tidak
          </Button>
        </Dialog>
      </div>
    </div>
  );
}

export default withRouter(EditProfile);
