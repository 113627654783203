import React from "react";

import useStyles from "./styles";


import { HFHeader } from "../../components/AppHeaderNew";
import { firestore, } from "../../components/FirebaseProvider";
import { useDocumentData } from "react-firebase-hooks/firestore";
// import set from "date-fns/fp/set/index";
import { useParams } from "react-router-dom";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";


function ContentCourse() {
    const classes = useStyles();

    const params = useParams();

    // get course data
    const courseDoc = firestore.doc(`course/${params?.contentId}`);
    const [dataCourse, loadDataCourse] = useDocumentData(courseDoc);
    // const price = 200;
    // reasec internal
    console.log(dataCourse)

    return (
        <>
            <div className={classes.root}>
                <HFHeader
                    title={` ${dataCourse?.nama}`}
                    desc="Kenali dan tambah pengetahuanmu dengan menonton vidio course berikut"
                    image="discover"
                    beta={true}
                />
                <Backdrop style={{ zIndex: 1000 }} open={loadDataCourse}>
                    <CircularProgress color="secondary" />
                </Backdrop>
                {
                    dataCourse?.daftar_content?.map((content, i) => {
                        return <div key={content?.videoId}>
                            <div style={{
                                marginTop: '20px',
                                backgroundColor: "#F26722",
                                borderRadius: '10px 10px 0 0'

                            }}>
                                <Typography style={{ padding: '5px', color: 'white' }}>Video {dataCourse?.nama} ke {i + 1}</Typography>

                            </div>
                            <div>
                                <iframe
                                    title={`course ${content?.videoId}`}
                                    src={content?.videoId}
                                    width='100%'
                                    height='auto'
                                    style={{ border: 0, maxWidth: '100%', top: 0, left: 0, height: '100%', width: '100%', }}
                                    allowFullScreen="true"
                                    allow="encrypted-media"
                                ></iframe>
                            </div>
                        </div>
                    })
                }
            </div>
        </>
    );
}

export default ContentCourse;

