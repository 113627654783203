import React from "react";

import useStyles from "./style";

import { withRouter, useHistory } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Box, Hidden } from "@material-ui/core";

import logo from "../../images/logo-full.svg";
import notif from "../../images/icon-notification.svg";
import { ChevronLeft } from "@material-ui/icons";
// import logoW from "../../images/logo-full-w.svg";
import idiscover from "../../images/illustration-discover.png";
import imission from "../../images/illustration-mission.png";
import iskill from "../../images/illustration-skill.png";
import imagang from "../../images/illustration-magang.png";
// import { useFirebase } from "../FirebaseProvider";
import GuideComponents from "../GuideComponent";
import TopNav from "../TopNav";

export function HomeHeader(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <Box className={classes.root}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={2}>
            <Box style={{ display: "flex", alignItems: "flex-end" }}>
              <img draggable={false} src={logo} alt="" width="150px" />
              <span
                className={classes.beta}
                style={{
                  fontWeight: "bold",
                  marginLeft: "12px",
                  marginBottom: "5px",
                }}
              >
                Beta
              </span>
              {/* <GuideComponents idTutorial='home' content='Dashboard fitur utama' /> */}
            </Box>
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={9}>
                <TopNav />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item md={1}>
              <IconButton onClick={() => history.push("/notifikasi")}>
                <img draggable={false} src={notif} alt="" />
              </IconButton>
            </Grid>
          </Hidden>
        </Grid>
      </Box>
      <div className={classes.mb} />
    </>
  );
}

export function HFHeader(props) {
  const classes = useStyles();
  const history = useHistory();
  // const { user } = useFirebase();

  return (
    <>
      <div
        className={classes.root2}
        // style={{ boxShadow: props.noBorder && "none" }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item md={1}>
            <IconButton onClick={() => history.goBack()}>
              <ChevronLeft viewBox="2 2 20 20" />
            </IconButton>
          </Grid>
          <Grid item md={2}>
            {props.title === "logo" ? (
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <img draggable={false} src={logo} alt="" width="150px" />
                <span
                  className={classes.beta}
                  style={{
                    fontWeight: "bold",
                    marginLeft: "12px",
                    marginRight: '5px',
                    marginBottom: "5px",
                  }}
                >
                  Beta
                </span>
              </div>
            ) : (
              <p className={classes.pgTitle}>
                {props.title}{" "}
                {props.beta && <span className={classes.beta}>Beta</span>
                }
                {
                  props.content && <GuideComponents idTutorial={props.idTutorial} content={props.content} />

                }
              </p>
            )}
          </Grid>
          <Hidden smDown>
            <Grid item xs={12} md={9}>
                <TopNav />
            </Grid>
          </Hidden>
          
          {/* <Hidden mdUp>
            <Grid item>
                <IconButton
                  // eslint-disable-next-line no-unused-expressions
                  onClick={() => {
                    window?.mixpanel?.track("Header notifications Button Clicked", {
                      userId: user?.uid,
                      tempUID: user?.tempUID,
                    });
                    history.push("/notifikasi");
                  }}
                >
                  <img draggable={false} src={notif} alt="" />
                </IconButton>
            </Grid>
          </Hidden> */}

          <Hidden mdUp>
            <Grid item>
              <IconButton onClick={() => history.push("/notifikasi")}>
                <img draggable={false} src={notif} alt="" />
              </IconButton>
            </Grid>
          </Hidden>

          {props.pgDesc !== false && (
            <Grid item style={{ margin: "auto" }}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                className={classes.pgAbt}
              >
                <Grid item>
                  <img
                    draggable={false}
                    width="70px"
                    src={
                      props.image === "discover"
                        ? idiscover
                        : props.image === "mission"
                          ? imission
                          : props.image === "skill"
                            ? iskill
                            : props.image === "magang" && imagang
                    }
                    alt=""
                  />
                </Grid>
                <Grid item xs>
                  <p className={classes.pgDesc}>{props.desc}</p>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
      <div className={props.pgDesc !== false ? classes.mb2 : classes.mb} />
    </>
  );
}

function AppHeaderNew() {
  return null;
}

export default withRouter(AppHeaderNew);
