// React
import React, { useState, useEffect } from "react";

// Styles
import useStyles from "./styles";
import { withStyles } from "@material-ui/styles";

// Material UI
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";

// Component
import AppHeader from "../../components/AppHeader";
import {
  firestore,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";
import { useCoin } from "../../components/CoinProvider";
import AppLoading from "../../components/AppLoading";
import LoadingOverlay from "../../components/LoadingOverlay";

// Images
import { baseURL } from "../../images/s3";

// React Firebase Hooks
import { useDocumentData } from "react-firebase-hooks/firestore";

// React Router Dom
import { useParams, useHistory } from "react-router-dom";

// Utils
import { currency, delimiters } from "../../utils/formatter-rupiah";

// JSON
import listRekening from "./list_rekening.json";

const CustomSwitch = withStyles((theme) => ({
  root: {
    width: 100,
    height: 40,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 3,
    "&$checked": {
      transform: "translateX(60px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#F26722",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#F26722",
    },
  },
  thumb: {
    width: 34,
    height: 34,
  },
  track: {
    borderRadius: 40 / 2,
    backgroundColor: "#F26722",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

function FormPembayaran({ auth = false }) {
  if (auth === true) {
    return <Auth auth={auth} />;
  }

  return <NoAuth />;
}

function NoAuth() {
  const classes = useStyles();

  const { eventId, transaksiId } = useParams();

  const history = useHistory();

  const eventDoc = firestore.doc(`events/${eventId}`);

  const [event, loadingEvent] = useDocumentData(eventDoc);

  const [bank, setBank] = useState("");

  const [isSubmitting, setIsSubmtting] = useState(false);

  const handleChange = (e) => {
    setBank((b) => (b === e.target.value ? "" : e.target.value));
  };

  const [uniqueCode, setUniqueCode] = useState(0);

  useEffect(() => {
    if (uniqueCode === 0) {
      setUniqueCode(Math.floor(Math.random() * 200) + 1);
    }
  }, [uniqueCode]);

  const total_pembayaran = parseInt(event?.harga_tiket_rp) + uniqueCode;

  const handleSubmit = async () => {
    try {
      setIsSubmtting(true);

      const choosedBank = listRekening[bank];

      const transaksiEvent = functions.httpsCallable("transaksiEvent");

      await transaksiEvent({
        type: "pembayaran",
        doc_id: transaksiId,
        nama_bank: bank,
        no_rekening: choosedBank.no_rek,
        atas_nama: choosedBank.an,
        total_pembayaran: parseInt(total_pembayaran),
      });

      history.push(`/acara/${eventId}/konfirmasi/${transaksiId}`);

      setIsSubmtting(false);
    } catch (error) {
      console.log(error);

      setIsSubmtting(false);
    }
  };

  const [cka, setCka] = useState(false);

  const handleChangeCka = (event) => {
    setCka(event.target.checked);
  };

  if (loadingEvent) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root2}>
      {isSubmitting && <LoadingOverlay />}
      <div className={classes.blueBg}></div>
      <AppHeader />
      <div className={classes.coinBody}>
        <div style={{ marginTop: 10 }}>
          <h1 className={classes.pageTitle}>Pembayaran</h1>
          <h3 className={classes.pageSubtitle1}>
            Acara {event?.nama ?? "-"} <br /> Tryout Nasional SBMPTN
          </h3>
          <p style={{ paddingTop: 10 }} className={classes.note}>
            Harap melakukan pembayaran sejumlah
          </p>
          <p className={classes.textRp}>
            {currency(total_pembayaran)},- /{" "}
            {delimiters(event?.harga_tiket_coin)} Coin
          </p>
          <p style={{ fontSize: 8 }}>
            Install aplikasi PakBudi Siswa di Android Playstore atau website{" "}
            <a
              style={{ textDecoration: "none", color: "#F26722" }}
              href="https://www.pakbudi.id"
            >
              www.pakbudi.id
            </a>{" "}
            untuk mengerjakan misi dan kumpulkan koin agar bisa IKUT ACARA INI
            SECARA GRATIS!*
          </p>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid
              item
              style={{
                color: !cka ? "#F26722" : "#E5E5E5",
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              Transfer
            </Grid>
            <Grid item style={{ opacity: 0.8 }}>
              <CustomSwitch
                disabled
                checked={cka}
                onChange={handleChangeCka}
                name="cka"
              />
            </Grid>
            <Grid
              item
              style={{
                color: cka ? "#F26722" : "#E5E5E5",
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              Coin
            </Grid>
          </Grid>
          <p className={classes.note}>Pilih Metode Transfer</p>
        </div>
        <Container maxWidth="sm">
          <form className={classes.checkoutForm}>
            <FormGroup aria-label="position" name="position" row>
              <RadioGroup aria-label="position" name="position" row>
                <div className={classes.choicesWrap}>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          color="primary"
                          icon={
                            <img
                              src={`${baseURL}img-payment-bri.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-bri.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          onChange={handleChange}
                          value="BRI"
                        />
                      }
                      label={listRekening.BRI.title}
                      labelPlacement="bottom"
                      disabled={isSubmitting}
                    />
                    <div style={{ marginTop: 5 }}>
                      <p
                        className={classes.account}
                        style={{ fontWeight: "bold" }}
                      >
                        {listRekening.BRI.no_rek}
                      </p>
                      <p className={classes.account}>
                        a.n. {listRekening.BRI.an}
                      </p>
                    </div>
                  </div>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          color="primary"
                          icon={
                            <img
                              src={`${baseURL}img-payment-bca.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-bca.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          onChange={handleChange}
                          value="BCA"
                        />
                      }
                      label={listRekening.BCA.title}
                      labelPlacement="bottom"
                      disabled={isSubmitting}
                    />
                    <div style={{ marginTop: 5 }}>
                      <p
                        className={classes.account}
                        style={{ fontWeight: "bold" }}
                      >
                        {listRekening.BCA.no_rek}
                      </p>
                      <p className={classes.account}>
                        a.n. {listRekening.BCA.an}
                      </p>
                    </div>
                  </div>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          color="primary"
                          icon={
                            <img
                              src={`${baseURL}img-payment-mandiri.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-mandiri.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          onChange={handleChange}
                          value="Mandiri"
                        />
                      }
                      label={listRekening.Mandiri.title}
                      labelPlacement="bottom"
                      disabled={isSubmitting}
                    />
                    <div style={{ marginTop: 5 }}>
                      <p
                        className={classes.account}
                        style={{ fontWeight: "bold" }}
                      >
                        {listRekening.Mandiri.no_rek}
                      </p>
                      <p className={classes.account}>
                        a.n. {listRekening.Mandiri.an}
                      </p>
                    </div>
                  </div>
                  <div className={classes.choiceBlock}>
                    <FormControlLabel
                      classes={{ label: classes.labelle }}
                      control={
                        <Radio
                          color="primary"
                          icon={
                            <img
                              src={`${baseURL}img-payment-bni.png`}
                              className={classes.choiceImg}
                              alt=""
                            />
                          }
                          checkedIcon={
                            <img
                              src={`${baseURL}img-payment-bni.png`}
                              className={classes.choiceImgChecked}
                              alt=""
                            />
                          }
                          onChange={handleChange}
                          value="BNI"
                        />
                      }
                      label={listRekening.BNI.title}
                      labelPlacement="bottom"
                      disabled={isSubmitting}
                    />
                    <div style={{ marginTop: 5 }}>
                      <p
                        className={classes.account}
                        style={{ fontWeight: "bold" }}
                      >
                        {listRekening.BNI.no_rek}
                      </p>
                      <p className={classes.account}>
                        a.n. {listRekening.BNI.an}
                      </p>
                    </div>
                  </div>
                </div>
              </RadioGroup>
            </FormGroup>
            <Button
              className={classes.btn}
              onClick={handleSubmit}
              disabled={isSubmitting || !bank}
              variant="contained"
              color="primary"
              size="large"
            >
              Bayar
            </Button>
          </form>
        </Container>
      </div>
    </div>
  );
}

function Auth({ auth }) {
  const classes = useStyles();

  const { user } = useFirebase();

  const { Coin } = useCoin();

  const { eventId, transaksiId } = useParams();

  const history = useHistory();

  const eventDoc = firestore.doc(`events/${eventId}`);

  const [event, loadingEvent] = useDocumentData(eventDoc);

  const eventTrxDoc = firestore.doc(`transaksi_event/${transaksiId}`);

  const [eventTrx, loadingEventTrx] = useDocumentData(eventTrxDoc);

  const [bank, setBank] = useState("");

  const [isSubmitting, setIsSubmtting] = useState(false);

  const handleChange = (e) => {
    setBank((b) => (b === e.target.value ? "" : e.target.value));
  };

  const [uniqueCode, setUniqueCode] = useState(0);

  useEffect(() => {
    if (uniqueCode === 0) {
      setUniqueCode(Math.floor(Math.random() * 200) + 1);
    }
  }, [uniqueCode]);

  useEffect(() => {
    const getData = async () => {
      await firestore
        .collection("event_state")
        .doc(`${eventId}_${user?.uid}`)
        .set(
          {
            path: `/acara/${eventId}/proses/${transaksiId}`,
          },
          { merge: true }
        );

      history.push(`/acara/${eventId}/proses/${transaksiId}`);
    };

    if (eventTrx && eventTrx?.status === "sukses") {
      getData();
    }
  }, [eventId, eventTrx, history, transaksiId, user]);

  const total_pembayaran = parseInt(event?.harga_tiket_rp) + uniqueCode;

  const handleSubmit = async () => {
    try {
      setIsSubmtting(true);

      const choosedBank = listRekening[bank];

      const transaksiEvent = functions.httpsCallable("transaksiEvent");

      await transaksiEvent({
        type: "pembayaran",
        doc_id: transaksiId,
        nama_bank: bank,
        no_rekening: choosedBank.no_rek,
        atas_nama: choosedBank.an,
        total_pembayaran: parseInt(total_pembayaran),
      });

      await firestore
        .collection("event_state")
        .doc(`${eventId}_${user?.uid}`)
        .set(
          {
            path: `/acara/u/${eventId}/konfirmasi/${transaksiId}`,
          },
          { merge: true }
        );

      history.push(`/acara/u/${eventId}/konfirmasi/${transaksiId}`);

      setIsSubmtting(false);
    } catch (error) {
      console.log(error);

      setIsSubmtting(false);
    }
  };

  const handleSubmitCoin = async () => {
    try {
      setIsSubmtting(true);

      const buyEvent = functions.httpsCallable("buyEvent");

      const res = await buyEvent({
        transaksi_event_id: transaksiId,
      });

      history.push(res?.data?.url);
    } catch (error) {
      console.log(error);

      setIsSubmtting(false);
    }
  };

  const [cka, setCka] = useState(false);

  const handleChangeCka = (event) => {
    setCka(event.target.checked);
  };

  if (loadingEvent || loadingEventTrx) {
    return <AppLoading />;
  }

  return (
    <div className={classes.root2}>
      {isSubmitting && <LoadingOverlay />}
      <div className={classes.blueBg}></div>
      <AppHeader />
      <div className={classes.coinBody}>
        <div style={{ marginTop: 10 }}>
          <h1 className={classes.pageTitle}>Pembayaran</h1>
          <h3 className={classes.pageSubtitle1}>
            Acara {event?.nama ?? "-"} <br /> Tryout Nasional SBMPTN
          </h3>
          <p style={{ paddingTop: 10 }} className={classes.note}>
            Harap melakukan pembayaran sejumlah
          </p>
          <p className={classes.textRp}>
            {currency(total_pembayaran)},- /{" "}
            {delimiters(event?.harga_tiket_coin)} Coin
          </p>
          <p style={{ fontSize: 8 }}>
            Install aplikasi PakBudi Siswa di Android Playstore atau website{" "}
            <a
              style={{ textDecoration: "none", color: "#F26722" }}
              href="https://www.pakbudi.id"
            >
              www.pakbudi.id
            </a>{" "}
            untuk mengerjakan misi dan kumpulkan koin agar bisa IKUT ACARA INI
            SECARA GRATIS!*
          </p>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={1}
          >
            <Grid
              item
              style={{
                color: !cka ? "#F26722" : "#E5E5E5",
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              Transfer
            </Grid>
            <Grid item style={{ opacity: 0.8 }}>
              <CustomSwitch
                disabled={auth === false}
                checked={cka}
                onChange={handleChangeCka}
                name="cka"
              />
            </Grid>
            <Grid
              item
              style={{
                color: cka ? "#F26722" : "#E5E5E5",
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              Coin
            </Grid>
          </Grid>
          <p className={classes.note}>
            {!cka
              ? "Pilih Metode Transfer"
              : `Coin Kamu ${delimiters(Coin?.coin ?? 0)}`}
          </p>
        </div>
        {/* Transfer */}
        {!cka ? (
          <Container maxWidth="sm">
            <form className={classes.checkoutForm}>
              <FormGroup aria-label="position" name="position" row>
                <RadioGroup aria-label="position" name="position" row>
                  <div className={classes.choicesWrap}>
                    <div className={classes.choiceBlock}>
                      <FormControlLabel
                        classes={{ label: classes.labelle }}
                        control={
                          <Radio
                            color="primary"
                            icon={
                              <img
                                src={`${baseURL}img-payment-bri.png`}
                                className={classes.choiceImg}
                                alt=""
                              />
                            }
                            checkedIcon={
                              <img
                                src={`${baseURL}img-payment-bri.png`}
                                className={classes.choiceImgChecked}
                                alt=""
                              />
                            }
                            onChange={handleChange}
                            value="BRI"
                          />
                        }
                        label={listRekening.BRI.title}
                        labelPlacement="bottom"
                        disabled={isSubmitting}
                      />
                      <div style={{ marginTop: 5 }}>
                        <p
                          className={classes.account}
                          style={{ fontWeight: "bold" }}
                        >
                          {listRekening.BRI.no_rek}
                        </p>
                        <p className={classes.account}>
                          a.n. {listRekening.BRI.an}
                        </p>
                      </div>
                    </div>
                    <div className={classes.choiceBlock}>
                      <FormControlLabel
                        classes={{ label: classes.labelle }}
                        control={
                          <Radio
                            color="primary"
                            icon={
                              <img
                                src={`${baseURL}img-payment-bca.png`}
                                className={classes.choiceImg}
                                alt=""
                              />
                            }
                            checkedIcon={
                              <img
                                src={`${baseURL}img-payment-bca.png`}
                                className={classes.choiceImgChecked}
                                alt=""
                              />
                            }
                            onChange={handleChange}
                            value="BCA"
                          />
                        }
                        label={listRekening.BCA.title}
                        labelPlacement="bottom"
                        disabled={isSubmitting}
                      />
                      <div style={{ marginTop: 5 }}>
                        <p
                          className={classes.account}
                          style={{ fontWeight: "bold" }}
                        >
                          {listRekening.BCA.no_rek}
                        </p>
                        <p className={classes.account}>
                          a.n. {listRekening.BCA.an}
                        </p>
                      </div>
                    </div>
                    <div className={classes.choiceBlock}>
                      <FormControlLabel
                        classes={{ label: classes.labelle }}
                        control={
                          <Radio
                            color="primary"
                            icon={
                              <img
                                src={`${baseURL}img-payment-mandiri.png`}
                                className={classes.choiceImg}
                                alt=""
                              />
                            }
                            checkedIcon={
                              <img
                                src={`${baseURL}img-payment-mandiri.png`}
                                className={classes.choiceImgChecked}
                                alt=""
                              />
                            }
                            onChange={handleChange}
                            value="Mandiri"
                          />
                        }
                        label={listRekening.Mandiri.title}
                        labelPlacement="bottom"
                        disabled={isSubmitting}
                      />
                      <div style={{ marginTop: 5 }}>
                        <p
                          className={classes.account}
                          style={{ fontWeight: "bold" }}
                        >
                          {listRekening.Mandiri.no_rek}
                        </p>
                        <p className={classes.account}>
                          a.n. {listRekening.Mandiri.an}
                        </p>
                      </div>
                    </div>
                    <div className={classes.choiceBlock}>
                      <FormControlLabel
                        classes={{ label: classes.labelle }}
                        control={
                          <Radio
                            color="primary"
                            icon={
                              <img
                                src={`${baseURL}img-payment-bni.png`}
                                className={classes.choiceImg}
                                alt=""
                              />
                            }
                            checkedIcon={
                              <img
                                src={`${baseURL}img-payment-bni.png`}
                                className={classes.choiceImgChecked}
                                alt=""
                              />
                            }
                            onChange={handleChange}
                            value="BNI"
                          />
                        }
                        label={listRekening.BNI.title}
                        labelPlacement="bottom"
                        disabled={isSubmitting}
                      />
                      <div style={{ marginTop: 5 }}>
                        <p
                          className={classes.account}
                          style={{ fontWeight: "bold" }}
                        >
                          {listRekening.BNI.no_rek}
                        </p>
                        <p className={classes.account}>
                          a.n. {listRekening.BNI.an}
                        </p>
                      </div>
                    </div>
                  </div>
                </RadioGroup>
              </FormGroup>
              <Button
                className={classes.btn}
                onClick={handleSubmit}
                disabled={isSubmitting || !bank}
                variant="contained"
                color="primary"
                size="large"
              >
                Bayar
              </Button>
            </form>
          </Container>
        ) : (
          <Container maxWidth="sm">
            <p style={{ fontWeight: "500" }}>Coin Kamu Setelah Pembayaran</p>
            <p style={{ fontWeight: "700", fontSize: 16 }}>
              {Coin?.coin - event?.harga_tiket_coin < 0
                ? "Coin Tidak Mencukupi"
                : delimiters(Coin?.coin - event?.harga_tiket_coin)}
            </p>
            <Button
              className={classes.btn}
              onClick={handleSubmitCoin}
              disabled={
                isSubmitting || Coin?.coin - event?.harga_tiket_coin < 0
              }
              variant="contained"
              color="primary"
              size="large"
            >
              Bayar
            </Button>
          </Container>
        )}
      </div>
    </div>
  );
}

export default FormPembayaran;
