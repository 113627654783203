import React, { useEffect, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles/timeoutDialog';

import useTimer from '../../components/useTimer';
import { functions } from '../../components/FirebaseProvider';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import notice from '../../images/notice.svg';
import Lottie from 'react-lottie-player'

export default function WaitingDialog({ open, handleClose, data }) {
    const classes = useStyles();
    const history = useHistory();
    const params = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const milisecondstart = data && data.created_at ? data.created_at.toMillis() : Date.now();
    const milisecondend = Date.now();
    const [isCanceling, setCanceling] = useState(false);
    const { seconds, timerStr, stopTimer } = useTimer(milisecondstart, milisecondend);
    const [lottieJson, setLottieJson] = useState('');

    useEffect(() => {

        import('./waiting-anim.json').then((data) => {
            setLottieJson(data);
        })
    }, [])
    const cancelChatRequest = useCallback(async () => {
        setCanceling(true)
        const cancelChatRequest = functions.httpsCallable('cancelChatRequest');
        // 
        try {
            stopTimer()
            await cancelChatRequest({
                chat_id: params.chatId
            });
            enqueueSnackbar("Chat dibatalkan", { variant: "success" })
            history.push(`/chat`)
        } catch (e) {
            enqueueSnackbar(e.message, { variant: "error" })
            setCanceling(false)
        }
    }, [enqueueSnackbar, history, params.chatId, stopTimer]);

    useEffect(() => {
        if (seconds >= 120 && data.status === 'waiting-for-accept') {
            if (!isCanceling) {

                cancelChatRequest();
            }
        }

    }, [seconds, data.status, isCanceling, cancelChatRequest])


    return <>
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
        >
            <DialogTitle color="primary" disableTypography className={classes.waitDialogTitle}>{"Menghubungi Mitra PakBudi"}</DialogTitle>
            <img className={classes.noticeImg} src={notice} alt="" />
            <Typography className={classes.txt} paragraph>Akan batal otomatis jika Mitra kami tidak menjawab dalam waktu 2 menit atau Anda menutup halaman ini.</Typography>
            {lottieJson && <div style={{ display: 'flex', justifyContent: 'center' }}> <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 150, height: 150 }}
            />
            </div>
            }
            <p className={classes.waitingTime}>
                Sudah menunggu selama {timerStr}
            </p>
            <Button
                onClick={cancelChatRequest}
                fullWidth
                className={classes.btnNo}
                disabled={isCanceling}
            >
                <CloseIcon />
                Batalkan Sekarang
            </Button>

        </Dialog >

    </>

}