// React
import React from "react";

// Styles
import useStyles from "./styles/dialog";

// MUI
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Warning from "@material-ui/icons/Warning";
import { Backdrop, CircularProgress } from "@material-ui/core";
// import CheckCircle from "@material-ui/icons/CheckCircle";

export default function BuyDialog({
  open,
  //   openOk,
  handleClose,
  //   handleOk,
  //   handleCloseOk,
  name,
  price,
  callFunction,
  loading,
  enable
}) {
  const classes = useStyles();
  // console.log(loading)
  return (
    <React.Fragment>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent className={classes.content}>
          <Backdrop style={{ zIndex: 1000 }} open={loading}>
            <CircularProgress color="secondary" />
          </Backdrop>
          <Warning color="primary" fontSize="large" />
          <p className={classes.text}>
            Anda yakin akan membeli <br />{" "}
            <span className={classes.name}>{name}</span>
            <br />
            seharga <span className={classes.price}>{price} Kredit</span> ?
          </p>
        </DialogContent>
        <DialogActions className={classes.btc}>
          <Button
            className={classes.btn1}
            onClick={handleClose}
            color="primary"
            variant="outlined"
            disableElevation
            fullWidth
            disabled={loading}
          >
            Tidak
          </Button>
          <Button
            className={classes.btn2}
            onClick={callFunction && callFunction}
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            disabled={loading}
          >{enable ? 'Kerjakan' : 'Beli'}

          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog Setelah Pembelian Sukses */}
      {/* <Dialog
        PaperProps={{
          style: {
            borderRadius: 8,
          },
        }}
        open={openOk}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CheckCircle color="primary" fontSize="large" />
          <p className={classes.text}>
            Anda telah membeli <br />{" "}
            <span className={classes.name}>{name}</span>
            <br />
            <br />
            Selamat Mengerjakan
          </p>
        </DialogContent>
        <DialogActions className={classes.btc}>
          <Button
            className={classes.btn2}
            onClick={handleCloseOk}
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
          >
            Kembali
          </Button>
        </DialogActions>
      </Dialog> */}
    </React.Fragment>
  );
}
