// React
import React, { useEffect, useMemo } from "react";

// Styles
import useStyles from "./styles/Home";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import TopAction from "../../components/TopAction";

// import { baseURL } from '../../images/s3';
import Home from "./Home";
import AppHeader from "../../components/AppHeader";
import SortDialog from "./sortDialog";
import Button from "@material-ui/core/Button";
import SortIcon from "@material-ui/icons/Sort";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";

import { firestore } from "../../components/FirebaseProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import { useChat } from "../../components/useChatList";
import { useParams } from "react-router-dom";

const sortfn = {
  "rating-desc": (a, b) => {
    return (
      parseFloat(b.average_rating || 0) - parseFloat(a.average_rating || 0)
    );
  },
  "rating-asc": (a, b) => {
    return (
      parseFloat(a.average_rating || 0) - parseFloat(b.average_rating || 0)
    );
  },
  "online-asc": (a, b) => {
    return a.online === b.online ? 0 : a.online ? -1 : 1;
  },
  "offline-asc": (a, b) => {
    return a.online === b.online ? 0 : a.online ? 1 : -1;
  },
  "nama-asc": (a, b) => {
    const x = a.nama.toLowerCase();
    const y = b.nama.toLowerCase();
    if (x > y) {
      return 1;
    }
    if (y > x) {
      return -1;
    }
    return 0;
  },
  "nama-desc": (a, b) => {
    const x = a.nama.toLowerCase();
    const y = b.nama.toLowerCase();
    if (x > y) {
      return -1;
    }
    if (y > x) {
      return 1;
    }
    return 0;
  },
  "harga-desc": (a, b) => {
    const x = a.harga ? parseInt(a.harga) : 0;
    const y = b.harga ? parseInt(b.harga) : 0;

    return y - x;
  },
  "harga-asc": (a, b) => {
    const x = a.harga ? parseInt(a.harga) : 0;
    const y = b.harga ? parseInt(b.harga) : 0;

    return x - y;
  },
};

function Manage(props) {
  // Style
  const classes = useStyles();

  //
  const { rate, harga } = useChat();

  // Router
  const { location, history } = props;
  const params = useParams();

  // Collection
  const [mitraCol, setMitraCol] = React.useState();

  useEffect(() => {
    if (params.type === "semua") {
      setMitraCol(firestore.collection("mitra").where("online", "==", true));
    } else {
      let query = firestore.collection("mitra");
      query = query.where("type", "==", params.type);
      query = query.where("online", "==", true);

      setMitraCol(query);
    }
  }, [params.type]);

  const [snapshot, loading] = useCollection(mitraCol);

  // dialog state
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("rating-desc");

  // State
  const [mitraItems, setMitraItems] = React.useState([]);
  const [filterMitra, setFilterMitra] = React.useState("");
  const [load, setLoad] = React.useState(false);

  // Functions
  const handleChange = (event, value) => {
    history.push(value);
  };

  const handleClose = (newValue) => {
    setOpen(false);

    if (newValue) {
      setValue(newValue);
    }
  };
  //
  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      setLoad(true);
      const rateDocs = rate.docs || [];
      const hargaDocs = harga.docs || [];
      const items = snapshot.docs.map((doc) => {
        const rateDoc = rateDocs.find((rd) => rd.id === doc.id);
        const rateData = rateDoc ? rateDoc.data() : {};
        const hargaDoc = hargaDocs.find((hd) => hd.id === doc.id);
        const hargaData = hargaDoc ? hargaDoc.data() : {};
        return {
          id: doc.id,
          ...doc.data(),
          ...rateData,
          ...hargaData,
        };
      });
      setMitraItems(items);
      setLoad(false);
    } else {
      setMitraItems([]);
    }
  }, [snapshot, rate, harga]);

  const filteredMitra = useMemo(() => {
    //
    return mitraItems
      .filter((item) => {
        return item.nama.toLowerCase().indexOf(filterMitra.toLowerCase()) > -1;
      })
      .sort(sortfn[value]);
    //
  }, [mitraItems, filterMitra, value]);

  //
  return (
    <div className={classes.root}>
      <div className={classes.pageHeader}>
        <AppHeader />
        <TopAction />
      </div>
      <div className={classes.pageWrap}>
        <div className={classes.topPart}>
          <div className={classes.topInfo}>
            {/* <div className={classes.topImg}><img src={`${baseURL}icon-bubble.png`} alt="" /></div> */}
            <div className={classes.topDesc}>
              <h3 className={classes.descTitle}>Konsultasi</h3>
              <p className={classes.descTxt}>
                Konsultasikan masalah-masalah karirmu
              </p>
            </div>
          </div>
          <div className={classes.filterBox}>
            <div className={classes.filterLabel}>Filter berdasarkan</div>
            <Button
              variant="text"
              className={classes.sortBtn}
              onClick={() => {
                setOpen(true);
              }}
            >
              <SortIcon /> Urutkan
            </Button>
          </div>
          <Tabs
            textColor="primary"
            className={classes.theTabs}
            indicatorColor="primary"
            value={location.pathname}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            <Tab
              classes={{ root: classes.rootTab, selected: classes.selectedTab }}
              label="Semua"
              value="/konsultasi/semua"
            />
            <Tab
              classes={{ root: classes.rootTab, selected: classes.selectedTab }}
              label="Karir Konselor"
              value="/konsultasi/konselor"
            />
            <Tab
              classes={{ root: classes.rootTab, selected: classes.selectedTab }}
              label="Enterpreneur"
              value="/konsultasi/enterpreneur"
            />
            <Tab
              classes={{ root: classes.rootTab, selected: classes.selectedTab }}
              label="Profesional"
              value="/konsultasi/profesional"
            />
          </Tabs>
          <div className={classes.searchBox}>
            <SearchIcon className={classes.searchIcon} />
            <InputBase
              className={classes.inputBase}
              placeholder="Cari nama yang ingin kamu ajak chat"
              inputProps={{
                "aria-label": "Cari nama yang ingin kamu ajak chat",
              }}
              value={filterMitra}
              onChange={(e) => setFilterMitra(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.botPart}>
          <Home
            load={load}
            loading={loading}
            filteredMitra={filteredMitra}
            mitraItems={mitraItems}
          />
        </div>
        <SortDialog
          classes={{
            paper: classes.paper,
          }}
          id="sort-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
        />
      </div>
    </div>
  );
}

export default Manage;
