// React
import React from "react";
// import { useState } from 'react';
import PropTypes from "prop-types";
// Styles
import useStyles from "./styles";
import defaultThumb from "../../images/thumbnail-profile-default.png";

// Component
// import AppHeader from '../../components/AppHeader';
// import { baseURL } from '../../images/s3';
// import RemoveIcon from '@material-ui/icons/Remove';
// import AddIcon from '@material-ui/icons/Add';
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

import { useHistory, useParams } from "react-router-dom";
// import { TabUnselected } from '@material-ui/icons';
import {
  firestore,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";
// import chunk from 'lodash/chunk';
import { FixedSizeList as List } from "react-window";
import { useTo } from "../../components/TryoutProvider";
import { formatDateLB } from "../../utils/formatter";
// import LoadingOverlay from '../../components/LoadingOverlay';
import AppLoading from "../../components/AppLoading";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
// import AppHeader from "../../components/AppHeader";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div p={2}>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function RankRow({ index, data, style, myId, position }) {
  const classes = useStyles();
  // const total = ;
  if (data?.siswa_id === myId && position === data?.rank) {
    return (
      <div style={style}>
        <div className={`${classes.rankRow} ${classes.myRank}`}>
          {/* <div className={`${classes.rankRow} ${total < 10 ? classes.myRankAlt : classes.myRank}`}> */}
          <div className={classes.rankMarker}>RANKING KU</div>
          <div className={classes.rowRankCell}>{data?.rank ?? index + 1}</div>
          <div className={classes.rowUserCell}>
            {data?.nama ?? "Nama Peserta"}{" "}
          </div>
          <div className={classes.rowSchoolCell}>
            {data?.sekolah_nama ?? "Nama Sekolah"}
          </div>
          <div className={classes.rowGradeCell}>
            {Math.round(data?.nilai ?? "0")}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div style={style} className={classes.rankWrap}>
      <div className={classes.rankRow}>
        <div className={classes.rowRankCell}>{data?.rank ?? index + 1}</div>
        <div className={classes.rowUserCell}>
          {data?.nama ?? "Nama Peserta"}{" "}
        </div>
        <div className={classes.rowSchoolCell}>
          {data?.sekolah_nama ?? "Nama Sekolah"}
        </div>
        <div className={classes.rowGradeCell}>
          {Math.round(data?.nilai ?? "0")}
        </div>
      </div>
    </div>
  );
}

function FotoProfil({ siswa_id }) {
  const profilRef = firestore.doc(`users/${siswa_id}`);
  const [profil, loading] = useDocumentDataOnce(profilRef);

  return !loading && profil?.photoURL ? (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img src={profil?.photoURL} alt={profil?.nama} />
  ) : (
    <img src={defaultThumb} alt="" />
  );
}

function PBLeaderboard(props) {
  // Styles
  const classes = useStyles();

  const params = useParams();
  //
  const history = useHistory();
  const [value, setValue] = React.useState("pertama");
  const { user } = useFirebase();

  const { tryout, hasiltryout } = useTo();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [loading, setLoading] = React.useState(false);
  const [podium, setPodium] = React.useState();
  const [restRank, setRestRank] = React.useState([]);
  const [position, setPosition] = React.useState(0);
  const [myRank, setMyRank] = React.useState({});
  const to = tryout?.find?.((i) => i.id === params.to_id) ?? {};
  const [timestamp, setTimestamp] = React.useState("00:00 01/01/2022");
  const count_hasilto = hasiltryout.filter((ht) => {
    if (ht.tryout?.id === params.to_id) {
      return true;
    }
    return false;
  })?.length;
  // const [chunkedRank, setChunkedRank] = React.useState([]);
  // const [pagination, setPagination] = React.useState({
  //     page: 0,
  //     total_page: 0,
  //     total: 0
  // })
  // State
  // const [count, setCount] = useState(0);
  // const [submit, setSubmit] = useState(true);

  const fetchData = React.useCallback(async (tryout_id, siswa_id, mode) => {
    try {
      const getToLeaderboard = functions.httpsCallable("getToLeaderboard");

      const result = await getToLeaderboard({
        tryout_id,
        siswa_id,
        mode,
      });

      setPodium({
        first: result.data.rank?.[0],
        second: result.data.rank?.[1],
        third: result.data.rank?.[2],
      });

      const restRank = result.data.rank?.slice(3);
      // console.log(new Date(result.data?.timestamp))
      setTimestamp(formatDateLB(result.data?.timestamp));
      setRestRank(restRank.filter?.((i) => i.siswa_id !== siswa_id));
      setMyRank(result.data.rank.find?.((i) => i.siswa_id === siswa_id));
      setPosition(result.data.position);
      // const chunkedArr = chunk(restRank, 20);
      // setPagination({
      //     page: 1,
      //     total_page: chunkedArr.length,
      //     total: restRank.length
      // })
      // setRestRank(chunkedArr[0]);
      // setChunkedRank(chunkedArr);
      // console.log(chunkedArr)
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  React.useEffect(() => {
    setLoading(true);
    fetchData(params.to_id, user.uid, value).finally(() => {
      setLoading(false);
    });
  }, [fetchData, params.to_id, user.uid, value]);

  if (loading) {
    return (
      <div style={{ height: "90vh" }}>
        <AppLoading />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <div className={classes.rankHead}>
        <IconButton
          aria-label="Kembali"
          className={classes.goBack}
          onClick={() => history.goBack()}
        >
          <ChevronLeftIcon />
        </IconButton>
        <div className={classes.rankTitle}>
          <h2>Ranking</h2>
          <p>{to?.judul}</p>
        </div>
        <div className={classes.rankTopThree}>
          <div className={`${classes.rankBox} ${classes.rank2}`}>
            <div className={`${classes.rankValue} ${classes.rankValue2}`}>
              {podium?.second?.rank ?? 2}
            </div>
            <div className={classes.rankUserImg}>
              <FotoProfil siswa_id={podium?.second?.siswa_id} />
            </div>
            <div className={classes.rankUserName}>
              {podium?.second?.nama ?? "-"}
            </div>
            <div className={classes.rankUserSchool}>
              {podium?.second?.sekolah_nama ?? "-"}
            </div>
            <div className={classes.rankUserScore}>
              {Math.round(podium?.second?.nilai ?? 0)}
            </div>
          </div>
          <div className={`${classes.rankBox} ${classes.rank1}`}>
            <div className={`${classes.rankValue} ${classes.rankValue1}`}>
              {podium?.first?.rank ?? 1}
            </div>
            <div className={`${classes.rankUserImg} ${classes.rankUserImg1}`}>
              <FotoProfil siswa_id={podium?.first?.siswa_id} />
            </div>
            <div className={`${classes.rankUserName} ${classes.rankUserName1}`}>
              {podium?.first?.nama ?? "-"}
            </div>
            <div
              className={`${classes.rankUserSchool} ${classes.rankUserSchool1}`}
            >
              {podium?.first?.sekolah_nama ?? "-"}
            </div>
            <div className={classes.rankUserScore}>
              {Math.round(podium?.first?.nilai ?? 0)}
            </div>
          </div>
          <div className={`${classes.rankBox} ${classes.rank3}`}>
            <div className={`${classes.rankValue} ${classes.rankValue3}`}>
              {podium?.third?.rank ?? 3}
            </div>
            <div className={classes.rankUserImg}>
              <FotoProfil siswa_id={podium?.third?.siswa_id} />
            </div>
            <div className={classes.rankUserName}>
              {podium?.third?.nama ?? "-"}
            </div>
            <div className={classes.rankUserSchool}>
              {podium?.third?.sekolah_nama ?? "-"}
            </div>
            <div className={classes.rankUserScore}>
              {Math.round(podium?.third?.nilai ?? 0)}
            </div>
          </div>
        </div>
        <div className={classes.lbTabs}>
          <Tabs
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
          >
            <Tab
              classes={{
                label: classes.tabLabel,
                selected: classes.TabSelected,
                root: classes.regColor,
              }}
              label="Ujian Pertama"
              value="pertama"
              {...a11yProps(0)}
            />
            <Tab
              classes={{
                label: classes.tabLabel,
                selected: classes.TabSelected,
                root: classes.regColor,
                textColorInherit: classes.regColor,
              }}
              label="Ujian Ulangan"
              value="pengulangan"
              disabled={count_hasilto <= 1}
              {...a11yProps(1)}
            />
          </Tabs>
          <div className={classes.lastUpdate}>
            <span>Data Masuk Terakhir :</span>
            <span>{timestamp}</span>
          </div>
        </div>
        <div className={classes.rankTable}>
          <div className={classes.rankTableHead}>
            <div className={classes.rankCell}>Rank</div>
            <div className={classes.userCell}>User</div>
            <div className={classes.schoolCell}>Asal Sekolah</div>
            <div className={classes.gradeCell}>Nilai</div>
          </div>
        </div>
      </div>
      <div className={classes.rankBody}>
        <TabPanel value={value} index="pertama">
          <div className={classes.rankTableBody}>
            <div
              className={`${classes.rankRow} ${restRank?.length > 10 ? classes.myRank : classes.myRankAlt
                }`}
            >
              <div className={classes.rankMarker}>RANKING KU</div>
              <div className={classes.rowRankCell}>{myRank?.rank}</div>
              <div className={classes.rowUserCell}>{myRank?.nama} </div>
              <div className={classes.rowSchoolCell}>
                {myRank?.sekolah_nama}
              </div>
              <div className={classes.rowGradeCell}>
                {Math.round(myRank?.nilai ?? 86)}
              </div>
            </div>

            <List
              itemCount={restRank?.length}
              itemSize={45}
              width="100%"
              height={350}
            >
              {(props) => {
                const item = restRank[props.index];
                return (
                  <RankRow
                    {...props}
                    data={item}
                    myId={user.uid}
                    position={position}
                  />
                );
              }}
            </List>
          </div>
        </TabPanel>
        <TabPanel value={value} index="pengulangan">
          <div className={classes.rankTableBody}>
            <div
              className={`${classes.rankRow} ${restRank?.length > 10 ? classes.myRank : classes.myRankAlt
                }`}
            >
              <div className={classes.rankMarker}>RANKING KU</div>
              <div className={classes.rowRankCell}>{myRank?.rank}</div>
              <div className={classes.rowUserCell}>{myRank?.nama} </div>
              <div className={classes.rowSchoolCell}>
                {myRank?.sekolah_nama}
              </div>
              <div className={classes.rowGradeCell}>
                {Math.round(myRank?.nilai ?? 86)}
              </div>
            </div>

            <List
              itemCount={restRank?.length}
              itemSize={45}
              width="100%"
              height={350}
            >
              {(props) => {
                const item = restRank[props.index];
                return (
                  <RankRow
                    {...props}
                    data={item}
                    myId={user.uid}
                    position={position}
                  />
                );
              }}
            </List>
          </div>
        </TabPanel>
      </div>
    </div>
  );
}

export default PBLeaderboard;
