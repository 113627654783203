import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      backgroundColor: theme.palette.neutrals.nf,
      paddingTop: 150,
      alignItems: "center",
    },
    ico: {
      fontSize: 50,
      color: theme.palette.primary.main
    },
    title: {
      fontSize: 25,
      fontWeight: "bold",
      textAlign: "center",
      color: "#4F4F4F",
      margin: "20px auto"
    },
    subtitle: {
      maxWidth: 245,
      fontSize: 12,
      fontWeight: 400,
      textAlign: "center",
      color: "#4F4F4F",
    },
    btn: {
      marginTop: 40,
      textTransform: "capitalize",
      width: 150,
      borderRadius: 5,
    },
}))

export default useStyles;