// React
import React from "react";

// Styles
import useStyles from "./styles";

// LinearProgres
import CircularProgress from "@material-ui/core/CircularProgress";

// import { baseURL } from "../../images/s3";
import Logo from "../../images/logo-full.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStylesCustom = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  bottom: {
    color: "#d4d4d4",
  },
  top: {
    color: theme.palette.secondary.main,
    animationDuration: "550ms",
    position: "absolute",
  },
  circle: {
    strokeLinecap: "round",
  },
}));

function CustomLoading(props) {
  const classes = useStylesCustom();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        className={classes.top}
        classes={{
          circle: classes.circle,
        }}
        size={40}
        thickness={4}
        {...props}
      />
    </div>
  );
}

const AppLoading = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.loadingBody}>
        <img className={classes.logo} src={Logo} alt="" />
        <div className={classes.loadingProgress}>
          <CustomLoading />
          <p className={classes.loadingText}>loading ...</p>
          {/* <p className={classes.subtitleText}>Harap Tunggu</p> */}
        </div>
      </div>
      {/* <div className={classes.quoteWrap}>
        <p className={classes.quoteText}>
          "Dapat Kerja Lebih Mudah & Lebih Cepat"
        </p>
      </div> */}
    </div>
  );
};

export default AppLoading;
