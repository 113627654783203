import React, { useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import IgLogo from "../../images/instagram-logos.webp";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import useStyles from "./styles";
import IcoWarning from "../../images/ico-warning.svg";
import { useFirebase } from "../../components/FirebaseProvider";

function LaporMasalah() {
  const classes = useStyles();
  const { user } = useFirebase();
  const [Open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLapor = () => {
    // eslint-disable-next-line no-unused-expressions
    window?.mixpanel?.track("Settings laporkan masalah Button Clicked", {
      userId: user?.uid,
      tempUID: user?.tempUID,
    })
    window.open("https://www.instagram.com/karir.ai_official/", "_blank");
  };

  return (
    <div>
      <ListItem button onClick={handleClickOpen}>
        <ListItemIcon classes={{ root: classes.listIcon }}>
          <img src={IcoWarning} alt="" />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.listItemText }}
          primary="Laporkan Masalah"
        />
        <ListItemSecondaryAction>
          <IconButton
            className={classes.iconBtn}
            edge="end"
            disabled
            aria-label="Laporkan Masalah"
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Dialog open={Open}>
        <DialogTitle className={classes.DialogTitle}>
          {"Laporkan Masalah"}
        </DialogTitle>
        <img alt="" src={IgLogo} className={classes.logoIg} />
        <p className={classes.txt}>
          Silahkan tinggalkan pesan di Instagram kami{" "}
          <span className={classes.ig}>@karir.ai_official</span>
        </p>
        <Button onClick={handleLapor} fullWidth className={classes.btnOke}>
          Laporkan Masalah
        </Button>
        <Button onClick={handleClose} fullWidth className={classes.btnNo}>
          Tutup
        </Button>
      </Dialog>
    </div>
  );
}

export default LaporMasalah;
