// React
import React from 'react';
// Styles
import useStyles from './styles';
// Components
import { Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { baseURL } from '../../images/s3';
import StyledBadge from '../../components/Badge';
// Firebase
import { useFirebase } from '../../components/FirebaseProvider';
import { useDocumentData } from 'react-firebase-hooks/firestore';
// Router
import { useHistory } from 'react-router-dom';
// Formatter
import { unixToTime } from '../../utils/formatter';
// 
export default function ChatItem({ chat, index }) {
    // Style
    const classes = useStyles();
    // Router
    const history = useHistory();
    // Firebase
    const { firestore } = useFirebase();
    // Query
    const [profileMitra] = useDocumentData(firestore.doc(`mitra/${chat.mitra_id}`));
    // Functions
    const handleListItemClick = (event, chatId) => {
        if (chat.status === 'active') {
            firestore.doc(`chat/${chatId}`).set({
                siswa_inApp: true
            }, { merge: true })
        }
        history.push(`/myChat/${chatId}`);
    };
    // Check Profile
    const profile = profileMitra || {};
    // Check Last Chat
    const lastChat = chat.last_chat || {};
    // Check Online
    const online = !profile.online ? classes.offline : classes.online;
    // Check Unread
    const unreadCount = chat.siswa_unread_count || 0;
    // Check Typing
    const typing = chat.mitra_typing === true ? 'Mengetik...' : '';
    // Check Session
    const session = chat.status === 'done' ? 'Sesi Chat Telah Berakhir' : '';
    // 
    return (
        <ListItem
            disabled={chat.status === 'canceled'}
            style={index % 2 ? { backgroundColor: "#eeeeee" } : { backgroundColor: "#ffffff" }}
            button
            onClick={event => handleListItemClick(event, chat.id)}
            alignItems="flex-start">
            <ListItemAvatar>
                <StyledBadge
                    overlap="circle"
                    classes={{ badge: online }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    variant="dot"
                >
                    <Avatar alt={profile.nama || chat.nama_mitra} src={profile.photoURL || `${baseURL}pakbudi-logo.png`} />
                </StyledBadge>
            </ListItemAvatar>
            <ListItemText
                className={classes.itemText}
                primary={
                    <React.Fragment>
                        <div className={classes.sender}>
                            <span className={classes.profileName}>
                                {profile && profile.nama ? profile.nama : chat.nama_mitra}
                            </span>
                            <Typography
                                component="span"
                                variant="body2"
                                className={classes.time}
                                color="textPrimary"
                            >
                                {lastChat.created_at ? unixToTime(lastChat.created_at) : unixToTime(chat.updated_at.toMillis())}
                            </Typography>
                            {unreadCount > 0 && <span className={classes.unreadCount}>{unreadCount}</span>}
                        </div>
                    </React.Fragment>
                }
                secondaryTypographyProps={{ noWrap: true, classes: {root: classes.lastMsg} }}
                secondary={` - ${typing || session || lastChat.text || (chat.status === 'canceled' ? 'Dibatalkan' : chat.status === 'waiting-for-accept' ? 'Menunggu persetujuan' : 'Belum Ada Pesan')}`}
            />

        </ListItem>
    )
}