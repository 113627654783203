
import { TextField, Typography } from '@material-ui/core';
import React from 'react';

export default function First({ form, handleChange, error }) {


    return (
        <><Typography style={{ textAlign: 'left', marginBottom: '10px' }}>Nama Panggilan*</Typography>
            <TextField
                fullWidth
                variant="outlined"
                id="nama_panggilan"
                name="nama_panggilan"
                placeholder="Nama Panggilan"
                // className={classes.textField}
                value={form.nama_panggilan}
                onChange={handleChange}
                error={error.nama_panggilan ? true : false}
                helperText={error.nama_panggilan}
            // disabled={isSubmiting}
            />
        </>
    )
}

