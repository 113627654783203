import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    // index
    root: {
        margin: "auto",
        // maxWidth: "630px",
        width: "100%",
        marginBottom: theme.spacing(12)
    },
    item1: {
        padding: '3px 15px 10px 15px',
        backgroundColor: '#F0FFF0',
        borderRadius: '10px',
        textAlign: 'left',
        margin: '0 7px 0 7px'
    },
    item2: {
        padding: '3px 15px 10px 15px',
        backgroundColor: '#FFF8DC',
        borderRadius: '10px',
        textAlign: 'left',
        margin: '10px 7px 0 7px'
    },
    item3: {
        padding: '3px 15px 10px 15px',
        backgroundColor: '#DBE9FA',
        borderRadius: '10px',
        textAlign: 'left',
        margin: '10px 7px 0 7px'
    },
    item4: {
        padding: '3px 15px 10px 15px',
        backgroundColor: '#FFE6E8',
        borderRadius: '10px',
        textAlign: 'left',
        margin: '10px 7px 0 7px'
    }
}));
export default useStyles;
