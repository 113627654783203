import React, { useState } from "react";

import useStyles from "./styles/topUp";

import uniqid from "uniqid";

import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { useFirebase, firestore } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";
import { useCoin } from "../../components/CoinProvider";
import { currency, delimiters } from "../../utils/formatter-rupiah";
import { HFHeader } from "../../components/AppHeaderNew";
import CreditIcon from "../../images/icon-credits";
//import axios from "axios";

function TopUp(props) {

  const classes = useStyles();
  const { user } = useFirebase();
  const { profile } = useSiswa();
  const { promo } = useCoin();

  const [count, setCount] = useState(0);
  const [submit, setSubmit] = useState(true);

  const increment = () => {
    setCount(count + 10);
    // eslint-disable-next-line no-unused-expressions
    window?.mixpanel?.track(" Mycredit->Topup - plus nominal Button Clicked", {
      userId: user?.uid,
      tempUID: user?.tempUID,
    })
    setSubmit(false);
  };
  const decrement = () => {
    setCount(count - 10);
    // eslint-disable-next-line no-unused-expressions
    window?.mixpanel?.track(" Mycredit->Topup - minus nominal Button Clicked", {
      userId: user?.uid,
      tempUID: user?.tempUID,
    })
    if (count <= 100) {
      setCount(0);
      setSubmit(true);
    }
  };

  const bilangan = count * 1000;

  return (
    <div className={classes.root}>
      <HFHeader title="logo" pgDesc={false} noBorder={true} />
      <div className={classes.content}>
        <div>
          <p className={classes.pageTitle}>Top-up Credit</p>
          <TextField
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    className={classes.counterBtn}
                    onClick={increment}
                  >
                    <AddIcon className={classes.counterItem} />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    className={classes.counterBtn}
                    onClick={decrement}
                  >
                    <RemoveIcon className={classes.counterItem} />
                  </IconButton>
                </InputAdornment>
              ),
              disableUnderline: true,
              className: classes.InputProps,
            }}
            inputProps={{
              className: classes.inputProps,
            }}
            value={delimiters(count)}
            disabled={true}
            className={classes.txtField}
          />
          <Button
            fullWidth
            disableElevation
            color="primary"
            disabled={submit}
            className={classes.prcBtn}
            onClick={async () => {
              const noTransaksi = uniqid();
              const docId = noTransaksi;

              await firestore.doc(`transaksi/${docId}`).set(
                {
                  user_id: user?.uid,
                  nama: profile?.nama ?? "",
                  username: profile?.username ?? "",
                  no_hp: profile?.no_hp ?? "",
                  email: profile?.email ?? "",
                  no_transaksi: `${noTransaksi}`,
                  jumlah_coin: count,
                  status: "pending",
                  tanggal: Date.now(),
                  jumlah_transfer: bilangan,
                  doc_id: docId,
                },
                { merge: true }
              );

              // eslint-disable-next-line no-unused-expressions
              window?.mixpanel?.track(" Mycredit->Topup - proses Button Clicked", {
                userId: user?.uid,
                tempUID: user?.tempUID,
              });

              // pixel
              //await axios.get(`https://auto.karir.ai/mtracking.gif?page_title=dashboard_topup&email=${profile?.email}`);
              props.history.push(`/topUpCheckout/${noTransaksi}`);
            }}
            variant="contained"
            size="large"
          >
            Proses ({currency(bilangan)})
          </Button>
        </div>
        <div>
          <div className={classes.dividerpromo} />
          <p className={classes.pageTitle}>Promo!!</p>

          {promo &&
            promo.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  {item.status === true ? (
                    <div className={classes.promoRow}>
                      <Typography className={classes.promoTitle}>
                        <CreditIcon
                          color="secondary"
                          style={{ fontSize: 20 }}
                        />
                        <span>{delimiters(item.nilai_koin)} Credit</span>
                      </Typography>
                      <div>
                        <Typography className={classes.strikeText}>
                          {currency(item.nilai_koin * 1000)}
                        </Typography>
                        <Button
                          disableElevation
                          color="primary"
                          className={classes.promoBtn}
                          onClick={async () => {
                            const noTransaksi = uniqid();
                            const docId = noTransaksi;

                            await firestore.doc(`transaksi/${docId}`).set(
                              {
                                user_id: user?.uid,
                                nama: profile?.nama ?? "",
                                username: profile?.username ?? "",
                                no_hp: profile?.no_hp ?? "",
                                email: profile?.email ?? "",
                                no_transaksi: `${noTransaksi}`,
                                jumlah_coin: item.nilai_koin,
                                status: "konfirmasi",
                                tanggal: Date.now(),
                                jumlah_transfer: item.nilai_rupiah,
                                doc_id: docId,
                              },
                              { merge: true }
                            );
                            // eslint-disable-next-line no-unused-expressions
                            window?.mixpanel?.track(` Mycredit->Topup - promo  ${item?.nilai_koin} Button Clicked`, {
                              userId: user?.uid,
                              tempUID: user?.tempUID,
                            })
                            props.history.push(`/topUpCheckout/${noTransaksi}`);
                          }}
                          variant="contained"
                        >
                          <Typography>
                            {currency(item.nilai_rupiah)}
                          </Typography>
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default TopUp;
