// Date Fns
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";

// React
import React, { useState, useCallback } from "react";

// Styles
import useStyles from "./styles";

// Component
import AppHeader from "../../components/AppHeader";
import {
  functions,
  firestore,
  useFirebase,
} from "../../components/FirebaseProvider";
import AppLoading from "../../components/AppLoading";

// Material UI
import { DateTimePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import Avatar from "@material-ui/core/Avatar";

// Dropzone
import { useDropzone } from "react-dropzone";

// Images
import { baseURL } from "../../images/s3";

// Axios
import axios from "axios";

// React Router Dom
import { useParams, useHistory } from "react-router-dom";

// React Firebase Hooks
import { useDocumentData } from "react-firebase-hooks/firestore";

// Utils
import { currency } from "../../utils/formatter-rupiah";
import LoadingOverlay from "../../components/LoadingOverlay";
import { useSnackbar } from "notistack";

function KonfirmasiPembayaran() {
  const classes = useStyles();

  const history = useHistory();

  const { enqueueSnackbar } = useSnackbar();

  const { eventId, transaksiId } = useParams();

  const { user } = useFirebase();

  const eventDoc = firestore.doc(`events/${eventId}`);

  const transaksiDoc = firestore.doc(`transaksi_event/${transaksiId}`);

  const [event, loadingEvent] = useDocumentData(eventDoc);

  const [transaksi, loadingTransaksi] = useDocumentData(transaksiDoc);

  const [loading, setLoading] = useState(false);

  const [isSubmitting, setIsSubmtting] = useState(false);

  const [photo, setPhoto] = useState("");

  const [form, setForm] = useState({
    atas_nama: "",
    nama_bank: "",
    waktu_pembayaran: null,
  });

  const [error, setError] = useState({
    atas_nama: "",
    nama_bank: "",
    waktu_pembayaran: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((e) => ({
      ...e,
      [name]: "",
    }));
  };

  const validate = () => {
    const newErrors = { ...error };

    const { atas_nama, nama_bank, waktu_pembayaran } = form;

    if (!atas_nama) {
      newErrors.atas_nama = "Atas Nama Wajib Diisi!";
    }

    if (!nama_bank) {
      newErrors.nama_bank = "Rekening Bank Wajib Diisi!";
    }

    if (waktu_pembayaran === null) {
      newErrors.waktu_pembayaran = "Tanggal Pembayaran Wajib Diisi!";
    }

    return newErrors;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");

      reader.onerror = () => console.log("file reading has failed");

      reader.onload = async () => {
        setLoading(true);
        try {
          const generateUploadUrl =
            functions.httpsCallable("generateUploadUrl");

          const result = await generateUploadUrl({
            filename: file.name,
            directory: `images/buktiTransfer/`,
            contentType: file.type,
            id: transaksiId,
          });

          await axios.put(result.data.uploadUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
          });

          setPhoto(result.data.photoUrl);

          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      };

      reader.readAsArrayBuffer(file);
    },
    [transaksiId]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    disabled: loading,
  });

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else if (!photo) {
      enqueueSnackbar("Bukti Pembayaran Wajib Diisi", { variant: "error" });
    } else {
      setIsSubmtting(true);
      try {
        const transaksiEvent = functions.httpsCallable("transaksiEvent");

        await transaksiEvent({
          type: "konfirmasi",
          doc_id: transaksiId,
          url_bukti: photo,
          nama_bank: form?.nama_bank,
          waktu_pembayaran: new Date(form?.waktu_pembayaran).getTime(),
          atas_nama: form?.atas_nama,
        });

        await firestore
          .collection("event_state")
          .doc(`${eventId}_${user?.uid}`)
          .set(
            {
              path: `/acara/${eventId}/proses/${transaksiId}`,
            },
            { merge: true }
          );

        history.push(`/acara/${eventId}/proses/${transaksiId}`);
      } catch (error) {
        console.log(error);
      }
    }
    setIsSubmtting(false);
  };

  if (loadingEvent || loadingTransaksi) {
    return <AppLoading />;
  }

  const rekening = transaksi?.metode_pembayaran;

  return (
    <div className={classes.root2}>
      {isSubmitting && <LoadingOverlay />}
      <div className={classes.blueBg}></div>
      <AppHeader />
      <div className={classes.coinBody}>
        <div style={{ marginTop: 10 }}>
          <h1 className={classes.pageTitle}>Konfirmasi Pembayaran</h1>
          <h3 className={classes.pageSubtitle1}>
            Acara {event?.nama ?? "-"} <br /> Tryout Nasional SBMPTN
          </h3>
          <p style={{ paddingTop: 10, color: "#000", fontWeight: "bolder" }}>
            Harap lakukan pembayaran ke
          </p>

          <div>
            <img
              src={`${baseURL}img-payment-${rekening?.nama_bank?.toLowerCase()}.png`}
              alt=""
            />
            <ul className={classes.infoList}>
              <li>
                Nama Bank : <strong>{rekening?.nama_bank ?? "-"}</strong>
              </li>
              <li>
                No. Rekening : <strong>{rekening?.no_rekening ?? "-"}</strong>
              </li>
              <li>
                Atas Nama : <strong>{rekening?.atas_nama ?? "-"}</strong>
              </li>
              <li>
                Jumlah :{" "}
                <strong>{currency(transaksi?.total_pembayaran ?? 0)}</strong>
              </li>
            </ul>
          </div>
          <p style={{ fontSize: 10 }}>
            Jika sudah melakukan pembayaran, Silahkan upload / unggah foto bukti
            transfer kamu untuk konfirmasi pembayaran.
            <br />
            konfirmasi pembayaran disini, atau bisa cek email yang telah kami
            kirimkan
          </p>
        </div>
        <Container maxWidth="sm">
          {!photo ? (
            <div {...getRootProps()} className={classes.thumbPLayout}>
              <input {...getInputProps()} />
              <div className={classes.gridCaption}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {/* Saat loading upload gambar */}
                  {loading ? (
                    <CircularProgress
                      style={{
                        margin: "auto",
                      }}
                    />
                  ) : (
                    <>
                      <ImageOutlinedIcon
                        color="primary"
                        style={{
                          margin: "auto",
                          marginBottom: 10,
                          fontSize: 50,
                        }}
                      />
                      <Typography variant="caption" color="primary">
                        Upload Bukti Transfer Kamu
                      </Typography>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className={classes.thumbPLayout}>
              <Button
                classes={{ root: classes.btnroot }}
                className={classes.btnClose}
                onClick={() => {
                  setPhoto("");
                }}
                disabled={isSubmitting}
              >
                <CloseIcon
                  className={classes.iconClose}
                  color={isSubmitting ? "disabled" : "error"}
                />
              </Button>
              <Avatar
                variant="square"
                className={classes.thumbPreview}
                src={photo}
              />
            </div>
          )}

          <div style={{ marginTop: 30 }}>
            <div className={classes.itemTf}>
              <TextField
                name="atas_nama"
                fullWidth
                label="Saya transfer atas nama"
                size="small"
                onChange={handleChange}
                value={form?.atas_nama}
                error={error?.atas_nama ? true : false}
                helperText={error?.atas_nama}
                disabled={isSubmitting}
              />
            </div>
            <div className={classes.itemTf}>
              <TextField
                name="nama_bank"
                fullWidth
                label="Menggunakan rekening bank"
                size="small"
                onChange={handleChange}
                value={form?.nama_bank}
                error={error?.nama_bank ? true : false}
                helperText={error?.nama_bank}
                disabled={isSubmitting}
              />
            </div>
            <div className={classes.itemTf}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  autoOk
                  ampm={false}
                  disableFuture
                  value={form.waktu_pembayaran}
                  onChange={(v) => {
                    setForm((f) => ({
                      ...f,
                      waktu_pembayaran: v,
                    }));

                    setError((e) => ({
                      ...e,
                      waktu_pembayaran: "",
                    }));
                  }}
                  label="Pada tanggal"
                  format="dd MMMM yyyy, HH:mm"
                  fullWidth
                  error={error?.waktu_pembayaran ? true : false}
                  helperText={error?.waktu_pembayaran}
                  disabled={isSubmitting}
                />
              </MuiPickersUtilsProvider>
            </div>
            <Button
              className={classes.btn}
              style={{ width: "auto" }}
              variant="contained"
              color="primary"
              size="large"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Konfirmasi Pembayaran
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default KonfirmasiPembayaran;
