// React
import React from "react";

// Router
import { Route, Switch } from "react-router-dom";

// Styles
import useStyles from "./styles";

// Components
import BottomNav from "../../components/BottomNav";
import EditProfile from "../settings/editProfile";
import ChangeEmailPassword from "../settings/changeEmailPassword";
import Vouchers from "../settings/vouchers";
import SetelanNotifikasi from "../settings/notifikasi";
import Setelan from "../settings/";
import UserEventIntro from "../acara/userIntro";
import LengkapiPendaftaran from "../acara/lengkapiPendaftaran";
import UserEticket from "../acara/userEticket";
import FormPembayaran from "../acara/formPembayaran";
import KonfirmasiPembayaran from "../acara/konfirmasiPembayaran";
import Misi from "../misi";
import ListMisi from "../misi/listMisi";
import KonfirmasiMisi from '../misi/konfirmasiMisi';
import MyCredit from "../myCredit";
import TopUpHistory from "../myCredit/history";
// import Berita from "../berita";
import BeritaDetail from "../berita/beritaDetail";
import Notifikasi from "../notifikasi";
import TopUp from "../myCredit/topUp";
import Bagikan from "../Bagikan";
import PBLeaderboard from "../leaderboard/";
import { Hidden } from "@material-ui/core";

function LayoutC() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.appBody}>
        <Switch>
          <Route path="/settings" component={Setelan} />
          <Route path="/editProfile" component={EditProfile} />
          <Route path="/editProfile" component={EditProfile} />
          <Route path="/notifikasisettings" component={SetelanNotifikasi} />
          <Route path="/ubahemaildanpassword" component={ChangeEmailPassword} />
          <Route path="/vouchers" component={Vouchers} />
          <Route path="/misi" component={Misi} />
          <Route path="/listmisi" component={ListMisi} />
          <Route path="/konfirmasimisi/:id" component={KonfirmasiMisi} />
          <Route path="/myCredit" component={MyCredit} />
          <Route path="/topUp" component={TopUp} />
          <Route path="/topUpHistory" component={TopUpHistory} />
          <Route path="/leaderboard/:to_id" component={PBLeaderboard} />
          {/* <Route path="/article" component={Berita} /> */}
          <Route path="/article/:id" component={BeritaDetail} />
          <Route path="/bagikan" component={Bagikan} />
          <Route path="/notifikasi" component={Notifikasi} />
        </Switch>
      </div>
      <div
        className={classes.appBody}
        style={{ width: "100%", paddingBottom: 40 }}
      >
        <Switch>
          <Route exact path="/acara/u/:eventId" component={UserEventIntro} />
          <Route
            path="/acara/u/:eventId/daftar"
            render={() => <LengkapiPendaftaran register={true} />}
          />
          <Route
            path="/acara/u/:eventId/pembayaran/:transaksiId"
            render={() => <FormPembayaran auth={true} />}
          />
          <Route
            path="/acara/u/:eventId/konfirmasi/:transaksiId"
            component={KonfirmasiPembayaran}
          />
          <Route
            path="/acara/u/:eventId/lengkapi-data/:pesertaId"
            component={LengkapiPendaftaran}
          />
          <Route
            path="/acara/ticket/:eventId/:pesertaId"
            component={UserEticket}
          />
        </Switch>
      </div>
      <Hidden mdUp><BottomNav /></Hidden>
    </div>
  );
}

export default LayoutC;
