let firebaseConfig = {
  apiKey: "AIzaSyBbCihMaSG08HJGitr6VJJAd5MBjxAU4yc",
  authDomain: "karirai-dev.firebaseapp.com",
  databaseURL:
    "https://karirai-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "karirai-dev",
  storageBucket: "karirai-dev.appspot.com",
  messagingSenderId: "934338373927",
  appId: "1:934338373927:web:24bbf2e122a03ee17c2102",
  measurementId: "G-8SRFB8V0K2",
};

let vapidKey =
  "BM8NPfCLZgphfsUM_suCU5mbSlSMG4Fl7wGZ755t8UQePvYteJM9-dMW7Qp_5HGQoT9Jy7DeYNwhckac9SmfVpc";

if (process.env.REACT_APP_FIREBASE_PROD === "YES") {
  firebaseConfig = {
    apiKey: "AIzaSyCfWPv9PvB8fgxdes2pMiiDvR8l4EZJJXs",
    authDomain: "karirai-prod.firebaseapp.com",
    projectId: "karirai-prod",
    storageBucket: "karirai-prod.appspot.com",
    messagingSenderId: "498302587148",
    appId: "1:498302587148:web:6b9242bbe2bdcc5915f929",
    measurementId: "G-R4XHCBWRVW"
  };
  vapidKey =
    "BOrLhwd9jJ8E7O-fZGXr6oVsS4SrQosF_dPmTrm3yxkXwVzH802jJ4OHYGrLocdi10yuJX961j1a0YT3loMZtLg";
}

export const publicVapidKey = vapidKey;

export default firebaseConfig;
