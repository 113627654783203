import React, { useState } from "react";

// Input
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";

// styles
import useStyles from "./styles";

// Router
import { Redirect } from "react-router-dom";

// Firebase & Profile Hooks
import { useFirebase } from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";

// import { baseURL } from "../../images/s3";

import img1 from "../../images/illustration-job.png";
import img2 from "../../images/illustration-magang.png";
import img3 from "../../images/illustration-mission.png";
import img4 from "../../images/illustration-move.png";
import img5 from "../../images/illustration-cvs.png";
import img6 from "../../images/illustration-search.png";

function Harapan() {
  // Firebase Use
  const { user, firestore } = useFirebase();

  // Profile Use
  const { profile } = useSiswa();

  // useStyles
  const classes = useStyles();

  const [isSubmiting, setSubmiting] = useState(true);

  const [state, setState] = useState({
    checkedA: "",
    checkedB: "",
    checkedC: "",
    checkedD: "",
    checkedE: "",
    checkedF: "",
  });

  // OnChange
  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    if (event.target.checked === true) {
      setSubmiting(false);
    } else {
      setSubmiting(true);
    }
  };

  // OnSubmit
  const handleSubmit = (e) => {
    e.preventDefault();

    const arr = [];
    for (const key in state) {
      if (state[key] === true) {
        arr.push(key);
      }
    }
    const data = arr.toString();
    try {
      setSubmiting(true);
      const date = new Date();
      const ref = firestore.doc(`users/${user.uid}`);
      ref.set(
        {
          harapan: data,
          diperbarui_pada: date,
        },
        { merge: true }
      );
      setSubmiting(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Condition if the user has filled out the harapan form
  if (profile && profile.harapan) {
    return <Redirect to="/home" />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.pageHeading}>
        {/* <h2 className={classes.pageTitle}>Halo, {profile.fullName}</h2> */}
        <h2 className={classes.pageTitle}>Selamat datang!</h2>
        <p className={classes.pageSubtitle}>
          Apa harapanmu dengan menggunakan aplikasi ini?
        </p>
      </div>
      <form onSubmit={handleSubmit} style={{ padding: "0px 16px" }}>
        <FormGroup
          aria-label="position"
          name="position"
          onChange={handleChange}
          row
        >
          <div className={classes.choicesWrap}>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Dapat kerja")}
                    icon={
                      <img src={img1} className={classes.choiceImg} alt="" />
                    }
                    checkedIcon={
                      <img
                        src={img1}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Dapat kerja"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Dapat tempat magang")}
                    icon={
                      <img src={img2} className={classes.choiceImg} alt="" />
                    }
                    checkedIcon={
                      <img
                        src={img2}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Dapat tempat magang"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Meningkatkan skill kerja")}
                    icon={
                      <img src={img3} className={classes.choiceImg} alt="" />
                    }
                    checkedIcon={
                      <img
                        src={img3}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Meningkatkan skill kerja"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Pindah jalur karir")}
                    icon={
                      <img src={img4} className={classes.choiceImg} alt="" />
                    }
                    checkedIcon={
                      <img
                        src={img4}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Pindah jalur karir"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Bikin smart CV")}
                    icon={
                      <img src={img5} className={classes.choiceImg} alt="" />
                    }
                    checkedIcon={
                      <img
                        src={img5}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Bikin smart CV"
                labelPlacement="bottom"
              />
            </div>
            <div className={classes.choiceBlock}>
              <FormControlLabel
                classes={{ label: classes.labelle }}
                control={
                  <Checkbox
                    onChange={handleChange("Cek profil karir & kepribadian")}
                    icon={
                      <img src={img6} className={classes.choiceImg} alt="" />
                    }
                    checkedIcon={
                      <img
                        src={img6}
                        className={classes.choiceImgChecked}
                        alt=""
                      />
                    }
                  />
                }
                label="Cek profil karir & kepribadian"
                labelPlacement="bottom"
              />
            </div>
          </div>
        </FormGroup>
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          fullWidth
          disabled={isSubmiting}
          style={{ maxWidth: 500 }}
        >
          Pilih
        </Button>
      </form>
    </div>
  );
}

export default Harapan;
