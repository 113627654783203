import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.textColor.blue2,
        height: '100vh',
        overflow: 'auto',
        // paddingBottom: 120,
    },
    container:{
        padding: '0 !important'
    },
    hasilTestBody:{
        width: '80%',
        margin: 'auto',
        '@media(min-width: 400px)': {
            width: '70%',
        },
        '@media(min-width: 500px)': {
            width: '65%',
        },
        '@media(min-width: 600px)': {
            width: '60%',
        },
        '@media(min-width: 700px)': {
            width: '50%',
        },
    },
    hasilTestHead:{
        padding: '10px 0',
    },
    logo: {
        width: 164,
        height: 'auto',
    },
    resultTitle:{
        color: theme.palette.secondary.main,
        fontSize: 40,
        margin: 0,
        lineHeight: '40px',
        textDecoration: 'underline',
    },
    resultSubtitle:{
        color: '#000',
        fontSize: 18,
        margin: '10px 0',
    },
    resultImgWrap:{
        width: 180,
        height: 180,
        overflow: 'hidden',
        borderRadius: '100%',
        border: 'solid 7px $white',
        padding: 5,
        display: 'block',
        margin: '13px auto',
        position: 'relative',
        zIndex: 2,
        background: 'linear-gradient(to bottom, #a4b357 0%,#75890c 100%)',
        '&:before':{
            content: '""',
            position: 'absolute',
            zIndex: -1,
            top: -2,
            left: -2,
            right: -2,
            bottom: -2,
            borderRadius: 100,
            border: '7px solid #fff',
            }
    },
    resultImg:{
        width: '100%',
        height: 'auto'
    },
    whiteText:{
        color: '#fff',
        fontSize: 12,
        fontWeight: 700,
        margin: '0 0 5px',
    },
    blackText:{
        color: '#000',
        fontSize: 12,
        fontWeight: 700,
        margin: '0 0 5px',
    },
    announcePerson:{
        backgroundColor: '#fff',
        padding: 7,
        textAlign: 'center',
        width: '85%',
        margin: '10px auto 10px',
        borderRadius: 8,
        textTransform: 'capitalize',
    },
    detailWrap:{
        margin: '20px 0',
    },
    heading:{
        fontSize: 14,
        textAlign: 'left',
        fontWeight: 'bold',
    },
    panelDetailList:{
        fontSize: 12,
        textAlign: 'left',
        paddingLeft: 15,
        paddingRight: 15,
    },
    panelDetail:{
        fontSize: 12,
        textAlign: 'left',
        paddingLeft: 10,
        paddingRight: 10,
    },
    panelDetailCapital:{
        textTransform: 'capitalize',
    },
    startBtn: {
        marginBottom: 20,
        textTransform: 'capitalize'
    }
}))

export default useStyles;