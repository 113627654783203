import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
// import Typography from '@material-ui/core/Typography';
import useStyles from "./styles/timeoutDialog";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";

export default function ReminderDialog({
    open,
    handleClose,
    handleClickOk,
    handleClickNo,
    harga,
    isLoading,
    name,
    durasi
}) {
    const classes = useStyles();
    if (!harga) {
        return null;
    }
    return (
        //
        // dialog muncul saat timer < 5 menit
        //
        // <Dialog
        //     disableBackdropClick
        //     disableEscapeKeyDown
        //     open={open}
        //     onClose={handleClose}
        // >
        //     {/* <DialogTitle className={classes.DialogTitle}>{"Lanjutkan Sesi"}</DialogTitle> */}
        //     <AccessAlarmIcon className={classes.warningTimer} />
        //     <p className={classes.timeoutTxt}>Waktu konsultasi kamu <strong>kurang dari 5 menit</strong> lagi, tambah durasi konsultasi?</p>
        //     {isLoading && <p className={classes.txt}>Extending...</p>}
        //     <DialogActions className={classes.dialogActionEnd}>
        //         <Button
        //         onClick={handleClickNo}
        //         className={classes.btnStop}
        //         disabled={isLoading}
        //         >
        //             Tidak
        //         </Button>
        //         <Button
        //             onClick={handleClickOk}
        //             className={classes.btnExtend}
        //             disabled={isLoading}
        //         >
        //             Tambah ({harga} Coin)
        //         </Button>
        //     </DialogActions>
        // </Dialog>
        // end timer dialog
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
        >
            {/* <DialogTitle className={classes.DialogTitle}>{"Lanjutkan Sesi"}</DialogTitle> */}
            <AccessAlarmIcon className={classes.dangerTimer} style={{ color: '#ffbb38' }} />
            <p className={classes.timeoutTxt}>
                Waktu konsultasi kamu <strong>kurang dari 1 menit</strong> lagi, tambah durasi konsultasi?{" "}
            </p>
            {/* <p className={classes.txt}>{harga} Coin/{durasi} Menit</p> */}
            {isLoading && <p className={classes.txt}>Extending...</p>}
            <DialogActions className={classes.dialogActionEnd}>
                <Button
                    onClick={handleClickNo}
                    className={classes.btnStop}
                    disabled={isLoading}
                >
                    Tidak
                </Button>
                <Button
                    onClick={handleClickOk}
                    className={classes.btnExtend}
                    disabled={isLoading}
                >
                    Tambah ({harga} Coin)
                </Button>
            </DialogActions>
        </Dialog>
    );
}
