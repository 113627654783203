//
import React, { useState, useEffect } from "react";

//
import useStyles from "./styles";

//
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

//
// import AppHeader from "../../components/AppHeader";
import DialogMisi from "./dialogMisi";
import DialogStatus from "./dialogStatus";

//
import { baseURL } from "../../images/s3";
import { useCoin } from "../../components/CoinProvider";
import {
  firestore,
  functions,
  useFirebase,
} from "../../components/FirebaseProvider";
import { useSiswa } from "../../components/SiswaProvider";
import { useHistory } from "react-router-dom";
// import imgMisi from "../../images/misi-default.png";
import imgMisi from "../../images/illustration-mission.png";
// import liveIG from '../../images/misi-live.png';
// import misiWa from '../../images/misi-wa.png';
// import shareIG from '../../images/misi-ig.png';
// import sembako from '../../images/misi-sembako.png';
import misiOrder from "../../utils/misiOrder";
import { HFHeader } from "../../components/AppHeaderNew";

function Misi(props) {
  //
  const classes = useStyles();
  //
  const history = useHistory();
  //
  const { misi, listMisi, misiDefault } = useCoin();
  const { profile } = useSiswa();
  const { user } = useFirebase();
  //
  const [dialog, setDialog] = useState({
    open: false,
    data: [],
    mode: "Kerjakan",
  });
  //
  const [dialogStatus, setDialogStatus] = useState({
    open: false,
    type: "success",
    coin: 0,
    message: "",
  });
  //
  const [List, setList] = useState([]);
  //
  useEffect(() => {
    if (misi) {
      const listDoc = listMisi || [];
      setList(
        misi.map((doc) => {
          const list = listDoc.find((list) => list.misi.id === doc.id);
          return {
            ...doc,
            sort:
              list === undefined
                ? 1
                : list.status === "review" ||
                  list.status === "pending" ||
                  list.status === "rejected"
                  ? 2
                  : 3,
            misi: list,
          };
        })
      );
    }
  }, [listMisi, misi]);
  //
  const handleClick = (item, mode = "Kerjakan") => {
    setDialog({
      open: true,
      data: item,
      mode,
    });
  };
  //
  const handleOk = async () => {
    console.log("diaglog data", dialog.data);

    try {
      const newData = {
        user: {
          nama: profile && profile.nama,
          email: profile && profile.email,
        },
        misi: {
          id: dialog.data.id,
          content: dialog.data.content,
          judul: dialog.data.judul,
          url: dialog.data.url ?? "",
        },
        user_id: user.uid,
        tanggal: Date.now(),
        jumlah_coin: dialog.data.reward,
        photoURL: "",
        status: "pending",
      };
      let id;
      if (dialog.mode === "Coba Lagi") {
        await firestore
          .collection("misi")
          .doc(dialog.data.misi.id)
          .set(newData);
        id = dialog.data.misi.id;
      } else {
        const db = await firestore.collection("misi").add(newData);
        id = db.id;
      }
      // eslint-disable-next-line no-unused-expressions
      window?.mixpanel?.track(" Misi - Kerjakan Button Clicked", {
        userId: user?.uid,
        tempUID: user?.tempUID,
      })
      //
      history.push(`/konfirmasimisi/${id}`);
      //
    } catch (error) {
      console.log(error.message);
    }
  };
  //

  // function claim coin

  const claimCoin = async (item) => {
    const claimMisi = functions.httpsCallable("credit-claimMisi");

    try {
      await claimMisi({ misi_id: item.misi?.id });
      // if (result.result.status === 'success')
      // eslint-disable-next-line no-unused-expressions
      window?.mixpanel?.track(" Misi - Claim Button Clicked", {
        userId: user?.uid,
        tempUID: user?.tempUID,
      })
      setDialogStatus({ open: true, type: "success", coin: item.reward });
    } catch (e) { }
  };

  const handleMisi = () => {
    props.history.push("/listmisi");
  };

  const newList = misiOrder(misiDefault, List);

  return (
    <div className={classes.root}>
      <HFHeader
        title="logo"
        desc="Kerjakan misi misi berikut dan dapatkan reward kredit"
        image="mission"
      />
      <Button
        variant="outlined"
        className={classes.btnListMisi}
        onClick={handleMisi}
      >
        Daftar Misi
      </Button>
      {/* <div className={classes.missionHead}>
        <AppHeader />
        <h1 className={classes.missionTitle}>
          Ayo selesaikan misinya dan dapatkan hadiah koin gratis!
        </h1>
        <div className={classes.btnListMisiCon}>
          
        </div>
      </div> */}
      <div className={classes.Body}>
        {newList?.map((item, index) => {
          let Img = imgMisi;

          // switch (item.category_misi) {
          //     case 'live-IG':
          //         Img = liveIG;
          //         break;
          //     case 'share-IG':
          //         Img = shareIG;
          //         break;
          //     case 'sembako':
          //         Img = sembako;
          //         break;
          //     case 'share-WA':
          //         Img = misiWa;
          //         break;
          //     default:
          //         Img = imgMisi;
          //         break;
          // }

          if (item?.status_misi === "active" && !item?.misi) {
            return (
              <div key={item.id}>
                <div className={classes.missionBox}>
                  <div className={classes.misiThumb}>
                    <img style={{ width: "55px", filter: "grayscale(100%)", opacity: "40%" }} alt="img" src={`${Img}`} />
                  </div>
                  <div className={classes.misiDetail}>
                    <Typography className={classes.misiTitle}>
                      {item.judul}
                    </Typography>
                    <Typography className={classes.misiSubtitle}>
                      Dapat{" "}
                      <img
                        src={`${baseURL}icon-coin.png`}
                        className={classes.imgCoin}
                        alt=""
                      />{" "}
                      {item.reward} Kredit
                    </Typography>
                    <Button
                      classes={{ label: classes.labelBtn }}
                      className={classes.goBtn}
                      fullWidth
                      onClick={() => handleClick(item)}
                    >
                      Kerjakan
                    </Button>
                  </div>
                </div>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "pending"
          ) {
            return (
              <div key={item.id}>
                <Button
                  className={classes.missionDone}
                  fullWidth
                  onClick={() =>
                    history.push(`/konfirmasimisi/${item.misi.id}`)
                  }
                >
                  <div className={classes.doneMsg}>
                    <span>Misi Belum Selesai</span>
                  </div>
                  <Button
                    classes={{ label: classes.labelBtn }}
                    className={classes.promoBtn}
                    fullWidth
                    disabled
                  >
                    <div className={classes.misiThumb}>
                      <img style={{ width: "55px", filter: "grayscale(100%)", opacity: "40%" }} alt="img" src={`${Img}`} />
                    </div>
                    <div className={classes.misiDetail}>
                      <Typography className={classes.misiTitle}>
                        {item.judul}
                      </Typography>
                      <Typography className={classes.misiSubtitle}>
                        Dapat{" "}
                        <img
                          src={`${baseURL}icon-coin.png`}
                          className={classes.imgCoin}
                          alt=""
                        />{" "}
                        {item.reward} Kredit
                      </Typography>
                      <Button
                        classes={{
                          label: classes.labelBtn,
                          disabled: classes.btnDisabled,
                        }}
                        className={classes.goBtn}
                        fullWidth
                        disabled
                      >
                        Kerjakan
                      </Button>
                    </div>
                  </Button>
                </Button>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "review"
          ) {
            return (
              <div key={item.id}>
                <Button
                  className={classes.missionBoxWrap}
                  fullWidth
                  onClick={() =>
                    setDialogStatus({ open: true, type: "review" })
                  }
                >
                  {/* <div className={classes.doneMsg}><span>Misi Menunggu Verifikasi</span></div> */}
                  <div
                    className={`${classes.missionBox} ${classes.missionBoxVerify}`}
                  >
                    <div className={classes.misiThumb}>
                      <img style={{ width: "55px", filter: "grayscale(100%)", opacity: "40%" }} alt="img" src={`${Img}`} />
                    </div>
                    <div className={classes.misiDetailVerify}>
                      <div className={classes.misiDetailLeft}>
                        <Typography className={classes.misiTitle}>
                          {item.judul}
                        </Typography>
                        <Typography className={classes.misiSubtitle}>
                          Dapat{" "}
                          <img
                            src={`${baseURL}icon-coin.png`}
                            className={classes.imgCoin}
                            alt=""
                          />{" "}
                          {item.reward} Kredit
                        </Typography>
                      </div>
                      {/* <Button
                                                    classes={{ label: classes.labelBtn }}
                                                    className={classes.promoBtn}
                                                    fullWidth
                                                    disabled
                                                > 
                                                    
                                                </Button> */}
                      <div className={classes.missionStatus}>
                        <img
                          src={`${baseURL}img-hourglass.png`}
                          className={classes.imgHg}
                          alt=""
                        />
                        <span className={classes.statusVerify}>
                          Menunggu Verifikasi
                        </span>
                      </div>
                    </div>
                  </div>
                </Button>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "pending-valid"
          ) {
            return (
              <div key={item.id}>
                <div className={classes.missionBox}>
                  <div className={classes.misiThumb}>
                    <img style={{ width: "55px", filter: "grayscale(100%)", opacity: "40%" }} alt="img" src={`${Img}`} />
                  </div>
                  <div className={classes.misiDetail}>
                    <Typography className={classes.misiTitle}>
                      {item.judul}
                    </Typography>
                    <Typography className={classes.misiSubtitle}>
                      Dapat{" "}
                      <img
                        src={`${baseURL}icon-coin.png`}
                        className={classes.imgCoin}
                        alt=""
                      />{" "}
                      {item.reward} Kredit
                    </Typography>
                    <Button
                      classes={{ label: classes.labelBtn }}
                      className={classes.claimBtn}
                      fullWidth
                      onClick={() => claimCoin(item)}
                    >
                      Claim Coin
                    </Button>
                  </div>
                </div>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "valid"
          ) {
            return (
              <div key={item.id}>
                <Button className={classes.missionDone} fullWidth disabled>
                  <div className={classes.doneMsg}>
                    <span>Misi Sudah Dikerjakan</span>
                  </div>
                  <Button
                    classes={{ label: classes.labelBtn }}
                    className={classes.promoBtn}
                    fullWidth
                    disabled
                  >
                    <div className={classes.misiThumb}>
                      <img style={{ width: "55px", filter: "grayscale(100%)", opacity: "40%" }} alt="img" src={`${Img}`} />
                    </div>
                    <div className={classes.misiDetail}>
                      <Typography className={classes.misiTitle}>
                        {item.judul}
                      </Typography>
                      <Typography className={classes.misiSubtitle}>
                        Dapat{" "}
                        <img
                          src={`${baseURL}icon-coin.png`}
                          className={classes.imgCoin}
                          alt=""
                        />{" "}
                        {item.reward} Kredit
                      </Typography>
                      <Button
                        className={classes.claimBtn}
                        classes={{ disabled: classes.btnDisabled }}
                        fullWidth
                        disabled
                        onClick={() =>
                          setDialogStatus({
                            open: true,
                            type: "success",
                            coin: item.reward,
                          })
                        }
                      >
                        Claim Coin
                      </Button>
                    </div>
                  </Button>
                </Button>
              </div>
            );
          }

          if (
            item?.status_misi === "active" &&
            item?.misi?.status === "rejected"
          ) {
            return (
              <div key={item.id}>
                <div
                  className={`${classes.missionBox} ${classes.missionBoxFailed}`}
                >
                  <div className={classes.misiThumb}>
                    <img style={{ width: "55px", filter: "grayscale(100%)", opacity: "40%" }} alt="img" src={`${Img}`} />
                  </div>
                  <div className={classes.misiDetail}>
                    <Typography className={classes.misiTitle}>
                      {item.judul}
                    </Typography>
                    <Typography className={classes.misiSubtitle}>
                      Dapat{" "}
                      <img
                        src={`${baseURL}icon-coin.png`}
                        className={classes.imgCoin}
                        alt=""
                      />{" "}
                      {item.reward} Kredit
                    </Typography>
                  </div>
                  <Button
                    className={classes.retryBtn}
                    fullWidth
                    onClick={() => handleClick(item, "Coba Lagi")}
                  >
                    Coba Lagi
                  </Button>
                </div>
              </div>
            );
          }
          return null;
        })}
      </div>
      <DialogMisi
        {...dialog}
        handleOk={handleOk}
        handleClose={() => {
          setDialog({
            open: false,
          });
        }}
      />
      <DialogStatus
        {...dialogStatus}
        handleOk={() => setDialogStatus({ open: false })}
      />
    </div>
  );
}

export default Misi;
