import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Route, Switch } from "react-router-dom";
import { firestore, useFirebase } from "../../components/FirebaseProvider";
import NotFound from "../notFound";
import ContentCourse from "./content";
import ListCourse from "./listCourse";



function Course() {

  // const [okDialog, setOkDialog] = useState(false);
  const { user } = useFirebase()
  // get hasil course
  const hasilCourseQuery = firestore.collection(`hasil_course`).where('user_id', '==', user?.uid || '');
  const [dataHasilCourse,] = useCollectionData(hasilCourseQuery);
  // console.log(dataHasilCourse)
  return (
    <>
      <Switch>
        {
          dataHasilCourse?.[0]?.status === 'purchased' &&
          <Route path="/course/content/:contentId" component={ContentCourse} />

        }
        <Route path="/course" component={ListCourse} />
        <Route component={NotFound} />


        {/* <Redirect to="/course" /> */}
      </Switch>

    </>
  );
}

export default Course;
