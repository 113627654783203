import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: 60,
    marginBottom: 100,
  },
  listBody: {
    background: "#fff",
    position: "relative",
    zIndex: 2,
    margin: "0 auto 50px",
    minHeight: 400,
    padding: 10,
    borderRadius: 8,
    "@media(min-width: 320px)": {
      width: "90%",
    },
    "@media(min-width: 960px)": {
      width: "70%",
    },
  },
  pageHeading: {
    padding: "5px 0",
  },
  pageTitle: {
    color: theme.palette.neutrals.n80,
    fontWeight: "bold",
    fontSize: 20,
    margin: "20px 0px",
  },
  tableWrap: {
    marginBottom: 30,
  },
  TableHead: {
    margin: "auto",
    display: "flex",
    justifyContent: "space-around",
    "@media(min-width: 320px)": {
      width: "100%",
    },
    "@media(min-width: 960px)": {
      width: 400,
    },
  },
  tableCell1: {
    color: theme.palette.primary.main,
    fontSize: 11,
    width: 96,
    margin: "10px 0",
  },
  tableCell: {
    color: theme.palette.primary.main,
    fontSize: 11,
    width: 65,
    margin: "10px auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  tableCellCoin: {
    color: theme.palette.primary.main,
    fontSize: 11,
    width: 40,
    margin: "10px 0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  TableBody: {
    margin: "auto",
    "@media(min-width: 320px)": {
      width: "100%",
    },
    "@media(min-width: 960px)": {
      width: 400,
    },
  },
  TableRow: {
    display: "flex",
    margin: "auto",
    "@media(min-width: 320px)": {
      width: "100%",
      justifyContent: "flex-start",
    },
    "@media(min-width: 360px)": {
      justifyContent: "space-between",
    },
    "@media(min-width: 412px)": {
      width: "100%",
    },
    "@media(min-width: 480px)": {
      width: "94%",
    },
    padding: "5px 0",
    borderBottom: "solid 1px" + theme.palette.textColor.gray6,
  },
  TRow: {
    color: theme.palette.textColor.gray,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(min-width: 360px)": {
      fontSize: 12,
      marginLeft: 10,
    },
  },
  TRowID: {
    width: "35%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  TRowDate: {
    textAlign: "center",
  },
  TRowCoin: {
    color: theme.palette.textColor.gray,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(min-width: 320px)": {
      fontSize: 13,
      width: 40,
    },
    "@media(min-width: 360px)": {
      fontSize: 12,
      width: 75,
      marginLeft: 10,
    },
  },
  itemStatus: {
    marginTop: 10,
  },
  statusFalse: {
    color: theme.palette.orange.main,
    fontSize: 12,
    width: 85,
  },
  statusTrue: {
    color: theme.palette.textColor.gray,
    fontSize: 12,
    width: 85,
  },
}))

export default useStyles;