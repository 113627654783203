
export default async function share(shareData) {
    //console.log(shareData, navigator)
    if (navigator.canShare && navigator.canShare({ files: shareData.files })) {
        await navigator.share(shareData);
    } else {
        throw new Error('Your browser doesn\'t support sharing files.');
    }
}


export const shareURL = async function (data) {
    if (navigator.canShare) {
        await navigator.share(data);
    } else {
        throw new Error('Your browser doesn\'t support sharing.');
    }
}


export const getFirstImage = function (htmlstr) {

    const elem = document.createElement('div');

    elem.style.display = 'none';
    document.body.appendChild(elem);
    elem.innerHTML = htmlstr;
    const img = elem.querySelector('img')
    if (img) {
        return img.src
    }

    return null

}