import React from "react";
import useStyles from "./styles/index";
import { withRouter, Link } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
// import Badge from "@material-ui/core/Badge";
// import NotificationsOutlinedIcon from "@material-ui/icons/NotificationsOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { useNotifikasi } from "../NotifikasiProvider";
import DashboardIcon from "../../images/icon-dashboard";
// import PinIcon from "../../images/icon-pin";
import CreditIcon from "../../images/icon-credits";
import PeopleIcon from "../../images/icon-people";

function BottomNav(props) {
  const classes = useStyles();

  const { location } = props;

  const { notifItems } = useNotifikasi();

  let unreadCount = null;

  if (notifItems) {
    const findunreadCount = notifItems.filter((item) => !item.read);
    if (findunreadCount) {
      unreadCount = findunreadCount.length;
    }
    if (unreadCount <= 0) {
      unreadCount = null;
    } else if (unreadCount > 10) {
      unreadCount = "10+";
    }
  }

  // const notifikasi = (
  //   <Badge badgeContent={unreadCount} color="primary">
  //     <NotificationsOutlinedIcon />
  //   </Badge>
  // );

  return (
    <div className={classes.root}>
      <BottomNavigation value={location.pathname} className={classes.botNav}>
        <BottomNavigationAction
          showLabel
          classes={{ label: classes.label }}
          className={classes.botNavAct}
          label="Dashboard"
          icon={<DashboardIcon />}
          component={Link}
          to="/home"
          value="/home"
        />
        <BottomNavigationAction
          showLabel
          classes={{ label: classes.label }}
          className={classes.botNavAct}
          label="Ajak Teman"
          icon={<PeopleIcon />}
          component={Link}
          to="/ajakTeman"
          value="/ajakTeman"
        />
        <BottomNavigationAction
          showLabel
          classes={{ label: classes.label }}
          className={classes.botNavAct}
          label="Kredit Saya"
          icon={<CreditIcon />}
          component={Link}
          to="/myCredit"
          value="/myCredit"
        />
        <BottomNavigationAction
          showLabel
          classes={{ label: classes.label }}
          className={classes.botNavAct}
          label="Setelan"
          icon={<SettingsOutlinedIcon viewBox="2 2 20 20" />}
          component={Link}
          to="/settings"
          value="/settings"
        />
      </BottomNavigation>
    </div>
  );
}

export default withRouter(BottomNav);
