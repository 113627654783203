import React from "react";

import { useCollectionData } from "react-firebase-hooks/firestore";

import useStyles from "./styles/history";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import { formatDate } from "../../utils/formatter";
import Notransaction from "../../images/illustration-tophis.png";
import { useFirebase, firestore } from "../../components/FirebaseProvider";
import { HFHeader } from "../../components/AppHeaderNew";

function TopUpHistory(props) {

  const classes = useStyles();
  const { user } = useFirebase();

  const [list, loading] = useCollectionData(
    firestore.collection("transaksi").where("user_id", "==", user.uid)
  );

  return (
    <div className={classes.root}>
      <HFHeader title="logo" pgDesc={false} />
      <div className={classes.listBody}>
        <div className={classes.pageHeading}>
          <h2 className={classes.pageTitle}>
            Daftar Transaksi
          </h2>
        </div>
        <div className={classes.tableWrap}>
          <div className={classes.TableHead}>
            <p className={classes.tableCell1}>No. Trx</p>
            <p className={classes.tableCell}>Tanggal</p>
            <p className={`${classes.tableCell} ${classes.tableCellCoin}`}>
              Credit
            </p>
            <p className={classes.tableCell}>Status</p>
          </div>
          {loading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: 300 }}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <div className={classes.TableBody}>
              {list
                ? list
                  .sort((a, b) => {
                    return b.tanggal - a.tanggal;
                  })
                  .map((item) => {
                    const handleClick = () => {
                      // eslint-disable-next-line no-unused-expressions
                      window?.mixpanel?.track(
                        " credit->hostory - Bayar Sekarang Button Clicked",
                        {
                          userId: user?.uid,
                          tempUID: user?.tempUID,
                        }
                      );
                      window.open(item?.payment_data?.invoice_url, "_self");
                    };

                    if (item.status === "review") {
                      return null;
                    }
                    return (
                      <div
                        key={item.no_transaksi}
                        className={classes.TableRow}
                      >
                        <p className={`${classes.TRow} ${classes.TRowID}`}>
                          {item.no_transaksi}
                        </p>
                        <p className={`${classes.TRow} ${classes.TRowDate}`}>
                          {formatDate(item.tanggal)}
                        </p>
                        <p className={`${classes.TRow} ${classes.TRowCoin}`}>
                          <strong>{item.jumlah_coin}</strong>
                        </p>
                        <div className={classes.itemStatus}>
                          {item.status === "pending" ? (
                            <Button
                              onClick={handleClick}
                              className={classes.statusFalse}
                              color="primary"
                            >
                              Bayar Sekarang
                            </Button>
                          ) : null}
                          {item.status === "paid" ? (
                            <Typography
                              className={classes.statusTrue}
                              component="p"
                            >
                              Berhasil
                            </Typography>
                          ) : null}
                          {item.status === "review" ? (
                            <Typography
                              className={classes.statusTrue}
                              component="p"
                            >
                              Dalam Proses Admin
                            </Typography>
                          ) : null}
                          {item.status === "rejected" ? (
                            <Typography
                              style={{ color: "red" }}
                              className={classes.statusTrue}
                              component="p"
                            >
                              Ditolak
                            </Typography>
                          ) : null}
                        </div>
                      </div>
                    );
                  })
                : null}
              {list.length === 0 ? (
                <>
                  <img
                    alt=""
                    src={Notransaction}
                    style={{
                      marginTop: "20px",
                      width: "100px",
                      filter: "grayscale(100%)",
                      opacity: "40%"}}
                  />
                  <p style={{ color: "gray" }}>
                    Belum ada riwayat top-up
                  </p>
                </>
              ) : null}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TopUpHistory;
