import React, { useState } from "react";
//
import { HFHeader } from "../../components/AppHeaderNew";
import useStyles from "./styles";
import { useSiswa } from "../../components/SiswaProvider";
import BottomNav from "../../components/BottomNav";
import { functions, useFirebase } from "../../components/FirebaseProvider";
//
import { Backdrop, Button, CircularProgress, Grid, Hidden, Box } from "@material-ui/core";
//
// import html2canvas from 'html2canvas';

//
// import { dataURItoFile } from '../../utils/converter';
// import share from '../../utils/share';
//
import { useSnackbar } from "notistack";

// icon
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ShareIcon from "@material-ui/icons/Share";
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import icon from "../../images/illustration-horn.png";
import TopNav from "../../components/TopNav";
//
function AjakTeman(props) {
    const classes = useStyles();

    const { usersData } = useSiswa();
    const { user } = useFirebase();
    //const { profile } = useSiswa();
    const { enqueueSnackbar } = useSnackbar();

    // const resultJson = personaData?.result && JSON.parse(personaData?.result)

    // const urlDownload = resultJson?.urlReport && Object.values(resultJson?.urlReport);
    // console.log(usersData);
    const [loading, setLoading] = useState(false);
    const handleGetLinkRefCV = async () => {
        setLoading(true);
        try {
            const getReff = functions.httpsCallable("user-createRefLink");

            await getReff({
                uid: user?.uid,
                type: "cvmaker",
            })
            // eslint-disable-next-line no-unused-expressions
            window?.mixpanel?.track("Ajak Teman: get link cv Button Clicked", {
                userId: user?.uid,
                tempUID: user?.tempUID,
            })
            // console.log(response);
        } catch (e) {
            console.log(e.message);
        }
        setLoading(false);
    };

    // getReff quiz
    const handleGetLinkRefQuiz = async () => {
        setLoading(true);
        try {
            const getReff = functions.httpsCallable("user-createRefLink");

            await getReff({
                uid: user?.uid,
                type: "quizkarir",
            })
            // eslint-disable-next-line no-unused-expressions
            window?.mixpanel?.track("Ajak Teman: get link quiz karir Button Clicked", {
                userId: user?.uid,
                tempUID: user?.tempUID,
            })
            // console.log(response);
        } catch (e) {
            console.log(e.message);
        }
        setLoading(false);
    };

    // share
    const shareBody = (data, type) => async (e) => {
        // console.log(data, type);
        let desc = "";
        if (type === "cv") {
            desc = "smart CV";
        } else if (type === "quiz") {
            desc = "coba quiz karir";
        }
        if (data && type)
            try {
                // eslint-disable-next-line no-unused-expressions
                window?.mixpanel?.track(`Ajak Teman: share ${desc} Button Clicked`, {
                    userId: user?.uid,
                    tempUID: user?.tempUID,
                })
                // const canvas = await html2canvas(document.body, {
                //     allowTaint: false,
                //     useCORS: true,
                //     windowHeight: 900,
                //     windowWidth: 450,
                // });
                // console.log(canvas.toDataURL())
                // const file = dataURItoFile(canvas.toDataURL());
                //console.log(file);
                const shareData = {
                    // files: [file],
                    title: "Karir.ai",
                    text: `Yuk bikin ${desc} disini, gratis!\n\n `,
                    url: data,
                };
                // const response = await share(shareData);
                await navigator.share(shareData);
                //  console.log(response)
            } catch (e) {
                enqueueSnackbar(e.message, { variant: "error" });
            }
    };
    // const { user } = useFirebase();
    // const { enqueueSnackbar } = useSnackbar();
    // const params = useParams();

    // if (loading || load || loadsnap || loadKateg) {
    //     return (
    //         <Container maxWidth="xs">
    //             <CircularProgres style={{ marginTop: '90%' }} />
    //         </Container>
    //     )
    // }
    // console.log(navigator.share)

    return (
        <>

            <Backdrop style={{ zIndex: 1000 }} open={loading}>
                <CircularProgress color="secondary" />
            </Backdrop>
            <div className={classes.root}>
                <HFHeader title="logo" pgDesc={false} noBorder={true} />
                {/* <img
                    src={`https://auto.karir.ai/mtracking.gif?page_title=dashboard_ajakteman&email=${profile?.email}`} alt=''
                /> */}
                <div className={classes.content}>
                    <Hidden mdUp>
                        <Box style={{ marginBottom: '20px' }}><TopNav /></Box>
                    </Hidden>
                    <h2>Ajak teman!</h2>
                    <img src={icon} alt="" width="80px" />
                    
                    <p>
                        Untuk setiap teman yang mendaftar ke karir.ai dengan link referral milikmu,
                        kamu akan mendapatkan reward 10 kredit. Caranya gampang, cukup bagikan link referral unik kamu dibawah!
                    </p>
                    <div style={{ marginTop: 50 }} className={classes.cover}>
                        
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <div>
                                    <h4>Link Referral Smart CV Maker</h4>
                                    {usersData?.ref_link_cvmaker ? (
                                        <Grid container>
                                            <Grid item xs>
                                                <p style={{ backgroundColor: "rgba(242, 103, 34, 0.1)", padding: "10px", margin: 0, }} >
                                                    {usersData?.ref_link_cvmaker}
                                                </p>
                                            </Grid>
                                            <Grid item>
                                                {
                                                    navigator.share ? (
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disableElevation
                                                            style={{ height: "40px" }}
                                                            onClick={shareBody(
                                                                usersData?.ref_link_cvmaker,
                                                                "cv"
                                                            )}
                                                        >
                                                            <ShareIcon fontSize="small" />
                                                        </Button>
                                                    ) : (
                                                        // <IconButton
                                                        //     size="small"
                                                        //     onClick={shareBody(usersData?.ref_link_cvmaker, 'cv')}
                                                        // >
                                                        //     <ShareIcon fontSize="small" />
                                                        // </IconButton>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            disableElevation
                                                            style={{ height: "40px" }}
                                                            onClick={() => {
                                                                navigator.clipboard.writeText(usersData?.ref_link_cvmaker);
                                                                // eslint-disable-next-line no-unused-expressions
                                                                window?.mixpanel?.track("Ajak Teman: copy link cv Button Clicked", {
                                                                    userId: user?.uid,
                                                                    tempUID: user?.tempUID,
                                                                })
                                                                enqueueSnackbar('text disalin di clipboard', { variant: 'info' })
                                                            }}
                                                        >
                                                            <FileCopyIcon fontSize="small" />
                                                        </Button>
                                                    )
                                                    // <IconButton
                                                    //     onClick={() => {
                                                    //         navigator.clipboard.writeText(usersData?.ref_link_cvmaker);
                                                    //         enqueueSnackbar('text disalin di clipboard', { variant: 'info' })
                                                    //     }}
                                                    // >
                                                    //     <FileCopyIcon />
                                                    // </IconButton>
                                                }
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <Button
                                            // variant='outlined'
                                            // style={{ borderRadius: '5px' }}
                                            onClick={handleGetLinkRefCV}
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            size="large"
                                            disableElevation
                                        >
                                            Buat Link
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                            <div>
                            <h4>Link Referral Quiz Minat Karir</h4>
                            {usersData?.ref_link_quizkarir ? (
                                <Grid container>
                                    <Grid item xs>
                                        <p style={{ backgroundColor: "rgba(242, 103, 34, 0.1)", padding: "10px", margin: 0, }} >
                                            {usersData?.ref_link_quizkarir}
                                        </p>
                                    </Grid>
                                    <Grid item>
                                        {
                                            navigator.share ? (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disableElevation
                                                    style={{ height: "40px" }}
                                                    onClick={shareBody(usersData?.ref_link_quizkarir, "quiz")}
                                                >
                                                    <ShareIcon fontSize="small" />
                                                </Button>
                                            ) : (
                                                // <IconButton

                                                //     onClick={() => {
                                                //         navigator.clipboard.writeText(usersData?.ref_link_quizkarir);
                                                //         enqueueSnackbar('text disalin di clipboard', { variant: 'info' })
                                                //     }}
                                                // >
                                                //     <FileCopyIcon />
                                                // </IconButton>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disableElevation
                                                    style={{ height: "40px" }}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(usersData?.ref_link_quizkarir);
                                                        // eslint-disable-next-line no-unused-expressions
                                                        window?.mixpanel?.track("Ajak Teman: copy link quiz karir Button Clicked", {
                                                            userId: user?.uid,
                                                            tempUID: user?.tempUID,
                                                        })
                                                        enqueueSnackbar('text disalin di clipboard', { variant: 'info' })
                                                    }}
                                                >
                                                    <FileCopyIcon fontSize="small" />
                                                </Button>
                                            )
                                            // <IconButton
                                            //     size="small"
                                            //     onClick={shareBody(usersData?.ref_link_quizkarir, 'quiz')}
                                            // >
                                            //     <ShareIcon fontSize="small" />
                                            // </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                            ) : (
                                // <div className={classes.item1} >
                                //         {
                                //             navigator.share ?
                                //                 <IconButton
                                //                     size="small"
                                //                     onClick={shareBody(usersData?.ref_link_quizkarir, 'quiz')}
                                //                 >
                                //                     <ShareIcon fontSize="small" />
                                //                 </IconButton> :
                                //                 <IconButton
                                //                     onClick={() => {
                                //                         navigator.clipboard.writeText(usersData?.ref_link_quizkarir);
                                //                         enqueueSnackbar('text disalin di clipboard', { variant: 'info' })
                                //                     }}
                                //                 >
                                //                     <FileCopyIcon />
                                //                 </IconButton>
                                //         }
                                //     </p>

                                // </div>
                                <Button
                                    // variant='outlined'
                                    // style={{ borderRadius: '5px' }}
                                    disableElevation
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                    size="large"
                                    onClick={handleGetLinkRefQuiz}
                                >
                                    Buat Link
                                </Button>
                            )}
                        </div>
                            </Grid>
                        </Grid>
                        
                        {/* 
                        <div className={classes.item2}>
                            <p style={{ fontSize: '20px', color: '#FFA500' }}>Kelebihan</p>
                            <ul>
                                {
                                    resultJson?.uraian?.kelebihan?.map((res) => {
                                        return <li>{res}</li>
                                    })
                                }
                            </ul>
                            <p></p>
                        </div>
                        <div className={classes.item3}>
                            <p style={{ fontSize: '20px', color: '#357EC7' }}>Area Pengembangan</p>
                            <ul>
                                {
                                    resultJson?.uraian?.area_pengembangan?.map((res) => {
                                        return <li>{res}</li>
                                    })
                                }
                            </ul>
                            <p></p>
                        </div>
                        <div className={classes.item4}>
                            <p style={{ fontSize: '20px', color: '#E7A1B0' }}>Karakteristik Pekrjaan</p>
                            <ul>
                                {
                                    resultJson?.uraian?.karakteristik_pekerjaan?.map((res) => {
                                        return <li>{res}</li>
                                    })
                                }
                            </ul>
                            <p></p>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <Button
                                onClick={() => {
                                    window.open(`${urlDownload?.[0]?.FullTPA}`)
                                }}
                            >
                                <CloudDownloadIcon style={{ marginRight: '8px' }} />
                                Download Full PDF
                            </Button>
                        </div> */}
                    </div>
                </div>
            </div>
            <Hidden mdUp><BottomNav /></Hidden>
        </>
    );
}

export default AjakTeman;
