import GlacialReg from './fonts/glacialindifference-regular-webfont.woff2';
import GlacialItc from './fonts/glacialindifference-italic-webfont.woff2';
import GlacialBd from './fonts/glacialindifference-bold-webfont.woff2';

const glacialReg = {
  fontFamily: 'Glacial Indifference',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    url(${GlacialReg}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const glacialItc = {
  fontFamily: 'Glacial Indifference',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    url(${GlacialItc}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
const glacialBd = {
  fontFamily: 'Glacial Indifference',
  fontStyle: 'bold',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${GlacialBd}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default {
  direction: "ltr",

  palette: {
    primary: {
      light: "#F8A880",
      main: "#F26722",
      dark: "#DF530D",
      contrastText: "#fff",
    },
    secondary: {
      light: "#F8CB9D",
      main: "#F2A254",
      dark: "#EF8C29",
      contrastText: "#FFF",
    },
    tertiary: {
      light: "#868689",
      main: "#2E2E2F",
      dark: "#272728",
      contrastText: "#FFF",
    },
    neutrals: {
      n100: "#25324B",
      n80: "#515B6F",
      n60: "#7C8493",
      n40: "#A8ADB7",
      n20: "#E4E5E7",
      n10: "#F9FAFC",
      n0: "#F8F8FD",
      nf: "#FFF",
      border: "#D6DDEB",
    },
    error: {
      light: "#FFA79C",
      main: "#FF6550",
      dark: "#FF3B22",
    },
    warning: {
      light: "#FFD78D",
      main: "#FFB836",
      dark: "#FFAA0C",
    },
    info: {
      light: "#82CBFF",
      main: "#26A4FF",
      dark: "#0091F9",
    },
    info2: {
      light: "#B4A5FF",
      main: "#7B61FF",
      dark: "#512FFF",
    },
    success: {
      light: "#9FE2D0",
      main: "#56CDAD",
      dark: "#39C09C",
    },
    textColor: {
      gray: "#777",
      gray2: "#ddd",
      gray3: "#eee",
      gray4: "#f2f4f4",
      gray5: "#0000008a",
      gray6: "#999999",
      gray7: "#e5e5e5",
      gray8: "#4f4f4f",
      gray9: "#BBB",
      gray10: '#c4c4c4',
      gray11: '#F2F4F3',
      gray12: '#130F26',
      gray13: '#cfcfcf',
      green: "#19b821",
      white: "#FFF",
      blue: "#2757ca",
      blue2: "#32bdea",
      green2: "#1CBA34",
      darkGray: "#222222",
      green3: '#11A030',
      yellow: '#FDED1F',
      otherBlue: '#33BDEB',
    },
    danger: {
      main: "#ff0000",
    },
    lightBlue: {
      main: "#F26722",
      secondary: "#A5E8FF",
    },
    darkBlue: {
      main: "#1461A8",
    },
    orange: {
      main: "#FFBB38",
    },
    red: {
      main: "#D72020",
    },
    brown: {
      main: "#C8894F",
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [glacialReg, glacialItc, glacialBd],
      },
    },
  },

  typography: {
    fontFamily: "'Glacial Indifference'",
  },

  shape: {
    borderRadius: 0,
  },
};
