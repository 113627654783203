import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: 60,
  },
  content: {
    width: "100%",
    // maxWidth: "630px",
    padding: "0px 16px",
  },
  pageTitle: {
    color: theme.palette.neutrals.n80,
    fontWeight: "bold",
    fontSize: 20,
    margin: "20px 0px",
  },
  pageDesc: {
    color: theme.palette.neutrals.n80,
    fontSize: 16,
  },
  credit: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  textRp: {
    margin: "auto",
    width: "fit-content",
    backgroundColor: theme.palette.warning.main,
    borderRadius: "8px",
    marginBottom: "5px",
    padding: "5px 15px",
    fontSize: "20px",
    color: theme.palette.neutrals.nf,
    fontWeight: "bold",
  },
  pageSubtitle: {
    margin: 0,
    fontSize: 12,
    color: theme.palette.neutrals.n40,
  }

}))

export default useStyles;