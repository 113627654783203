// React
import React from "react";

// Styles
import useStyles from "./styles/hasilTest";

// Component
// import AppHeader from '../../components/AppHeader';

import Container from "@material-ui/core/Container";
// Images

import { baseUrl } from "../../../images/s3";

// Expansion
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Typography
import Typography from "@material-ui/core/Typography";
import results from "./data/results.json";

import { Redirect, Link } from "react-router-dom";
import { Button } from "@material-ui/core";

// Firebase
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useFirebase } from "../../../components/FirebaseProvider";

function HasilTest(props) {
  const classes = useStyles();
  const { match, location } = props;
  const { symbol } = match.params;

  const { firestore } = useFirebase();
  const [banner] = useDocumentData(firestore.doc(`events/banner`));

  if (!symbol || !Object.keys(results).includes(symbol.toUpperCase())) {
    return <Redirect to="/" />;
  }
  const ucSymbol = symbol.toUpperCase();
  const result = results[ucSymbol];

  const nama = location.state && location.state.nama ? location.state.nama : "";

  return (
    <Container className={classes.container} maxWidth="lg" component="main">
      <div className={classes.root}>
        <div
          data-html2canvas-ignore
          className={classes.hasilTestHead}
          style={{
            margin: "10px 0px",
            display: "grid",
            placeContent: "center",
          }}
        >
          <Link to={`/acara/u/${banner?.target_event?.id}`}>
            <img
              src={banner && banner.photoUrl}
              alt=""
              crossOrigin="anonymous"
              width="300px"
            />
          </Link>
        </div>
        <div className={classes.hasilTestHead}>
          <img src={`${baseUrl}new-logo-w-tmb.png`} alt="" className={classes.logo} />
        </div>
        <div className={classes.hasilTestBody}>
          {nama && (
            <>
              <p className={classes.whiteText}>Hasil tes minat bakat untuk:</p>
              <h3 className={classes.announcePerson}>{nama}</h3>
            </>
          )}
          <div className={classes.resultImgWrap}>
            <img
              src={`${baseUrl}${symbol}.png`}
              alt=""
              className={classes.resultImg}
            />
          </div>
          <h4 className={classes.resultTitle}>{result.symbol}</h4>
          <p className={classes.resultSubtitle}>({result.short})</p>
          <div className={classes.resultExp}>
            <p className={classes.whiteText}>Kamu adalah calon:</p>
            <p className={classes.blackText}>{result.profession}</p>
          </div>
          <div className={classes.figureExp}>
            <p className={classes.whiteText}>
              Tokoh sukses yang mirip kepribadian kamu:
            </p>
            <p className={classes.blackText}>{result.tokoh}</p>
          </div>
          <div className={classes.detailWrap}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  Penjelasan Karakter Kamu
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  component="ol"
                  className={classes.panelDetailList}
                  dangerouslySetInnerHTML={{ __html: result.description }}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Saran Pengembangan
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.panelDetail}>
                  {result.improvement}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Profesi yang Cocok Buat Kamu
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  className={`${classes.panelDetail} ${classes.panelDetailCapital}`}
                >
                  {result.profession}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Jurusan Kuliah yang Cocok Buat Kamu
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.panelDetail}>
                  {result.jurusan}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Tipe Kepribadian Orang Lain yang Cocok Denganmu
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.panelDetail}>
                  {result.partner}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  Contoh Orang Sukses Dengan Kepribadian yang Sama
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className={classes.panelDetail}>
                  {result.tokoh}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={classes.whiteText}>
            <p>
              Klik tombol diatas untuk tahu lebih dalam tentang karakter kamu,
              jurusan yang cocok, pekerjaan yang cocok, teman yang cocok, dan
              lainnya
            </p>
            <div
              data-html2canvas-ignore
              className={classes.hasilTestHead}
              style={{
                margin: "10px 0px",
                display: "grid",
                placeContent: "center",
              }}
            >
              <Link
                to={`/acara/u/${
                  banner &&
                  banner.tmb_target_event &&
                  banner.tmb_target_event.id
                }`}
              >
                <img
                  src={banner && banner.photoUrl}
                  alt=""
                  crossOrigin="anonymous"
                  width="300px"
                />
              </Link>
            </div>
            <Button
              className={classes.startBtn}
              variant="contained"
              color="secondary"
              component={Link}
              to="/home"
            >
              Kembali Ke Home
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default HasilTest;
