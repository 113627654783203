import React, { useContext } from "react";

import { useFirebase } from "./FirebaseProvider";
import {
  useDocumentData,
  useCollectionData,
} from "react-firebase-hooks/firestore";
import AppLoading from "./AppLoading";

const CoinContext = React.createContext();

export function useCoin() {
  return useContext(CoinContext);
}

function CoinProvider(props) {
  //
  const { user, firestore } = useFirebase();
  const coindoc = firestore.doc(`koin_user/${user.uid}`);
  const promoCol = firestore.collection("promo_koin");
  const [Coin, loadingcoin] = useDocumentData(coindoc);
  const [promo, loadingPromo] = useCollectionData(promoCol, { idField: "id" });
  const [misi, loadingMisi] = useCollectionData(
    firestore.collection("misi").where("kategori", "==", "misi"),
    { idField: "id" }
  );
  const [listMisi, loadingList] = useCollectionData(
    firestore.collection("misi").where("user_id", "==", user.uid),
    { idField: "id" }
  );
  const [misiDefault, loadingMisiDefault] = useDocumentData(
    firestore.collection("misi").doc("default")
  );
  //
  if (
    loadingcoin ||
    loadingPromo ||
    loadingMisi ||
    loadingList ||
    loadingMisiDefault
  ) {
    return <AppLoading />;
  }
  //
  return (
    <CoinContext.Provider
      value={{
        Coin,
        loadingcoin,
        promo,
        loadingPromo,
        misi,
        loadingMisi,
        listMisi,
        loadingList,
        misiDefault,
      }}
    >
      {props.children}
    </CoinContext.Provider>
  );
}

export default CoinProvider;
