// React
import React from "react";

// Router
import { Redirect } from "react-router-dom";

// Styles
// import useStyles from "./styles";

// Input
// import Button from "@material-ui/core/Button";

// Component's
import AppLoading from "../../components/AppLoading";
import { useFirebase } from "../../components/FirebaseProvider";

// import logo from "../../images/logo-full-w.svg";

function Landing() {
  // UseStyles
  // const classes = useStyles();
  //
  const { user, loading } = useFirebase();
  //
  if (user) {
    return <Redirect to="/lengkapiData" />;
  }
  //
  if (loading) {
    return <AppLoading />;
  }
  //
  return (
    <Redirect to="/login" />
    // <div className={classes.root}>
    //   <div className={classes.container1}>
    //     <div className={classes.container1a}>
    //       <p className={classes.txt1}>Halo..</p>
    //       <p className={classes.txt2}>Selamat Datang di</p>
    //       <img className={classes.img} src={logo} alt="logo" />
    //       {/* <p className={classes.txt3}>"Solusi pintar untuk belajar"</p>
    //       <p className={classes.txt4}>
    //         Aplikasi dengan sejuta fitur untuk membantu kamu belajar dan masuk
    //         PTN impian
    //       </p> */}
    //     </div>
    //     <Button
    //       variant="text"
    //       className={classes.btn}
    //       component={Link}
    //       to="/login"
    //       value="/login"
    //       endIcon={<span style={{ fontSize: 26.67 }}>{">"}</span>}
    //     >
    //       Next
    //     </Button>
    //   </div>
    //   {/* <div className={classes.container2}>
    //   </div> */}
    // </div>
  );
}

export default Landing;
