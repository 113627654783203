import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './styles/index';

// import clsx from 'clsx';

export default function PetaSoal({ open, handleClose, jawaban, orderIds, goToIndex }) {


    const [fullWidth] = React.useState(true);
    const [maxWidth] = React.useState('sm');
    const classes = useStyles();
    return <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle className={classes.mapTitle} id="alert-dialog-title">
            {"Peta Soal"} 
                <Button style={{ position: 'absolute', top: 10, right: -16, width: 15, height: 10 }}  onClick={handleClose} >
                    <CloseIcon style={{ color: '#fff' }} />
                </Button>
            </DialogTitle>
        <DialogContent>

            <div className={classes.numWrap}>
                {orderIds.map((id, index) => {
                    const activeJawaban = jawaban.find(item => item.id === id) || {};
                    return <Button
                        onClick={() => {
                            goToIndex(index);
                            handleClose();
                        }}
                        classes={{ label: classes.numBoxLabel }} key={id} className={`${activeJawaban.jawaban && classes.numBoxChecked} ${classes.numBox}`}>
                        <span className={classes.userNum}>{index + 1}</span>
                        <span className={classes.userChoice}>{activeJawaban.jawaban}</span>
                    </Button>
                })}


            </div>
            <div className={classes.numLegend}>
                <div className={classes.indicatorWrap}>
                    <span className={classes.indicatorChecked}></span>
                    <span className={classes.indicatorLabel}>Sudah dijawab</span>
                </div>
                <div className={classes.indicatorWrap}>
                    <span className={classes.indicatorUnchecked}></span>
                    <span className={classes.indicatorLabel}>Belum dijawab</span>
                </div>
            </div>

        </DialogContent>
        <DialogActions>
            <Button style={{ alignSelf: 'center', margin: '10px auto 20px', width: 106, height: 27, padding: 0, }} variant='contained' onClick={handleClose} color="primary" autoFocus>
                Tutup
            </Button>
        </DialogActions>
    </Dialog>
}