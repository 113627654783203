
import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { CheckCircleOutline, } from '@material-ui/icons';

export default function Seventh({ form, handleChange, error, setForm, setError }) {
    const handleRadioChange = (event) => {
        setForm({
            ...form,
            cari_kerja: event.target.value

        });

        setError({
            ...error,
            cari_kerja: ''
        });
    };
    return (
        <>
            <div style={{ textAlign: 'left' }}>
                <Typography style={{ marginBottom: '10px' }}>Apakah {form?.nama_panggilan} sedang mencari pekerjaan?*</Typography>
                <Typography variant='subtitle2'>Jika {form?.nama_panggilan} adalah fresh graduate yang belum pernah bekerja full-time sebelumnya, silahkan pilih none.</Typography>
                <FormControl style={{ marginTop: '20px', marginLeft: '20px' }} component="fieldset" error={error.cari_kerja ? true : false}
                // className={classes.formControl}
                >
                    <RadioGroup aria-label="quiz" name="quiz" value={form?.cari_kerja} onChange={handleRadioChange}>
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Iya, aktif mencari pekerjaan baru" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Iya, aktif mencari pekerjaan baru" />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Tidak, karena saya masih pelajar" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Tidak, karena saya masih pelajar " />
                        <FormControlLabel style={{ border: '1px solid', borderRadius: '7px', marginBottom: '3px', paddingRight: '5px' }} value="Tidak, karena alasan tertentu" control={<Radio checkedIcon={<CheckCircleOutline color='primary' />} />} label="Tidak, karena alasan tertentu" />
                    </RadioGroup>
                    {/* <FormHelperText>{helperText}</FormHelperText> */}

                </FormControl>
                <Typography variant='subtitle2' color='error'>{error.cari_kerja && error.cari_kerja}</Typography>

            </div>
        </>
    )
}

