import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    containerBox:{
      "@media(max-width: 959px)": {
        justifyContent:'flex-end',
      },
    },
    profilee: {
        backgroundColor: theme.palette.primary.main,
        padding: "7px",
        // borderRadius: "8px",
        width: '100%'
      },
      profileBox:{
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        justifyContent: 'flex-end'
      },
      profileDesc:{

      },
      profileImg: {
        width: "54px !important",
        height: "54px !important",
        // marginRight: "16px",
        pointerEvents: "none",
      },
      profileName: {
        fontSize: "16px",
        textAlign: "left",
        color: theme.palette.neutrals.nf,
        fontWeight: "bold",
        margin: '0 10px',
        width: '90px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        "@media(max-width: 959px)": {
          width: 'fit-content',
        },
      },
      topNavBox:{
        display: 'flex',
        justifyContent:'space-between',
        width: '100%',
        height: '100%',
        alignItems:'center',
      },
      topNav:{
        listStyle: 'none',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        // width: '80%',
        margin: 0,
        padding: 0,
        "@media(max-width: 1111px)": {
          width: '100%',
        },
      },
      topNavItem:{
        margin: '0 7px'
      },
      topNavLink:{
        color: '#fff',
        display: 'flex',
        
      },
      topNavText:{
        fontSize: '14px !important',
        "@media(max-width: 1031px)": {
          fontSize: '12px !important'
        },
      },
      topNavIcon:{
        marginRight: '7px',
        fontSize: '1rem'
      },
      credit: {
        marginRight: "5px",
        paddingTop: "2px",
        paddingBottom: "2px",
        backgroundColor: theme.palette.neutrals.nf,
        borderRadius: "100px",
        
        color: theme.palette.primary.main,
        fontWeight: "bold",
        "&:hover": {
          backgroundColor: theme.palette.neutrals.n10,
        },
      },
      myCredit:{
        fontSize: "14px",
        
        
      }
}))

export default useStyles;