import React, { useState } from "react";

import { Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { useSnackbar } from "notistack";

import useStyles from "./styles";
import { withStyles } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { useFirebase } from "../../components/FirebaseProvider";
import AppHeader from "../../components/AppHeader";

const CustomTextField = withStyles({
  root: {
    backgroundColor: "#fff",
    borderRadius: 4,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
      "&:hover fieldset": {
        boxShadow: "0px 2px 15px -3px rgba(0, 0, 0, 0.1)",
      },
    },
  },
})(TextField);

function LupaPassword() {
  // Firebase Use
  const { auth, loading } = useFirebase();

  // UseStyles
  const classes = useStyles();

  // Form
  const [form, setForm] = useState({
    email: "",
  });

  // Error
  const [error, setError] = useState({
    email: "",
  });

  // Snackbar
  const { enqueueSnackbar } = useSnackbar();

  // On change
  const handleChange = (e) => {
    if (e.target.value === "") {
      setSubmitting(true);
    } else {
      setSubmitting(false);
    }

    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });

    setError({
      ...error,
      [e.target.name]: "",
    });
  };

  // Submit Button
  const [isSubmitting, setSubmitting] = useState(true);

  // Validator error
  const validate = () => {
    const newError = { ...error };
    const { email } = form;

    if (!email) {
      newError.email = "Email wajib di isi";
    } else if (!isEmail(email)) {
      newError.email = "Email tidak valid";
    }
    return newError;
  };

  // OnSubmit
  const handleSubmit = async (e) => {
    e.preventDefault();
    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      try {
        setSubmitting(true);
        // Action
        const actionCodeSettings = {
          url: `${window.location.origin}/login`,
        };
        await auth.sendPasswordResetEmail(form.email, actionCodeSettings);

        // Show Notification
        enqueueSnackbar(
          `Cek Email ${form.email} , Tautan Reset Password Telah Dikirim.`,
          { variant: "success" }
        );

        setSubmitting(false);
      } catch (e) {
        const newError = {};

        switch (e.code) {
          case "auth/user-not-found":
            newError.email = "Alamat Email Tidak Terdaftar";
            break;
          case "auth/invalid-email":
            newError.email = "Almat Email Tidak Valid";
            break;
          case "auth/user-disabled":
            newError.email = "User Di Blokir";
            break;
          default:
            newError.email = "Terjadi Kesalahan Silahkan Coba Lagi";
            break;
        }
        setError(newError);
      }
    }
  };

  // Condition Loading
  if (loading) {
    // console.log("oke");
  }

  return (
    <div className={classes.root}>
      <AppHeader />
      <div className={classes.pageHeading}>
        <h2 className={classes.pageTitle}>Lupa Kata Sandi</h2>
        <p className={classes.pageSubtitle}>
          Kirim email untuk setting kata sandi baru
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className={classes.formRow}>
          <CustomTextField
            fullWidth
            variant="outlined"
            size="small"
            id="email"
            name="email"
            placeholder="Email"
            value={form.email}
            onChange={handleChange}
          />
          {error?.email && <p className={classes.error}>{error?.email}</p>}
        </div>
        <div className={classes.formRowBtn}>
          <div className={classes.otherReg}>
            <Button
              type="submit"
              className={`${classes.btnKirim} ${classes.regBtns}`}
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              Kirim
            </Button>
            <Button
              className={`${classes.loginBtn} ${classes.regBtns}`}
              variant="outlined"
              color="primary"
              component={Link}
              to="/login"
            >
              Masuk
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default LupaPassword;
